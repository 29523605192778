import vendor1 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/vendor1.png';
import vendor2 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/vendor2.png';
import vendor3 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/vendor3.png';
import vendor4 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/vendor4.png';
import vendor5 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/vendor5.png';
import vendor6 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList3/image 6.png';
export const DummyVendorsList3 = [
  {
    image: vendor1,
    name: 'Carbon Tiles',
    type: 'Carbon Craft',
    desc: 'Eco-friendly designer tiles by Upcycling carbon',
  },
  {
    image: vendor2,
    name: 'Urban Plantscaping',
    type: 'Natura India',
    desc: 'Transforming the urban landscape from grey to green. ',
  },
  {
    image: vendor3,
    name: 'Smartest Energy Saver',
    type: 'Minion Labs',
    desc: 'Plug & play devices that do not need any device level sensors',
  },
  {
    image: vendor4,
    name: 'Smart Energy',
    type: 'Bluesinq',
    desc: 'Control and monitor your energy consumption over WiFi and save energy.',
  },
  {
    image: vendor5,
    name: 'Eco-friendly Bricks',
    type: 'Angrius',
    desc: 'Bricks and Paver blocks made from recycled plastic that are Waterproof and Lightweight.',
  },
  {
    image: vendor6,
    name: 'Porotherm Bricks',
    type: 'Wienerberger',
    desc: 'Load & Non-load bearing clay hollow bricks that provide better efficiency & durability.',
  },
];
