import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
  root: {
    color: '#ffffff',
    background: 'linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)',
    borderRadius: '40px',
    '&:focus': {
      outline: 'none',
    },
  },
})(Button);

function WhitepaperCard(props) {


  const requested = sessionStorage.getItem('WHITE_PAPER');
  return (
    <div>
      <Paper style={{ background: 'transparent', marginRight: '20px' }} elevation={0}>
        <div data-aos="fade-right">
          <img src={props.coverpic} alt="..." width="230px" />
        </div>
        <Grid container item xs={12} style={{ paddingTop: '5%', textAlign: 'center' }}>
          <Grid item xs={2} />
          <Grid item xs={8} style={{ textAlign: 'center' }}>
            {requested ? (
              <div data-aos="fade-left">
                <StyledButton onClick={props.download}>download</StyledButton>
              </div>
            ) : (
              <div data-aos="fade-left">
                <StyledButton onClick={props.onModalOpen} size="large">
                  <Typography style={{ fontSize: '10px' }}> get the white paper</Typography>
                </StyledButton>
              </div>
            )}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Paper>
    </div>
  );
}

export default WhitepaperCard;
