import React from 'react';
import { Container, Grid } from '@material-ui/core';
import useScreenType from '../../../../../hooks/checkScreenType';
import ImpactProductsGroupDesktop from './components/ImpactProductsGroupDesktop';
import ImpactProductsGroupMobile from './components/ImpactProductsGroupMobile';
import resourceimage from '../../../../../../assets/Marketplace/resourceimage.svg';
import SectionHeader from '../../../common/SectionHeader';
import ResourceCardLong from '../../../common/ResourceCard/ResourceCardLong';
import { MarketplaceStyles } from '../../../config';
import useImpactProductsSpotlight from '../../../hooks/useImpactProductsSpotlightData';

const { sectionPadding } = MarketplaceStyles;
const MarketplaceImpactProductsSpotlight = (props) => {
  const { isMobile, isdesktop } = useScreenType();

  // data from grpahql request
  const { imapctProductsData } = useImpactProductsSpotlight();

  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          paddingBottom: !isMobile ? sectionPadding.paddingBottom : '3%',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <SectionHeader Title="Top Sustainable Products with High Return on Investment " Description="" />
        </Grid>
        <Grid container justifyContent="center" spacing={2} style={{ marginTop: '3%', paddingLeft: '10%', paddingRight: '10%' }}>
          {/* {!isMobile && (
            <Grid xs={3} sm={3} md={4} lg={isdesktop ? 3 : 4} xl={3} item container>
              <ResourceCardLong sourceImage={resourceimage} text="Guide" longText="Make your next Real Estate Project 100% Self-sustaining" />
            </Grid>
          )} */}
          {!isMobile && <ImpactProductsGroupDesktop isMobile={isMobile} impactProducts={imapctProductsData?.attributes?.products?.data} />}
        </Grid>
      </Container>
      {isMobile && <ImpactProductsGroupMobile isMobile={isMobile} impactProducts={imapctProductsData?.attributes?.products?.data} />}
    </>
  );
};

export default MarketplaceImpactProductsSpotlight;
