import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Home5BigImage from '../../../../assets/bigSVGs/Home5BigImage.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  title: {
    lineHeight: '40px',
    letterSpacing: '1px',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  button: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '70%',
    height: '6vh',
    marginTop: '8%',
  },
  buttonText: {
    color: '#FFFFFF',
    WebkitTextFillColor: '#FFFFFF',
  },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo2: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  typo3: {
    lineHeight: '30px',
  },
}));

function Home5(props) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
          <div data-aos="fade-right">
            <img src={Home5BigImage} style={{ width: '96%' }} alt="homepageBigThree" />
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <div data-aos="fade-left">
            <Typography className={classes.title} variant="h3">
              Building Certification
              <br />
              <br />
            </Typography>
          </div>
          <div data-aos="fade-right">
            <Typography className={classes.typo3} variant="h5">
              Instantly check feasibility of different <br /> building certifications at any time of <br />
              project design.
              <br />
              <br />
              Make informed decisions to pursue the <br /> certification based on cost-benefit <br />
              analysis of implementation.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default Home5;
