import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ProductDetailsAddToInterventionWithLogin from './ProductDetailsAddToInterventionWithLogin';
import UseInterventionMappingsDataInProductDetails from '../hooks/UseInterventionMappingsDataInProductDetails';
import ProductDetailsAddToInterventionWithoutLogin from './ProductDetailsAddToInterventionWithoutLogin';
import axios from '../../../../../../config/Axios';
import { useParams } from 'react-router-dom';
import ProductDetailsAddToInterventionSuccess from './ProductDetailsAddToInterventionSuccess';
import ProductDetailsAddToInterventionFailure from './ProductDetailsAddToInterventionFailure';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4, 4),
    borderRadius: '15px',
  },
}));

export default function ProductDetailsAddToInterventions({ showAddToIntervention, handleShowModalForAddToIntervention, productDetailsData }) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { iid } = useParams();
  const projectInterventionMapping = query.get('mappingID');
  const { interventionMappingData, ProjectName, InterventionName } = UseInterventionMappingsDataInProductDetails();

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [mappingData, setMappingData] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  useEffect(() => {
    if (interventionMappingData) setMappingData(interventionMappingData);
  }, [interventionMappingData]);

  const handleCloseModal = (success, failure) => {
    handleShowModalForAddToIntervention();
    if (success) setShowSuccessModal(true);
    if (failure) setShowFailureModal(true);
  };

  const handleAddToShortlistedProduct = () => {
    const { shortlistedProducts } = mappingData && mappingData;
    if (shortlistedProducts && shortlistedProducts.includes(iid)) handleCloseModal(false, true);
    else {
      let body = { shortlistedProducts: [...shortlistedProducts, productDetailsData?.attributes?.identifier] };
      axios.put(`ProjectInterventionMapping/intervention/${interventionMappingData?._id}?key=addShortlistedProducts`, body).then((res) => {
        handleCloseModal(true, false);
        axios.get(`ProjectInterventionMapping/${projectInterventionMapping}`, body).then((res) => {
          let data = res?.data?.data?.data[0];
          setMappingData(data);
        });
      });
    }
  };

  const [interventionFromLogin, setInterventionFromLogin] = useState(null);
  return (
    <div>
      <Modal
        open={showAddToIntervention}
        onClose={handleShowModalForAddToIntervention}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {projectInterventionMapping ? (
          <ProductDetailsAddToInterventionWithLogin
            className={classes.paper}
            style={modalStyle}
            project={ProjectName && ProjectName}
            intervention={InterventionName && InterventionName}
            handleAddToShortlistedProduct={handleAddToShortlistedProduct}
            onClose={handleShowModalForAddToIntervention}
          />
        ) : (
          <ProductDetailsAddToInterventionWithoutLogin
            className={classes.paper}
            style={modalStyle}
            onClose={handleShowModalForAddToIntervention}
            setShowSuccessModal={() => setShowSuccessModal(true)}
            setShowFailureModal={() => setShowFailureModal(true)}
            setInterventionFromLogin={(q) => setInterventionFromLogin(q)}
            productDetailsData={productDetailsData && productDetailsData}
          />
        )}
      </Modal>
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ProductDetailsAddToInterventionSuccess
          className={classes.paper}
          style={modalStyle}
          intervention={interventionFromLogin ? interventionFromLogin : InterventionName && InterventionName}
          onClose={() => setShowSuccessModal(false)}
        />
      </Modal>
      <Modal
        open={showFailureModal}
        onClose={() => setShowFailureModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ProductDetailsAddToInterventionFailure
          className={classes.paper}
          style={modalStyle}
          intervention={interventionFromLogin ? interventionFromLogin : InterventionName && InterventionName}
          onClose={() => setShowFailureModal(false)}
        />
      </Modal>
    </div>
  );
}
