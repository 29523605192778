import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Links from '../../../../common/Typography/Links';
import { MarketplaceTypography } from '../../../../config';

const TextWithPoints = ({ data, classname, image, imageindex, justifyContent, imageStyle }) => {
  return (
    <Grid container>
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
        {data.map((ele, index) => {
          return (
            <>
              <Typography className={classname} style={{ textAlign: 'justify' }}>
                {index + 1}. <span style={{ fontWeight: 'bold' }}>{ele.split(':')[0]}:</span>
                {ele.split(':')[1]}
              </Typography>
              {image && imageindex === index && (
                <Grid xs={12} item container alignItems="flex-start" justifyContent={justifyContent ? justifyContent : 'center'}>
                  <img src={image} alt="rrr" style={imageStyle} />
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TextWithPoints;
