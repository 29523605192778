import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Grid } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import { lastIndexOf } from 'lodash';
import { DrawerItems } from './MarketplaceNavigatorMobile';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  labelfont: {
    textDecoration: 'none !important',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: MarketplaceColors.Secondary,
  },
  selected: {
    backgroundColor: '#FFFFFF',
    color: MarketplaceColors.Secondary,
    '&:focus, &:active, &:hover': {
      textDecoration: 'none',
      color: MarketplaceColors.Secondary,
      backgroundColor: '#FFFFFF',
    },
  },
}));

const DrawerNavigationList = (props) => {
  const { URLS } = useContext(ProductsPageContext);
  const { page, pagesize } = URLS;

  const classes = useStyles();
  let history = useHistory();
  const { drawerData, handleSelected, selected, setOpen } = props;

  return (
    <Grid item xs={12}>
      <List>
        <ListItem button className={selected === DrawerItems.products ? classes.selected : classes.labelfont}>
          <ListItemText primary="Products" onClick={() => handleSelected(DrawerItems.products)} />
        </ListItem>
        <ListItem button className={selected === DrawerItems.services ? classes.selected : classes.labelfont}>
          <ListItemText primary="Services" onClick={() => handleSelected(DrawerItems.services)} />
        </ListItem>
        <ListItem button className={selected === DrawerItems.certifications ? classes.selected : classes.labelfont}>
          <ListItemText primary="Certifications" onClick={() => handleSelected(DrawerItems.certifications)} />
        </ListItem>
        {drawerData &&
          drawerData.map(({ attributes }, index) => (
            <ListItem button key={lastIndexOf} className={selected === DrawerItems.supercategories ? classes.selected : classes.labelfont}>
              <ListItemText
                primary={attributes?.Name}
                onClick={() => {
                  handleSelected(DrawerItems.supercategories);
                  history.push(
                    `/marketplace/categoryDetails?source=${NavigatorSource.supercategories}&supercategory=${attributes?.Name}&page=${page}&pagesize=${pagesize}`
                  );
                  setOpen(false);
                }}
              />
            </ListItem>
          ))}
      </List>
    </Grid>
  );
};

export default DrawerNavigationList;
