import React from 'react';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import Steppercard from './components/StepperCard';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import { ResourcesData, types } from '../MaterialSelectionGuideData';
import ImageWithSource from './components/ImageWithSource';
import ScrollableTable from './components/ScrollableTable';

const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

function createData(name, a, b, c, d, e) {
  return { name, a, b, c, d, e };
}
const rows = [
  createData('Default Flooring Type', `Vitrified`, `Vitrified `, `Ceramic`, `Vitrified`, `Vitrified`),
  createData('Finish', 'Glazed', 'Glazed', 'Glazed', 'Glazed', 'Glazed'),
  createData('Durability (years)', '10-15', '10-15', `10-15`, `10-15`, `10-15`),
  createData('Slip resistance', 'Moderate-High', 'Moderate-High', 'High', `Moderate-High`, `High`),
  createData('Stain resistance ', 'Good', 'Good', 'High ', 'Good', `Good`),
  createData('Moisture resistance ', `High`, `High`, `High`, 'High', 'High'),
  createData('Maintenance ', `Easy to clean`, `Easy to clean`, `Easy to clean`, `Easy to clean`, `Easy to clean`),
  createData('Cost ', `Moderate`, `Moderate`, `Moderate`, `Moderate`, `Moderate`),
];
const tableHeaders = ['Name', 'Kitchen', 'Living & Dining ', 'Bathroom floors', 'Bedroom', 'Utility & Balcony'];

function createDataForTableTwo(name, a, b, c, d, e, f, g) {
  return { name, a, b, c, d, e, f, g };
}
const rowsForTableTwo = [
  createDataForTableTwo('Breaking Strength (N/mm²)', `>1300`, `900-1000 `, `>1200`, `40-80`, `15-25`, `>1600`, '900-1000'),
  createDataForTableTwo(
    'Durability (Years)',
    '20-30',
    '10-20',
    '20-100+',
    '10-100 (depending on the type of wood and maintenance)',
    '10-20',
    '20-50+',
    '10-30'
  ),
  createDataForTableTwo('Hardness (Mohs Scale)', '7', '3-4', `6-7 (Marble), 7-8 (Granite)`, `2-6 (depending on the type of wood)`, `2-3`, '5-7', '3-7'),
  createDataForTableTwo('Moisture Resistance (%)', '1-2', '3-6', '10-20', `6-10`, `0.05-0.2`, '2-5', '2-3'),
  createDataForTableTwo('Thermal Conductivity (W/mK) ', '1-2', '1-2', '2-3 ', '0.12-0.15', `0.25`, '1.4-2.1', '0.5-2'),
  createDataForTableTwo('Fire Resistance (Class)', `A1`, `A1`, `A1`, 'C', 'Bfl-s1', 'A1', 'A2fl-s1'),
  createDataForTableTwo('Chemical Resistance (Class) ', `A`, `A-B`, `B-C`, `C-D`, `A-B`, 'A-B', 'A-B'),
  createDataForTableTwo('Cost ', `Moderate`, `Moderate`, `Expensive`, `Expensive`, `Moderate`, `Moderate`, `Moderate`),
  createDataForTableTwo(
    'Maintenance  ',
    `Easy to clean`,
    `Easy to clean`,
    `Regular cleaning,
  sealing and polishing`,
    `Regular maintenance`,
    `Easy to clean`,
    `Easy to clean`,
    `Easy to clean`
  ),
  createDataForTableTwo('Slip Resistance', `Moderate `, `High`, `Moderate `, `Moderate `, `High`, 'High', 'High'),
  createDataForTableTwo('Stain Resistance', `Moderate`, `High `, `Low`, `Low`, `High`, `High`, 'High'),
];
const tableHeadersForTableTwo = [
  '',
  'Vitrified Tiles',
  'Ceramic Tiles',
  'Natural Stone (Marble, Granite)',
  'Wooden Flooring',
  'Vinyl Flooring',
  'Cement/Concrete Flooring',
  'Terrazzo',
];

function createDataForTableThree(name, a, b, c, d, e, f, g) {
  return { name, a, b, c, d, e, f, g };
}
const rowsForTableThree = [
  createDataForTableThree(
    'Application',
    `Living and Dining,Kitchen,Bedroom,Bathroom,Utility and Balcony`,
    `Living and Dining,Kitchen,Bedroom,Bathroom,Utility and Balcony`,
    `Living and Dining,Kitchen,Bedroom,Bathroom,Utility and Balcony`,
    `Bathroom,Utility and Balcony`,
    `Living and Dining,Bedroom,Utility and Balcony`,
    `Living and Dining,Kitchen,Bedroom,Utility and Balcony`,
    'Bedroom'
  ),
  createDataForTableThree('Embodied energy (MJJ)', '8.2', '161.8', '118.4', '7.8', '896.0', '1.8', '577.5'),
  createDataForTableThree('GWP (kg CO2 eq.)', '16.32', '10.21', `41.44`, `12.06`, `21.00`, '75.60', '12.075'),
  createDataForTableThree('ODP (kg CFC-11e)', '1.32E-10', '7.63E-06', '-', `6.84E-11`, `8.26E-10`, '2.52E-10', '5.25E-10'),
  createDataForTableThree('Acidification potential (kg SO2e) ', '0.1968', '0.0729', '- ', '0.162', `0.378`, '0.19656', '0.1733'),
  createDataForTableThree('Eutrophicaion Potential (kg PO4e)', `0.00648`, `0.00778`, `-`, '0.00450', '0.02660', '0.01990', '1.21E-02'),
  createDataForTableThree('Abiotic Depletion (ADP elements) (kg Sb-Eq) ', `-`, `3.36E-06`, `-`, `1.30E-04`, `-`, '6.60E-05', '-'),
  createDataForTableThree('Abiotic Depletion (ADP Fossil) (MJ) ', `-`, `48.3`, `-`, `79.3`, `-`, `2000.0`, `-`),
];
const tableHeadersForTableThree = ['', 'Vitrified Tiles', 'Marble', 'Granite', 'Ceramic Tiles', 'Solid Wood', 'Terrazzo', 'Bamboo'];

const InteriorFlooring = () => {
  const steppers = [];
  const history = useHistory();
  const classes = useStyles();
  const interiorFlooringData = ResourcesData.filter((d) => d.iid === 'Interior Flooring');
  interiorFlooringData.forEach((d) => steppers.push(d.data.find((g) => g.type === types.steppers)));
  const icons = { Assess: Seek, Consider: Check, Check: Inquire, Select: Choose };

  return (
    <div>
      {' '}
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{interiorFlooringData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>
        {interiorFlooringData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start" style={{ marginLeft: '4%' }}>
            {/* steppers */}
            <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers[0].data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d, index) => (
              <>
                {/* text */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.text && d.id === 2 && (
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      {d.text}
                    </Typography>
                  )}
                </Grid>
                {/* table heading */}
                {d.type === types.heading && d.id === 3 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {/* table */}
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 4 && <ScrollableTable rows={rows} tableHeaders={tableHeaders} />}
                </Grid>
                {/* table heading 2*/}
                {d.type === types.heading && d.id === 5 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 6 && <ScrollableTable rows={rowsForTableTwo} tableHeaders={tableHeadersForTableTwo} />}
                </Grid>
                {d.type === types.points && d.id === 7 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} : </b>
                                {point.text}
                              </Typography>
                            </Grid>
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                {d.type === types.heading && d.id === 8 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {d.type === types.points && d.id === 9 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} : </b>
                                {point.text}
                              </Typography>
                            </Grid>
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                {d.type === types.points && d.id === 10 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} : </b>
                                {point.text}
                              </Typography>
                            </Grid>
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                {d.type === types.points && d.id === 11 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} : </b>
                                {point.text}
                              </Typography>
                            </Grid>
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 12 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '90%' }} />}
                </Grid>
                {/* table */}
                <Grid style={{ marginBottom: '5%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 13 && <ScrollableTable rows={rowsForTableThree} tableHeaders={tableHeadersForTableThree} />}
                </Grid>
              </>
            ))}
          </Grid>
        ))}
      </Container>
      <MarketplaceFooter />
    </div>
  );
};

export default InteriorFlooring;
