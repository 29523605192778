import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';

function Addfile(props) {
  const {
    uploadError,
    selectedFile,
    sizeError,
    fileError,
    filename,
    fileLink,
    startUpload,
    isSuccess,
    handleUploadClick,
    setSizeError,
    setFileError,
    setUploadError,
  } = props;
  return (
    <>
      <Grid xs={12} item direction="column" style={{ marginTop: '2%' }}>
        <Typography variant="h5">Add File </Typography>
        <Typography variant="h6" color="textSecondary">
          Supported formats -<b> .rvt, .dwg, .skp, .doc / .docx, .xls / .xlsx, .pdf</b>
        </Typography>
        <Typography variant="h6" style={{ marginTop: '2%' }} color="textSecondary">
          Max file size 50 MB. Please provide link for larger files
        </Typography>
        <Grid item container xs={12} style={{ marginTop: '3%' }}>
          {!uploadError && !selectedFile && !sizeError && !fileError ? (
            <>
              <AddBoxIcon style={{ color: `${filename && !fileLink ? 'rgb(102, 227, 190)' : '#9E9E9E'}`, width: '25px', height: '70%' }} />
              {filename && !fileLink ? (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} />
              ) : fileLink ? (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} disabled />
              ) : (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} disabled />
              )}
              <label
                htmlFor="upload-photo"
                style={{ color: `${filename && !fileLink ? 'rgb(102, 227, 190)' : '#9E9E9E'}`, cursor: 'pointer', marginTop: '1%', fontSize: '16px' }}
              >
                Browse
              </label>
            </>
          ) : (
            ''
          )}
          {selectedFile && startUpload === false && isSuccess === false ? (
            <>
              <Typography variant="h6">{selectedFile.name}</Typography>
              <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} />
              <label htmlFor="upload-photo" style={{ color: '#00C58A', cursor: 'pointer', marginTop: '0.5%', fontSize: '16px', marginLeft: '1%' }}>
                Browse
              </label>
            </>
          ) : (
            ''
          )}
          {sizeError ? (
            <>
              <Typography variant="h6" color="textSecondary" style={{ color: '#ED5E68' }}>
                <b>
                  File size exceeds limit
                  <span style={{ color: '#00C58A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSizeError(false)}>
                    Try Again
                    <RefreshIcon />
                  </span>
                </b>
              </Typography>{' '}
            </>
          ) : (
            ''
          )}
          {fileError ? (
            <>
              <Typography variant="h6" color="textSecondary" style={{ color: '#ED5E68' }}>
                <b>
                  Incompatible file type
                  <span style={{ color: '#00C58A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setFileError(false)}>
                    Try Again
                    <RefreshIcon />
                  </span>
                </b>
              </Typography>{' '}
            </>
          ) : (
            ''
          )}
          {startUpload ? (
            <>
              <Typography variant="h6" style={{ color: '#00C58A' }}>
                <CircularProgress style={{ color: '#00C58A' }} size={20} /> {selectedFile.name}
              </Typography>
            </>
          ) : (
            ''
          )}
          {isSuccess ? (
            <>
              <Typography variant="h6" style={{ color: '#00C58A' }}>
                <CheckCircleIcon style={{ color: '#00C58A', size: '15px' }} /> {selectedFile.name}
              </Typography>
            </>
          ) : (
            ''
          )}
          {uploadError ? (
            <>
              <Typography variant="h6" color="textSecondary" style={{ color: '#ED5E68' }}>
                <b>
                  Error Uploading File.{` `}
                  <span style={{ color: '#00C58A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setUploadError(false)}>
                    Try Again
                    <RefreshIcon />
                  </span>
                </b>
              </Typography>{' '}
            </>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={3} style={{ marginTop: '2%' }}>
          <Typography variant="h6">Or</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Addfile;
