import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import EarthBig from '../../../../assets/VERSION-TWO/landingPage/new earth 1.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  title: {
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '12%',
    background: 'black',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  // button: {
  //   background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
  //   width: '70%',
  //   height: '6vh',
  //   marginTop: '8%',
  // },
  // buttonText: {
  //   color: '#FFFFFF',
  //   WebkitTextFillColor: '#FFFFFF',
  // },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo2: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  typo3: {
    lineHeight: '30px',
  },
  root: {
    '& > *': {
      margin: theme.spacing(3, 0, 0, 0),
      width: '25ch',
    },
  },
  button: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '50%',
    height: '6vh',
    marginTop: '3%',
    [theme.breakpoints.down('md')]: {
      width: '70%',
      height: '4vh',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: '4vh',
    },
  },
  buttonText: {
    fontStyle: 'normal',
    fontSize: '17px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    WebkitTextFillColor: '#FFFFFF',
  },
}));

function Home6(props) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        style={{ background: 'linear-gradient(136.5deg, rgba(0, 206, 255, 0.5) 0%, rgba(59, 120, 238, 0.5) 136.3%)' }}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div data-aos="fade-right">
            <Typography variant="h3" className={classes.title}>
              There is always more you can do for our planet
              <br />
              <br />
            </Typography>
          </div>
          <div data-aos="fade-left">
            <Typography className={classes.typo} variant="body2">
              Get in touch with us
              <br />
            </Typography>
          </div>
          <div data-aos="fade-right">
            <Typography className={classes.typo2} variant="h6">
              Don’t worry, we hate spam too.
            </Typography>
          </div>
          <div data-aos="fade-right" style={{ marginLeft: '12%' }}>
            <Button variant="contained" className={classes.button} onClick={props.onModalOpen}>
              <Typography className={classes.buttonText}>request demo</Typography>
            </Button>
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <div data-aos="fade-left">
            <img src={EarthBig} style={{ width: '100%', paddingTop: '6%' }} alt="homepageBigThree" />
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '2%' }}></Grid>
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" style={{ background: 'white' }}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="h6" className={classes.title}>
            A platform for a sustainable future.
          </Typography>

          <Typography className={classes.typo} variant="h6">
            connect@sdplus.io
            <br />
            <br />
          </Typography>

          <Typography className={classes.typo2} variant="h6" color="textSecondary">
            Copyright © 2020 SD+. All Rights Reserved
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '2%' }}></Grid>
      </Grid>
    </>
  );
}
export default Home6;
