import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from '../../../../routing/IndexRouting';

////////////////////////
import S3 from 'react-aws-s3';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
}));

const Organisations = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [changedName, setChangedName] = useState('');
  const { userDetails } = useContext(UserContext);
  const [organisations, setOrganisations] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchOrganisation, setsearchOrganisation] = useState('');
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = [1, 2, 3, 4, 5, 6];
  const [name, setName] = useState('');
  const [payloadId, setPayloadId] = React.useState('');
  const [open, setOpen] = useState(false);
  const [orgAsset, setOrgasset] = useState([]);
  const [{ status, response }, makeRequest] = useApiRequest('/organisationsschemev2', {
    verb: 'get',
  });
  const handleChangeName = (e) => setChangedName(e.target.value);
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setOrganisations(response.data.data);
    }
  }, [response]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = organisations && organisations.data.filter((category) => category.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setsearchOrganisation(newData);
  };

  const handleName = (event) => setName(event.target.value);
  const handleMultiline = (event) => setPayloadId(event.target.value);

  const handleSelect = (e) => {
    if (e.target.value === 'none') {
      setSelectedValue('');
    } else {
      setSelectedValue(e.target.value);
    }
  };

  //name change
  const nameChange = (e) => {
    e.preventDefault();
    axios
      .put(`/organisationsschemev2?name=${name}&&newName=${changedName}`)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Name' });
        setSelectedValue('');
        setChangedName('');
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleClose = () => {
    setSelectedValue('');
    setChangedName('');
    setName('');
  };

  const handleSelectName = (name) => setName(name);
  const handleComplte = (event, newValue) => {
    if (newValue) {
      axios.get(`organisationsschemev2?useremail=${newValue.email}`).then((response) => {
        if (response && response.data.data) {
          let orgname = response.data.data.data;
          swal({ icon: 'success', title: `${orgname.name}`, text: 'This email has linked to above organisation' });
        }
      });
    }
  };
  ////////////////////////////////////////////////
  const [id, setId] = useState('');
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState('');
  const [upload, setUpload] = useState(false);
  const [payloadid, setPayloadid] = useState('');
  const [showloader, setShowloader] = useState(false);
  const handleSelectOrganisation = (name, id, iid) => {
    setName(name);
    setId(id);
    setPayloadid(iid);
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);

  const handleUpload = (e) => {
    e.preventDefault();
    setShowloader(true);
    if (file) {
      const config = {
        bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
        dirName: 'public/organisations' /* optional */,
        region: 'us-east-2',
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, file.name).then((data) => {
        if (data.status === 204) {
          setUpload(true);
          const body = {
            URL: data.location,
          };
          axios
            .post('/sign-s3', body)
            .then((res) => {
              if (res && res.data.data) {
                setAsset(res.data.data.data);
                setShow(true);
                setShowloader(false);
              }
            })
            .catch((err) => {
              console.log(err, 'err');
            });
          // }
        } else {
          console.log('fail');
        }
      });
    }
  };
  const handleNonupload = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
    setName('');
  };
  const handleSelected = () => {
    setFile('');
    setName('');
    setUpImg('');
    setCompletedCrop('');
    setSelectedValue('none');
  };
  const handlePost = () => {
    setShowloader(true);
    let body = { name: name, payloadId: asset._id };
    axios
      .post(`/organisationsschemev2`, body)
      .then(() => {
        makeRequest();
        setOpen(!open);
        setFile('');
        setName('');
        setUpImg('');
        setCompletedCrop('');
        setSelectedValue('none');
        setShowloader(false);
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleOpen = () => {
    setOpen(!open);
    setFile('');
    setName('');
    setUpImg('');
    setCompletedCrop('');
    setSelectedValue('none');
  };
  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Organizations Management</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '30%' }} onClick={handleOpen}>
            Add Organisation <AddCircleIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Organisation name" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3} align="left">
          <Autocomplete
            options={userDetails && userDetails.allusers}
            onChange={handleComplte}
            getOptionLabel={(option) => option.email}
            fullWidth
            renderInput={(params) => <TextField {...params} autoFocus placeholder="Get Organisation By User's Email Id" />}
          />
        </Grid>
        <Grid item xs />
      </Grid>
      {open ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Name" onChange={handleName} value={name} />
                <Grid item xs={12} align="left">
                  <Grid item xs={12}>
                    <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
                      <div className="App">
                        <div>
                          <input type="file" accept="image/*" onChange={onSelectFile} />
                        </div>
                        <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                        <div>
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              width: Math.round(completedCrop?.width ?? 0),
                              height: Math.round(completedCrop?.height ?? 0),
                            }}
                          />
                        </div>
                        <button type="button" disabled={!completedCrop?.width || !completedCrop?.height}>
                          Download cropped image
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              {showloader ? <CircularProgress /> : ''}
              {file && !show && !showloader ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonupload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show && !showloader ? (
                <Button variant="contained" color="primary" style={{ marginRight: '2%' }} onClick={handlePost}>
                  Add
                </Button>
              ) : (
                ''
              )}

              <Button variant="contained" color="secondary" onClick={handleOpen} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'logo' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={12} align="left">
              <Grid item xs={12}>
                <Typography variant="h5">Update Logo For {name}</Typography>
                <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
                  <div className="App">
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                    <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>
                    <button type="button" disabled={!completedCrop?.width || !completedCrop?.height}>
                      Download cropped image
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} align="left">
              {showloader ? <CircularProgress /> : ''}
              {file && !show && !showloader ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show && !showloader ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    axios.put(`/organisationsschemev2?payloadId=${payloadid}&&newPayloadId=${asset._id}`).then((response) => {
                      swal({ icon: 'success', title: `Vendor updated successfully` });
                      makeRequest();
                      setSelectedValue('');
                      setFile('');
                      setUpImg('');
                      setSelectedValue('');
                      setUpload(false);
                    });
                  }}
                  style={{ marginRight: '2%' }}
                >
                  Update
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonupload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'name' ? (
        <>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="standard-basic" label="name" value={changedName} onChange={handleChangeName} />
            <Button variant="contained" color="primary" onClick={nameChange} style={{ marginRight: '2%' }}>
              Update
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose} style={{ marginRight: '2%' }}>
              Close
            </Button>
          </form>
          <br />
          <br />
        </>
      ) : (
        ''
      )}

      {searchValue && searchOrganisation ? (
        searchOrganisation.map((organisation, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center" xs={12}>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{organisation.name.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <Link
                          onClick={() => {
                            window.open(organisation.payloadId.URL);
                          }}
                        >
                          {organisation.payloadId.URL.slice(0, 40)}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12} direction="column">
                    <Typography variant="h6">Delete</Typography>
                    <DeleteIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        axios.get(`/organisationsschemev2/users/${organisation._id}`).then((response) => {
                          let users = response.data.data.data;

                          if (users.length !== 0) {
                            swal({ icon: 'error', title: `${users.length} Users linked to this Organisation` });
                          } else {
                            axios
                              .delete(`/organisationsschemev2/${organisation._id}`)
                              .then(() => {
                                makeRequest();
                                swal({ icon: 'success', title: `Organisation deleted successfully` });
                              })
                              .catch((err) => console.log(err, 'err'));
                          }
                        });
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : organisations ? (
        organisations.data.map((organisation, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{organisation.name.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <Link
                          onClick={() => {
                            window.open(organisation.payloadId.URL);
                          }}
                        >
                          {organisation.payloadId.URL.slice(0, 40)}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue={selectedValue}>
                        <MenuItem value="name" onClick={() => handleSelectName(organisation.name)}>
                          Name
                        </MenuItem>
                        <MenuItem value="logo" onClick={() => handleSelectOrganisation(organisation.name, organisation._id, organisation.payloadId._id)}>
                          Logo
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12} direction="column">
                    <Typography variant="h6">Delete</Typography>
                    <DeleteIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        axios.get(`/organisationsschemev2/users/${organisation._id}`).then((response) => {
                          let users = response.data.data.data;

                          if (users.length !== 0) {
                            swal({ icon: 'error', title: `${users.length} Users linked to this Organisation` });
                          } else {
                            axios
                              .delete(`/organisationsschemev2/${organisation._id}`)
                              .then(() => {
                                makeRequest();
                                swal({ icon: 'success', title: `Organisation deleted successfully` });
                              })
                              .catch((err) => console.log(err, 'err'));
                          }
                        });
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <>
          {data &&
            data.map(() => {
              return <Skeleton animation="wave" height={50} />;
            })}
        </>
      )}
    </>
  );
};
export default Organisations;
