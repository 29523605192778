import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { categoryDetailsQuery } from '../graphql-queries/CategoryDetails.query';
import { NavigatorSource } from '../utils/Defaults';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// export const getQueryAsArray = (input) => (input !== null ? JSON.stringify(input.split(',')) : null);

const useCategoryDetails = () => {
  const { search } = useLocation();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  // URL
  const query = new URLSearchParams(search);
  const [source, setSource] = useState(query.get('source'));
  const [category, setCategory] = useState(query.get('category'));
  const [subcategory, setSubCategory] = useState(query.get('subcategory'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  const [certification, setCertification] = useState(query.get('certification'));
  const [brands, setBrands] = useState(query.get('brands'));
  const [page, setPage] = useState(query.get('page'));
  const [pagesize, setPageSize] = useState(query.get('pagesize'));
  const [filters, setFilters] = useState(query.get('filters'));
  const [sort, setSort] = useState(query.get('sort'));
  const [searchquery, setSearchquery] = useState(query.get('searchquery'));
  const [identifiers, setIdentifiers] = useState(query.get('identifiers') || '');
  const [productstatus, setProductstatus] = useState(query.get('productstatus'));
  // states
  const [productsresponse, setProductsResponse] = useState(null);
  const [categories, setCategories] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [allbrands, setAllBrands] = useState(null);
  const [ecoLabels, setEcolabels] = useState(null);

  // Data
  const [state, SendRequest] = useGraphqlRequest(
    categoryDetailsQuery(
      source,
      category,
      subcategory,
      supercategory,
      certification,
      brands,
      page,
      pagesize,
      filters,
      sort,
      searchquery,
      identifiers,
      productstatus
    )
  );

  useEffect(() => {
    if (!productsresponse) {
      SendRequest();
    }
  }, [productsresponse]);

  useEffect(() => {
    if (previousLocation !== location) {
      setCategory(query.get('category'));
      setSource(query.get('source'));
      setSubCategory(query.get('subcategory'));
      setSuperCategory(query.get('supercategory'));
      setCertification(query.get('certification'));
      setBrands(query.get('brands'));
      setPage(query.get('page'));
      setPageSize(query.get('pagesize'));
      setFilters(query.get('filters'));
      setSearchquery(query.get('searchquery'));
      setSort(query.get('sort') || 'default');
      setIdentifiers(query.get('identifiers') || '');
      setProductstatus(query.get('productstatus'));

      // Reset on applied filters to fetch `categoryDetailsQuery` again
      setProductsResponse(null);
    }
  }, [location, previousLocation]);

  // set Data
  useEffect(() => {
    if (state && state?.response !== null) {
      if (source === NavigatorSource.supercategories) {
        setProductsResponse(state?.response?.data?.data?.customSuperCategories?.products);
        setCategories(state?.response?.data?.data?.categories?.data);
        // console.log(state?.response, 'state?.response');
        setTotalProducts(state?.response?.data?.data?.customSuperCategories?.totalproducts);
      } else if (source === NavigatorSource.categories) {
        setProductsResponse(state?.response?.data?.data?.customProducts?.products);
        setCategories(state?.response?.data?.data?.categories?.data);
        setTotalProducts(state?.response?.data?.data?.customProducts?.totalproducts);
        // console.log(state?.response, 'state?.response');
      } else if (source === NavigatorSource.ecolabels) {
        // console.log(state?.response, 'state?.response');
        setProductsResponse(state?.response?.data?.data?.customProducts?.products);
        setAllBrands(state?.response?.data?.data?.brands?.data);
        setEcolabels(state?.response?.data?.data?.ecoLabels?.data);
        setTotalProducts(state?.response?.data?.data?.customProducts?.totalproducts);
      } else if (source === NavigatorSource.search) {
        // console.log(state?.response, 'state?.response');
        setProductsResponse(state?.response?.data?.data?.searchProducts?.products);
        setAllBrands(state?.response?.data?.data?.brands?.data);
        setEcolabels(state?.response?.data?.data?.ecoLabels?.data);
        setTotalProducts(state?.response?.data?.data?.searchProducts?.totalproducts);
      } else if (source === NavigatorSource.intervention) {
        setProductsResponse(state?.response?.data?.data?.getProducts?.products);
        setCategories(state?.response?.data?.data?.categories?.data);
        setTotalProducts(state?.response?.data?.data?.getProducts?.totalproducts);
      } else setProductsResponse(state?.response?.data?.data);
    }
  }, [state, source]);
  // console.log(totalProducts, 'totalProducts');
  return {
    productsresponse,
    totalProducts,
    source,
    categories,
    category,
    supercategory,
    certification,
    subcategory,
    filters,
    page,
    pagesize,
    allbrands,
    ecoLabels,
  };
};

export default useCategoryDetails;
