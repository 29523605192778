import { withWidth, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useScreenType from '../../../../../hooks/checkScreenType';
import MarketplaceButton from '../../../common/Button';

const checkProperties = (checkprops) => {
  if (!checkprops) return false;
  else if (checkprops === '') return false;
  else if (checkprops === 'NA') return false;
  else if (checkprops === null) return false;
  else if (checkprops === undefined) return false;
  else return true;
};

const ProductContactDetails = ({ handleClose, setJoinus, companyDetails }) => {
  console.log(companyDetails && companyDetails, 'company details***********');
  const { isMobile } = useScreenType();
  const body = (
    <Grid container alignItems="flex-start" justifyContent="flex-start" style={isMobile ? { paddingLeft: '3%', marginBottom: '3%' } : null}>
      <Grid xs={12}>
        <Typography variant="h5">
          <b>Contact Details</b>
        </Typography>
      </Grid>

      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <Typography variant="h6" color="textSecondary">
            Name of the vendor
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6">{checkProperties(companyDetails?.Name) !== false ? companyDetails && companyDetails?.Name : '-'}</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <Typography variant="h6" color="textSecondary">
            Point of contact
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6">{(companyDetails && companyDetails?.PoC && companyDetails?.PoC[0]) || '-'}</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <Typography variant="h6" color="textSecondary">
            Company email address
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6">
            {checkProperties(companyDetails?.Company_Email) !== false ? companyDetails && companyDetails?.Company_Email : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <Typography variant="h6" color="textSecondary">
            Company phone number
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6">
            {checkProperties(companyDetails?.Company_Phone_Number) !== false ? companyDetails && companyDetails?.Company_Phone_Number : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent={!isMobile ? 'flex-end' : 'center'}
        style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}
      >
        <Grid xs={!isMobile ? 4 : 12} container alignItems="center" justifyContent={!isMobile ? 'flex-end' : 'center'}>
          <MarketplaceButton children="DONE" style={isMobile ? { width: '217px' } : null} onClick={!isMobile ? handleClose : () => setJoinus(false)} />
        </Grid>
      </Grid>
    </Grid>
  );

  return body;
};

export default withWidth()(ProductContactDetails);
