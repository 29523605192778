import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Drawer } from '@material-ui/core';
import CustomAddButton from './CustomAddButton';
import DwellingUnitTypesHandler from '../DwellingUnitTypes/components/DwellingUnitTypesHandler';
import AddIcon from '@material-ui/icons/Add';
import DwellingUnitInteriorHandler from '../DwellingUnitInteriors/components/dwellingUnitInteriorHandler';
import WindowTypesHandler from '../WindowTypes/components/WindowTypesHandler';
import { Sections } from '../DataTypes';

export default function CustomDrawer({
  title,
  section,
  customAddButton,
  customTitle,
  editTable,
  handleEditTable,
  editableData,
  handleEditableData,
  setUpdate,
  update,
  indexforEdit,
  mount,
  setMount,
  sectionName,
  residentHealthAndWellBeingData,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
    handleEditTable(false);
    handleEditableData('');
  };

  useEffect(() => {
    if (editTable === true) setState({ ...state, ['right']: true });
  }, [editTable]);

  const list = (anchor, section) => (
    <div
      role="presentation"
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
    >
      {section === Sections.Dwelling_Unit_Types && (
        <DwellingUnitTypesHandler
          update={update}
          setUpdate={setUpdate}
          handleClose={toggleDrawer('right', false)}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          mount={mount}
          setMount={setMount}
        />
      )}

      {section === Sections.Window_Types && (
        <WindowTypesHandler
          update={update}
          setUpdate={setUpdate}
          handleClose={toggleDrawer('right', false)}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          mount={mount}
          setMount={setMount}
        />
      )}

      {section === Sections.Dwelling_Unit_Interiors && (
        <DwellingUnitInteriorHandler
          update={update}
          setUpdate={setUpdate}
          sectionName={sectionName}
          handleClose={toggleDrawer('right', false)}
          mount={mount}
          setMount={setMount}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          residentHealthAndWellBeingData={residentHealthAndWellBeingData}
          handleEditTable={handleEditTable}
        />
      )}
    </div>
  );

  return (
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {section === 'Dwelling Unit Interiors' ? (
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              <AddIcon style={{ backgroundColor: '#49DDB1', color: '#ffff', borderRadius: '2px' }} onClick={toggleDrawer(anchor, true)} />{' '}
            </Typography>
          ) : (
            <></>
          )}
          {customAddButton && <CustomAddButton title={title} handleOpenDrawer={toggleDrawer(anchor, true)} />}
          {!customAddButton && <Typography variant="h6">{customTitle}</Typography>}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor, section)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

const useStyles = makeStyles({
  list: {
    width: 450,
  },
  fullList: {
    width: 'auto',
  },
});
