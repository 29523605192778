import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationInsightsCarousal from '../child-components/LocationInsightsCarousal';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(3),
    borderRadius: '6px',
  },
}));

const LocationBasedInsights = (props) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.gridContainer} disableGutters data-tour="locationbasedinsights">
        <LocationInsightsCarousal insights={props.insights} />
      </Container>
    </>
  );
};

export default LocationBasedInsights;
