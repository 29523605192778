import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Container, Grid, Typography } from '@material-ui/core';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../config/index';
import SidebarAppliedFilters from './components/SidebarAppliedFilters';
import FilterSidebarBrands from './components/FilterSidebarBrands';
import FilterSidebarCategories from './components/FilterSidebarCategories';
import FilterSidebarCustomButtons from './components/FilterSidebarCustomButtons';
import CustomCheckBox from '../../../../../../../common/checkbox/CustomCheckBox';
import { ProductsPageContext } from '../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../utils/Defaults';
import FilterSidebarEcoLabels from './components/FilterSideBarEcoLabels';
import FilterSidebarContextual from './components/FilterSideBarContextual';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '18%',
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: '18%',
    border: '1px solid #00000014',
    background: '#FFFFFF',
    borderRadius: '10px',
    marginTop: '5%',
  },
  drawerContainer: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    paddingBottom: '50%',
  },
  padding: {
    paddingTop: '5%',
  },
  texthead: MarketplaceTypography.Heading3,
  checkboxpadding: {
    paddingLeft: '10px',
  },
}));

export const FilterSections = {
  NoSection: '',
  Brands: 'Brands',
  Filters: 'Filters',
  EcoLabels: 'Ecolabels',
  Contextual: 'Contextual',
};
export const FilterSubSections = {
  NoSection: '',
  SubCategory: 'SubCategory',
};

export default function FilterSidebar(props) {
  const { URLS, filtersData, handleFilterData, handleAppliedFilters } = useContext(ProductsPageContext);
  const { category, subcategory, filters, brands, source, mappingid, productstatus, productids } = URLS;
  const { categories, allbrands, ecoLabels, uniqueBrands, uniqueSubcategories, uniqueEcolabels, userSelectedFirstSection } = props;
  const classes = useStyles();

  const [section, setSection] = useState('');
  const [subsection, setSubSection] = useState('');

  const getCheckboxState = (label) => {
    return filtersData && filtersData.find((d) => d.value === label) && filtersData.find((d) => d.value === label)?.checked === false
      ? false
      : filtersData && filtersData.find((d) => d.value === label) && filtersData.find((d) => d.value === label)?.checked === true
      ? true
      : false;
  };

  useEffect(() => {
    if (brands !== null) {
      brands.split(',').map((ele) => {
        handleFilterData(FilterSections.Brands, ele, true);
        handleAppliedFilters(FilterSections.Brands, ele, true);
      });
    }
    if (filters !== null && source !== NavigatorSource.ecolabels) {
      filters.split(',').map((ele) => {
        handleFilterData(FilterSections.Filters, ele, true);
        handleAppliedFilters(FilterSections.Filters, ele, true);
      });
    }
    if (filters !== null && source === NavigatorSource.ecolabels) {
      filters.split(',').map((ele) => {
        handleFilterData(FilterSections.EcoLabels, ele, true);
        handleAppliedFilters(FilterSections.EcoLabels, ele, true);
      });
    }
    if (subcategory !== null) {
      subcategory.split(',').map((ele) => {
        handleFilterData(FilterSubSections.SubCategory, ele, true);
        handleAppliedFilters(FilterSubSections.SubCategory, ele, true);
      });
    }
    if (mappingid !== null && productstatus === 'removeproducts') {
      handleFilterData(FilterSections.Contextual, 'Hide Shortlisted Products', true);
      handleAppliedFilters(FilterSections.Contextual, 'Hide Shortlisted Products', true);
    }
    if (mappingid !== null && productids !== null) {
      handleFilterData(FilterSections.Contextual, 'Only Show Shortlisted Products', true);
      handleAppliedFilters(FilterSections.Contextual, 'Only Show Shortlisted Products', true);
    }
    if (category !== null && source === NavigatorSource.supercategories)
      category.split(',').map((ele) => handleAppliedFilters(NavigatorSource.categories, ele, true));
  }, [URLS]);

  const CheckboxComponent = (subsection, label) => (
    <Grid container className={classes.checkboxpadding}>
      <CustomCheckBox
        handleChange={(e) => handleFilterData(subsection, label, e.target.checked)}
        checkState={getCheckboxState(label)}
        color={MarketplaceColors.Secondary}
        checkedcolor={MarketplaceColors.Secondary}
        label={label}
      />
    </Grid>
  );

  const Header = (
    <Grid item container xs={12} sm={12} lg={12} xl={12} className={classes.padding}>
      <Typography className={classes.texthead}>Filters</Typography>
    </Grid>
  );

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Container className={classes.drawerContainer}>
          <Grid container xs={12} sm={12} lg={12} xl={12} className={classes.padding}>
            {Header}
            {/* ____________applied filters__________*/}
            <Grid container item xs={12} sm={12} lg={12} xl={12}>
              <SidebarAppliedFilters />
            </Grid>
            {/* 1 */}
            {source === NavigatorSource.ecolabels && (
              <>
                {/* For All Brands Accross Categories */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarBrands
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    categories=""
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    allbrands={allbrands && allbrands}
                    uniqueBrands={uniqueBrands}
                    userSelectedFirstSection={userSelectedFirstSection}
                  />
                </Grid>
                {/* For All EcoLabels Accross Categories */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarEcoLabels
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    ecoLabels={ecoLabels && ecoLabels}
                    userSelectedFirstSection={userSelectedFirstSection}
                    uniqueEcolabels={uniqueEcolabels}
                  />
                </Grid>
              </>
            )}
            {/* 2 */}
            {source === NavigatorSource.search && (
              <>
                {/* For All Brands Accross Categories */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarBrands
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    categories=""
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    allbrands={allbrands && allbrands}
                    uniqueBrands={uniqueBrands}
                    userSelectedFirstSection={userSelectedFirstSection}
                  />
                </Grid>
                {/* For All EcoLabels Accross Categories */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarEcoLabels
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    ecoLabels={ecoLabels && ecoLabels}
                  />
                </Grid>
              </>
            )}
            {/* 3 */}
            {source !== NavigatorSource.ecolabels && source !== NavigatorSource.search && (
              <>
                {/* ____________show categories__________*/}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarCategories
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    subsection={subsection && subsection}
                    setSubSection={(s) => setSubSection(s)}
                    categories={categories && categories}
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    // get unique subcategories and categories
                    userSelectedFirstSection={userSelectedFirstSection}
                    uniqueSubcategories={uniqueSubcategories}
                    uniqueBrands={uniqueBrands}
                    uniqueEcolabels={uniqueEcolabels}
                  />
                </Grid>

                {/* ____________show brands__________ */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarBrands
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    categories={categories && categories}
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    // get unique brands
                    uniqueBrands={uniqueBrands}
                    userSelectedFirstSection={userSelectedFirstSection}
                  />
                </Grid>
                {/* ____________show Contextual Filters__________ */}
                <Grid container item xs={12} sm={12} lg={12} xl={12}>
                  <FilterSidebarContextual
                    section={section && section}
                    setSection={(s) => setSection(s)}
                    categories={categories && categories}
                    CheckboxComponent={CheckboxComponent}
                    getCheckboxState={getCheckboxState}
                    // get unique brands
                    uniqueBrands={uniqueBrands}
                    userSelectedFirstSection={userSelectedFirstSection}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {/* ____________apply filter__________ */}
          <FilterSidebarCustomButtons />
        </Container>
      </Drawer>
    </>
  );
}
