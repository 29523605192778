import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesKitchenSinkProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesKitchenSink_oneBHK,
    efficientFixturesKitchenSink_twoBHK,
    efficientFixturesKitchenSink_threeBHK,
    efficientFixturesKitchenSink_fourBHK,
    efficientFixturesKitchenSink_studio,
    efficientFixturesKitchenSink_pentHouseOneBhk,
    efficientFixturesKitchenSink_pentHouseTwoBhk,
    efficientFixturesKitchenSink_pentHouseThreeBhk,
    efficientFixturesKitchenSink_pentHouseFourBhk,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesKitchenSink_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesKitchenSink_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesKitchenSink_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesKitchenSink_fourBHK);
  const studio = getQuantityValue(efficientFixturesKitchenSink_studio);

  const penthouse_onebhk = getQuantityValue(efficientFixturesKitchenSink_pentHouseOneBhk);
  const penthouse_twobhk = getQuantityValue(efficientFixturesKitchenSink_pentHouseTwoBhk);
  const penthouse_threebhk = getQuantityValue(efficientFixturesKitchenSink_pentHouseThreeBhk);
  const penthouse_fourbhk = getQuantityValue(efficientFixturesKitchenSink_pentHouseFourBhk);

  const onebhk_label = getQuantityLabel(efficientFixturesKitchenSink_oneBHK);
  const twobhk_label = getQuantityLabel(efficientFixturesKitchenSink_twoBHK);
  const threebhk_label = getQuantityLabel(efficientFixturesKitchenSink_threeBHK);
  const fourbhk_label = getQuantityLabel(efficientFixturesKitchenSink_fourBHK);
  const studio_label = getQuantityLabel(efficientFixturesKitchenSink_studio);

  const onebhk_unit = getQuantityUnit(efficientFixturesKitchenSink_oneBHK);
  const twobhk_unit = getQuantityUnit(efficientFixturesKitchenSink_twoBHK);
  const threebhk_unit = getQuantityUnit(efficientFixturesKitchenSink_threeBHK);
  const fourbhk_unit = getQuantityUnit(efficientFixturesKitchenSink_fourBHK);
  const studio_unit = getQuantityUnit(efficientFixturesKitchenSink_studio);

  const penthouse_onebhk_label = getQuantityLabel(efficientFixturesKitchenSink_pentHouseOneBhk);
  const penthouse_twobhk_label = getQuantityLabel(efficientFixturesKitchenSink_pentHouseTwoBhk);
  const penthouse_threebhk_label = getQuantityLabel(efficientFixturesKitchenSink_pentHouseThreeBhk);
  const penthouse_fourbhk_label = getQuantityLabel(efficientFixturesKitchenSink_pentHouseFourBhk);

  const penthouse_onebhk_unit = getQuantityUnit(efficientFixturesKitchenSink_pentHouseOneBhk);
  const penthouse_twobhk_unit = getQuantityUnit(efficientFixturesKitchenSink_pentHouseTwoBhk);
  const penthouse_threebhk_unit = getQuantityUnit(efficientFixturesKitchenSink_pentHouseThreeBhk);
  const penthouse_fourbhk_unit = getQuantityUnit(efficientFixturesKitchenSink_pentHouseFourBhk);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{studio_label && studio_label}</Typography>
          <Typography className={classes.textHeader3}>
            {studio} {studio_unit && studio_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{onebhk_label && onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {onebhk} {onebhk_unit && onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{twobhk_label && twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {twobhk} {twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {threebhk} {threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {fourbhk} {fourbhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_onebhk} {penthouse_onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_twobhk} {penthouse_twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_threebhk} {penthouse_threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_fourbhk} {penthouse_fourbhk_unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesKitchenSinkProjectInputs;
