import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ProjectBar from './ProjectsBar';
import { Grid, Typography } from '@material-ui/core';
// import HelpIcon from '@material-ui/icons/Help';
import ListItemLink from './ListItemLink';
import Feedback from './ModalItems/Feedback';
import sidebarline from '../../../assets/sidebarline.svg';
import { wlakthroughContext } from '../../../projectRouting/Index';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useApiRequest from '../../hooks/useApiRequest';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '13%',
    flexShrink: 0,
    zIndex: 0,
    border: 'none',
  },
  drawerPaper: {
    width: '13%',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  drawerPaperFAQ: {
    width: '13%',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    zIndex: 0,
  },
  drawerContainer: {
    overflow: 'hidden',
  },
  line: {
    paddingBottom: '10%',
  },
  highlight: {
    background: 'rgba(64, 225, 156, 0.1)',
    borderStyle: 'none solid none none',
    borderRightColor: '#29E1AC',
  },
}));

export default function VerticalTabs(props) {
  const { projectData } = props;
  const location = useLocation();
  const v = useParams();
  const classes = useStyles();
  const link = props.links || [];
  const [state, setState] = useState(true);
  const [select, handleSelect] = useState(false);

  const handleClick = () => {
    handleMyWalkthrough(true);
    setState(!state);
    handleSelect(true);
  };
  let contextData = useContext(wlakthroughContext);
  let uiWalkthroughContext;
  let handleMyWalkthrough;
  if (!props.fromFaq) {
    handleMyWalkthrough = contextData.handleMyWalkthrough;
    uiWalkthroughContext = contextData.uiWalkthroughContext;
  }

  return (
    <>
      {props.fromFaq ? (
        <>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaperFAQ,
            }}
          >
            <div style={{ marginTop: '34%' }}>{props.children}</div>
          </Drawer>
        </>
      ) : (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <ProjectBar projectData={projectData && projectData} />
          <div className={classes.drawerContainer}>
            <Grid container direction="column" justifyContent="center" item xs>
              <img src={sidebarline} alt="..." className={classes.line} style={{ width: '100%' }} />
              <List>
                {link.map((item, index) => (
                  <ListItemLink key={index} to={item.url} label={item.name} />
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="center"
              style={location && v && location.pathname === `/projects/${v.id}/marketing` ? { marginTop: '40%', display: 'none' } : { marginTop: '40%' }}
            >
              <List className={uiWalkthroughContext && uiWalkthroughContext ? classes.highlight : ''}>
                <ListItemLink to="#" label={<Typography>How It Works</Typography>} onClick={handleClick} />
              </List>
            </Grid>
          </div>
        </Drawer>
      )}
    </>
  );
}
