import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import IGBCPoints from './IGBCPoints';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IGBCDataUpload from './IGBCDataUpload';
import IGBCDocumentation from './IGBCDocumentation';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  fff: {
    minHeight: 380,
    padding: 18,
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    position: 'relative',
    width: 'auto',
  },
  alignment: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function IGBCSummary({ igbcCertificationData, dataUploadprogress, bargraphData, projectId }) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1280px)');
  const [projectdocumentUploadPercentage, setprojectdocumentUploadPercentage] = useState(0);
  useEffect(() => {
    // if (creditDetailsForaSingleCredit && creditDetailsForaSingleCredit._id) {
    axios.get(`/igbc-credit-asset/${projectId}/percentage`).then((response) => {
      if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.percentage) {
        setprojectdocumentUploadPercentage(response.data.data.data.percentage);
      } else {
        setprojectdocumentUploadPercentage(0);
      }
    });
    // }
  }, [projectId]);
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={11} lg={8} xl={8} container className={classes.fff}>
          <IGBCPoints igbcCertificationData={igbcCertificationData && igbcCertificationData} bargraphData={bargraphData && bargraphData} />
        </Grid>
        <Grid
          item
          xs={!matches ? 8 : 4}
          sm={!matches ? 8 : 4}
          md={!matches ? 8 : 4}
          lg={!matches ? 8 : 4}
          xl={!matches ? 8 : 4}
          container
          direction={!matches ? 'row' : 'column'}
          className={classes.alignment}
        >
          <IGBCDataUpload dataUploadprogress={dataUploadprogress && dataUploadprogress} />
          <IGBCDocumentation documentUploadPercentage={projectdocumentUploadPercentage && projectdocumentUploadPercentage} />
        </Grid>
      </Grid>
    </>
  );
}
