import React from 'react';
import { Grid, GridList, Popover, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MarketplaceColors } from '../../../config';
import UseNavigatorData from '../../../hooks/useNavigatorData';
import { HoverText } from '../../../utils/HoverText';
import { DummyNavigatorData } from '../../StaticNavigatorData';
import { useState } from 'react';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../utils/Defaults';
import NavigatorColumnsAndCollections from './NavigatorColumnsAndCollections';
import Links from '../../../common/Typography/Links';

const StyledPopover = withStyles({
  root: {
    marginTop: 50,
    boxShadow: 'none',
  },
  paper: {
    color: '#ffffff',
    background: '#FFF',
    // width: 'auto',
    // height: '704px',
    height: 'auto',
    width: '880px',
    paddingLeft: '1%',
    paddingBottom: '1%',
    '&:focus': {
      outline: 'none',
    },
    overflowX: 'auto',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
})(Popover);

const useStyles = makeStyles((theme) => ({
  tabs: {
    padding: theme.spacing(2, 20, 2, 0),
  },
}));

export default function MarketplaceNavigatorDesktop(props) {
  const classes = useStyles();
  const { Primary, TextColorTertiary } = MarketplaceColors;
  const { handleReset, URLS } = useContext(ProductsPageContext);
  const { page, pagesize } = URLS;
  const { open, setOpen } = props;
  // data
  const { navigatorData } = UseNavigatorData();
  const { columns: navigatorColumns } = navigatorData && navigatorData;

  const superCategory_collections = navigatorColumns?.find((column) => column.source === NavigatorSource.supercategories)?.collections;

  // dummy data to show in case data is not available
  const { tabs } = DummyNavigatorData;
  const NavigatorTabs = tabs && tabs.length !== 0 && tabs;

  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => setOpen(false);

  const [selectedTab, setSelectedTab] = useState(NavigatorTabs[0]);

  const selectedText = (text) => (
    <Typography variant="body2" color="textSecondary" style={{ transition: 'color 0.3s ease', cursor: 'pointer', color: Primary }}>
      {text}
    </Typography>
  );

  const newColumnsAndCollections = (
    <Grid container>
      {navigatorColumns?.map(({ id, source, collections }) => (
        <NavigatorColumnsAndCollections
          source={source}
          data={collections}
          handleReset={() => handleReset()}
          superCategory_collections={superCategory_collections}
        />
      ))}
    </Grid>
  );

  const navigatorTabs = (
    <Grid container justifyContent="flex-start" className={classes.tabs}>
      <Grid
        xs={2}
        container
        justifyContent="center"
        style={{ paddingTop: '0.5%', paddingBottom: '0.5%' }} //borderRight: '1px solid #9E9E9E',
        onClick={() => setSelectedTab(NavigatorTabs[0])}
      >
        {selectedTab === NavigatorTabs[0] ? selectedText(selectedTab) : HoverText(NavigatorTabs[0], Primary, TextColorTertiary, true)}
      </Grid>
      <Grid xs={2} container justifyContent="center" style={{ paddingTop: '0.5%', paddingBottom: '0.5%' }} onClick={() => setSelectedTab(NavigatorTabs[1])}>
        {selectedTab === NavigatorTabs[1] ? selectedText(selectedTab) : HoverText(NavigatorTabs[1], Primary, TextColorTertiary, true)}
      </Grid>
    </Grid>
  );

  const Collections = (
    <StyledPopover
      open={open}
      onMouseLeave={handleClickAway}
      onClose={handleClickAway}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {navigatorTabs}
      {selectedTab === NavigatorTabs[0] && newColumnsAndCollections}
      {selectedTab === NavigatorTabs[1] && newColumnsAndCollections}
    </StyledPopover>
  );

  return (
    <div className={classes.root}>
      <Typography variant="body1" style={{ cursor: 'pointer' }} onMouseEnter={handleClick} onClick={handleClick}>
        CATEGORIES
      </Typography>
      {Collections}
    </div>
  );
}
