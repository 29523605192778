import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomizedTooltips from '../../common/tooltip/TooltipV2';

const GoalCategoryCard = ({
  border,
  background,
  icon,
  title,
  text,
  progressValue,
  barBackground,
  barText,
  showProgress = true,
  showValue = true,
  description,
}) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 16,
      background: '#E5E5E5',
      borderRadius: 30,
    },
    bar: {
      borderRadius: 30,
      background: barBackground,
    },
  }))(LinearProgress);
  return (
    <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', background: background, height: 200, width: 250 }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid container alignItems="flex-end" justifyContent="flex-end" style={{ paddingRight: '5%', paddingTop: '5%' }}>
          <CustomizedTooltips
            icon="info"
            title={
              <>
                <Typography>{description}</Typography>
              </>
            }
            placement="right"
          />
        </Grid>
        <Grid container alignItems="center" justifyContent="center" style={{ marginBottom: '10%' }}>
          <img src={icon} alt="..." style={{ height: '44px', width: '60px' }} />
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="center" style={{ paddingTop: '2%', width: '50%', height: '30px' }}>
          <Typography variant="h6" style={{ textAlign: 'center', color: '#000000' }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        {text && showValue && (
          <Grid container alignItems="flex-end" justifyContent="center" style={{ paddingTop: '10%' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              <b>{text}</b>
            </Typography>
          </Grid>
        )}
        {showProgress && (
          <Grid container alignItems="flex-end" justifyContent="center" style={{ paddingTop: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <BorderLinearProgress variant="determinate" value={progressValue} style={{ flexGrow: '1' }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                  fontSize: '10px',
                }}
              >
                <b>{`${barText}%`}</b>
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default GoalCategoryCard;
