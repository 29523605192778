import React from 'react';
import { Grid } from '@material-ui/core';
import UnitAwareTextField from '../../../../common/textfield/UnitAwareTextField';

function CustomDwellingPIP(props) {
  const { index, field, unit, projectPIP, appendChanged, type, xs } = props;
  return (
    <>
      <Grid container item xs={12}>
        <UnitAwareTextField
          index={index}
          unit={unit && unit}
          field={field && field}
          projectPIP={projectPIP && projectPIP}
          appendChanged={appendChanged}
          xs={xs ? xs : 4}
        />
      </Grid>
    </>
  );
}

export default CustomDwellingPIP;
