export default function unit(value) {
  if (value.toLowerCase() === 'kwh') {
    return 'kWh';
  } else if (value.toLowerCase() === 'kl') {
    return 'kL';
  } else if (value.toLowerCase() === 'kg') {
    return 'kg';
  } else if (value.toLowerCase() === 't') {
    return 't';
  } else if (value.toLowerCase() === 'cr') {
    return 'cr';
  } else if (value.toLowerCase() === 'mwh') {
    return 'mWh';
  } else if (value.toLowerCase() === 'l') {
    return 'l';
  } else {
    return value;
  }
}
