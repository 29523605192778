import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  typo2: {
    lineHeight: '20px',
    paddingLeft: '12%',
    paddingTop: '4%',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
    marginTop: '4%',
  },
  icon: {
    marginLeft: '3px',
    color: '#0EBAFC',
    width: '26px',
    height: '25px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  icon2: {
    textAlign: 'center',
    marginLeft: '10%',
    color: '#0EBAFC',
    width: '30px',
    height: '28px',
    paddingBottom: '1%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
}));

let data = [
  {
    id: 'Configure',
    value: 'Upload project and get customized solutions to align with your project’s sustainability and financial goals',
  },
  { id: 'Choose', value: 'Choose from our curated database of reliable vendors for each solution' },
  {
    id: 'Track',
    value: 'Track, measure and compare the success of your sustainable measures',
  },
];

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const line2 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  return (
    <>
      {matches ? (
        <div>
          <Grid item xs={12} container alignItems="left" direction="column">
            {/* part 1 */}
            <Grid item xs={12} container alignItems="left" direction="row">
              <Grid xs={1} item alignItems="left" direction="row">
                <Grid item xs>
                  <Brightness1Icon className={classes.icon} />
                </Grid>
              </Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="h5" style={{ marginTop: '-10px' }}>
                    <b>Configure</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="left" direction="row">
              <Grid xs={1} item alignItems="left" direction="row" style={{ marginTop: '-12px' }}>
                <Grid item xs alignItems="right">
                  {line2 &&
                    line2.map((d) => {
                      return (
                        <>
                          <div style={{ borderLeft: '2px solid #3BC7EE', height: '4px', marginLeft: '15px', marginTop: '2px', marginBottom: '1px' }} />
                        </>
                      );
                    })}
                  <div style={{ borderLeft: '2px solid #3BC7EE', height: '4px', marginLeft: '15px', marginTop: '2px', marginBottom: '-4px' }} />
                  <ArrowLeftIcon
                    style={{
                      transform: 'rotate(270deg)',
                      width: '32px',
                      height: '32px',
                      color: '#3BC7EE',
                      marginTop: -10,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="body1" style={{ color: '#666666' }}>
                    Upload project and get customized
                    <br /> solutions to align with your
                    <br /> project’s sustainability and financial <br />
                    goals
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* part 2 */}
            <Grid item xs={12} container alignItems="left" direction="row" style={{ marginTop: '-10px' }}>
              <Grid xs={1} item alignItems="left" direction="row">
                <Grid item xs>
                  <Brightness1Icon className={classes.icon} />
                </Grid>
              </Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="h5" style={{ marginTop: '-10px' }}>
                    <b>Choose</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="left" direction="row">
              <Grid xs={1} item alignItems="left" direction="row" style={{ marginTop: '-12px' }}>
                <Grid item xs>
                  <Grid item xs alignItems="center">
                    {line2 &&
                      line2.map((d) => {
                        return (
                          <>
                            <div style={{ borderLeft: '2px solid #3BC7EE', height: '4px', marginLeft: '15px', marginTop: '2px', marginBottom: '1px' }} />
                          </>
                        );
                      })}
                    <div style={{ borderLeft: '2px solid #3BC7EE', height: '4px', marginLeft: '15px', marginTop: '2px', marginBottom: '-4px' }} />
                    <ArrowLeftIcon
                      style={{
                        transform: 'rotate(270deg)',
                        width: '32px',
                        height: '32px',
                        color: '#3BC7EE',
                        marginTop: -10,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="body1" style={{ color: '#666666' }}>
                    Choose from our curated database
                    <br /> of reliable vendors for each solution
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* part 3 */}
            <Grid item xs={12} container alignItems="left" direction="row" style={{ marginTop: '-10px' }}>
              <Grid xs={1} item alignItems="left" direction="row">
                <Grid item xs>
                  <Brightness1Icon className={classes.icon} />
                </Grid>
              </Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="h5" style={{ marginTop: '-10px' }}>
                    <b>Track</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="left" direction="row">
              <Grid xs={1} item alignItems="left" direction="row" style={{ marginTop: '-10px' }}></Grid>
              <Grid xs={10} item alignItems="left" direction="row">
                <Grid item xs>
                  <Typography variant="body1" style={{ color: '#666666' }}>
                    Track, measure and compare the <br /> success of your sustainable measures
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="left" direction="row"></Grid>
        </div>
      ) : (
        <div data-aos="fade-up" className={classes.root}>
          {data &&
            data.map((d) => {
              return (
                <>
                  <div data-aos="fade-up">
                    <Brightness1Icon className={classes.icon2} />
                  </div>
                  <div data-aos="fade-up">
                    <Typography className={classes.typo2} variant="h4">
                      {d.id}
                    </Typography>
                  </div>
                  <div data-aos="fade-up">
                    <Typography className={classes.typo3} variant="h5" style={{ color: '#666666' }}>
                      {d.value}
                    </Typography>
                  </div>
                </>
              );
            })}
        </div>
      )}
    </>
  );
}
