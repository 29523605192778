import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles({
  root: {
    color: ' #42E198',
    width: '100%',
  },
})(CircularProgress);

export default function Loader(props) {
  return <ColorCircularProgress size={30} thickness={5} style={props.style} />;
}
