import React, { useState, useEffect, useContext } from 'react';
import ProductActions from './components/ProductActions';
import ProductSummary from './components/ProductSummary';
import useScreenType from '../../../../hooks/checkScreenType';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import { Container, Grid, makeStyles, withWidth } from '@material-ui/core';
import ProductDetailsTable from './components/ProductDetailsTable';
import HeadAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import ProductDetailsTableMobile from './components/ProductDetailsTableMobile';
import { useWindowScrollPositions } from '../../../../hooks/useScrollPosition';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';
import useProductDetails from '../../hooks/useProductDetailsData';
import { Default, NavigatorSource } from '../../utils/Defaults';
import MarketplaceFooter from '../../footer/MarketplaceFooter';
import ProductDetailsAddToInterventions from './components/ProductDetailsAddToInterventions';
import { useLocation } from 'react-router-dom';

const ProductDetails = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { isMobile } = useScreenType();
  const [showInTab, setShowInTab] = useState(false);
  const scrollPosition = useWindowScrollPositions();
  const [mappingID] = useState(query.get('mappingID'));

  const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(15),
      paddingRight: theme.spacing(!isMobile ? 22 : 2),
      paddingLeft: theme.spacing(!isMobile ? 22 : 2),
    },
    bredCrumb: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(!isMobile ? 5 : 3),
    },
  }));
  const classes = useStyles();

  // data - currently dummy products and category added from strapi later change it
  let { id: category_id, iid: product_id } = props.match.params;
  const { categoryDetailsData, productDetailsData, fetchData } = useProductDetails(product_id, category_id);
  const { id: productID, attributes: productAttributes } = productDetailsData;
  const { id: categoryID, attributes: categoryAttributes } = categoryDetailsData;

  useEffect(() => {
    fetchData();
  }, [product_id, category_id]);

  function GetNavigatorURL(mappingID) {
    if (mappingID !== undefined) {
      let mainURL = `/marketplace/categoryDetails?source=${NavigatorSource.intervention}&category=${categoryAttributes && categoryAttributes.name}&page=${
        Default.page
      }&pagesize=${Default.pagesize}&sort=default&mappingID=${mappingID}`;
      return mainURL;
    } else {
      let url = `/marketplace/categoryDetails?source=${NavigatorSource.categories}&category=${categoryAttributes && categoryAttributes.name}&page=${
        Default.page
      }&pagesize=${Default.pagesize}`;
      return url;
    }
  }

  const links = [
    { href: `/marketplace/categories`, text: 'Categories' },
    {
      href: GetNavigatorURL(mappingID),
      text: categoryAttributes ? categoryAttributes.name : '',
    },
  ];

  useEffect(() => {
    if (scrollPosition > 700 && isMobile) setShowInTab(true);
    else setShowInTab(false);
  }, [scrollPosition]);

  const [showAddToInterventionModal, SetShowAddToInterventionModal] = useState(false);
  return (
    <>
      <MarketplaceNavbar showInTab={showInTab} />
      <Container maxWidth="xl">
        <div className={classes.container}>
          <HeadAndBackIcon
            heading={productAttributes?.sku_name}
            arrowColor={MarketplaceColors.Secondary}
            variant={!isMobile ? 'h4' : null}
            style={MarketplaceTypography.PageHeading}
            iconHeight={isMobile ? '18px' : null}
            iconWidth={isMobile ? '20px' : null}
            bold={true}
            handleClick={() => history.goBack()}
            showAddToIntervention={true}
            handleShowModalForAddToIntervention={() => SetShowAddToInterventionModal(!showAddToInterventionModal)}
          />
          <Grid container xs={12} className={classes.bredCrumb}>
            <CustomBreadCrumbs links={links} stage={<b>{productAttributes?.sku_name}</b>} />
          </Grid>
          <ProductSummary productAttributes={productAttributes} />
          {!isMobile && <ProductActions productAttributes={productAttributes} />}
          {!isMobile && <ProductDetailsTable productAttributes={productAttributes} />}
          {isMobile && <ProductDetailsTableMobile productAttributes={productAttributes} />}
        </div>
      </Container>
      <MarketplaceFooter />
      <ProductDetailsAddToInterventions
        showAddToIntervention={showAddToInterventionModal}
        handleShowModalForAddToIntervention={() => SetShowAddToInterventionModal(!showAddToInterventionModal)}
        productDetailsData={productDetailsData && productDetailsData}
      />
    </>
  );
};

export default withWidth()(ProductDetails);
