import React from 'react';
import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Tooltip({ id, open, anchorEl, onClose, body, anchorOrigin, transformOrigin }) {
  const classes = useStyles();
  return (
    <Popover id={id} open={open} anchorEl={anchorEl} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
      <Typography className={classes.typography}>{body}</Typography>
    </Popover>
  );
}
