export const WasteWater = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  return (
    <svg width={65} height={91} viewBox="0 0 81 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={color}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.4312 1.49889C41.4312 1.4989 41.4312 1.49891 41.4312 1.49892L51.2152 18.078C55.1927 24.8178 52.7666 33.3835 45.9013 37.2083C39.0715 41.0134 30.331 38.7429 26.3765 32.0421C23.7341 27.5645 23.9305 22.248 26.3736 18.0831L26.3765 18.078L26.3765 18.078L36.1605 1.49902C36.1605 1.49898 36.1606 1.49894 36.1606 1.49889C37.34 -0.499761 40.2518 -0.4995 41.4312 1.49889ZM38.7959 5.7409L30.1908 20.3222C30.1903 20.323 30.1899 20.3238 30.1894 20.3246C28.5218 23.1694 28.4011 26.7653 30.1878 29.7929L28.2822 30.9175L30.1878 29.7929C32.8923 34.3755 38.9642 36.0072 43.7475 33.3423L44.7631 35.1653L43.7475 33.3423C48.4953 30.6972 50.0854 24.871 47.4039 20.3272L38.7959 5.7409Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.4312 62.6644C41.4312 62.6644 41.4312 62.6644 41.4312 62.6644L51.2152 79.2435C55.1927 85.9834 52.7666 94.5491 45.9013 98.3739C39.0715 102.179 30.331 99.9084 26.3765 93.2076C23.7341 88.7301 23.9305 83.4135 26.3736 79.2486L26.3765 79.2435L26.3765 79.2436L36.1605 62.6646C36.1605 62.6645 36.1606 62.6645 36.1606 62.6644C37.34 60.6658 40.2518 60.666 41.4312 62.6644ZM38.7959 66.9064L30.1908 81.4877C30.1903 81.4886 30.1899 81.4894 30.1894 81.4902C28.5218 84.3349 28.4011 87.9308 30.1878 90.9584L28.2822 92.083L30.1878 90.9584C32.8923 95.541 38.9642 97.1728 43.7475 94.5079L44.7631 96.3308L43.7475 94.5079C48.4953 91.8628 50.0854 86.0365 47.4039 81.4927L38.7959 66.9064Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M70.7159 69.2422C71.5306 69.2422 72.1911 69.9026 72.1911 70.7174V73.164C72.1911 73.9787 71.5306 74.6392 70.7159 74.6392C69.9012 74.6392 69.2407 73.9787 69.2407 73.164V70.7174C69.2407 69.9026 69.9012 69.2422 70.7159 69.2422Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M77.0864 75.6107C77.0864 76.4254 76.426 77.0858 75.6113 77.0858L73.1646 77.0858C72.3499 77.0858 71.6895 76.4254 71.6895 75.6107C71.6895 74.796 72.3499 74.1355 73.1646 74.1355L75.6113 74.1355C76.426 74.1355 77.0864 74.796 77.0864 75.6107Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M70.7157 81.9788C69.901 81.9788 69.2406 81.3183 69.2406 80.5036L69.2406 78.057C69.2406 77.2422 69.901 76.5818 70.7157 76.5818C71.5305 76.5818 72.1909 77.2422 72.1909 78.057L72.1909 80.5036C72.1909 81.3183 71.5305 81.9788 70.7157 81.9788Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.3486 75.6108C64.3486 74.7961 65.0091 74.1356 65.8238 74.1356L68.2704 74.1356C69.0851 74.1356 69.7456 74.7961 69.7456 75.6108C69.7456 76.4255 69.0851 77.0859 68.2704 77.0859L65.8238 77.0859C65.0091 77.0859 64.3486 76.4255 64.3486 75.6108Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7755 88.8164C11.5902 88.8164 12.2506 89.4769 12.2506 90.2916V93.3499C12.2506 94.1646 11.5902 94.825 10.7755 94.825C9.96075 94.825 9.30029 94.1646 9.30029 93.3499V90.2916C9.30029 89.4769 9.96075 88.8164 10.7755 88.8164Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.3677 96.408C18.3677 97.2227 17.7072 97.8832 16.8925 97.8832L13.8342 97.8832C13.0195 97.8832 12.3591 97.2227 12.3591 96.408C12.3591 95.5933 13.0195 94.9329 13.8342 94.9329L16.8925 94.9329C17.7072 94.9329 18.3677 95.5933 18.3677 96.408Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7758 104C9.96109 104 9.30063 103.34 9.30063 102.525L9.30063 99.4665C9.30063 98.6518 9.96109 97.9914 10.7758 97.9914C11.5905 97.9914 12.251 98.6518 12.251 99.4665L12.251 102.525C12.251 103.34 11.5905 104 10.7758 104Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.18359 96.4079C3.18359 95.5932 3.84405 94.9327 4.65876 94.9327L7.71705 94.9327C8.53176 94.9327 9.19222 95.5932 9.19222 96.4079C9.19222 97.2226 8.53176 97.8831 7.71705 97.8831L4.65876 97.8831C3.84405 97.8831 3.18359 97.2226 3.18359 96.4079Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 49.922C0 48.6999 0.990685 47.7092 2.21276 47.7092H5.37298C6.59505 47.7092 7.58574 48.6999 7.58574 49.922C7.58574 51.1441 6.59505 52.1347 5.37298 52.1347H2.21276C0.990685 52.1347 0 51.1441 0 49.922ZM15.8011 49.922C15.8011 48.6999 16.7918 47.7092 18.0139 47.7092H24.3343C25.5564 47.7092 26.5471 48.6999 26.5471 49.922C26.5471 51.1441 25.5564 52.1347 24.3343 52.1347H18.0139C16.7918 52.1347 15.8011 51.1441 15.8011 49.922ZM34.7625 49.922C34.7625 48.6999 35.7532 47.7092 36.9752 47.7092H43.2957C44.5177 47.7092 45.5084 48.6999 45.5084 49.922C45.5084 51.1441 44.5177 52.1347 43.2957 52.1347H36.9752C35.7532 52.1347 34.7625 51.1441 34.7625 49.922ZM53.7238 49.922C53.7238 48.6999 54.7145 47.7092 55.9366 47.7092H62.257C63.4791 47.7092 64.4698 48.6999 64.4698 49.922C64.4698 51.1441 63.4791 52.1347 62.257 52.1347H55.9366C54.7145 52.1347 53.7238 51.1441 53.7238 49.922ZM72.6852 49.922C72.6852 48.6999 73.6758 47.7092 74.8979 47.7092H78.0581C79.2802 47.7092 80.2709 48.6999 80.2709 49.922C80.2709 51.1441 79.2802 52.1347 78.0581 52.1347H74.8979C73.6758 52.1347 72.6852 51.1441 72.6852 49.922Z"
        />
      </g>
    </svg>
  );
};
