import { withWidth, Grid } from '@material-ui/core';
import React from 'react';
import useScreenType from '../../../../hooks/checkScreenType';
import { Skeleton } from '@material-ui/lab';

const CategoriesSkeletons = (props) => {
  const { width } = props;
  const { isMobile } = useScreenType();
  return (
    <Grid
      container
      direction="row"
      xs={4}
      sm={3}
      md={3}
      lg={2}
      xl={2}
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: '2%', marginLeft: '3%' }}
    >
      <Grid item container xs={12} justifyContent="center">
        <Skeleton variant="circle" animation="wave" height={!isMobile ? '160px' : '80px'} width={!isMobile ? '160px' : '80px'} />
      </Grid>
      <Grid item container xs={12} justifyContent="center" style={{ marginTop: '3%' }}>
        <Skeleton variant="text" animation="wave" height={!isMobile ? '4vh' : '2vh'} width="100px" />
      </Grid>
    </Grid>
  );
};

export default withWidth()(CategoriesSkeletons);
