import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import landingpageimage2 from '../../../../assets/landingpageimage2.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '10%',
  },
  typo2: {
    color: '#00CEFF',
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '5%',
  },

  button2: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '100%',
    height: '8vh',
    color: '#FFFFFF',
  },
  grid2: {
    marginTop: '-20%',
  },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
}));

function ResponsiveHome3() {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.navbar2} id="features"></Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" alignContent="center">
        <div data-aos="fade-left" style={{ textAlign: 'center' }}>
          <Typography
            style={{
              lineHeight: '40px',
              letterSpacing: '1px',
              background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: '12%',
            }}
            variant="h3"
          >
            Integrate Sustainable <br />
            solutions into design
            <br />
            <br />
          </Typography>
        </div>
        <div data-aos="fade-right" style={{ textAlign: 'center' }}>
          <Typography className={classes.typo3} variant="h5">
            Discover the best sustainable solutions for your project & seamlessly integrate into your design.
            <br /> <br />
            Make informed decisions on solutions to adopt by understanding environmental impact & cost-benefit analysis
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div data-aos="fade-up" style={{ textAlign: 'center' }}>
          <img src={landingpageimage2} style={{ height: 'auto', width: '100%' }} alt="Earth" />
        </div>
      </Grid>
    </>
  );
}
export default ResponsiveHome3;
