import React, { useState } from 'react';
import { Grid, Typography, withWidth, Modal, makeStyles, withStyles } from '@material-ui/core';
import { MarketplaceColors, MarketplaceStyles } from '../../../config';
import MarketplaceButton from '../../../common/Button';
import useScreenType from '../../../../../hooks/checkScreenType';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ProductContactDetails from './ProductContactDetails';

const DraggableDrawer = withStyles({
  paper: {
    borderRadius: '28px 28px 0px 0px',
    left: '1%',
    right: '1%',
  },
})(SwipeableDrawer);

const LinkStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
  textTransform: 'uppercase',
  color: MarketplaceColors.Primary,
};
const disabledLinkStyle = {
  color: MarketplaceColors.TextColorTertiary,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 3, 2, 4),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  draggerStyle: {
    color: 'transparent',
    fontSize: '30px',
    background: '#666666',
    borderRadius: '10px',
    height: '6px',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const checkBrochureExists = (file) => {
  if (!file) return false;
  else if (file === '') return false;
  else if (file === 'NA') return false;
  else return true;
};

const ProductActions = (props) => {
  const classes = useStyles();
  const { width, productAttributes } = props;
  const { isMobile } = useScreenType(width);

  // console.log(productAttributes && productAttributes, 'productAttributes && productAttributes');

  const [modalStyle] = useState(getModalStyle);
  const [joinus, setJoinus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const ModalData = (
    <div style={modalStyle} className={classes.paper}>
      <ProductContactDetails
        companyDetails={productAttributes && productAttributes?.company_details}
        handleClose={handleClose}
        setJoinus={(a) => setJoinus(a)}
      />
    </div>
  );

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" style={!isMobile ? { marginTop: '4%' } : { marginTop: '3%' }}>
        {!isMobile && (
          <Grid item xs={4} container alignItems="center" justifyContent="center">
            <a target="_blank" onclick="return false;" rel="noreferrer" style={LinkStyle}>
              {/* update for next release */}
              {/* <Typography style={MarketplaceStyles.Link}>HOW IT WORKS</Typography> */}
            </a>
          </Grid>
        )}
        {!isMobile && (
          <Grid item xs={4} container alignItems="center" justifyContent="flex-start" style={{ paddingLeft: '5%' }}>
            {checkBrochureExists(productAttributes?.brochure) != false ? (
              <a target="_blank" onclick="return false;" rel="noreferrer" style={LinkStyle} href={productAttributes?.brochure}>
                <Typography style={MarketplaceStyles.Link}>Download Brochure</Typography>
              </a>
            ) : (
              <Typography style={disabledLinkStyle}>Brochure unavailable</Typography>
            )}
          </Grid>
        )}
        <Grid item xs={5} sm={4} md={3} lg={3} xl={3} container alignItems="center" justifyContent="flex-end">
          <MarketplaceButton
            children="Get in Touch"
            style={isMobile ? { width: '217px' } : null}
            onClick={!isMobile ? () => setShowModal(true) : () => setJoinus(true)}
          />
        </Grid>
        {isMobile && (
          <Grid item container alignItems="center" justifyContent="center" style={{ marginTop: '4%', marginBottom: '7%' }}>
            <Grid xs={6} sm={5} md={5} lg={5} xl={5} container alignItems="center" justifyContent="center">
              <a target="_blank" rel="noreferrer">
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    color: MarketplaceColors.Secondary,
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.714px',
                    cursor: 'pointer',
                  }}
                >
                  HOW IT WORKS
                </Typography>
              </a>
            </Grid>
            <Grid xs={6} sm={5} md={5} lg={5} xl={5} container alignItems="center" justifyContent="center">
              <a target="_blank" rel="noreferrer">
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    color: MarketplaceColors.Secondary,
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.714px',
                  }}
                >
                  Download Brochure
                </Typography>
              </a>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* product contact details */}
      {!isMobile ? (
        <Modal open={showModal} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {ModalData}
        </Modal>
      ) : (
        <DraggableDrawer anchor="bottom" open={joinus} onClose={() => setJoinus(false)}>
          <Grid container item xs={12} justifyContent="center" alignItems="center" style={{ paddingTop: '3%' }}>
            <DragHandleIcon className={classes.draggerStyle} onClick={() => setJoinus(false)} />
          </Grid>
          <ProductContactDetails handleClose={handleClose} setJoinus={(a) => setJoinus(a)} />
        </DraggableDrawer>
      )}
    </>
  );
};

export default withWidth()(ProductActions);
