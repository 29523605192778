import React, { useState } from 'react';
import CustomHeader from '../../../common/CustomHeader';
import { useHistory } from 'react-router-dom';
import CustomDrawer from '../common/CustomDrawer';
import CustomTableForWindowType from './components/CustomTable';
import { Collapse } from '@material-ui/core';
import { Sections } from '../DataTypes';

let tableData = [];

const WindowTypes = ({ residentHealthAndWellBeingData, mount, setMount }) => {
  const history = useHistory();

  const [editTable, setEditTable] = useState(false);
  const [editableData, setEditableData] = useState('');
  const [collapseSection, setCollapseSection] = useState(true);
  const [indexforEdit, setIndexForEditData] = useState('');
  const [update, setUpdate] = useState(false);

  const handleExpandIcon = () => setCollapseSection((prev) => !prev);

  const handleEditableData = (data, section) => {
    const body = {};
    body['data'] = data;
    body['section'] = section;
    setEditableData(body);
  };

  if (
    residentHealthAndWellBeingData &&
    residentHealthAndWellBeingData['customData'] &&
    residentHealthAndWellBeingData['customData'][0] &&
    residentHealthAndWellBeingData['customData'][0]['data']
  ) {
    const customData = residentHealthAndWellBeingData['customData'][0]['data'];
    const foundData = customData.find((d) => d.section === Sections.Window_Types);
    if (foundData) tableData = foundData['data'];
  }

  return (
    <>
      <CustomHeader sectionName={Sections.Window_Types} i={1} history={history} expand={true} handleExpandIcon={handleExpandIcon} />
      <CustomDrawer
        customAddButton={true}
        title="ADD WINDOW TYPE"
        section={Sections.Window_Types}
        operation="add"
        editTable={editTable}
        handleEditTable={(val) => setEditTable(val)}
        editableData={editableData}
        handleEditableData={(d) => setEditableData(d)}
        setUpdate={setUpdate}
        update={update}
        indexforEdit={indexforEdit}
        mount={mount}
        setMount={setMount}
      />
      <Collapse in={collapseSection}>
        <CustomTableForWindowType
          update={update}
          setUpdate={setUpdate}
          handleEdit={(data, index) => {
            setIndexForEditData(index);
            setEditTable(true);
            handleEditableData(data, Sections.Window_Types);
          }}
          tableData={tableData}
          mount={mount}
          setMount={setMount}
        />
      </Collapse>
    </>
  );
};

export default WindowTypes;
