import HeaderAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import { MarketplaceColors } from '../../config';
import React from 'react';

const FaqData = () => {
  const history = useHistory();
  return (
    <Container maxWidth="md" style={{ marginBottom: '3%' }}>
      <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
        <HeaderAndBackIcon heading={<Typography variant="h4">FAQ</Typography>} arrowColor={MarketplaceColors.Secondary} handleClick={() => history.goBack()} />
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>What is SD+ Marketplace?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            SD+ Marketplace is India’s first dedicated resource for developers, architects, MEP engineers, and green consultants, for them to discover,
            shortlist, compare and select sustainable materials, solutions and services that meet their project goals. It gives them access to information about
            the environmental impact of the listed products, the commitment of their vendors towards sustainability, and other information that is required to
            evaluate a product for a specific project, such as physical properties and cost. SD+ Marketplace also serves as a channel for sustainability-focused
            innovative material and solution vendors that seek to connect with like-minded developers who are at the forefront of the sustainability movement.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>How are the products listed on SD+ Marketplace, different from other marketplaces?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            The products listed on SD+ Marketplace have gone through a rigorous vetting process where a team of industry experts and environmental engineers
            have verified the environmental benefits of the product, and have determined that utilizing them would affect a step-change in the environmental
            foot-print of a project, as opposed to the generic alternative. Such products typically have been certified by 3rd parties and have been awarded
            green certifications to signal this differentiation.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>What are Green Certifications?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Green certifications are designations or labels that signify a product has met certain environmentally-friendly and sustainable criteria. These
            certifications are typically awarded by independent third-party organizations and are used to inform consumers, businesses, and other stakeholders
            about their environmental and sustainability performance.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>What are Beyond Green products?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Beyond Green products are a selection of products that are outperformers when it comes to environmental impact. This designation is proprietary to
            SD+ Marketplace and is intended to aid the easy discovery of products that have an outsized impact on environmental sustainability. The designation
            is refreshed from time to time based on new data.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>I can’t find the product category that I want. Why is that?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Our current list of categories reflect the main construction elements that drive the environmental impact of a project. SD+ Marketplace will
            continue to build on this and add new categories and products as we collect and verify more available data. If you have a specific request and would
            like to see a specific category or product listed, please reach out to us using the ‘contact us’ feature on the top right corner from any page or
            under the menu page on the mobile version.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>The product page has missing information. How can I get that?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            We continue to collect and vet the information available to us for the products listed on SD+ Marketplace. This is a continuous process, where we
            try to be comprehensive but relevant where it’s possible to do so. If you would like to see a specific type of data on a product that is not
            available, please each out to us using the ‘contact us’ feature on the top right corner from any page or under the menu page on the mobile version.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>I can’t find a specific product that I am looking for. What do I do?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            If you don’t find a product that you are looking for please let us know and we can attend to that request or provide you with the information you
            need. From any product list page, click on ‘Let us know what we can add’ at the bottom of the page. You will be presented with a form where you can
            input your information and provide us with the details of your request.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>Where can I learn more about sustainable materials and how to use them?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            SD+ Marketplace aims to ease discovery of sustainable materials AND share our expertise on how to use them in your projects. You can visit our
            ‘Resources’ page from the link on the top bar from any page and you will be directed to out Knowledge Hub where we have a collection of guides and
            white papers that can help you become a subject matter expert on sustainable materials and solutions.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>How can I get samples or purchase the product for my project?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Currently, SD+ Marketplace does not handle transactions and sample requests. We offer access to vendor contact information. If you would like to
            contact a vendor to obtain a sample or purchase their product, please click on ‘Get in touch’ from the product page and follow the prompts.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>Is the information on SD+ Marketplace free to use?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Users of SD+ Marketplace are encouraged to access and read the information provided to learn and discover new products with the intent of selecting
            and using them in their projects. Further utilization of the information is governed by the terms and conditions defined at
            www.sdplusmarketplace.com/terms.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>I am a vendor, how can I have my own products listed?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            To become a partner vendor on SD+ Marketplace, please click on ‘Become a vendor’ from the top bar or menu on the mobile version. You will be
            directed to a form where you can provide us your information and our team will reach out to you over email to connect.
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6">
            <b>What are the benefits of becoming a vendor on the marketplace?</b>
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '28px' }}>
            Unlike other material marketplaces, our users are actively interested in sustainable products. They represent the top developers and project teams
            of India that are innovating on the forefront of the sustainability movement. If you are a vendor developing and selling products which have a
            positive impact on climate change, becoming a partner vendor and listing your products on SD+ Marketplace will provide you with a source or warm
            leads for your sales pipeline.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FaqData;
