import { Typography, makeStyles, withWidth } from '@material-ui/core';
import React from 'react';
import ProductTabs, { ProductTab } from './ProductTabs';
import { Tabs } from './ProductDetailsTable';
import useScreenType from '../../../../../hooks/checkScreenType';

const ProductDetailsTabs = (props) => {
  const { tabvalue, handleChange, width } = props;
  const { isMobile } = useScreenType(width);

  const useStyles = makeStyles((theme) => ({
    design: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
      padding: 0,
    },
    design2: {
      borderRight: !isMobile ? '1px solid rgba(0, 0, 0, 0.14)' : 0,
      borderSpacing: '10px',
      borderCollapse: 'separate',
      paddingRight: 30,
      paddingLeft: 0,
    },
  }));

  const classes = useStyles();

  return (
    <ProductTabs value={tabvalue && tabvalue} onChange={handleChange} aria-label="ant example" className={classes.design}>
      {Tabs &&
        Tabs.map(({ id, name }, index) => {
          return (
            <ProductTab
              className={index != Tabs.length - 1 ? classes.design2 : null}
              key={index}
              disableRipple
              value={id}
              label={
                tabvalue === id ? (
                  <Typography variant="h6">
                    <b>{name}</b>
                  </Typography>
                ) : (
                  <Typography variant="h6" color="textSecondary">
                    {name}
                  </Typography>
                )
              }
            />
          );
        })}
    </ProductTabs>
  );
};

export default withWidth()(ProductDetailsTabs);
