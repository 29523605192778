import React, { useEffect, useState } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import { Paper } from '@material-ui/core';

const ProjectsService = () => {
  const [projects, setProjects] = useState('');
  const [allUsers, setAllUsers] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest('/projectv2', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setProjects(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    axios.get(`/authv2/whoami`).then((res) => {
      if (res.data && res.data.data && res.data.data.data) {
        setAllUsers(res.data.data.data.allusers);
      }
    });
  }, []);

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Project Management - {projects.data ? projects.data.length : 0}</Typography>
        </Grid>
        <Grid item xs={5} align="left"></Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12}>
        {projects &&
          projects.data.map((data) => {
            return (
              <>
                <Grid container direction="column" item xs={12} style={{ paddingBottom: '10px' }}>
                  <Paper>
                    <Grid container item xs={12} style={{ height: '50px', paddingTop: '17px', paddingLeft: '20px' }}>
                      <Grid item xs={4}>
                        {data.name}
                      </Grid>
                      <Grid item xs={2}>
                        {data.createdAt.slice(0, 10)}
                      </Grid>
                      <Grid item xs={2}>
                        {/* <Button size="small" color="primary" onClick={() => handleDelete(data._id)}>
                          Delete
                        </Button> */}
                        {allUsers && allUsers.find((user) => user._id === data.createdBy)
                          ? allUsers && allUsers.find((user) => user._id === data.createdBy).name
                          : 'NA'}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() =>
                            swal({
                              title: `Are you sure you want to delete the project - ${data.name}`,
                              icon: 'warning',
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                axios.delete(`/HouseKeeping/removeproject/${data._id}`).then((re) => {
                                  if (re.data && re.data.data) {
                                    swal(`Successfully deleted Project - ${data.name}`, {
                                      icon: 'success',
                                    });
                                    makeRequest();
                                  }
                                });
                              }
                            })
                          }
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
};
export default ProjectsService;
