import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '0px 100px 100px 0px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

export default function CustomizedBar({ value, bgcolor, barvalue, insideColor, percentValue }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 20,
      background: `${insideColor ? insideColor : '#FFFFFF'}`,
      borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: '0px 100px 100px 0px',
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Grid item xs={7}>
          <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
        </Grid>
        {/* <Grid item xs={1} /> */}
        <Grid item xs={5} style={{ paddingLeft: '3%' }}>
          <Typography style={{ color: `${bgcolor}`, fontSize: '13px' }}>
            <b>{percentValue ? `${Math.round(value)} %` : `${value} Cr`}</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
