import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

function ShowMandates({ mandates, mandateicon, style }) {
  const classes = useStyles();
  return mandates && mandates[0] && mandates[0].value[0] !== ''
    ? mandates[0].value.map((mandate) => {
        return (
          <Grid container item xs={12} style={style} justifyContent="flex-start">
            <Grid item xs={0} style={{ paddingLeft: '2%', paddingRight: '2%' }}>
              <img src={mandateicon} height="30" alt="..." />
            </Grid>
            <Grid item xs={11}>
              {mandate.includes('<br>') ? (
                mandate.split('<br>').map((d) => {
                  return (
                    <>
                      <Typography className={classes.mandatetext}>{d}</Typography>
                    </>
                  );
                })
              ) : (
                <Typography className={classes.mandatetext}>{mandate}</Typography>
              )}
            </Grid>
          </Grid>
        );
      })
    : '';
}

export default ShowMandates;

const useStyles = makeStyles((theme) => ({
  mandatetext: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.714286px',
    color: '#2E2E2E',
  },
}));
