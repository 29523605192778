import { Container, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import HeaderAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors } from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ProductsPageContext } from '../../../../../routing/IndexRouting';

const PrivacyPolicyData = () => {
  const { handleShowContactUs } = useContext(ProductsPageContext);
  const history = useHistory();
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -80;
      const offsetPosition = section.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">Privacy Policy</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>

        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              This privacy notice for Smarter Dharma ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your
              information when you use our services ('Services'), such as when you:
            </Typography>
            <ul>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Visit our website at{' '}
                  <a href="https://www.sdplus.io" style={{ color: 'black' }} target="_blank">
                    https://www.sdplus.io
                  </a>
                  , or any website of ours that links to this privacy notice
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Engage with us in other related ways, including any sales, marketing, or events
                </Typography>
              </li>
            </ul>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies
              and practices, please do not use our Services. If you still have any questions or concerns, please contact us at{' '}
              <a href="mailto:connect@sdplus.io" style={{ color: 'black' }}>
                connect@sdplus.io
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', lineHeight: '28px', marginTop: '3%' }}>
            <Typography variant="h6">SUMMARY OF KEY POINTS</Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link
              following each key point or by using our{' '}
              <a
                href="#table_of_contents"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('table_of_contents');
                }}
                style={{ color: 'black' }}
              >
                table of contents
              </a>{' '}
              below to find the section you are looking for.
            </Typography>
          </Grid>
        </Grid>

        {/* Type 1 */}
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>What personal information do we process? </b> When you visit, use, or navigate our Services, we may process personal information depending on
              how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about{` `}
              <a
                href="#personal_information"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('1');
                }}
                style={{ color: 'black' }}
              >
                personal information you disclose to us
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Do we process any sensitive personal information? </b> We do not process sensitive personal information.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Do we receive any information from third parties? </b> We do not receive any information from third parties.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>How do we process your information?</b>We process your information to provide, improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so. Learn more about{' '}
              <a
                href="#2"
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('2');
                }}
              >
                how we process your information
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and
              with specific categories of third parties. Learn more about{' '}
              <a
                href="#3"
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('3');
                }}
              >
                when and with whom we share your personal information
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>How do we keep your information safe? </b> We have organisational and technical processes and procedures in place to protect your personal
              information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we
              cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Learn more about{' '}
              <a
                href="#6"
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('6');
                }}
              >
                {' '}
                how we keep your information safe
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>What are your rights? </b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights
              regarding your personal information. Learn more about{' '}
              <a
                href="#8"
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('8');
                }}
              >
                your privacy rights
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>How do you exercise your rights? </b> The easiest way to exercise your rights is by visiting https://sdplus.io/marketplace/contact-us, or by
              contacting us. We will consider and act upon any request in accordance with applicable data protection laws. Want to learn more about what we do
              with any information we collect?{' '}
              <a
                href="#1"
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('1');
                }}
              >
                Review the privacy notice in full
              </a>
            </Typography>
          </Grid>
        </Grid>

        {/* Type 2 */}
        <div id="table_of_contents">
          <Grid container alignItems="flex-start" justifyContent="flex-start">
            <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
              <Typography variant="h5">
                <b>TABLE OF CONTENTS</b>
              </Typography>
            </Grid>
            <Grid xs={12} style={{ marginLeft: '4%' }}>
              <ol>
                <li>
                  <a
                    href="#1"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('1');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      WHAT INFORMATION DO WE COLLECT?
                    </Typography>
                  </a>
                </li>
                <li id="how_we_process_your_information">
                  <a
                    href="#2"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('2');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      HOW DO WE PROCESS YOUR INFORMATION?
                    </Typography>
                  </a>
                </li>
                <li id="when_and_with_whom_we">
                  <a
                    href="#3"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('3');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#4"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('4');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#5"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('5');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#6"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('6');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#7"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('7');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      DO WE COLLECT INFORMATION FROM MINORS?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#8"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('8');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      WHAT ARE YOUR PRIVACY RIGHTS?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#9"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('9');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      CONTROLS FOR DO-NOT-TRACK FEATURES
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#10"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('10');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      DO WE MAKE UPDATES TO THIS NOTICE?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#11"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('11');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </Typography>
                  </a>
                </li>
                <li>
                  <a
                    href="#12"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection('12');
                    }}
                    style={{ color: 'black' }}
                  >
                    <Typography variant="h6" style={{ lineHeight: '28px' }}>
                      HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </Typography>
                  </a>
                </li>
              </ol>
            </Grid>
          </Grid>
        </div>

        {/* Type 3 */}
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="1">1. WHAT INFORMATION DO WE COLLECT?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', marginTop: '2%', marginBottom: '2%' }}>
              <span id="personal_information">Personal information you disclose to us</span>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px' }}>
              <b>In Short: </b>We collect personal information that you provide to us.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products
              and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the
              Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <ul>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px' }}>
                  names
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px' }}>
                  email addresses
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px' }}>
                  location
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px' }}>
                  website link
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px' }}>
                  brochure link
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Sensitive Information. </b>We do not process sensitive information. All personal information that you provide to us must be true, complete, and
              accurate, and you must notify us of any changes to such personal information. Information automatically collected
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '2%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific
              identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use
              our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and
              for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies. The
              information we collect includes:
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginTop: '3%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>The information we collect includes:</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <ul>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect
                  when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP
                  address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps
                  associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event
                  information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services.
                  Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application
                  identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system
                  configuration information.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much
                  information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other
                  technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to
                  collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you
                  choose to opt out, you may not be able to use certain aspects of the Services.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="2">2. HOW DO WE PROCESS YOUR INFORMATION?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short: </b>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your information for other purposes with your consent.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <ul>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential
                  issues you might have with the requested service.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To send administrative information to you. We may process your information to send you details about our products and services, changes to our
                  terms and policies, and other similar information.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication
                  with another user.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if
                  this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see '
                  <a href="#8" style={{ color: 'black' }}>
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                  ' below.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored
                  to your interests, location, and more.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud
                  monitoring and prevention.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is
                  necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services,
                  products, marketing, and your experience.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can
                  improve them.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide
                  marketing and promotional campaigns that are most relevant to you.
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and
                  exercise, establish, or defend our legal rights.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="3">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short: </b> We may share information in specific situations described in this section and/or with the following categories of third parties.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with third-party vendors, service providers,
              contractors, or agents (third parties') who perform services for us or on our behalf and require access to such information to do that work. We
              have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do
              anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any
              organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The
              categories of third parties we may share personal information with are as follows:
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <ul>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Ad Networks
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Affiliate Marketing Programs
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Cloud Computing Services
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Communication & Collaboration Tools
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Data Analytics Services
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Data Storage Service Providers
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Government Entities
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Performance Monitoring Tools
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Product Engineering & Design Tools
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Retargeting Platforms
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Sales & Marketing Tools
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Social Networks
                </Typography>
              </li>
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Testing Tools
                </Typography>
              </li>{' '}
              <li>
                <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
                  Website Hosting Service Providers
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.
              <br /> We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="5">5. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b>
              We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law. We will
              only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention
              period is required or permitted by law (such as tax, accounting, or other legal requirements).
              <br />
              <br /> When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information,
              or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your
              personal information and isolate it from any further processing until deletion is possible.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="6">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> We aim to protect your personal information through a system of organisational and technical security measures.
              <br /> We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any
              personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the
              Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
              other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own
              risk. You should only access the Services within a secure environment.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px' }}>
              <b id="7">7. DO WE COLLECT INFORMATION FROM MINORS?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.
              <br />
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18
              or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal
              information from users less than 18 years of age has been collected, we will take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected from children under age 18, please contact us at{' '}
              <a href="mailto:connect@sdplus.io" style={{ color: 'black' }}>
                connect@sdplus.io
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="8">8. WHAT ARE YOUR PRIVACY RIGHTS?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> You may review, change, or terminate your account at any time.
              <br />
              Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent
              depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting
              us by using the contact details provided in the section '
              <a href="#11" style={{ color: 'black' }}>
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              ' below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows,
              will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </Typography>
          </Grid>

          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at any time
              by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section '
              <a href="#11" style={{ color: 'black' }}>
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              ' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related
              messages that are necessary for the administration and to respond to service requests, or for other non-marketing purposes.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '2%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
              browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services
              of our Services.
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              If you have questions or comments about your privacy rights, you may email us at{' '}
              <a href="mailto:connect@sdplus.io" style={{ color: 'black' }}>
                connect@sdplus.io
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to
              signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology
              standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other
              mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow
              in the future, we will inform you about that practice in a revised version of this privacy notice.
            </Typography>
          </Grid>
        </Grid>
        {/* 10 */}
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="10">10. DO WE MAKE UPDATES TO THIS NOTICE?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
            </Typography>
          </Grid>

          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version
              will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting
              a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
              how we are protecting your information.
            </Typography>
          </Grid>
        </Grid>
        {/* 11 */}
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="11">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              If you have questions or comments about this notice, you may email us at{' '}
              <a href="mailto:connect@sdplus.io" style={{ color: 'black' }}>
                connect@sdplus.io
              </a>
            </Typography>
          </Grid>
        </Grid>
        {/* 12 */}
        <Grid container alignItems="flex-start" justifyContent="flex-start" style={{ marginBottom: '4%' }}>
          <Grid xs={12} style={{ marginLeft: '4%', marginBottom: '3%', marginTop: '2%' }}>
            <Typography variant="h5" style={{ lineHeight: '28px', textAlign: 'justify' }}>
              <b id="12">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b>
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '4%' }}>
            <Typography variant="h6" style={{ lineHeight: '28px' }}>
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change
              that information, or delete it. To request to review, update, or delete your personal information, please{`  `}
              <a style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleShowContactUs(true)}>
                contact-us
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PrivacyPolicyData;
