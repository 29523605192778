import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../routing/IndexRouting';

const checkPermission = (userRole, allowedRoles) => {
  return allowedRoles.includes(userRole);
};
const Loader = (WrappedComponent, props) => {
  const { userDetails } = useContext(UserContext);
  return function Loader(role) {
    const [permission, setPermission] = useState(false);
    useEffect(() => {
      setPermission(checkPermission(userDetails.role, role));
    }, [role]);
    return !permission ? <div></div> : <WrappedComponent {...props} />;
  };
};

export default Loader;
