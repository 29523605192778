import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const ComponentTitle = ({ title, marginbottom }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      marginBottom: theme.spacing(marginbottom),
    },
    title: {
      // color: '#666666',
      // letterSpacing: '1px',
    },
  }));
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant="h5">{title}</Typography>
    </Grid>
  );
};

export default ComponentTitle;
