import React from 'react';
import Carousal from '../../../../common/Carousal/Carousal';
import { Grid, withWidth } from '@material-ui/core';
// import LeftArrow from '../../../../common/LeftArrow';
// import RightArrow from '../../../../common/RightArrow';

function CarousalDesktop({ updateCurrentSlide, next, prev, currentSlide, leftarrow, rightarrow }, props) {
  const { width } = props;

  return (
    <Grid container direction="row" item xs={12} alignItems="center" justifyContent="center" style={{ paddingBottom: '1%', textAlign: 'center' }}>
      <Carousal
        renderArrowNext={(onClickHandler, hasNext, label) => hasNext}
        renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev}
        width={width}
        next={next}
        prev={prev}
        updateCurrentSlide={updateCurrentSlide}
        currentSlide={currentSlide}
      />
    </Grid>
  );
}

export default withWidth()(CarousalDesktop);

// arrow removed when needed enable this
// const leftarrowStyles = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: 'auto',
// };
// const rightarrowStyles = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: 'auto',
// };
// const imageStyles = {
//   margin: 'auto',
//   display: 'block',
//   cursor: 'pointer',
// };
{
  /* <LeftArrow leftarrow={leftarrow} prev={prev} arrowStyles={leftarrowStyles} imageStyles={imageStyles} /> */
}
{
  /* <Grid item xs={11}>
        <Carousal
          renderArrowNext={(onClickHandler, hasNext, label) => hasNext}
          renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev}
          width={width}
          next={next}
          prev={prev}
          updateCurrentSlide={updateCurrentSlide}
          currentSlide={currentSlide}
        />
      </Grid> */
}
{
  /* <RightArrow rightarrow={rightarrow} next={next} arrowStyles={rightarrowStyles} imageStyles={imageStyles} /> */
}
