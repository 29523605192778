import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import ComplexPIPchildren from './ComplexPIPChildren';
import { CAPEXPIPsForWWB } from '../EditProjectCommon';

const penthHousePIPName = 'project__pi_penthouseEnabled';

function ComplexPIPS(props) {
  const { index, field, projectPIP, appendChanged, type, PIPs, subcategory, pipValues } = props;
  const capexPips = PIPs.filter((d) => d.name === CAPEXPIPsForWWB.OrganicComposter || d.name === CAPEXPIPsForWWB.Composting);

  const radioButtonValue = pipValues[field?._id];
  return (
    <>
      <Grid container direction="row" item xs={12} key={index} style={{ height: 'Auto', marginLeft: '2%', marginBottom: '2%' }}>
        <Grid item alignItems="left" xs={12}>
          {field?.name === penthHousePIPName && subcategory !== 'villa' ? (
            <Typography variant="h5">
              <b>Penthouse Details</b>
            </Typography>
          ) : (
            <Typography variant="h5">{field.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
          )}
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
            <Typography
              variant="h6"
              style={{
                lineHeight: '22px',
                letterSpacing: '0.571429px',
                color: 'rgba(0, 0, 0, 0.38)',
                maxWidth: '750px',
              }}
            >
              {field.description}
            </Typography>
          </Grid>
        ) : null}
        {field?.name === penthHousePIPName && subcategory !== 'villa' && (
          <Grid container alignItems="center" justifyContent="flex-start" item xs={12} style={{ marginBottom: '1%' }}>
            <Grid item xs={1} container alignItems="center" justifyContent="flex-start">
              <Checkbox
                checked={pipValues[field._id]}
                style={{ color: '#66E3BE', marginLeft: '-10%' }}
                onChange={(e) => {
                  appendChanged(field._id, e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={6} container alignItems="center" justifyContent="flex-start" alignContent="flex-start">
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', marginLeft: '-10%' }}>
                {field?.displayName}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" item xs={12} key={index + 1} style={{ height: 'Auto' }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={radioButtonValue || ''} onChange={(event) => appendChanged(field._id, event.target.value)}>
              {field.options &&
                field.options.map((option, index) => {
                  if (option === 'Default') {
                    return <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />;
                  } else if (option === 'Organic Waste Composter') {
                    return (
                      <>
                        <FormControlLabel value={option || ''} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Organic Waste Composter' &&
                          radioButtonValue === 'Organic Waste Composter' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.OrganicComposter)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  } else if (option === 'Natural Composting') {
                    return (
                      <>
                        <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Natural Composting' &&
                          radioButtonValue === 'Natural Composting' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.Composting)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  }
                })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default ComplexPIPS;
