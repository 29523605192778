import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import HomeLandingImage2 from '../../../../assets/HomeLandingImage2.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  textTitle: {
    lineHeight: '40px',
    letterSpacing: '1px',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  button: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '70%',
    height: '6vh',
    marginTop: '8%',
  },
  buttonText: {
    color: '#FFFFFF',
    WebkitTextFillColor: '#FFFFFF',
  },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo2: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  typo3: {
    lineHeight: '30px',
  },
}));

function Home3(props) {
  const classes = useStyles();

  return (
    <>
      <section id="features">
        <Grid item xs={12} className={classes.navbar2}></Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
            <div data-aos="fade-right">
              <img src={HomeLandingImage2} style={{ width: '100%', marginLeft: '-10%' }} alt="homepageBigTwo" />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div data-aos="fade-left">
              <Typography className={classes.textTitle} variant="h3">
                Integrate Sustainable <br />
                Solutions Into Design
                <br />
                <br />
              </Typography>
            </div>
            <div data-aos="fade-right" style={{ textAlign: 'left' }}>
              <Typography className={classes.typo3} variant="h5">
                Discover the best sustainable solutions <br /> for your project & seamlessly integrate
                <br /> into your design. <br /> <br /> Make informed decisions on solutions to <br /> adopt by understanding environmental
                <br /> impact & cost-benefit analysis.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
export default Home3;
