import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { usePrevious } from '../../hooks/useCategoryDetailsData';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import Footer from '../../footer/MarketplaceFooter';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 1000,
    border: 'none',
    boxShadow: 'none',
    paddingTop: '8%',
  },
  arrowstyle: {
    fontSize: '180%',
    color: MarketplaceColors.Secondary,
    cursor: 'pointer',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    // paddingLeft: '2%',
    // paddingRight: '3%',
  },
  mainheading: MarketplaceTypography.PageHeader,
  padding: {
    paddingLeft: '3%',
  },
  subHeading: MarketplaceTypography.Heading3,
  paddingforsubheads: {
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  body: MarketplaceTypography.BodyRegularForTandC,
  bodystyle: {
    textAlign: 'justify',
  },
  tablelist: MarketplaceTypography.BodyRegular,
}));

function LegalTerms() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const handleNavigate = () => {
    // console.log(previousLocation, 'previousLocation');
    history.goBack();
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -60;
      const offsetPosition = section.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <MarketplaceNavbar />
      <Paper className={classes.paper}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item className={classes.headerStyle}>
          <ArrowBackIosIcon className={classes.arrowstyle} onClick={handleNavigate} />
          {/*  onClick={() => history.push('/marketplace')} */}
          <Grid item xs={11}>
            <Typography className={classes.mainheading}>Terms & Conditions</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.padding}>
          <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>Agreement to Our Legal Terms</Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            We are Smarter Dharma ('Company', 'we', 'us'; or 'our'). We operate the website ‘Smarter Dharma Marketplace’ (the 'Site' or the ‘Marketplace’) with
            the url: https://www.sdplus.io/marketplace, as well as any other related products and services that refer or link to these legal terms (the 'Legal
            Terms') (collectively, the 'Services').
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            ‘Smarter Dharma Marketplace’ is India's first sustainable materials and solutions marketplace allowing developers to discover, compare and choose
            sustainable materials and solutions, and connect with vendors to source materials and solutions for their construction projects. Sustainable
            material and solution vendors can use the Marketplace to feature their products.
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            You can contact us by email at connect@sdplus.io.
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Smarter Dharma,
            concerning your access to and use of the Services. You agree that by accessing the Services; you have read, understood, and agreed to be bound by
            all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by
            reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We
            will alert you about any changes by updating the 'Last updated' date of these Legal Terms; and you waive any right to receive specific notice of
            each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will
            be deemed to have been made aware of and to have accepted the changes in any revised Legal Terms by your continued use of the Services after the
            date such revised Legal Terms are posted.
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use the Services.
          </Typography>
          <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
            We recommend that you print a copy of these Legal Terms for your records.
          </Typography>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction="column">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`} style={{ textAlign: 'left' }}>
              TABLE OF CONTENTS
            </Typography>
            <ol style={{ listStyle: 'number', paddingLeft: '3%', marginTop: '-1%' }}>
              <li className={classes.tablelist}>
                <a
                  href="#section1"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section1');
                  }}
                  style={{ color: 'black' }}
                >
                  OUR SERVICES
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section2"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section2');
                  }}
                  style={{ color: 'black' }}
                >
                  INTELLECTUAL PROPERTY RIGHTS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section3"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section3');
                  }}
                  style={{ color: 'black' }}
                >
                  USER REPRESENTATIONS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section4"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section4');
                  }}
                  style={{ color: 'black' }}
                >
                  PRODUCTS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section5"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section5');
                  }}
                  style={{ color: 'black' }}
                >
                  PROHIBITED ACTIVITIES
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section6"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section6');
                  }}
                  style={{ color: 'black' }}
                >
                  USER GENERATED CONTRIBUTIONS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section7"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section7');
                  }}
                  style={{ color: 'black' }}
                >
                  CONTRIBUTION LICENSE
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section8"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section8');
                  }}
                  style={{ color: 'black' }}
                >
                  THIRD-PARTY WEBSITES AND CONTENT
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section9"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section9');
                  }}
                  style={{ color: 'black' }}
                >
                  SERVICES MANAGEMENT
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section10"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section10');
                  }}
                  style={{ color: 'black' }}
                >
                  PRIVACY POLICY
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section11"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section11');
                  }}
                  style={{ color: 'black' }}
                >
                  TERM AND TERMINATION
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section12"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section12');
                  }}
                  style={{ color: 'black' }}
                >
                  MODIFICATIONS AND INTERRUPTIONS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section13"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section13');
                  }}
                  style={{ color: 'black' }}
                >
                  GOVERNING LAW
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section14"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section14');
                  }}
                  style={{ color: 'black' }}
                >
                  DISPUTE RESOLUTION
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section15"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section15');
                  }}
                  style={{ color: 'black' }}
                >
                  CORRECTIONS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section16"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section16');
                  }}
                  style={{ color: 'black' }}
                >
                  DISCLAIMER
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section17"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section17');
                  }}
                  style={{ color: 'black' }}
                >
                  LIMITATIONS OF LIABILITY
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section18"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section18');
                  }}
                  style={{ color: 'black' }}
                >
                  INDEMNIFICATION
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section19"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section19');
                  }}
                  style={{ color: 'black' }}
                >
                  USER DATA
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section20"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section20');
                  }}
                  style={{ color: 'black' }}
                >
                  ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section21"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section21');
                  }}
                  style={{ color: 'black' }}
                >
                  MISCELLANEOUS
                </a>
              </li>
              <li className={classes.tablelist}>
                <a
                  href="#section22"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('section22');
                  }}
                  style={{ color: 'black' }}
                >
                  CONTACT US
                </a>
              </li>
            </ol>
          </Grid>
          <div id="section1">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>1. OUR SERVICES</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country
              where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such
              jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are
              solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            </Typography>
          </div>
          <div id="section2">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>2. INTELLECTUAL PROPERTY RIGHTS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Our intellectual property</strong>
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality,
              software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 'Content'), as well as the trademarks,
              service marks, and logos contained therein (the 'Marks').
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws)
              and treaties in India and around the world.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Your use of our Services</strong>
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
              non-transferable, revocable license to:
            </Typography>
            <ul>
              <li className={`${classes.body} ${classes.bodystyle}`}>access the Services; and</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                download or print a copy of any portion of the Content to which you have properly gained access.
              </li>
            </ul>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              solely for your personal, non-commercial use or internal business purpose.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced,
              aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise
              exploited for any commercial purpose whatsoever, without our express prior written permission.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please
              address your request to: info@sdplus.io. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or
              Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice
              appears or is visible on posting, reproducing, or displaying our Content.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will
              terminate immediately.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Your submissions</strong>
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us
              and (b) obligations you have when you submit any content through the Services.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services
              ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be
              entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>You are responsible for what you submit:</strong> By sending us Submissions through any part of the Services you:
            </Typography>
            <ul>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services
                any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person
                or group, sexually explicit, false, inaccurate, deceitful, or misleading;
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                warrant that any such Submission is original to you or that you have the necessary rights and licenses to submit such Submissions and that you
                have full authority to grant us the above-mentioned rights in relation to your Submissions;
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                warrant and represent that your Submissions do not constitute confidential information.
              </li>
            </ul>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>
                You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of
                your breach of (a) this section, (b) any third party's intellectual property rights, or (c) applicable law.
              </strong>
            </Typography>
          </div>
          <div id="section3">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>3. USER REPRESENTATIONS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              By using the Services, you represent and warrant that:
            </Typography>
            <ol>
              <li className={`${classes.body} ${classes.bodystyle}`}>you have the legal capacity and you agree to comply with these Legal Terms;</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>you are not a minor in the jurisdiction in which you reside;</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                you will not access the Services through automated or non-human means, whether through a bot, script, or otherwise;
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>you will not use the Services for any illegal or unauthorized purpose; and</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>your use of the Services will not violate any applicable law or regulation.</li>
            </ol>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to refuse any and all current or future
              use of the Services (or any portion thereof).
            </Typography>
          </div>
          <div id="section4">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>4. PRODUCTS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the
              Services. However, we do not guarantee that the colors, features, specifications, cost, and details of the products will be accurate, complete,
              reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products.
              We reserve the right to discontinue the listing of any products at any time for any reason.
            </Typography>
          </div>
          <div id="section5">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>5. PROHIBITED ACTIVITIES</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in
              connection with any commercial endeavors except those that are specifically endorsed or approved by us.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              As a user of the Services, you agree not to:
            </Typography>
            <ul>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation,
                database, or directory without written permission from us.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use
                or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Use any information obtained from the Services in order to harass, abuse, or harm another person.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Make improper use of our support services or submit false reports of abuse or misconduct.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Engage in unauthorized framing of or linking to the Services.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters
                and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Services or
                modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data
                gathering and extraction tools.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Delete the copyright or other proprietary rights notice from any Content.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Attempt to impersonate another user or person.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission
                mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1 xl pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Copy or adapt the Services' software, including but not limited to PHP, HTML, JavaScript, or other code.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making
                up a part of the Services.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system,
                including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any
                unauthorized script or other software.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the
                purpose of sending unsolicited email, or under false pretenses.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor
                or commercial enterprise.
              </li>
            </ul>
          </div>
          <div id="section6">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>6. USER GENERATED CONTRIBUTIONS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              The Services do not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit,
              perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video,
              audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may
              be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated in accordance
              with the Services' Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:
            </Typography>
            <ul>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do
                not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights
                of any third party.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the
                Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name
                or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated
                by the Services and these Legal Terms.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Your Contributions are not false, inaccurate, or misleading.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings,
                or other forms of solicitation.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as
                determined by us).
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a
                specific person or class of people.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Your Contributions do not violate any applicable law, regulation, or rule.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions do not violate any applicable law concerning child pornography or are otherwise intended to protect the health or well-being
                of minors.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical
                handicap.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Your Contributions do not otherwise violate or link to material that violates any provision of these Legal Terms or any applicable law or
                regulation.
              </li>
            </ul>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension
              of your rights to use the Services.
            </Typography>
          </div>
          <div id="section7">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>7. CONTRIBUTION LICENCE</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              You and Services agree that we may access, store, process, and use any information and personal data that you provide following the terms of the
              Privacy Policy and your choices (including settings). By submitting suggestions or other feedback regarding the Services, you agree that we can
              use and share such feedback for any purpose without compensation to you.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property
              rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your
              Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services, and you expressly
              agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
            </Typography>
          </div>
          <div id="section8">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>8. THIRD-PARTY WEBSITES AND CONTENT</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              The Services may contain (or you may be sent via the Site) links to other websites ('Third-Party Websites') as well as articles, photographs,
              text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or
              originating from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or
              checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the
              Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy, offensiveness,
              opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Inclusion of, linking to, or permitting the use or purchase or installation of any Third-Party Websites or any Third-Party Content does not imply
              approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or purchase or install
              any Third-Party Content, you do so at your own risk, and you should be aware these Legal Terms no longer govern. You should review the applicable
              terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any
              applications or products you use or purchase or install from the Services.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility
              whatsoever in relation to such purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do
              not endorse the products or services offered on Third-Party Websites, and you shall hold us blameless from any harm caused by your purchase of
              such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or
              resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
            </Typography>
          </div>
          <div id="section9">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>9. SERVICES MANAGEMENT</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We reserve the right, but not the obligation, to:
            </Typography>
            <ol>
              <li className={`${classes.body} ${classes.bodystyle}`}>Monitor the Services for violations of these Legal Terms.</li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation,
                reporting such user to law enforcement authorities.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
                feasible) any of your Contributions or any portion thereof.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                In our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that
                are excessive in size or are in any way burdensome to our systems.
              </li>
              <li className={`${classes.body} ${classes.bodystyle}`}>
                Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
              </li>
            </ol>
          </div>
          <div id="section10">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>10. PRIVACY POLICY</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We care about data privacy and security. Please review our Privacy Policy:{' '}
              <a href="/marketplace/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                Privacy Policy
              </a>
              . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services
              are hosted in India. If you access the Services from any other region of the world with laws or other requirements governing personal data
              collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Services, you are transferring
              your data to India, and you expressly consent to have your data transferred to and processed in India.
            </Typography>
          </div>
          <div id="section11">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>11. TERM AND TERMINATION</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE
              RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN
              IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
              CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY
              CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              If we terminate or suspend your access to the Services for any reason, you are prohibited from accessing the Services, even if you may be acting
              on behalf of a third party. In addition to terminating or suspending your access, we reserve the right to take appropriate legal action, including
              without limitation pursuing civil, criminal, and injunctive redress.
            </Typography>
          </div>
          <div id="section12">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>12. MODIFICATIONS AND INTERRUPTIONS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without
              notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part
              of the Services without notice at any time. We will not be liable to you or any third party for any modification, suspension, or discontinuance of
              the Services.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform
              maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
              discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever
              for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the
              Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.
            </Typography>
          </div>
          <div id="section13">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>13. GOVERNING LAW</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              These Legal Terms shall be governed by and defined following the laws of India. Smarter Dharma and yourself irrevocably consent that the courts of
              India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these Legal Terms.
            </Typography>
          </div>
          <div id="section14">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>14. DISPUTE RESOLUTION</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Informal Negotiations</strong>
              <br />
              To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 'Dispute' and collectively,
              the 'Disputes') brought by either you or us (individually, a 'Party' and collectively, the 'Parties'), the Parties agree to first attempt to
              negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration.
              Such informal negotiations commence upon written notice from one Party to the other Party.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Binding Arbitration</strong>
              <br />
              Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination,
              shall be referred to and finally resolved by the Indian Council of Arbitration (ICA) in accordance with the Rules of the ICA, which, as a result
              of referring to it, is considered as a part of this clause. The number of arbitrators shall be one (1). The seat, or legal place, of arbitration
              shall be Bengaluru, India. The language of the proceedings shall be English. The governing law of these Legal Terms shall be the substantive law
              of India.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Restrictions</strong>
              <br />
              The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a)
              no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action
              basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative
              capacity on behalf of the general public or any other persons.
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              <strong>Exceptions to Informal Negotiations and Arbitration</strong>
              <br />
              The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a)
              any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute
              related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If
              this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this
              provision found to be illegal or unenforceable, and such Dispute shall be decided by a court of competent jurisdiction within the courts listed
              for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
            </Typography>
          </div>
          <div id="section15">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>15. CORRECTIONS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing,
              availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the
              information on the Services at any time, without prior notice.
            </Typography>
          </div>
          <div id="section16">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>16. DISCLAIMER</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
              EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
              LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
              INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE
              OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
              TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
              THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
              OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
              FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
              APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
              BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
              ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </Typography>
          </div>
          <div id="section17">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>17. LIMITATIONS OF LIABILITY</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
              INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE
              SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY
              YOU TO US. CERTAIN INDIAN LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
              DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </Typography>
          </div>
          <div id="section18">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>18. INDEMNIFICATION</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              You agree to defend, indemnify, and hold us harmless, including our affiliates, and all of our respective officers, agents, partners, and
              employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third
              party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties
              set forth in these Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or
              (5) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we
              reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you
              agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or
              proceeding which is subject to this indemnification upon becoming aware of it.
            </Typography>
          </div>
          <div id="section19">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>19. USER DATA</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data
              relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you
              transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or
              corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
            </Typography>
          </div>
          <div id="section20">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>
              20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic
              communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and
              on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
              CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
              VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by
              any means other than electronic means.
            </Typography>
          </div>
          <div id="section21">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>21. MISCELLANEOUS</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement
              and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver
              of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and
              obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond
              our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that
              provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining
              provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms
              or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any
              and all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these
              Legal Terms.
            </Typography>
          </div>
          <div id="section22">
            <Typography className={`${classes.subHeading} ${classes.paddingforsubheads}`}>22. CONTACT US</Typography>
            <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please email us at:
              connect@sdplus.io{' '}
            </Typography>
            {/* <Typography paragraph className={`${classes.body} ${classes.bodystyle}`}>
              Smarter Dharma
              <br />
              4th Floor, 27a, K.R. Colony
              <br />
              Krishna Reddy Layout, Domlur
              <br />
              Bengaluru, Karnataka 560071
              <br />
              India
              <br />
              Phone: +91 OOOOOOOOOO
              <br />
              <a href="mailto:info@sdplus.io">Email: info@sdplus.io</a>
            </Typography> */}
          </div>
        </Grid>
      </Paper>
      <Footer />
    </>
  );
}

export default LegalTerms;
