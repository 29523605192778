import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MarketplaceColors } from '../../../config';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { Default } from '../../../utils/Defaults';
import Links from '../../../common/Typography/Links';

const { Primary } = MarketplaceColors;

const useStyles = makeStyles((theme) => ({
  main: {
    borderRight: '1px solid #9E9E9E',
    paddingLeft: '2%',
  },
  heading: {
    color: Primary,
    cursor: 'pointer',
  },
}));

const SuperCategoriesLogicalGroup = ({ data, source }) => {
  const classes = useStyles();
  const { handleReset } = useContext(ProductsPageContext);
  // const { page, pagesize } = URLS;
  return (
    <Grid>
      {data &&
        data.map(({ attributes }) => (
          <Grid style={{ marginBottom: '3%' }}>
            <Links
              variant="string"
              href={`/marketplace/categoryDetails?source=${source}&supercategory=${attributes?.Name}&page=${Default.page}&pagesize=${Default.pagesize}`}
              tag={attributes?.Name}
              isBold={true}
              className={classes.heading}
              onClick={() => handleReset()}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default SuperCategoriesLogicalGroup;
