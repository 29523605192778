import React from 'react';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../footer/MarketplaceFooter';
import KnowledgeHubData from './KnowledgeHubData';

const ResourcesKnowledgeHub = () => {
  return (
    <>
      <MarketplaceNavbar />
      <KnowledgeHubData />
      <MarketplaceFooter />
    </>
  );
};

export default ResourcesKnowledgeHub;
