import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import certifid from '../../../../../../../assets/carousal/certified.svg';
import verified from '../../../../../../../assets/carousal/checked.svg';
import planet from '../../../../../../../assets/carousal/planet.svg';
import { MarketplaceColors, MarketplaceStyles } from '../../../../config';
import search from '../../../../../../../assets/carousal/Search.svg';

const Features = ({ isMobile }) => {
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontFamily: 'Lato',
      fontSize: isMobile ? '12px' : '20px',
      fontWeight: 700,
      lineHeight: isMobile ? '14px' : '24px',
      letterSpacing: '0.7142857313156128px',
      textAlign: 'left',
      color: MarketplaceColors.Primary,
      paddingTop: '2%',
      paddingBottom: '2%',
    },
    subhead: {
      fontFamily: 'Lato',
      fontSize: isMobile ? '10px' : '16px',
      fontWeight: 400,
      lineHeight: isMobile ? '15px' : '32px',
      letterSpacing: '0.7142857313156128px',
      textAlign: 'left',
      color: MarketplaceColors.Secondary,
    },
  }));
  const classes = useStyles();
  return (
    <Grid container item xs={12} direction="row" style={MarketplaceStyles.sectionPadding}>
      <Grid item xs={5} style={{ paddingLeft: isMobile ? '2%' : '5%' }}>
        <img src={search} alt="..." height={isMobile ? '20px' : '50px'} />
        <Typography className={classes.heading}>Discover</Typography>
        {isMobile ? (
          <>
            <Typography className={classes.subhead}>Find leading eco-friendly products</Typography>
            <Typography className={classes.subhead}>across various categories for your</Typography>
            <Typography className={classes.subhead}>project.</Typography>
          </>
        ) : (
          <>
            <Typography className={classes.subhead}>Find leading eco-friendly products</Typography>
            <Typography className={classes.subhead}>across various categories for your</Typography>
            <Typography className={classes.subhead}>project.</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={4}>
        <img src={verified} alt="..." height={isMobile ? '20px' : '50px'} />
        <Typography className={classes.heading}>Select</Typography>
        {isMobile ? (
          <>
            <Typography className={classes.subhead}>Easily evaluate environmental</Typography>
            <Typography className={classes.subhead}>impact, review product details,</Typography>
            <Typography className={classes.subhead}> and contact vendors.</Typography>
          </>
        ) : (
          <>
            <Typography className={classes.subhead}>Easily evaluate environmental</Typography>
            <Typography className={classes.subhead}>impact, review product details,</Typography>
            <Typography className={classes.subhead}> and contact vendors.</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={3} style={{ paddingRight: isMobile ? '3%' : '0%' }}>
        <img src={planet} alt="..." height={isMobile ? '20px' : '50px'} />
        <Typography className={classes.heading}>Learn</Typography>
        {isMobile ? (
          <>
            <Typography className={classes.subhead}>Become an expert in sustainable</Typography>
            <Typography className={classes.subhead}>construction with product</Typography>
            <Typography className={classes.subhead}>selection guides and articles on</Typography>
            <Typography className={classes.subhead}>latest innovations.</Typography>
          </>
        ) : (
          <>
            <Typography className={classes.subhead}>Become an expert in sustainable</Typography>
            <Typography className={classes.subhead}>construction with product</Typography>
            <Typography className={classes.subhead}>selection guides and articles on</Typography>
            <Typography className={classes.subhead}>latest innovations.</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Features;
