import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Grow } from '@material-ui/core';
import NavBar from '../commmon/NavBar';
import homepageBigOne from '../../../../assets/VERSION-TWO/landingPage/homepageBigOne.svg';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '24%',
  },
  typo2: {
    color: '#00CEFF',
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '5%',
  },
  button2: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '336px',
    height: '55px',
    borderRadius: '6px',
  },
  buttonText: {
    fontStyle: 'normal',
    fontSize: '17px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    WebkitTextFillColor: '#FFFFFF',
  },
  grid2: {
    marginTop: '-20%',
    textAlign: 'center',
  },
}));

const CustomColor = withStyles({
  root: {
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '5%',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
})(Typography);

function ResponsiveHome1({ handleRequestDemo }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);

  return (
    <>
      <Grid item xs={12} className={classes.navbar2}>
        <NavBar handleRequestDemo={handleRequestDemo} />
      </Grid>
      <Grid container item xs={12} direction="column" alignItems="flex-start" alignContent="flex-start">
        <div data-aos="fade-up">
          <CustomColor className={classes.typo2} variant="h3">
            Join us on our mission towards building a data-driven sustainable future
          </CustomColor>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.grid2}>
        <Grow in={checked} style={{ transformOrigin: '10 0 0' }} {...(checked ? { timeout: 2000 } : {})}>
          <img src={homepageBigOne} style={{ height: '80vh', width: '100%' }} alt="aaa" />
        </Grow>
      </Grid>
      <Grid item xs={12} className={classes.grid2} alignItems="center" alignContent="center">
        <Grow in={checked} style={{ transformOrigin: '0 0 10' }} {...(checked ? { timeout: 1000 } : {})}>
          <Button variant="contained" className={classes.button2} onClick={handleRequestDemo}>
            <Typography className={classes.buttonText}>request demo</Typography>
          </Button>
        </Grow>
      </Grid>
    </>
  );
}
export default ResponsiveHome1;
