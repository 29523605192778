export const Waste = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  return (
    <svg width={65} height={91} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={color}>
        <path d="M37.5002 34.3314C38.0377 34.6439 38.7315 34.4564 39.0471 33.9189C39.7815 32.6439 41.8284 32.6439 42.5658 33.9189L45.7471 39.4315L44.4346 38.694C43.8877 38.3815 43.2033 38.5784 42.894 39.1252C42.5877 39.669 42.7815 40.3627 43.3252 40.6658L48.1315 43.3721C48.3034 43.469 48.494 43.5159 48.6877 43.5159C48.8877 43.5159 49.0846 43.4627 49.2627 43.3596C49.6127 43.1533 49.8221 42.7783 49.819 42.3721L49.7565 36.8565C49.7502 36.2378 49.244 35.7378 48.6252 35.7378H48.6127C47.9877 35.7441 47.4877 36.2565 47.494 36.8815L47.5065 37.9503L44.5252 32.7877C43.7502 31.444 42.3565 30.6409 40.8065 30.6409C39.2565 30.6409 37.8659 31.444 37.0878 32.7877C36.7721 33.3284 36.9565 34.019 37.5002 34.3314Z" />
        <path d="M33 50.8873C33.625 50.8873 34.1313 50.3811 34.1313 49.7561C34.1313 49.1311 33.625 48.6248 33 48.6248C32.2656 48.6248 31.6063 48.2436 31.2438 47.6123C30.875 46.9748 30.875 46.2185 31.2438 45.581L34.425 40.0716L34.4407 41.5747C34.4469 42.1935 34.9532 42.6935 35.5719 42.6935H35.5844C36.2094 42.6872 36.7094 42.1747 36.7032 41.5497L36.6438 36.031C36.6375 35.631 36.4188 35.256 36.0688 35.0591C35.7157 34.8591 35.2844 34.8654 34.9375 35.0716L30.1906 37.8842C29.6531 38.2029 29.475 38.8967 29.7938 39.4342C30.1125 39.9685 30.8063 40.1498 31.3438 39.831L32.2656 39.2873L29.2844 44.4498C28.5094 45.7936 28.5094 47.3998 29.2844 48.7436C30.0562 50.0842 31.4469 50.8873 33 50.8873L33 50.8873Z" />
        <path d="M49.1722 44.9627C48.6284 45.2752 48.444 45.9658 48.7597 46.5064C49.1284 47.1408 49.1284 47.9002 48.7597 48.5377C48.3909 49.1721 47.7347 49.5502 47.0034 49.5502H40.644L41.9377 48.7877C42.4752 48.469 42.6533 47.7752 42.3346 47.2377C42.019 46.7065 41.3221 46.5252 40.7846 46.8408L36.0377 49.6534C35.6877 49.8596 35.4784 50.2346 35.4815 50.6408C35.4877 51.0471 35.7065 51.4158 36.0565 51.6127L40.8627 54.319C41.0377 54.419 41.2284 54.4627 41.4159 54.4627C41.8096 54.4627 42.1971 54.2565 42.4033 53.8877C42.7096 53.344 42.5158 52.6564 41.9721 52.3471L41.0346 51.819H47.0034C48.5565 51.819 49.9471 51.0159 50.7221 49.6721C51.4971 48.3284 51.4971 46.7221 50.7221 45.3784C50.4033 44.8346 49.7065 44.644 49.1721 44.9628L49.1722 44.9627Z" />
        <path d="M49.1812 66.92C49.1812 69.0825 50.9437 70.8419 53.1062 70.8419C55.2686 70.8419 57.028 69.0825 57.028 66.92C57.028 64.7575 55.2686 62.995 53.1062 62.995C50.9436 62.9981 49.1812 64.7544 49.1812 66.92ZM54.7656 66.92C54.7656 67.8356 54.0218 68.5763 53.1062 68.5763C52.1905 68.5763 51.4468 67.8325 51.4468 66.92C51.4468 66.0044 52.1905 65.2606 53.1062 65.2606C54.0218 65.2606 54.7656 66.0043 54.7656 66.92Z" />
        <path d="M61.7812 16.4439H57.6L56.5156 9.96893C56.0468 7.16266 53.6406 5.1252 50.7937 5.1252L29.2064 5.12207C26.3626 5.12207 23.9564 7.15954 23.4845 9.9658L22.4001 16.4408H18.2188C16.8157 16.4408 15.6782 17.5783 15.6782 18.9814V22.7501C15.6782 24.1533 16.8157 25.2907 18.2188 25.2907H19.3626L24.9126 66.6314C25.3688 70.0939 28.35 72.7064 31.8499 72.7064H47.6812C49.1062 74.0408 51.0062 74.8751 53.1062 74.8751C57.4937 74.8751 61.0622 71.3064 61.0622 66.9191C61.0622 63.5723 58.9778 60.7129 56.0435 59.5411L60.6372 25.2918H61.7841C63.1872 25.2918 64.3247 24.1543 64.3247 22.7512V18.9825C64.3247 17.5825 63.1841 16.445 61.781 16.445L61.7812 16.4439ZM34.3999 7.38793H45.6032V9.9754C45.6032 10.4191 45.2407 10.7817 44.797 10.7817H35.2096C34.7659 10.7817 34.4034 10.4192 34.4034 9.9754L34.3999 7.38793ZM25.7126 10.3442C26.0001 8.63166 27.4688 7.38793 29.2 7.38793H32.1313V9.9754C32.1313 11.6691 33.5063 13.0441 35.2 13.0441H44.7874C46.4811 13.0441 47.8592 11.6691 47.8592 9.9754L47.8624 7.38793H50.7936C52.5311 7.38793 53.9968 8.63166 54.2811 10.3442L55.303 16.4442H24.6936L25.7126 10.3442ZM58.7999 66.9229C58.7999 70.0603 56.2468 72.6166 53.1062 72.6166C49.9687 72.6166 47.4124 70.0635 47.4124 66.9229C47.4124 63.7854 49.9687 61.2291 53.1062 61.2291C56.2436 61.2323 58.7999 63.7823 58.7999 66.9229ZM53.8249 59.0382C53.5843 59.0163 53.3499 58.9632 53.103 58.9632C48.7156 58.9632 45.147 62.5319 45.147 66.9192C45.147 68.1911 45.4752 69.3755 46.0096 70.4442H31.8536C29.4848 70.4442 27.4661 68.6786 27.1598 66.3317L21.6504 25.2877H58.3571L53.8249 59.0382ZM62.0622 22.7542C62.0622 22.9073 61.9372 23.0292 61.7841 23.0292H18.2188C18.0656 23.0292 17.9406 22.9042 17.9406 22.7542V18.9855C17.9406 18.8323 18.0656 18.7105 18.2188 18.7105H61.7874C61.9406 18.7105 62.0656 18.8355 62.0656 18.9855L62.0622 22.7542Z" />
      </g>
    </svg>
  );
};
