import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import ResetFields from '../common/textFiledwithReset';
const SwmCustomBasicParameters = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  const clubhouse = getQuantityValue(schema.swm__pi_clubhouseUtilitySensor);
  const supplySideTanker = getQuantityValue(schema.swm__pi_supplySideTankerUtilitySensor);
  const supplySideBorewell = getQuantityValue(schema.swm__pi_supplySideBorewellUtilitySensor);
  const other = getQuantityValue(schema.swm__pi_otherSensor);
  const otherSensorNumber = getQuantityValue(schema.swm__pi_userOtherSensor);
  return (
    <Grid item xs={12} container alignItems="flex-start" direction="row">
      <div style={{ display: 'flex' }}>
        <>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.swm_totalNumberOfSensors)}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
              <Typography>{getQuantityValue(schema.swm_totalNumberOfSensors)}</Typography>
              {/* <Typography style={{ marginLeft: '5%' }}>{getQuantityUnit(schema.swm_totalNumberOfSensors)}</Typography> */}
            </div>
          </Grid>
        </>
      </div>
      <div style={{ display: 'flex' }}>
        <Grid xs={12}>
          <Typography className={classes.textheader2}>Sensors at Utility Points</Typography>
          <div style={{ display: 'flex', marginTop: '2%' }}>
            {!clubhouse && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.clubhouseUtilitySensorEnabled', clubhouse === true ? 'NO' : 'YES');
                  // setClubhouse(!clubhouse);
                  updateQuantityValue(schema.swm__pi_clubhouseUtilitySensor, !clubhouse);
                }}
              />
            )}
            {clubhouse && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.clubhouseUtilitySensorEnabled', clubhouse === true ? 'NO' : 'YES');
                  // setClubhouse(!clubhouse);
                  updateQuantityValue(schema.swm__pi_clubhouseUtilitySensor, !clubhouse);
                }}
              />
            )}
            <Typography>
              {getQuantityLabel(schema.swm_clubhouse)} ({getQuantityValue(schema.swm_clubhouse)})
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!supplySideTanker && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.supplySideTankerUtilitySensorEnabled', supplySideTanker === true ? 'NO' : 'YES');
                  // setSupplySideTanker(!supplySideTanker);
                  updateQuantityValue(schema.swm__pi_supplySideTankerUtilitySensor, !supplySideTanker);
                }}
              />
            )}
            {supplySideTanker && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.supplySideTankerUtilitySensorEnabled', supplySideTanker === true ? 'NO' : 'YES');
                  // setSupplySideTanker(!supplySideTanker);
                  updateQuantityValue(schema.swm__pi_supplySideTankerUtilitySensor, !supplySideTanker);
                }}
              />
            )}
            <Typography>
              {getQuantityLabel(schema.swm_supplySideTanker)} ({getQuantityValue(schema.swm_supplySideTanker)})
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!supplySideBorewell && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.supplySideBorewellUtilitySensorEnabled', supplySideBorewell === true ? 'NO' : 'YES');
                  // setSupplySideBorewell(!supplySideBorewell);
                  updateQuantityValue(schema.swm__pi_supplySideBorewellUtilitySensor, !supplySideBorewell);
                }}
              />
            )}
            {supplySideBorewell && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.supplySideBorewellUtilitySensorEnabled', supplySideBorewell === true ? 'NO' : 'YES');
                  // setSupplySideBorewell(!supplySideBorewell);
                  updateQuantityValue(schema.swm__pi_supplySideBorewellUtilitySensor, !supplySideBorewell);
                }}
              />
            )}
            <Typography>
              {getQuantityLabel(schema.swm_supplySideBorewell)} ({getQuantityValue(schema.swm_supplySideBorewell)})
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!other && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.otherSensorEnabled', other === true ? 'NO' : 'YES');
                  // setOther(!other);
                  updateQuantityValue(schema.swm__pi_otherSensor, !other);
                }}
              />
            )}
            {other && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  // appendChanged('swm.otherSensorEnabled', other === true ? 'NO' : 'YES');
                  // setOther(!other);
                  updateQuantityValue(schema.swm__pi_otherSensor, !other);
                }}
              />
            )}
            <Typography>{getQuantityLabel(schema.swm__pi_otherSensor)}</Typography>
          </div>
          <div style={{ marginTop: '2%' }}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={otherSensorNumber}
              placeholder=""
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                // setOtherSensorNumber(e.target.value);
                // appendChanged('swm.others.user', Number(e.target.value));
                updateQuantityValue(schema.swm__pi_userOtherSensor, +e.target.value);
              }}
              onClick={(e) => {
                // setOtherSensorNumber(getValueFromId(selectedInterventionDetails, 'swm.others.default'));
                // appendChanged('swm.others.user', undefined);
                // updateQuantityValue(schema.swm__pi_userOtherSensor, +e.target.value);
              }}
              disabled={!other}
              disableTextField={!other}
              removeResetIcon={true}
            />
          </div>
        </Grid>
      </div>
    </Grid>
  );
};
export default SwmCustomBasicParameters;
