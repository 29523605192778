import React from 'react';
import Categories from './components/Categories';
import { MarketplaceStyles } from '../../../config';
import { Container, Grid, makeStyles } from '@material-ui/core';
import MarketplaceButton from '../../../common/Button';
import SectionHeader from '../../../common/SectionHeader';
import useScreenType from '../../../../../hooks/checkScreenType';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { sectionPadding } = MarketplaceStyles;
const ButtonWidth = '80%';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
    paddingBottom: sectionPadding.paddingBottom,
  },
}));

const CategoriesSpotlight = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { isMobile } = useScreenType();

  return (
    <Container maxWidth="xl">
      <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
        <SectionHeader Title="Products by Material Categories" Description="" />
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={10}
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={!isMobile ? { marginTop: '3%' } : { marginTop: '5%' }}
        >
          <Categories />
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
          alignItems="center"
          justifyContent="center"
          style={!isMobile ? { marginTop: '1%' } : { marginTop: '3%' }}
        >
          <Grid xs={12} sm={6} md={4} lg={4} xl={4}>
            <MarketplaceButton
              children="VIEW ALL CATEGORIES"
              style={{ width: ButtonWidth }}
              onClick={() => {
                history.push(`/marketplace/categories`);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoriesSpotlight;
