import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Links from '../../../../common/Typography/Links';
import { MarketplaceTypography } from '../../../../config';

const ImageAndSource = ({ source, image, imageStyle, justifyContent, classname }) => {
  return (
    <Grid container>
      <Grid xs={12} item container alignItems="flex-start" justifyContent={justifyContent ? justifyContent : 'center'}>
        <img src={image} alt="rrr" style={imageStyle} />
      </Grid>
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
        <Typography className={classname} style={{ textAlign: 'justify' }}>
          <span style={{ fontWeight: 'bold' }}>{source.split(':')[0]}:</span>
          {source.split(':')[1]}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ImageAndSource;
