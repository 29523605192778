import { NavigatorSource } from '../utils/Defaults';

export const DummyNavigatorData = {
  tabs: ['Products'], //  tabs: ['Products', 'Services'],
  columns: [
    {
      id: 1,
      source: NavigatorSource.categories,
      collections: [
        {
          name: 'Plumbing Fixtures',
          count: 169,
          subcategories: [
            { id: '134', attributes: { name: 'WCs' } },
            { id: '139', attributes: { name: 'Showers' } },
            { id: '140', attributes: { name: 'Accesories' } },
            { id: '141', attributes: { name: 'Faucets' } },
          ],
        },
        {
          name: 'Paints',
          count: 76,
          subcategories: [
            { id: '138', attributes: { name: 'Exterior Paint' } },
            { id: '144', attributes: { name: 'Interior Paint' } },
          ],
        },
        {
          name: 'Wall Blocks',
          count: 38,
          subcategories: [
            { id: '147', attributes: { name: 'Non Load Bearing' } },
            { id: '151', attributes: { name: 'Load Bearing' } },
          ],
        },
        {
          name: 'Flooring',
          count: 25,
          subcategories: [
            { id: '133', attributes: { name: 'Exterior Flooring' } },
            { id: '150', attributes: { name: 'Interior Flooring' } },
          ],
        },
        {
          name: 'Cement',
          count: 23,
          subcategories: [
            { id: '136', attributes: { name: 'CHD' } },
            { id: '137', attributes: { name: 'PPC' } },
            { id: '143', attributes: { name: 'PCC' } },
            { id: '146', attributes: { name: 'PSC' } },
            { id: '149', attributes: { name: 'OPC' } },
          ],
        },
        { name: 'Steel', count: 21, subcategories: [{ id: '145', attributes: { name: 'TMT Bars' } }] },
        {
          name: 'Glazing',
          count: 19,
          subcategories: [
            { id: '135', attributes: { name: 'Single Glazed' } },
            { id: '142', attributes: { name: 'Insulated Glazed' } },
            { id: '148', attributes: { name: 'Double Glazed' } },
          ],
        },
      ],
    },
    {
      id: 2,
      source: NavigatorSource.supercategories,
      collections: [
        { id: '2', attributes: { Name: 'Envelope' } },
        { id: '3', attributes: { Name: 'Structure' } },
        { id: '4', attributes: { Name: 'Interior' } },
        { id: '5', attributes: { Name: 'Finishes' } },
        { id: '7', attributes: { Name: 'Plumbing' } },
      ],
    },
    {
      id: 3,
      source: NavigatorSource.ecolabels,
      collections: [
        { id: '9', attributes: { name: 'ECBC' } },
        { id: '10', attributes: { name: 'Green Assure' } },
        { id: '11', attributes: { name: 'GreenCo' } },
        { id: '13', attributes: { name: 'Green Label' } },
        { id: '12', attributes: { name: 'GreenPro' } },
        { id: '14', attributes: { name: 'Griha' } },
        { id: '15', attributes: { name: 'IGBC' } },
        { id: '16', attributes: { name: 'LEED' } },
        { id: '17', attributes: { name: 'UIPC-I' } },
      ],
    },
  ],
  drawerData: [
    {
      id: 1,
      name: 'Products',
      data: [
        { id: 1, name: 'Wall Blocks', data: ['Hollow', 'Solid'] },
        { id: 2, name: 'Plumbing Fixtures', data: ['Showers', 'Plumbing', 'Faucets', 'WCs'] },
        { id: 3, name: 'Paints', data: ['Interior Paints', 'Exterior Paints'] },
        { id: 4, name: 'Glazing', data: ['Single Glazed', 'Double Glazed'] },
      ],
    },
    {
      id: 2,
      name: 'Services',
      data: [],
    },
    {
      id: 3,
      name: 'Certifications',
      data: [],
    },
    {
      id: 4,
      name: 'Envelope',
      data: [],
    },
    {
      id: 5,
      name: 'Interior',
      data: [],
    },
    {
      id: 6,
      name: 'Exterior',
      data: [],
    },
    {
      id: 7,
      name: 'Plumbing',
      data: [],
    },
    {
      id: 8,
      name: 'Appliances',
      data: [],
    },
  ],
};
