import React, { useEffect, useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import useApiRequest from '../../../hooks/useApiRequest';

export const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    border: '1px solid ' + theme.palette.grey[400],
    boxSizing: 'border-box',
    borderRadius: '10px',
    minWidth: '150px',
    maxWidth: '105px',
    '&:focus': {
      outline: 'none',
    },
    minHeight: '65px',
    textTransform: 'capitalize',
  },
  selected: {
    border: '1px solid ' + theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark + '!important',
    color: theme.palette.common.white + '!important',
  },
}))(ToggleButton);

export const IGBCCategories = [
  'Sustainable Design',
  'Water Conservation',
  'Energy Efficiency',
  'Material and Resources',
  'Resident Health And Well Being',
  'Innovation and Design',
];

function TabButtonGrp(props) {
  const [categoriesData, setCategoriesData] = useState('');
  const [categories, setCategories] = useState('');

  const [{ status, response }, makeRequest] = useApiRequest(`/igbc-category`, {
    verb: 'get',
  });

  if (status === null) makeRequest();

  useEffect(() => {
    if (response && response.data && response.data && response.data.data.data) {
      const id = response.data.data.data.filter((d) => d.categoryName === 'Sustainable Design')[0]._id;
      const e = [];
      IGBCCategories.forEach((category) => e.push(response.data.data.data.find((d) => d.categoryName === category)));
      const d = e.map((d) => d.categoryName);
      setCategories(d);
      setCategoriesData(e);
      props.setActiveCategoryid(id);
    }
  }, [response]);
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
        {categoriesData &&
          categories &&
          categoriesData.map((data, index) => (
            <Grid item xs key={index}>
              {data.categoryName === 'Sustainable Design' ||
              data.categoryName === 'Material and Resources' ||
              data.categoryName === 'Energy Efficiency' ||
              data.categoryName === 'Water Conservation' ||
              data.categoryName === 'Resident Health And Well Being' ? (
                <StyledButton
                  value={data.categoryName}
                  selected={props.activeStep === categories[index]}
                  onChange={() => {
                    props.setActiveStep(data.categoryName);
                    props.setActiveCategoryid(data._id);
                  }}
                >
                  <Typography variant="body1">{data.categoryName}</Typography>
                </StyledButton>
              ) : (
                <StyledButton
                  value={data.categoryName}
                  disabled
                  selected={props.activeStep === categories[index]}
                  onChange={() => {
                    props.setActiveStep(data.categoryName);
                    props.setActiveCategoryid(data._id);
                  }}
                >
                  <Typography variant="body1">{data.categoryName}</Typography>
                </StyledButton>
              )}
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default TabButtonGrp;
