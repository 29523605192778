import { Container, Grid, makeStyles, withWidth } from '@material-ui/core';
import React, { useState } from 'react';
import useScreenType from '../../../../../hooks/checkScreenType';
import JoinusDeskTop from './JoinUsDesktop';
import JoinusMobile from './JoinusMobile';
import { MarketplaceStyles } from '../../../config/index';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#EBF7FF',
    height: 'auto',
    width: '100%'
  },
  padding: {
    paddingBottom: MarketplaceStyles.sectionPadding.paddingBottom,
  },
  textHead: {
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28.8px',
    letterSpacing: '0.8642857313156128px',
    color: '#33518E',
  },
  customContainer: {
    maxWidth: 1440,
    margin: '0 auto',
  },
}));

const MarketplaceJoinus = (props) => {
  const classes = useStyles();
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const [joinus, setJoinus] = useState(false);
  return (
    <Container maxWidth="false" style={{width:'100%', padding: 0}} >
      <Grid container alignItems="center" justifyContent="center" >
        <Grid container alignItems="center" justifyContent="center" className={classes.main}>
          <Container className={classes.customContainer}>
            <Grid container alignItems="center" justifyContent="center" className={classes.main}>
              {isMobile ? <JoinusMobile joinus={joinus} setJoinus={setJoinus} /> : <JoinusDeskTop setJoinus={setJoinus} />}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withWidth()(MarketplaceJoinus);
