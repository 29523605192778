import { Grid, Typography, makeStyles } from '@material-ui/core';
import CustomCheckBox from '../../../../../common/checkbox/CustomCheckBox';
import { Accordion, AccordionSummary } from './DrawerLogicalList';
import React, { useContext } from 'react';
import { MarketplaceColors } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import closedpolygon from '../../../../../../assets/Marketplace/closedpolygon.svg';
import { FilterSections } from '../../../views/certification-and-super-categories/components/desktopcomponents/sidebar/FilterSidebar';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
  checkboxpadding: {
    paddingLeft: '20px',
  },
}));

const MobileNavigatorBrands = (props) => {
  const classes = useStyles();

  const { handleFilterData, filtersData } = useContext(ProductsPageContext);
  // const { brands } = URLS;

  const [expanded, setExpanded] = React.useState(true);
  const handleChange = () => setExpanded(!expanded);
  let history = useHistory();
  const { brands } = props;
  const { Primary: marketplacePrimary } = MarketplaceColors;

  const getCheckboxState = (label) => {
    return filtersData && filtersData.find((d) => d.value === label) && filtersData.find((d) => d.value === label)?.checked === false
      ? false
      : filtersData && filtersData.find((d) => d.value === label) && filtersData.find((d) => d.value === label)?.checked === true
      ? true
      : false;
  };
  let isBrand = filtersData.filter((ele) => ele.id === FilterSections.Brands);

  return (
    <Grid container>
      <Accordion square expanded={expanded} onChange={handleChange}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Grid container justifyContent="flex-start" alignItems="left" spacing={1}>
            <Grid item>
              <Typography style={{ color: marketplacePrimary, fontSize: '16px' }}>
                {isBrand.length !== 0 && <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary, paddingRight: '10px' }} />}
                <b>All Brands</b>
              </Typography>
            </Grid>
            <Grid item style={{ color: marketplacePrimary }}>
              <img src={closedpolygon} alt="closedpolygon" />
            </Grid>
          </Grid>
        </AccordionSummary>
        {brands.map((d) =>
          d.map((f) => {
            return (
              <Grid container className={classes.checkboxpadding}>
                <CustomCheckBox
                  handleChange={(e) => handleFilterData(FilterSections.Brands, f?.attributes.name, e.target.checked)}
                  checkState={getCheckboxState(f?.attributes.name)}
                  color={MarketplaceColors.Secondary}
                  checkedcolor={MarketplaceColors.Secondary}
                  label={f?.attributes.name}
                />
              </Grid>
            );
          })
        )}
      </Accordion>
    </Grid>
  );
};

export default MobileNavigatorBrands;
