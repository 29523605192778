import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/common/header/Header';
import AdminSidebar from '../components/common/sidebar/AdminSidebar';
import adminRouting from './Routes';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '3%',
  },
  content: {
    flexGrow: 1,
    marginTop: '3%',
    padding: theme.spacing(3),
  },
}));

export default function AdminRoutes(props) {
  let match = useRouteMatch();
  const adminbarlink = [
    {
      url: `${match.url}/category-scheme`,
      name: 'Categories',
    },
    {
      url: `${match.url}/subcategory-scheme`,
      name: 'Subcategories',
    },
    {
      url: `${match.url}/pip-scheme`,
      name: 'PIPs',
    },
    {
      url: `${match.url}/interventions-scheme`,
      name: 'Interventions',
    },
    {
      url: `${match.url}/goals`,
      name: 'Goals',
    },
    // {
    //   url: `${match.url}/pip-activities`,
    //   name: 'PIP Activities',
    // },
    {
      url: `${match.url}/intervention-vendor-mapping`,
      name: 'Intervention vendor Mappings',
    },
  ];
  const managementlink = [
    {
      url: `${match.url}/dropins`,
      name: 'Dropins',
    },
    {
      url: `${match.url}/vendors`,
      name: 'Vendors',
    },
    {
      url: `${match.url}/organisation-scheme`,
      name: 'Organisations',
    },
    {
      url: `${match.url}/users`,
      name: 'Users',
    },
    {
      url: `${match.url}/platformassets`,
      name: 'Platform Assets',
    },
    {
      url: `${match.url}/projects`,
      name: 'Project Service',
    },
    {
      url: `${match.url}/vendorrequest`,
      name: 'Vendor Request',
    },
  ];
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <AdminSidebar adminlinks={adminbarlink} management={managementlink} />
      <main className={classes.content}>
        <Suspense fallback={<Typography variant="body1">Loading...</Typography>}>
          <Switch>
            {adminRouting.map((route, index) => (
              <Route key={index} exact={route.exact} path={`${match.path}${route.path}`} component={route.component} />
            ))}
            <Route
              exact
              path={`${match.path}`}
              component={() => (
                <>
                  <Redirect to={`${match.url}/dropins`} />
                </>
              )}
            />
          </Switch>
        </Suspense>
      </main>
    </div>
  );
}
