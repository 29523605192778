import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles((theme) => ({
  root: {
    minHeight: 10,
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      top: 12,
    },
  },
  input: {
    borderRadius: 7,
    minWidth: 70,
    position: 'relative',
    border: '1.5px solid #1FDEDB',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 7,
      border: '1.5px solid #1FDEDB',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    minWidth: 130,
  },
}));

const CustomSelectFI = ({ age, handleChange }) => {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.margin}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          defaultValue={age}
          value={age}
          onChange={handleChange}
          input={<BootstrapInput />}
          style={{ minHeight: 30 }}
        >
          <MenuItem value={1}>1 Year</MenuItem>
          <MenuItem value={2}>2 Years</MenuItem>
          <MenuItem value={3}>3 Years</MenuItem>
          <MenuItem value={4}>4 Years</MenuItem>
          <MenuItem value={5}>5 Years</MenuItem>
          <MenuItem value={10}>10 Years</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default CustomSelectFI;
