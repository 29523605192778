import ToggleButton from '@material-ui/lab/ToggleButton';
import { withStyles } from '@material-ui/core/styles';

export const StyledButtonVilla = withStyles((theme) => ({
  root: {
    border: '1px solid' + theme.palette.common.dark,
    borderRadius: '40px',
    minWidth: '140px',
    '&:focus': {
      outline: 'none',
    },
    color: theme.palette.common.black,
  },
  selected: {
    border: '1px solid ' + theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark + '!important',
    color: theme.palette.common.white + '!important',
  },
}))(ToggleButton);
