import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { MarketplaceColors, MarketplaceStyles } from '../config';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
  },
  padding: {
    paddingBottom: MarketplaceStyles.sectionPadding.paddingBottom,
  },
}));

const SectionHeader = ({ Title, Description }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
        <Typography variant="h5" style={{ color: MarketplaceColors.Primary }}>
          <b>{Title}</b>
        </Typography>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
        <Typography variant="h6" style={{ color: MarketplaceColors.Secondary }}>
          {Description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SectionHeader;
