import React from 'react';
import { Grid, Container } from '@material-ui/core';
import ResourceCardDesktop from '../../../../common/ResourceCard/ResourceCardDesktop';
import cement from '../../../../../../../assets/Marketplace/material-resources/Cement-07.png';
import steel from '../../../../../../../assets/Marketplace/material-resources/Steel-feature-06.png';
import paintimage4 from '../../../../../../../assets/Marketplace/Resources/Paint2.png';
import glazingimage10 from '../../../../../../../assets/Marketplace/Resources/glazingimage10.png';
// import fallbackimagetwo from '../../../../../../../assets/Marketplace/fallbackimagetwo.svg';
// import wallblocks from '../../../../../../../assets/Marketplace/material-resources/wallblocks.png';

const cardHeight = '320px';

const KnowledgeHubListDesktop = (props) => {
  return (
    <Container maxWidth="xl" style={{ marginTop: '3%', marginBottom: '3%' }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
          <ResourceCardDesktop
            cardWidth="120%"
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={cement}
            text="Guide"
            longText="Cement For Residential Appartment"
            href={'/marketplace/resources/cement'}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardWidth="120%"
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={steel}
            text="Guide"
            longText="Steel for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/steel'}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardWidth="120%"
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={paintimage4}
            text="Guide"
            longText="Paints for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/paints'}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardWidth="120%"
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={glazingimage10}
            text="Guide"
            longText="Glazing for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/glazing'}
          />
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={fallbackimagetwo}
            text="Guide"
            longText="Interior Flooring for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/interiorflooring'}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={wallblocks}
            text="Guide"
            longText="Wall Blocks for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/wallblocks'}

          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={fallbackimagetwo}
            text="Guide"
            longText="Choosing the best Wall Blocks for your next Real estate project next Real estate project"
            shortCard={true}
          />
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={fallbackimagetwo}
            text="Guide"
            longText="Choosing the best Wall Blocks for your next Real estate project next Real estate project"
          />
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default KnowledgeHubListDesktop;
