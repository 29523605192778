import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { DummyVendors } from '../commmon/dummyVendorsLandingaPage';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '10%',
  },
  typo: {
    lineHeight: '20px',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  card: {
    maxWidth: 630,
    // width: '170px',/
    height: 420,
  },
  media: {
    height: 250,
  },
  newRoot: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  cardwrapper: { marginTop: '30px', marginLeft: '5%' },
}));

function ResponsiveHome4({ handleRequestDemo }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" alignContent="center">
        <div data-aos="fade-left" style={{ textAlign: 'center' }}>
          <Typography
            style={{
              lineHeight: '40px',
              letterSpacing: '1px',
              background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: '12%',
            }}
            variant="h3"
          >
            Reliable Vendors
            <br />
            <br />
          </Typography>
        </div>
        <div data-aos="fade-right" style={{ textAlign: 'center' }}>
          <Typography className={classes.typo3} variant="h5">
            Discover & select the best vendors for all innovative sustainable solutions.
            <br />
            <br />
            Vendors are rated on 8 parameters including projects implemented, client references, pricing & technology maturity.
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div data-aos="fade-right" style={{ textAlign: 'center' }}>
          <Typography className={classes.typo} variant="body2">
            Vendor catalog
            <br />
          </Typography>
        </div>

        <div className={classes.newRoot} data-aos="fade-left">
          <GridList className={classes.gridList} cols={1.2}>
            {DummyVendors &&
              DummyVendors.map((tile, index) => (
                <GridListTile key={index} cols={1.1} rows={2.8}>
                  {index <= 3 ? (
                    <React.Fragment>
                      <Grid className={classes.cardwrapper}>
                        <Card className={classes.card}>
                          <CardActionArea>
                            <CardMedia className={classes.media} image={tile.image} />
                            <CardContent style={{ textAlign: 'left' }}>
                              <Typography gutterBottom variant="h4" style={{ color: '#224B8B' }}>
                                {tile.name}
                              </Typography>
                              <Typography gutterBottom variant="h5">
                                <b>{tile.type}</b>
                                <br />
                              </Typography>
                              <Typography variant="h6">{tile.desc}</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid className={classes.cardwrapper} style={{ background: 'white', minHeight: 416 }}>
                        <div style={{ paddingTop: 160 }}>
                          <Typography gutterBottom variant="body2" style={{ lineHeight: '144%', fontWeight: 'normal' }}>
                            This website is in its beta version. <br />
                            We will be happy to show you a demo.
                            <br />
                            <br />
                          </Typography>

                          <Button
                            variant="h6"
                            style={{
                              background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
                              borderRadius: '6px',
                              width: '179px',
                              height: '36px',
                              color: 'white',
                              marginTop: 30,
                            }}
                            onClick={handleRequestDemo}
                          >
                            Request Demo
                          </Button>
                        </div>
                      </Grid>
                    </React.Fragment>
                  )}
                </GridListTile>
              ))}
          </GridList>
        </div>
      </Grid>
    </>
  );
}
export default ResponsiveHome4;
