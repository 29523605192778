import React from 'react';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import KnowledgeHubList from './KnowledgeHubList';
import useScreenType from '../../../../../hooks/checkScreenType';
import MarketplaceButton from '../../../common/Button';
import { MarketplaceStyles } from '../../../config/index';
import SectionHeader from '../../../common/SectionHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const { sectionPadding } = MarketplaceStyles;
const buttonWidthMobile = '30%';
const buttonWidthDesktop = '20%';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
  },
  padding: {
    paddingBottom: sectionPadding.paddingBottom,
  },
}));

const MarketplaceKnowledgeHub = (props) => {
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.padding}>
      <SectionHeader Title="Resources" Description="Learn how to select, build and operate green buildings with sustainable solutions" />
      <KnowledgeHubList />
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MarketplaceButton
            children="VIEW ALL"
            style={!isMobile ? { width: buttonWidthDesktop } : { width: buttonWidthMobile }}
            onClick={() => history.push('/marketplace/resources')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withWidth()(MarketplaceKnowledgeHub);
