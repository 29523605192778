import React from 'react';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import { products } from '../../../../utils/DummyProducts';
import ImpactProductCard from './ImpactProductCard';
import useScreenType from '../../../../../../hooks/checkScreenType';

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingBottom: '15px',
    paddingRight: '15px',
  },
}));

const ImpactProductsGroupDesktop = (props) => {
  const { width, impactProducts } = props;
  const { isTablet } = useScreenType();
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} sm={12} md={12} lg={9} xl={9} justifyContent="flex-start">
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[0]} width={width} product={impactProducts && impactProducts[0]} />
        </Grid>
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[1]} width={width} product={impactProducts && impactProducts[1]} />
        </Grid>
        {/* {!isTablet && ( */}
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[2]} width={width} product={impactProducts && impactProducts[2]} />
        </Grid>
        {/* )} */}
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[3]} width={width} product={impactProducts && impactProducts[3]} />
        </Grid>
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[4]} width={width} product={impactProducts && impactProducts[4]} />
        </Grid>
        {/* {!isTablet && ( */}
        <Grid item xs={4} container justifyContent="flex-start" className={classes.padding}>
          <ImpactProductCard ele={products[5]} width={width} product={impactProducts && impactProducts[5]} />
        </Grid>
        {/* )} */}
      </Grid>
    </>
  );
};

export default withWidth()(ImpactProductsGroupDesktop);
