import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import MarketplaceButton from '../../../common/Button';

const ProductDetailsAddToInterventionWithLogin = ({ style, className, intervention, project, handleAddToShortlistedProduct, onClose = () => {} }) => {
  return (
    <div style={style} className={className}>
      <Grid container>
        <Grid xs={12} container alignItems="center" justifyContent="center">
          <Typography variant="h5">
            <b>Add To Intervention</b>
          </Typography>
        </Grid>
        <Grid xs={12} container alignItems="center" justifyContent="flex-start" style={{ marginTop: '4%' }}>
          <Typography variant="h6" style={{ lineHeight: '32px' }}>
            Do you want to add this product to "{intervention}" for the project "{project}" ?
          </Typography>
        </Grid>
        <Grid xs={12} container alignItems="center" justifyContent="flex-start" style={{ marginTop: '10%' }}>
          <Typography style={{ fontSize: '10px' }} color="textSecondary">
            Only the last 12 products are retained for each intervention
          </Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-end" justifyContent="flex-end" style={{ marginTop: '8%' }}>
          <Grid xs={3}>
            <Typography style={{ color: MarketplaceColors.Secondary, textTransform: 'uppercase' }} onClick={() => onClose()}>
              Close
            </Typography>
          </Grid>
          <Grid xs={6}>
            <MarketplaceButton style={{ fontSize: '10px' }} children="Add To Intervention" onClick={handleAddToShortlistedProduct} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDetailsAddToInterventionWithLogin;
