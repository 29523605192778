import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { StyledButton } from './StyledButton';

export const IGBCCategories = [
  'Sustainable Design',
  'Water Conservation',
  'Energy Efficiency',
  'Material and Resources',
  'Resident Health and Wellbeing',
  'Innovation and Design',
];

function TabButtonGrp({ categoriesData, activeStep, projectId, handleURL, handleNext }) {
  const [sortedCategories, setsortedCategories] = useState([]);

  useEffect(() => {
    if (IGBCCategories && activeStep) {
      const findElement = (element) => element === activeStep;
      let categoriesIndex = IGBCCategories.findIndex(findElement);
      handleNext(IGBCCategories[categoriesIndex + 1]);
    }
  }, [activeStep, IGBCCategories]);

  useEffect(() => {
    if (categoriesData && IGBCCategories) {
      const d = [];
      IGBCCategories.forEach((category) => d.push(categoriesData.find((d) => d.categoryName === category)));
      setsortedCategories(d);
    }
  }, [IGBCCategories, categoriesData]);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
        {sortedCategories &&
          IGBCCategories &&
          sortedCategories.map((data, index) => {
            return (
              <Grid item xs key={index}>
                <StyledButton
                  value={data?.categoryName}
                  selected={activeStep === IGBCCategories[index]}
                  onChange={() => handleURL(projectId, IGBCCategories[index])}
                >
                  <Typography variant="body1">{data?.categoryName}</Typography>
                </StyledButton>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default TabButtonGrp;
