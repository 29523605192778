import React from 'react';
import { Accordion, AccordionSummary } from './DrawerLogicalList';
import { Typography, Grid } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import closedpolygon from '../../../../../../assets/Marketplace/closedpolygon.svg';
import DrawerLogicalItem from './DrawerLogicalItem';
import { useHistory } from 'react-router-dom';

const CertificationDrawerList = ({ collections }) => {
  let history = useHistory();

  const { Primary: marketplacePrimary } = MarketplaceColors;
  const [expanded, setExpanded] = React.useState(true);
  const handleChange = () => setExpanded(!expanded);
  return (
    <Accordion square expanded={expanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Typography style={{ color: marketplacePrimary, fontSize: '16px' }}>
              <b>Certifications</b>
            </Typography>
          </Grid>
          <Grid item style={{ color: marketplacePrimary }}>
            <img src={closedpolygon} alt="closedpolygon" />
          </Grid>
        </Grid>
      </AccordionSummary>
      <Grid container direction="row" style={{ marginTop: 0, marginLeft: '5%' }}>
        {collections?.map(({ attributes }, index) => {
          return (
            <DrawerLogicalItem
              index={index}
              name={attributes?.name.includes(':') ? attributes?.name.split(':')[0] : attributes?.name}
              // disabled for now as certifications backend is incomplete
              // handleRouting={() =>
              //   history.push(
              //     `/marketplace/categoryDetails?source=${Source.certifications}&certification=${
              //       attributes?.name.includes(':') ? attributes?.name.split(':')[0] : attributes?.name
              //     }`
              //   )
              // }
            />
          );
        })}
      </Grid>
    </Accordion>
  );
};

export default CertificationDrawerList;
