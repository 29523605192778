import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';

const CustomAddButton = ({ title, handleOpenDrawer }) => {
  return (
    <Grid container alignItems="center" style={{ marginTop: '1%', marginBottom: '1%' }}>
      <Grid>
        <AddBoxIcon style={{ color: '#49DDB1', fontSize: '150%', cursor: 'pointer' }} />
      </Grid>
      <Grid style={{ paddingLeft: '1%', color: '#49DDB1', cursor: 'pointer' }} onClick={handleOpenDrawer}>
        <Typography variant="h6">
          <b>{title}</b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CustomAddButton;
