import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const WWMProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const {
    wwm_oneBHK,
    wwm_twoBHK,
    wwm_threeBHK,
    wwm_fourBHK,
    wwm_studio,
    wwm_penthouseOneBHK,
    wwm_penthouseTwoBHK,
    wwm_penthouseThreeBHK,
    wwm_penthouseFourBHK,
    wwm_subcategory,
    wwm_penthouseEnabled,
  } = schema;

  const onebhk = getQuantityValue(wwm_oneBHK);
  const twobhk = getQuantityValue(wwm_twoBHK);
  const threebhk = getQuantityValue(wwm_threeBHK);
  const fourbhk = getQuantityValue(wwm_fourBHK);
  const studio = getQuantityValue(wwm_studio);

  const onebhk_label = getQuantityLabel(wwm_oneBHK);
  const twobhk_label = getQuantityLabel(wwm_twoBHK);
  const threebhk_label = getQuantityLabel(wwm_threeBHK);
  const fourbhk_label = getQuantityLabel(wwm_fourBHK);
  const studio_label = getQuantityLabel(wwm_studio);

  const onebhk_unit = getQuantityUnit(wwm_oneBHK);
  const twobhk_unit = getQuantityUnit(wwm_twoBHK);
  const threebhk_unit = getQuantityUnit(wwm_threeBHK);
  const fourbhk_unit = getQuantityUnit(wwm_fourBHK);
  const studio_unit = getQuantityUnit(wwm_studio);

  const penthouse_onebhk = getQuantityValue(wwm_penthouseOneBHK);
  const penthouse_twobhk = getQuantityValue(wwm_penthouseTwoBHK);
  const penthouse_threebhk = getQuantityValue(wwm_penthouseThreeBHK);
  const penthouse_fourbhk = getQuantityValue(wwm_penthouseFourBHK);

  const penthouse_onebhk_label = getQuantityLabel(wwm_penthouseOneBHK);
  const penthouse_twobhk_label = getQuantityLabel(wwm_penthouseTwoBHK);
  const penthouse_threebhk_label = getQuantityLabel(wwm_penthouseThreeBHK);
  const penthouse_fourbhk_label = getQuantityLabel(wwm_penthouseFourBHK);

  const penthouse_onebhk_unit = getQuantityUnit(wwm_penthouseOneBHK);
  const penthouse_twobhk_unit = getQuantityUnit(wwm_penthouseTwoBHK);
  const penthouse_threebhk_unit = getQuantityUnit(wwm_penthouseThreeBHK);
  const penthouse_fourbhk_unit = getQuantityUnit(wwm_penthouseFourBHK);

  const subcategory = getQuantityValue(wwm_subcategory);
  const penthouseEnabled = getQuantityValue(wwm_penthouseEnabled);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        {subcategory === 'apartment' && (
          <Grid item xs={3}>
            <Typography className={classes.textHeader5}>{studio_label && studio_label}</Typography>
            <Typography className={classes.textHeader3}>
              {studio} {studio_unit && studio_unit}
            </Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{onebhk_label && onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {onebhk} {onebhk_unit && onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{twobhk_label && twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {twobhk} {twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {threebhk} {threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {fourbhk} {fourbhk_unit}
          </Typography>
        </Grid>
        {penthouseEnabled && subcategory === 'apartment' && (
          <>
            <Grid item xs={3}>
              <Typography className={classes.textHeader5}>{penthouse_onebhk_label}</Typography>
              <Typography className={classes.textHeader3}>
                {penthouse_onebhk} {penthouse_onebhk_unit}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader5}>{penthouse_twobhk_label}</Typography>
              <Typography className={classes.textHeader3}>
                {penthouse_twobhk} {penthouse_twobhk_unit}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader5}>{penthouse_threebhk_label}</Typography>
              <Typography className={classes.textHeader3}>
                {penthouse_threebhk} {penthouse_threebhk_unit}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader5}>{penthouse_fourbhk_label}</Typography>
              <Typography className={classes.textHeader3}>
                {penthouse_fourbhk} {penthouse_fourbhk_unit}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default WWMProjectInputs;
