import React from 'react';
import { Link } from 'react-router-dom';

const CustomLink = ({ label, to, style, onClick }) => {
  const defaultStyle = {
    textDecoration: 'underline',
    color: 'rgba(0, 219, 153, 0.82)',
  };
  return (
    <Link to={to} onClick={onClick} style={style ? style : defaultStyle}>
      {label}
    </Link>
  );
};

export default CustomLink;
