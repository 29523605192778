import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import rsToCr from '../../../../../utils/rsToCr';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 20,
    height: window.innerHeight - 20,
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const Page2 = (props) => {
  const data = props.data;
  let saving = data && data.interventions.quantities.overall.finance.opexSavings.value;
  let fiveyearsaving = data && data.interventions.quantities.overall.finance.opexSavings.value * 5;
  let capex = data && data.interventions.quantities.overall.finance.capex.value;
  let energygoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Energy');
  let watergoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Water');
  let wastegoal = data && data.goals.categories[0].find((goal) => goal && goal.name === 'Waste');
  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>Your Project Is</Text>
        </View>
        <View>
          <Text
            style={{
              fontFamily: 'Open Sans',
              fontWeight: 'SemiBold',
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
              // textAlign: 'center',
              paddingLeft: '5px',
            }}
          >
            {`${props.sustainabilitypercentage} More`}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Open Sans',
              fontWeight: 'SemiBold',
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
              // textAlign: 'center',
            }}
          >
            Sustainable
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase', paddingLeft: '5px' }}>When</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>Compared to the base case</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '30px' }}>
        <Text style={{ fontFamily: 'Open Sans', fontSize: '14px', color: 'rgba(28, 36, 28, 0.93)' }}>Project savings in the next 5 years will be*</Text>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Open Sans',
              fontWeight: 'SemiBold',
              fontSize: '72px',
              textTransform: 'uppercase',
              color: '#10DDC2',
            }}
          >
            {isNaN(fiveyearsaving) ? rsToCr('Rs', 0) : rsToCr('Rs', fiveyearsaving)}
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '36px', paddingLeft: '35px', paddingTop: '20px' }}>Cr</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '20px' }}>
        <Text style={{ fontFamily: 'Open Sans', fontSize: '14px', color: 'rgba(28, 36, 28, 0.93)' }}>With a capital investment of</Text>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Open Sans',
              fontWeight: 'SemiBold',
              fontSize: '72px',
              textTransform: 'uppercase',
              color: '#F57170',
            }}
          >
            {rsToCr('Rs', capex)}
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '36px', paddingLeft: '35px', paddingTop: '20px' }}>Cr</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '20px' }}>
        <Text style={{ fontFamily: 'Open Sans', fontSize: '14px', color: 'rgba(28, 36, 28, 0.93)' }}>{`*Project annual saving will be ${
          isNaN(saving) ? rsToCr('Rs', 0) : rsToCr('Rs', saving)
        } Cr`}</Text>
      </View>
      <View style={{ flexDirection: 'Column', display: 'flex', height: '53px', width: '100%', backgroundColor: '#13C6BD', marginTop: '20px' }}>
        <Text style={{ fontFamily: 'Open Sans', color: '#FFFFFF', fontWeight: 'SemiBold', fontSize: '30px', paddingLeft: '10px', paddingTop: '8px' }}>
          GOALS
        </Text>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '30px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', color: '#F28D14', fontSize: '12px', textAlign: 'center' }}>ENERGY</Text>
          <Text style={{ fontFamily: 'Open Sans', color: '#F28D14', fontSize: '40px', fontWeight: 'SemiBold', textAlign: 'center', paddingTop: '10px' }}>
            {`${Math.round(energygoal && energygoal.quantities.actualPercentage.value)}%`}
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', textAlign: 'left', paddingLeft: '15px', paddingTop: '10px' }}>
            Maximise on-site Renewable Energy sources
          </Text>
        </View>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', color: '#3BC7EE', fontSize: '12px', textAlign: 'center' }}>WATER</Text>
          <Text style={{ fontFamily: 'Open Sans', color: '#3BC7EE', fontSize: '40px', fontWeight: 'SemiBold', textAlign: 'center', paddingTop: '10px' }}>
            {`${Math.round(watergoal && watergoal.quantities.actualPercentage.value)}%`}
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', textAlign: 'left', paddingLeft: '15px', paddingTop: '10px' }}>
            Zero dependency on external sources for Water
          </Text>
        </View>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', color: '#BC8A5F', fontSize: '12px', textAlign: 'center' }}>WASTE</Text>
          <Text style={{ fontFamily: 'Open Sans', color: '#BC8A5F', fontSize: '40px', fontWeight: 'SemiBold', textAlign: 'center', paddingTop: '10px' }}>
            {`${Math.round(wastegoal && wastegoal.quantities.actualPercentage.value)}%`}
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', textAlign: 'left', paddingLeft: '15px', paddingTop: '10px' }}>Zero Waste to Landfill</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ marginTop: '5%' }}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default Page2;
