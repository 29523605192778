import { useState, useEffect } from 'react';
import { DummyNavigatorData } from '../navbar/StaticNavigatorData';

const UseNavigatorData = () => {
  const [navigatorData, setNavigatorData] = useState([]);
  // currently static Data is used
  useEffect(() => {
    const { columns } = DummyNavigatorData;
    setNavigatorData({ columns });
  }, []);

  return { navigatorData };
};

export default UseNavigatorData;

// !!!do not remove the below!!! currently the graphql data not used in the navigator- !!!do not remove the below!!! -

// import CategoriesSpotlight_UseGraphqlData from '../graphql-data/CategoriesSpotlight.graphqlData';
// import CertificationsSpotlight_UseGraphqlData from '../graphql-data/CertificationSpotlight.graphqlData';
// import SuperCategories_graphqlData from '../graphql-data/SuperCategories.graphqlData';

// const { allCategories, categoriesWithProductsCount } = CategoriesSpotlight_UseGraphqlData();
// const { allCertifications } = CertificationsSpotlight_UseGraphqlData();
// const { superCategories } = SuperCategories_graphqlData();
// useEffect(() => {
//   if (categoriesWithProductsCount.length !== 0 && allCategories.length !== 0 && superCategories?.length !== 0 && allCertifications.length !== 0) {
//     const categories_group = categoriesWithProductsCount.map((element) => {
//       let obj = element;
//       const subcategories = allCategories.find((s) => s?.attributes?.name === element?.name)?.attributes?.subcategories?.data;
//       obj['subcategories'] = subcategories;
//       return obj;
//     });
//     let columns = [];
//     let group_1 = { id: 1, source: Source.categories, collections: categories_group };
//     let group_2 = { id: 2, source: Source.supercategories, collections: superCategories };
//     let group_3 = { id: 3, source: Source.certifications, collections: allCertifications };
//     columns.push(group_1, group_2, group_3);
//     // const { columns } = DummyNavigatorData;
//     setNavigatorData({ columns });
//   }
// }, [allCategories, categoriesWithProductsCount, superCategories, allCertifications]);
