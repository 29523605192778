import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    paddingTop: '3%',
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant="h3">{title}</Typography>
    </Grid>
  );
};

export default PageTitle;
