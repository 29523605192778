import React from 'react';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import useScreenType from '../../../../../hooks/checkScreenType';
import ResourceCard from '../../../common/ResourceCard';
import Certifications from './components/Certifications';
import imageforguide from '../../../../../../assets/Marketplace/imageforguide.svg';
import { MarketplaceColors, MarketplaceStyles } from '../../../config/index';
import MarketplaceButton from '../../../common/Button';
import { useHistory } from 'react-router-dom';
import { withWidth } from '@material-ui/core';
import { certificationsQuery } from '../../../graphql-queries/CertificationsSpotlight.query';
import useGraphqlRequest from '../../../../catalogue/Common/SendRequest';
import { useEffect } from 'react';
import { useState } from 'react';
import { Default } from '../../../utils/Defaults';

const checkWidth = (width) => {
  if (width === 'xs') return '95%';
  if (width === 'sm') return '50%';
  if (width === 'md') return '30%';
  if (width === 'lg') return '30%';
  else return '30%';
};

const MarketplaceCertificationsSpotlight = (props) => {
  const { width } = props;
  const { isMobile, isTablet } = useScreenType();

  const [ecoLabels, setEcoLabels] = useState('');
  const [state, SendRequest] = useGraphqlRequest(certificationsQuery);
  if (state && state?.response === null) SendRequest();

  useEffect(() => {
    if (state && state?.response !== null) setEcoLabels(state?.response?.data?.data?.ecoLabels?.data);
  }, [state]);

  const useStyles = makeStyles((theme) => ({
    padding: {
      marginBottom: MarketplaceStyles.sectionPadding.paddingBottom,
      backgroundColor: '#EBF7FF',
    },
    button: {
      width: checkWidth(width),
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  return (
    <Container maxWidth="false" style={{ width:'100%', padding: 0 }}>
      <Grid className={classes.padding}>
        <Container maxWidth="xl">
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item justifyContent="center" style={{ paddingTop: '2%' }}>
            <Typography variant="h5" style={{ color: MarketplaceColors.Primary }}>
              <b>Products by Green Certifications</b>
            </Typography>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            {/* {!isMobile && !isTablet && (
              <Grid xs={3} sm={3} md={3} lg={3} xl={3} item container alignItems="center" justifyContent={width === 'xl' ? 'flex-end' : 'center'}>
                <ResourceCard
                  cardWidth="240px"
                  cardPadding="2%"
                  imageWidth="100%"
                  sourceImage={imageforguide}
                  text="Guide"
                  longText="Choosing the best Wall Blocks for your next Real estate project"
                />
              </Grid>
            )} */}
            <Grid
              xs={12}
              sm={12}
              md={10}
              lg={8}
              xl={8}
              container
              item
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '2%', marginBottom: '2%' }}
            >
              <Certifications title="Green Certifications" ecoLabels={ecoLabels} />
            </Grid>
          </Grid>
        </Container>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
          justifyContent="center"
          style={!isMobile ? { marginTop: '1%', paddingBottom: '4%' } : { marginTop: '1%', paddingBottom: '4%' }}
        >
          <MarketplaceButton
            children="VIEW ALL CERTIFIED PRODUCTS"
            className={classes.button}
            backgroundColor={MarketplaceColors.Primary}
            onClick={() => {
              history.push(`/marketplace/categorydetails?source=Ecolabels&page=${Default.page}&pagesize=${Default.pagesize}`);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withWidth()(MarketplaceCertificationsSpotlight);
