import React from 'react';
import { makeStyles, withWidth } from '@material-ui/core';
import logo from '../../../../../assets/Marketplace/sdplusmarketplacelogo.svg';
import useScreenType from '../../../../hooks/checkScreenType';

const useStyles = makeStyles((theme) => ({
  naviconDesktop: {
    height: '38px',
  },
  naviconMobile: {
    marginTop: '1%',
    height: '40px',
  },
}));

const MarketplaceNavicon = (props) => {
  const { width, handleClick } = props;
  const classes = useStyles();
  const { isMobile } = useScreenType(width);
  return (
    <>
      {!isMobile ? (
        <a href="/marketplace" onclick="return false;">
          <img src={logo} alt="sdpluslogo" className={classes.naviconDesktop} onClick={handleClick} />
        </a>
      ) : (
        <a href="/marketplace" onclick="return false;">
          <img src={logo} alt="sdpluslogo" className={classes.naviconMobile} onClick={handleClick} />
        </a>
      )}
    </>
  );
};

export default withWidth()(MarketplaceNavicon);
