import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DetailsHead from './Header';
import WorkDetails from './WorkDetails';
import Timeline from './Timeline';
import Contact from './Contact';
import Carousel from './Carousel';
import CustomBreadCrumbs from '../../../common/breadcrumbs/CustomBreadCrumbs';
import axios from '../../../../config/Axios';
import { PreviousPageContext, ProjectContext } from '../../../../routing/IndexRouting';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '3%',
    paddingLeft: '2%',
  },
  spacing2: {
    marginTop: '6%',
    padding: theme.spacing(3),
  },
  paper: {
    background: '#FAFAFA',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingBottom: '1%',
    marginTop: '5%',
  },
}));

const VendorDetail = (props) => {
  const classes = useStyles();
  const [vendor, setVendor] = useState('');
  const { projectData } = useContext(ProjectContext);
  let id = props.match.params.id;
  let iid = props.match.params.iid;
  let param = props.match.params;
  const [contactPhoto, setContactPhoto] = useState();
  const { setPreviousPage, setIntervnetionMappingID, setInterventionVendorMappingID } = useContext(PreviousPageContext);

  useEffect(() => {
    setPreviousPage('vendorDetails');
    setInterventionVendorMappingID(id);
    setIntervnetionMappingID(iid);
    axios.get(`/interventionVendorMapping/${id}`).then((response) => {
      setVendor(response.data.data.data);
      let photo = response.data.data.data && response.data.data.data.contact.photo;
      axios.get(`/sign-s3/${photo}`).then((response) => {
        if (response && response.data.data) {
          setContactPhoto(response.data.data.data);
        }
      });
    });
  }, [id, iid]);

  const links = [
    { href: `/projects/${projectData.id}/interventions`, text: 'Interventions' },
    { href: `/projects/${projectData.id}/interventions/${param.iid}`, text: vendor && vendor.interventionScheme.name },
  ];

  return (
    <>
      <Grid container direction="column" item xs={12} className={classes.spacing2}>
        <CustomBreadCrumbs
          links={links}
          stage={
            vendor && vendor.vendor.vendorname ? (
              <b>{vendor.vendor.vendorname}</b>
            ) : (
              <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />
            )
          }
        />
        <DetailsHead vendor={vendor} param={param} />

        <Paper className={classes.paper}>
          <Typography variant="body2">{vendor ? vendor.interventionScheme.name : ''}</Typography>
        </Paper>
        <Carousel vendor={vendor} />
        <WorkDetails vendor={vendor} />
        <br />
        <br />
        <Timeline vendor={vendor} />
        <Contact vendor={vendor} contactPhoto={contactPhoto} />
      </Grid>
    </>
  );
};

export default VendorDetail;
