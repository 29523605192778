import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Links from '../../../../common/Typography/Links';
import { MarketplaceTypography } from '../../../../config';

const TextWithHeading = ({ text, classname }) => {
  return (
    <Grid container>
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
        <Typography className={classname} style={{ textAlign: 'justify' }}>
          <span style={{ fontWeight: 'bold' }}>{text.split(':')[0]}:</span>
          {text.split(':')[1]}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TextWithHeading;
