import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import ActiveIntervCard from './ActiveIntervcard';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';
// import vendorcivil from '../../../../../assets/new-pdf/vendorcivil.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    // top: 30,
    // paddingTop: '90%',
  },
});

const Page6 = (props) => {
  const contentFunction = (name) => {
    if (name === 'Solar PV') return 'SPV System converts sunlight into electricity which can be either fed into the grid or used on site';
    if (name === 'Solar Water Heater') return 'Solar collectors heat up water stored in the tank & subsequently supply to the toilets in the upper floor units';
    if (name === 'Centralized RO') return 'Installing a Centralized RO plant instead of individual units in apartments is more water efficient';
    if (name === 'Efficient Fixtures') return 'With an added aerator, air is added to the water flow for water efficiency gains without a drop in pressure';
    if (name === 'Dual Flush') return 'It is a variation of the flush toilet that uses 2 buttons to flush different amounts of water';
    if (name === 'Smart Water Meters') return 'Flow sensors installed at unit level help occupants monitor water consumption to pay only for their usage';
    if (name === 'Rooftop RWH') return 'Rooftop rain water harvesting is the technique to capture rain water from the roof catchments and store in reservoirs';
    if (name === 'Surface RWH') return 'Surface rain water harvesting is the technique to capture rain water from the ground surfaces and store in reservoirs';
    if (name === 'Grey Water Re-use') return 'An ecofriendly FBR & FFR system based susbsurface flow system that works without power & daily surveillance';
    if (name === 'Black Water Re-use') return 'An ecofriendly FBR & FFR system based susbsurface flow system that works without power & daily surveillance';
    if (name === 'Wet Waste Management') return 'Wet waste is turned into Biogas with a bio-digester and subsequently can be used to generate electricity';
    if (name === 'Sanitary Napkin Incinerator') return 'The sanitary napkins are burnt in an automated incinerator and converted into ash';
    if (name === 'Collection & Segregation Point') return 'Make space provisions within the premises for collection & segregation of dry, medical & e-waste';
    else return 'Custom Intervention';
  };

  const capacityFunction = (intervention, data) => {
    if (intervention === 'Solar PV') {
      let value = data && data.find((D) => D.id === 'solarpv.SPVCapacity') ? data && data.find((D) => D.id === 'solarpv.SPVCapacity').value : '-';
      let unit = data && data.find((D) => D.id === 'solarpv.SPVCapacity') ? data && data.find((D) => D.id === 'solarpv.SPVCapacity').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Solar Water Heater') {
      let value =
        data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity') ? data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity').value : '-';
      let unit =
        data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity') ? data && data.find((D) => D.id === 'solarwaterheater.SWHCapacity').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Centralized RO') {
      let value =
        data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily')
          ? data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily')
          ? data && data.find((D) => D && D.id === 'centralizedro.outcomes.crodaily').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Grey Water Re-use') {
      let value =
        data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'greywaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Black Water Re-use') {
      let value =
        data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.find((D) => D && D.id === 'blackwaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Rooftop RWH') {
      let value =
        data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize').value : '-';
      let unit =
        data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize') ? data && data.find((D) => D && D.id === 'rooftoprwh.rrwhtanksize').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Surface RWH') {
      let value =
        data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize').value : '-';
      let unit =
        data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D && D.id === 'surfacerwh.srwhtanksize').unit : '-';
      return { value, unit };
    }
    if (intervention === 'Wet Waste Management') {
      let value =
        data && data.find((D) => D.id === 'wetwastetobiogas.outcomes.wwbcapacity')
          ? data && data.find((D) => D.id === 'wetwastetobiogas.outcomes.wwbcapacity').value
          : '-';
      let unit =
        data && data.find((D) => D.id === 'wetwastetobiogas.outcomes.wwbcapacity')
          ? data && data.find((D) => D.id === 'wetwastetobiogas.outcomes.wwbcapacity').unit
          : '-';
      return { value, unit };
    }
    if (intervention === 'Sanitary Napkin Incinerator') {
      let value =
        data && data.find((D) => D.id === 'sanitarynapkinincinerator.outcomes.snicapacity')
          ? data && data.find((D) => D.id === 'sanitarynapkinincinerator.outcomes.snicapacity').value
          : '-';
      let unit =
        data && data.find((D) => D.id === 'sanitarynapkinincinerator.outcomes.snicapacity')
          ? data && data.find((D) => D.id === 'sanitarynapkinincinerator.outcomes.snicapacity').unit
          : '-';
      return { value, unit };
    }
  };

  const areaFunction = (intervention, data) => {
    if (intervention === 'Solar PV') {
      let value = data && data.filter(d=>!!d).find((D) => D.id === 'solarpv.arearequired') ? data && data.find((D) => D.id === 'solarpv.arearequired').value : '-';
      let unit = data && data.filter(d=>!!d).find((D) => D.id === 'solarpv.arearequired') ? data && data.find((D) => D.id === 'solarpv.arearequired').unit : '-';
      return { value: value || '', unit: unit || '-' };
    }
    if (intervention === 'Solar Water Heater') {
      let value =
        data && data.filter(d=>!!d).find((D) => D.id === 'solarwaterheater.arearequired')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'solarwaterheater.arearequired').value
          : '-';
      let unit =
        data && data.filter(d=>!!d).find((D) => D.id === 'solarwaterheater.arearequired')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'solarwaterheater.arearequired').unit
          : '-';
      return { value: value || '', unit: unit || '-' };
    }
    if (intervention === 'Centralized RO') {
      let value =
        data && data.filter(d=>!!d).find((D) => D.id === 'centralizedro.outcomes.crodaily') ? data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily').value : '-';
      let unit =
        data && data.filter(d=>!!d).find((D) => D.id === 'centralizedro.outcomes.crodaily') ? data && data.find((D) => D.id === 'centralizedro.outcomes.crodaily').unit : '-';
      return { value: value || '', unit: unit || '-' };
    }
    if (intervention === 'Grey Water Re-use') {
      let value =
        data && data.filter(d=>!!d).find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.filter(d=>!!d).find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'greywaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value: value || '', unit: unit || '-' };
    }
    if (intervention === 'Black Water Re-use') {
      let value =
        data && data.filter(d=>!!d).find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity').value
          : '-';
      let unit =
        data && data.filter(d=>!!d).find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity')
          ? data && data.filter(d=>!!d).find((D) => D.id === 'blackwaterreuse.derivatives.stpcapacity').unit
          : '-';
      return { value: value || '-', unit: unit || '-' };
    }
    if (intervention === 'Rooftop RWH') {
      let value = data && data.filter(d=> !!d).find((D) => D.id === 'rooftoprwh.arearequired') ? data && data.filter(d=> !!d).find((D) => D.id === 'rooftoprwh.arearequired').value : '-';
      let unit = data && data.filter(d=> !!d).find((D) => D.id === 'rooftoprwh.arearequired') ? data && data.filter(d=> !!d).find((D) => D.id === 'rooftoprwh.arearequired').unit : '-';
      return { value: value || '-', unit: unit || '-' };
    }
    if (intervention === 'Surface RWH') {
      let value = data && data.filter(d=>!!d).find((D) => D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D.id === 'surfacerwh.srwhtanksize').value : '-';
      let unit = data && data.filter(d=>!!d).find((D) => D.id === 'surfacerwh.srwhtanksize') ? data && data.find((D) => D.id === 'surfacerwh.srwhtanksize').unit : '-';
      return { value: value || '-', unit: unit || '-' };
    }
  };

  const selectedVendorFunction = (index, mappingId, data, vendorMappings, vendors, intname) => {
    if (mappingId && data && vendorMappings && vendors) {
      let A = data.find((a) => a._id === mappingId && a.hasOwnProperty('selectedVendor'));
      let B = A && vendorMappings.find((vendor) => vendor._id === A.selectedVendor);
      let C = B && vendors.find((v) => v._id === B.vendor._id);
      return { logo: C && C.logo.URL };
    }
  };

  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      <Text style={{ fontFamily: 'Open Sans', fontWeight: 'Bold', fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>
        Implemented interventions
      </Text>
      {props.uncategorized && props.uncategorized.length > 0 ? (
        props.uncategorized.map((group, index) => {
          if (index <= 3) {
            return (
              <View style={{ marginBottom: index === 3 ? '10%' : '' }}>
                <ActiveIntervCard
                  border={props.border}
                  color={props.color}
                  content={contentFunction(group.name)}
                  name={group.name}
                  capex={
                    group.quantities && group.quantities.finance && group.quantities.finance.capex ? rsToCr('Rs', group.quantities.finance.capex.value) : 0
                  }
                  opex={
                    group.quantities && group.quantities.finance && group.quantities.finance.opexSavings
                      ? rsToCr('Rs', group.quantities.finance.opexSavings.value)
                      : 0
                  }
                  resource={
                    group.quantities && group.quantities.resource && group.quantities.resource.category
                      ? numberWithCommas(group.quantities.resource.category.value)
                      : 0
                  }
                  unit={group.quantities && group.quantities.resource && group.quantities.resource.category ? group.quantities.resource.category.unit : '-'}
                  capacity={
                    capacityFunction(group.name, group.quantities && group.quantities.details)
                      ? capacityFunction(group.name, group.quantities && group.quantities.details).value
                      : '-'
                  }
                  cpacityunit={
                    capacityFunction(group.name, group.quantities && group.quantities.details)
                      ? capacityFunction(group.name, group.quantities && group.quantities.details).unit
                      : '-'
                  }
                  areaunit={
                      (areaFunction(group.name, group.quantities && group.quantities.details) && areaFunction(group.name, group.quantities && group.quantities.details).unit) || '-'
                  }
                  area={
                      (areaFunction(group.name, group.quantities && group.quantities.details) && areaFunction(group.name, group.quantities && group.quantities.details).value) || ''
                  }
                  selectedVendor={
                    selectedVendorFunction(
                      index,
                      group.interventionProjectMappingId,
                      props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                      props.allVendorMappings && props.allVendorMappings,
                      props.allvendors && props.allvendors,
                      group.name
                    ) &&
                    selectedVendorFunction(
                      index,
                      group.interventionProjectMappingId,
                      props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                      props.allVendorMappings && props.allVendorMappings,
                      props.allvendors && props.allvendors,
                      group.name
                    )
                  }
                />
                {index === 3 ? (
                  <View style={{ marginTop: '5%' }}>
                    <Footer year={props.year} />
                  </View>
                ) : (
                  ''
                )}
              </View>
            );
          } else if (index > 3) {
            return (
              <View style={{ marginTop: index === 4 ? '3%' : '' }}>
                {index === 4 ? <Header companyname={props.companyname} /> : ''}
                <ActiveIntervCard
                  border={props.border}
                  color={props.color}
                  content={contentFunction(group.name)}
                  name={group.name}
                  capex={
                    group.quantities && group.quantities.finance && group.quantities.finance.capex ? rsToCr('Rs', group.quantities.finance.capex.value) : 0
                  }
                  opex={
                    group.quantities && group.quantities.finance && group.quantities.finance.opexSavings
                      ? rsToCr('Rs', group.quantities.finance.opexSavings.value)
                      : 0
                  }
                  resource={
                    group.quantities && group.quantities.resource && group.quantities.resource.category
                      ? numberWithCommas(group.quantities.resource.category.value)
                      : 0
                  }
                  unit={group.quantities && group.quantities.resource && group.quantities.resource.category ? group.quantities.resource.category.unit : '-'}
                  capacity={
                    capacityFunction(group.name, group.quantities && group.quantities.details)
                      ? capacityFunction(group.name, group.quantities && group.quantities.details).value
                      : '-'
                  }
                  cpacityunit={
                    capacityFunction(group.name, group.quantities && group.quantities.details)
                      ? capacityFunction(group.name, group.quantities && group.quantities.details).unit
                      : '-'
                  }
                  areaunit={
                    (areaFunction(group.name, group.quantities && group.quantities.details) && areaFunction(group.name, group.quantities && group.quantities.details).unit) || '-'
                  }
                  area={
                    (areaFunction(group.name, group.quantities && group.quantities.details) && areaFunction(group.name, group.quantities && group.quantities.details).value) || ''
                  }
                  selectedVendor={
                    selectedVendorFunction(
                      index,
                      group.interventionProjectMappingId,
                      props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                      props.allVendorMappings && props.allVendorMappings,
                      props.allvendors && props.allvendors,
                      group.name
                    ) &&
                    selectedVendorFunction(
                      index,
                      group.interventionProjectMappingId,
                      props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                      props.allVendorMappings && props.allVendorMappings,
                      props.allvendors && props.allvendors,
                      group.name
                    )
                  }
                />
              </View>
            );
          }
        })
      ) : (
        <ActiveIntervCard
          border={props.border}
          color={props.color}
          area={0}
          capex={0}
          areaunit="-"
          capacity={0}
          opex={0}
          cpacityunit="-"
          resource={0}
          unit="-"
        />
      )}

      <View
        style={{
          marginTop:
            props.numnber > 4
              ? `${props.numnber - 4 === 1 ? '100%' : props.numnber - 4 === 2 ? '60%' : props.numnber - 4 === 3 ? '40%' : '10%'}`
              : props.numnber === 3
              ? '40%'
              : props.numnber === 2
              ? '60%'
              : props.numnber === 1
              ? '100%'
              : !props.numnber
              ? '100%'
              : '10%',
        }}
      >
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default Page6;
