import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useApiRequest from '../../hooks/useApiRequest';
import { FETCHING, SUCCESS, ERROR } from '../../hooks/useApiRequest/actionTypes';
import Loader from '../../common/progressbar/Loader';
import OutlinedTextField from '../../common/textfield/OutlinedTextField';
import CustomButton from '../../common/button/CustomButton';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ReCAPTCHA from 'react-google-recaptcha';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  response1: {
    marginTop: '3%',
    padding: '3% 3%',
    background: '#C6F2CD',
    border: '1px solid #49DDB1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: '#666666',
    textAlign: 'center',
  },
  response2: {
    marginTop: '3%',
    padding: '3% 3%',
    color: theme.palette.warning.main,
    backgroundColor: '#FFF5E2',
    border: '1px solid rgba(255, 167, 86, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '6px',
    textAlign: 'center',
  },
}));
const roles = ['Real Estate Developer', 'Architect', 'MEP Consultant', 'Green Consultant', 'Student', 'Individual'];

export default function RequestDemo(props) {
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
  const classes = useStyles();
  const purpose = props.purpose;
  const [spacialCharacter, setSpecialCharacter] = useState(false);
  const [roleSpacialCharacter, setRoleSpecialCharacter] = useState(false);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [organisationName, setOrganisationName] = useState('');
  const [role, setRole] = useState(null);
  const [inputRole, setInputRole] = useState('');

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleOrganisation = (event) => {
    setOrganisationName(event.target.value);
  };
  const handleFullName = (event) => {
    if (format.test(event.target.value)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
      setFullName(event.target.value);
    }
  };
  const submitDropin = (event) => {
    event.preventDefault();
    if (fullName && email && organisationName && role && purpose) {
      makeRequest();
    }
  };
  let [{ status, response }, makeRequest] = useApiRequest('/dropins', {
    verb: 'post',
    params: { email: email, fullname: fullName, organisationname: organisationName ? organisationName : '', role: role, purpose: purpose },
  });
  const [org, orgMakeRequest] = useApiRequest('/organisations', {
    verb: 'get',
  });
  if (org.status === null) orgMakeRequest();
  useEffect(() => {
    if (status === SUCCESS && response) {
      sessionStorage.setItem('WHITE_PAPER', 'Success');
      setFullName('');
      setEmail('');
      setOrganisationName('');
      setRole(null);
      setInputRole('');
    }
  }, [status, response, org]);

  if (status === ERROR && response && response.toString().includes('400')) {
    sessionStorage.setItem('WHITE_PAPER', 'Success');
  }

  /*Captcha*/
  const [googleCaptcha, setGoogleCaptch] = useState(false);
  /*change with sdplus google captcha sitekey*/
  const sitekey = process.env.REACT_APP_SITE_KEY;
  const handleChange = (value) => {
    setGoogleCaptch(true);
  };
  return (
    <>
      <form className={classes.form} noValidate>
        <OutlinedTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={fullName}
          onChange={handleFullName}
          id="fullName"
          label="Full Name"
          name="fullName"
          autoComplete="fullName"
          autoFocus
        />
        {spacialCharacter && spacialCharacter ? (
          <Typography variant="h6" style={{ textAlign: 'left', color: '#ED5E68', fontSize: '10px' }}>
            special characters are not allowed !!
          </Typography>
        ) : null}
        <OutlinedTextField
          variant="outlined"
          margin="normal"
          required
          value={email}
          onChange={handleEmail}
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
        />
        <OutlinedTextField
          variant="outlined"
          margin="normal"
          value={organisationName}
          onChange={handleOrganisation}
          fullWidth
          id="organisation"
          label="Organisation"
          name="organisation"
          autoComplete="organisation"
        />
        <Autocomplete
          style={{ marginTop: '15px' }}
          value={role}
          onChange={(event, newValue) => {
            if (!format.test(newValue)) {
              setRoleSpecialCharacter(false);
              setRole(newValue);
            } else {
              setRoleSpecialCharacter(true);
            }
          }}
          inputValue={inputRole}
          onInputChange={(event, newInputValue) => {
            if (!format.test(newInputValue)) {
              setInputRole(newInputValue);
              setRoleSpecialCharacter(false);
            } else {
              setRoleSpecialCharacter(true);
            }
          }}
          id="Role"
          options={roles}
          renderInput={(params) => <OutlinedTextField {...params} label="Role*" variant="outlined" />}
        />
        {roleSpacialCharacter && roleSpacialCharacter ? (
          <Typography variant="h6" style={{ textAlign: 'left', color: '#ED5E68', fontSize: '10px' }}>
            Invalid characters !!
          </Typography>
        ) : null}
        <Typography variant="h6" style={{ textAlign: 'left', marginTop: '10px' }}>
          *All fields are mandatory
        </Typography>
        <Grid container item xs={12} style={{ marginTop: '10px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
          {!googleCaptcha && <ReCAPTCHA sitekey={sitekey} onChange={handleChange} />}
        </Grid>
        {status !== FETCHING && googleCaptcha && (
          <CustomButton type="submit" onClick={submitDropin} fullWidth variant="contained" className={classes.submit}>
            SIGNUP
          </CustomButton>
        )}
        {status === FETCHING && (
          <Grid container direction="column" alignItems="center" item xs={12}>
            <Grid item xs={12} className={classes.submit}>
              <Loader />
            </Grid>
          </Grid>
        )}
      </form>

      {status === SUCCESS && (
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <Paper className={classes.response1}>
              <Typography variant="body1" color="inherit">
                Thank you! We are sending across an introductory email to you.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}

      {status === ERROR && (
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <Paper className={classes.response2}>
              <Typography variant="body1" color="inherit">
                {response && response.toString().includes('Network Error')
                  ? 'Sorry, can’t do that right now'
                  : response && response.toString().includes('404')
                  ? 'Sorry, can’t do that right now'
                  : response && response.toString().includes('400') && purpose === 'Dropin'
                  ? 'We already have your details & have sent out an email. Please reach out to us at connect@sdplus.io if you need further assistance.'
                  : response && response.toString().includes('400') && purpose === 'White Paper'
                  ? 'Welcome back! White papers are now ready to download.'
                  : response && response.toString().includes('408')
                  ? 'Facing timeout issues, please retry later'
                  : 'Sorry, can’t do that right now'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
