import vendor1 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 12.png';
import vendor2 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 22.png';
import vendor3 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 32.png';
import vendor4 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 42.png';

export const DummyVendors = [
  {
    image: vendor1,
    name: 'Sewage to Water',
    type: 'ECOSTP',
    desc: 'A biomimmicry innovation to treat sewage naturally without power or chemicals.',
  },
  {
    image: vendor2,
    name: 'Agri Fiber Panel',
    type: 'Strawcture',
    desc: 'Next generation building materials out of waste in form of High Engineered panels',
  },
  {
    image: vendor3,
    name: 'Smart Water Meter',
    type: 'WEGoT',
    desc: 'Real-time water management to help manage consumption & quality with ease.',
  },
  {
    image: vendor4,
    name: 'Air Quality Monitor',
    type: 'Clairco',
    desc: 'Enhanced air purification and real-time insights to improve occupant experience',
  },
  {
    image: vendor3,
    name: 'Sewage to clean water',
    type: 'Openwater',
    desc: 'Easy. Scalable. Zero wastage. Make water treatment and management hassle-free with Openwater',
  },
];
