import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import { ProductsPageContext } from '../../../routing/IndexRouting';
import { useContext } from 'react';

export default function CustomBreadCrumbs(props) {
  const { links, stage, fontSize } = { ...props };
  const { handleReset } = useContext(ProductsPageContext);

  return (
    <div>
      <Breadcrumbs separator={<ArrowRightIcon fontSize="small" />} aria-label="breadcrumb">
        {links.map((link, index) => {
          if (link.text) {
            return (
              <Link key={index} style={{ color: 'black', fontSize: fontSize }} to={link.href} onClick={handleReset}>
                {link.text}
              </Link>
            );
          } else {
            return <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />;
          }
        })}
        <Typography color="textPrimary" style={{ fontSize: fontSize }}>
          {stage}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
