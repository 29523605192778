import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Grid, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useAppApiRequest } from '../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../metafire/utils/types';
import ApiError from '../../common/Error/ApiError';
import { useAppMetafireUtilities } from '../../../metafire/hooks/useAppMetafireUtilities';
import numberWithCommas from '../../../utils/numberToComma';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import GoalCategoryCard from './GoalCategoryCard';
import { GOALS_CATEGORIES } from './styles';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import PrimaryButton from '../../common/button/Button';
import GoalCard from './GoalCard';
import axios from 'axios';
import { getGoal, getTarget } from './utils/util';

const useStyles = makeStyles((theme) => ({
  main: { paddingLeft: '4%', paddingRight: '4%', paddingTop: '9%' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GoalsInsights = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { getRoundOffValue } = useAppMetafireUtilities();
  const [overAllData, setOverAllData] = useState(null);
  const [allGoals, setAllGoals] = useState([]);

  const { data: response, isFetching, isError, refetch } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.GOALS}`);
  const { data: goalResponse, isFetching: isGoalFetching, isError: isGoalError } = useAppApiRequest(`/goalsschemev2`);
  const {
    data: projectTargetResponse,
    isFetching: isTargetFetching,
    isError: isTargetError,
    refetch: targetFetch,
  } = useAppApiRequest(`/projectTarget?project=${params?.id}`);
  const goalsdata = goalResponse?.data?.data?.data;
  let targetMapping = projectTargetResponse?.data?.data?.data;

  useEffect(() => {
    if (response?.data) {
      const overallData = response?.data?.data?.data?.find((data) => data.id === 'goals_overall');
      const allGoals = response?.data?.data?.data?.filter((data) => data.id !== 'goals_overall');
      setOverAllData(overallData);
      setAllGoals(allGoals);
    }
  }, [response]);

  const handleStatusUpdate = (checked, target) => {
    axios
      .put(`/projectTarget/${target?._id}`, { status: checked, projectId: params?.id })
      .then((res) => {
        refetch(); // Trigger refetch after successful update
        targetFetch();
      })
      .catch((error) => {
        console.error('Error updating target:', error);
      });
  };

  if (isError)
    return (
      <div className={classes.main}>
        <ApiError />
      </div>
    );

  return (
    <>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      <Grid className={classes.main} container justifyContent="center" alignItems="center" xs={12}>
        <Grid xs={12} style={{ paddingBottom: '3%', marginLeft: '-3%' }}>
          <CommonHeadingWithIcon heading="Goals" variant="h3" handleClick={() => history.goback()} />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start">
          {isFetching ? (
            <CasaSkeleton variant="rect" height="200px" width="98%" animation="wave" />
          ) : (
            allGoals &&
            allGoals?.map((goalCategory, index) => {
              return (
                <Grid container xs={3} key={index}>
                  <GoalCategoryCard
                    border={`2px solid ${GOALS_CATEGORIES[goalCategory?.id].border}`}
                    background={GOALS_CATEGORIES[goalCategory?.id].background}
                    icon={GOALS_CATEGORIES[goalCategory?.id].icon}
                    title={goalCategory?.value?.name}
                    text={`${numberWithCommas(getRoundOffValue(goalCategory?.value?.additionalCostPerSqft))} ${goalCategory?.value?.additionalCostPerSqftUnit}`}
                    barBackground={GOALS_CATEGORIES[goalCategory?.id].bar}
                    progressValue={goalCategory?.value?.progress}
                    barText={numberWithCommas(getRoundOffValue(goalCategory?.value?.progress))}
                    description={getGoal(goalsdata, goalCategory?.value?.name)?.description}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
        {/* Score Card */}
        <Grid xs={12} style={{ marginTop: '3%' }}>
          <Typography variant="h5">
            <b>Achievable Targets</b>
          </Typography>
        </Grid>
        {/* Targets */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} style={{ marginBottom: '3%', paddingRight: '10%' }}>
          <Typography style={{ textAlign: 'justify', lineHeight: 2 }} variant="h6">
            Here are targets categorized to align with your Net Zero goals. You can toggle each target based on preference and project needs. Turning off a
            target disables associated interventions unless shared by other active targets. Targets are classified into Tiers I to IV, indicating impact levels
            on Net Zero goals up to 25%, 50%, 75%, and 100% respectively.
          </Typography>
        </Grid>
        <Grid container>
          {isFetching ? (
            <CasaSkeleton variant="rect" height="100px" width="98%" animation="wave" />
          ) : (
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              style={{ marginBottom: '3%', border: '2px solid #C6F2CD', paddingLeft: '2%', paddingRight: '2%', paddingBottom: '2%', borderRadius: '16px' }}
            >
              <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                <Typography variant="h5">
                  <b>Scoreboard</b>
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-start" alignItems="center" xs={3} direction="column" style={{ marginRight: '4%' }}>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                  <Typography variant="h6" color="textSecondary" style={{ marginBottom: '3%' }}>
                    Goals Achieved
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center" style={{ marginTop: '3%' }}>
                  <Typography variant="h6">
                    <b>
                      {overAllData?.value?.goalsAchieved?.achievedGoals}/{overAllData?.value?.goalsAchieved?.totalGoals} Goals
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center" style={{ marginTop: '5%' }}>
                  <GoalCard.Progress
                    variant="determinate"
                    progressValue={(overAllData?.value?.goalsAchieved?.achievedGoals / overAllData?.value?.goalsAchieved?.totalGoals) * 100}
                    style={{ flexGrow: '1' }}
                    background="#E5E5E5"
                    barBackground="linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)"
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-start" alignItems="center" xs={3} direction="column" style={{ marginRight: '4%' }}>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                  <Typography variant="h6" color="textSecondary" style={{ marginBottom: '3%' }}>
                    Targets Achieved
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center" style={{ marginTop: '3%' }}>
                  <Typography variant="h6">
                    <b>
                      {overAllData?.value?.targetsAchieved?.achievedGoals}/{overAllData?.value?.targetsAchieved?.totalGoals} Targets
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center" style={{ marginTop: '5%' }}>
                  <GoalCard.Progress
                    variant="determinate"
                    progressValue={(overAllData?.value?.targetsAchieved?.achievedGoals / overAllData?.value?.targetsAchieved?.totalGoals) * 100}
                    style={{ flexGrow: '1' }}
                    background="#E5E5E5"
                    barBackground="linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)"
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-start" alignItems="center" xs={3} direction="column">
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                  <Typography variant="h6" color="textSecondary" style={{ height: 29 }}>
                    Capex Increase
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                  <Typography variant="h6" style={{ marginTop: '1%', height: 32 }}>
                    <b>
                      {numberWithCommas(getRoundOffValue(overAllData?.value?.capexIncrease))} {overAllData?.value?.capexIncreaseUnit}
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start" alignItems="center" style={{ marginTop: '5%' }}></Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item container justifyContent="flex-start" alignItems="flex-start" xs={12} style={{ marginBottom: '5%' }}>
          {isTargetFetching ? (
            <div style={{ marginTop: 16, width: '100%' }}>
              <CasaSkeleton variant="rect" height="100px" width="98%" animation="wave" />
            </div>
          ) : (
            allGoals?.map((goal, index) => {
              return (
                <Grid key={index} item xs={3} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item container alignItems="center" justifyContent="center" style={{ marginBottom: '6%' }}>
                    <Grid xs={3} container alignItems="center" justifyContent="center">
                      <img src={GOALS_CATEGORIES[goal?.id].targets.icon} alt="icon" style={{ height: '44px', width: '60px' }} />
                    </Grid>
                    <Grid xs={9} container alignItems="center" justifyContent="flex-start">
                      <Typography variant="h5" style={{ lineHeight: '20px', letterSpacing: '0.7px' }}>
                        {GOALS_CATEGORIES[goal?.id].targets.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: '3%' }}>
                    {goal?.value?.targets.map(({ achievability, name, tier, additionalCostPerUnitArea, progress, state }) => {
                      if (achievability === 'No') {
                        return <GoalCard.NotAchievable border="2px solid #CCCCCC" title={name} />;
                      } else if (achievability === 'Yes' && getTarget(targetMapping, name)?.enabled !== true) {
                        return (
                          <GoalCard.Disabled
                            border={`2px solid ${GOALS_CATEGORIES[goal?.id].targets.border}`}
                            title={name}
                            tier={tier}
                            text={`+${numberWithCommas(getRoundOffValue(additionalCostPerUnitArea))} Rs/Sqft`}
                            progressValue={progress}
                            barBackground={GOALS_CATEGORIES[goal?.id].targets.bar}
                            barText={numberWithCommas(getRoundOffValue(progress))}
                            status={getTarget(targetMapping, name)?.enabled}
                            target={getTarget(targetMapping, name)}
                            handleStatusUpdate={handleStatusUpdate}
                          />
                        );
                      } else {
                        return (
                          <GoalCard.Default
                            border={`2px solid ${GOALS_CATEGORIES[goal?.id].targets.border}`}
                            title={name}
                            tier={tier}
                            text={`+${numberWithCommas(getRoundOffValue(additionalCostPerUnitArea))} Rs/Sqft`}
                            progressValue={progress}
                            barBackground={GOALS_CATEGORIES[goal?.id].targets.bar}
                            barText={numberWithCommas(getRoundOffValue(progress))}
                            status={getTarget(targetMapping, name)?.enabled}
                            target={getTarget(targetMapping, name)}
                            handleStatusUpdate={handleStatusUpdate}
                          />
                        );
                      }
                    })}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
        <Grid container item xs={12} alignItems="center" style={{ marginBottom: '5%' }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <PrimaryButton
              children="Save and Exit"
              style={{ width: '150px', borderRadius: '40px', height: '30px' }}
              onClick={() => history.push(`/projects/${params?.id}/insights`)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GoalsInsights;
