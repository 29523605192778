import React, { useState } from 'react';
import ProductCarousal from '../../../../marketplace/views/productDetails/components/ProductCarousal';
import RightArrow from '../../../../marketplace/common/RightArrow';
import LeftArrow from '../../../../marketplace/common/LeftArrow';
import leftarrow from '../../../../../../assets/carousal/leftarrow.svg';
import rightarrow from '../../../../../../assets/carousal/rightarrow.svg';
import { Modal, makeStyles } from '@material-ui/core';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        display: 'flex',
        background: 'transparent',
    };
}
const rightarrowStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    cursor: 'pointer',
};

const useStyles = makeStyles((theme) => ({
    arrowStyles: {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 25,
        height: 56,
        cursor: 'pointer',
    },
    paper: {
        position: 'absolute',
        width: '100%',
        height: '80vw',
        outline: 'none',
        background: 'transparent',
        borderRadius: '15px',
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function ImageViewer({ staticassetids, showModal, setShowModal }) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const next = () => { };
    const prev = () => { };
    const onClose = () => {
        setShowModal(false);
    };
    const handleClick = () => {
        setShowModal(true);
    };
    const leftarrowStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        cursor: 'pointer',
        width: '20px'
    };
    const imageStyles = {
        display: 'block',
    };
    return (
        <Modal open={showModal} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div onClick={onClose} style={{ direction: 'flex', flexDirection: 'row', ...modalStyle }} className={classes.paper}>
                <LeftArrow zoomed={true} leftarrow={leftarrow} next={next} prev={prev} arrowStyles={leftarrowStyles} imageStyles={imageStyles} />
                <div style={{ width: '80%', borderRadius: '15px', backgroundColor: 'white', margin: '0.5%' }}>
                    <ProductCarousal zoomed={true} handleClick={handleClick} interventionpage={true} staticassets={staticassetids} />
                </div>
                <RightArrow zoomed={true} rightarrow={rightarrow} next={next} arrowStyles={rightarrowStyles} imageStyles={{ opacity: 1, ...imageStyles }} />
            </div>
        </Modal>
    )
}