import React from 'react';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../footer/MarketplaceFooter';
import PrivacyPolicyData from './PrivacyPolicyData';

const PrivacyPolicy = () => {
  return (
    <>
      <MarketplaceNavbar />
      <PrivacyPolicyData />
      <MarketplaceFooter />
    </>
  );
};

export default PrivacyPolicy;
