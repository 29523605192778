import React, { useEffect, useContext } from 'react';
import MarketplaceNavbar from '../../../../navbar/MarketplaceNavbar';
import { MarketplaceTypography, MarketplaceColors } from '../../../../config';
import { ProductsPageContext } from '../../../../../../../routing/IndexRouting';
import { Button, Grid, Paper, Link, makeStyles, withWidth, Typography } from '@material-ui/core';
import MarketplaceNavigatorMobile from '../../../../navbar/components/mobile/MarketplaceNavigatorMobile';

import Main from '../Main';
import { NavigatorSource } from '../../../../utils/Defaults';
import MarketplaceFooter from '../../../../footer/MarketplaceFooter';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  stickypaper: {
    position: 'fixed',
    bottom: theme.spacing(0),
    backgroundColor: MarketplaceColors.CommonWhite,
    height: '56px',
    boxShadow: '0px -2px 10px 0px #00000012',
    width: '100%',
  },
  buttonstyle: {
    background: 'transparent',
    height: '100%',
    color: MarketplaceColors.TextColorPrimary,
    borderRight: '1px solid #9E9E9E',
    boxShadow: 'none',
    height: '56px',
    borderRadius: '0px',
  },
  buttonText: MarketplaceTypography.ButtonSmall,
  link: MarketplaceTypography.CaptionSmall,
}));

export const FilterSections = {
  NoSection: '',
  Brands: 'Brands',
  Filters: 'Filters',
};
export const FilterSubSections = {
  NoSection: '',
  SubCategory: 'SubCategory',
};

function CategoryDetailsMobile(props) {
  const classes = useStyles();

  const { URLS, handleFilterData, handleAppliedFilters } = useContext(ProductsPageContext);
  const { category, subcategory, filters, brands, source } = URLS;

  const { products, categories, totalproducts, ecoLabels, allbrands } = props;
  console.log(filters, 'filters');
  useEffect(() => {
    if (brands !== null) {
      brands.split(',').map((ele) => {
        handleFilterData(FilterSections.Brands, ele, true);
        handleAppliedFilters(FilterSections.Brands, ele, true);
      });
    }
    if (filters !== null) {
      filters.split(',').map((ele) => {
        handleFilterData(FilterSections.Filters, ele, true);
        handleAppliedFilters(FilterSections.Filters, ele, true);
      });
    }
    if (subcategory !== null) {
      subcategory.split(',').map((ele) => {
        handleFilterData(FilterSubSections.SubCategory, ele, true);
        handleAppliedFilters(FilterSubSections.SubCategory, ele, true);
      });
    }
    if (category !== null && source === NavigatorSource.supercategories)
      category.split(',').map((ele) => handleAppliedFilters(NavigatorSource.categories, ele, true));
  }, []);

  return (
    <>
      <MarketplaceNavbar categories={categories} />
      <div className={classes.mainContainer}>
        <Main products={products} categories={categories} totalproducts={totalproducts} ecoLabels={ecoLabels} allbrands={allbrands} />
      </div>
      <Paper className={classes.stickypaper}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Button variant="contained" fullWidth className={classes.buttonstyle}>
              <Typography className={classes.buttonText}>
                Sort by<Link className={classes.link} style={{ color: MarketplaceColors.Tertiary }}>{`  A -> Z`}</Link>
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Button variant="contained" className={classes.buttonstyle} fullWidth>
              <MarketplaceNavigatorMobile source="filter" categories={categories} ecoLabels={ecoLabels} allbrands={allbrands} />
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <MarketplaceFooter />
    </>
  );
}

export default withWidth()(CategoryDetailsMobile);
