import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grow, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { DummyVendorsList3 } from './DummyVendorsList3';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { FETCHING, SUCCESS, ERROR } from '../../../hooks/useApiRequest/actionTypes';
import Loader from '../../../common/progressbar/Loader';
import useApiRequest from '../../../hooks/useApiRequest';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomButton from '../../../common/button/CustomButton';

const OutlinedTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
        width: '266px',
        paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: '266px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '210px',
        },
      },
    },
  },
}))(TextField);

const roles = ['Real Estate Developer', 'Architect', 'MEP Consultant', 'Green Consultant', 'Student', 'Individual'];
function VendorsList2(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      maxHeight: 'auto',
      marginLeft: '11%',
      marginRight: '10%',
      paddingTop: '5%',
      paddingBottom: '2%',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      width: '170px',
      height: '250px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        width: '133px',
        height: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '270px',
      },
    },
    media: {
      width: '173px',
      height: '130px',
      [theme.breakpoints.down('md')]: {
        width: '133px',
        height: '100px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '103px',
        height: '100px',
      },
    },
    typo2: {
      textAlign: 'left',
      fontSize: '18px',
      lineHeight: '31px',
    },
    response1: {
      padding: '10px',
      marginTop: '15px',
      width: '266px',
      height: 'auto',
      background: '#C6F2CD',
      border: '1px solid #49DDB1',
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: '#666666',
      [theme.breakpoints.down('sm')]: {
        width: '210px',
        height: 'auto',
      },
    },
    response2: {
      padding: '10px',
      marginTop: '15px',
      width: '266px',
      height: 'auto',
      background: '#FFF5E2',
      border: '1px solid #FFF5E2',
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.warning.main,
      [theme.breakpoints.down('sm')]: {
        width: '210px',
        height: 'auto',
      },
    },
    submit: {
      marginTop: theme.spacing(2),
      marginLeft: '-1%',
    },
    root: {
      textAlign: 'left',
      '& > *': {
        textAlign: 'left',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(10),
        width: '25ch',
      },
    },
    icon: {
      marginTop: 200,
      textAlign: 'center',
      background: 'rgba(0, 0, 0, 0.05)',
      width: '41px',
      height: '92px',
    },
    dropdown: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: `10px`,
          width: '266px',
          paddingBottom: '30px',
          [theme.breakpoints.down('md')]: {
            width: '266px',
          },
          [theme.breakpoints.down('sm')]: {
            width: '210px',
          },
        },
      },
    },
    cardcontent: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
      },
    },
    select: {
      padding: '1px',
      borderRadius: `15px`,
      [theme.breakpoints.down('sm')]: {
        width: '210px',
        padding: '2px',
        borderRadius: `15px`,
      },
    },
  }));
  const classes = useStyles();
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const [spacialCharacter, setSpecialCharacter] = useState(false);
  const [orgSpacialCharacter, setOrgSpecialCharacter] = useState(false);
  const matches = useMediaQuery('(min-width:1025px)');
  const matchesTab = useMediaQuery('(min-width:1020px)');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [organizationName, setOrganizationName] = useState(null);
  const [role, setRole] = useState('');
  const handleEmail = (event) => setEmail(event.target.value);
  const handleOrganization = (event) => {
    if (format.test(event.target.value)) {
      setOrgSpecialCharacter(true);
    } else {
      setOrgSpecialCharacter(false);
      setOrganizationName(event.target.value);
    }
  };
  const handleFullName = (event) => {
    if (format.test(event.target.value)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
      setFullName(event.target.value);
    }
  };
  let [{ status, response }, makeRequest] = useApiRequest('/dropins', {
    verb: 'post',
    params: { email: email, fullname: fullName, organisationname: organizationName ? organizationName : '', role: role },
  });
  const submitDropin = (event) => {
    event.preventDefault();
    if (fullName && email && organizationName && role) {
      makeRequest();
    }
  };
  useEffect(() => {
    if (status === SUCCESS && response) {
      setFullName('');
      setEmail('');
      setOrganizationName('');
      setRole('');
    }
  }, [status, response]);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);
  return (
    <>
      <Grid container xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" style={{ marginLeft: '6%' }}>
        <Paper elevation={3} style={{ width: '95%', height: 'auto', padding: '10px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div data-aos="fade-right" style={{ textAlign: 'right' }}>
              <Button onClick={props.handleClose}>
                <CloseIcon />
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div data-aos="fade-right" style={{ textAlign: 'center' }}>
              <Typography variant="body2">Vendor Catalogue</Typography>
            </div>
          </Grid>
          <br />
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction="row">
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <div className={classes.icon} data-aos="fade-left" onClick={props.handleScroll}>
                <ArrowBackIosIcon style={{ marginTop: 38 }} />
              </div>
            </Grid>
            <Grow in={checked} style={{ transformOrigin: '0 10 0' }} {...(checked ? { timeout: 1000 } : {})}>
              <Grid container item xs={7} sm={7} md={7} lg={7} xl={7} alignItems="left">
                {DummyVendorsList3 &&
                  DummyVendorsList3.map((tile, index) => {
                    return (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index} style={{ paddingBottom: '10px', paddingRight: '20%' }}>
                          <Card className={classes.card}>
                            <CardActionArea>
                              <CardMedia className={classes.media} image={tile.image} />
                              <CardContent className={classes.cardcontent}>
                                <Typography style={{ color: '#224B8B', fontSize: '12.1636px' }}>{tile.name}</Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  <b>{tile.type}</b>
                                </Typography>
                                <Typography style={{ fontSize: ' 9.58846px', marginTop: '4%' }}>{tile.desc}</Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grow>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} alignItems="left">
              <div>
                {matches ? (
                  <Typography className={classes.typo2} variant="body1">
                    Sign up to know more about <br />
                    our integrated vendor <br /> solutions
                  </Typography>
                ) : (
                  <Typography className={classes.typo2} variant="body1">
                    Sign up to know more about our integrated vendor solutions
                  </Typography>
                )}
              </div>
              <form className={classes.root} noValidate autoComplete="off">
                <OutlinedTextField label="Full Name" required variant="outlined" value={fullName} onChange={handleFullName} />
                {spacialCharacter && spacialCharacter ? (
                  <Typography variant="h6" style={{ textAlign: 'left', color: '#ED5E68', fontSize: '10px' }}>
                    Invalid characters !!
                  </Typography>
                ) : null}
                <OutlinedTextField label="Email" required variant="outlined" value={email} onChange={handleEmail} />
                <OutlinedTextField label="Organization Name" variant="outlined" value={organizationName} onChange={handleOrganization} />
                {orgSpacialCharacter && orgSpacialCharacter ? (
                  <Typography variant="h6" style={{ textAlign: 'left', color: '#ED5E68', fontSize: '10px', marginBottom: '3px' }}>
                    Invalid characters !!
                  </Typography>
                ) : null}
                <FormControl variant="outlined" style={{ width: '266px', borderRadius: `50px` }} className={classes.dropdown}>
                  <InputLabel id="demo-simple-select-label">Role*</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="role"
                    placeholder="role"
                    className={classes.select}
                    value={role}
                  >
                    {roles
                      ? roles.map((role) => (
                          <MenuItem onClick={() => setRole(role)} value={role} name={role} style={{ paddingTop: '2%' }}>
                            {role}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>

                <Typography variant="h6">*All fields are mandatory</Typography>
                {status !== FETCHING && (
                  <CustomButton
                    type="submit"
                    onClick={submitDropin}
                    fullWidth
                    variant="contained"
                    className={classes.submitButton}
                    style={
                      matchesTab
                        ? {
                            width: '265px',
                            height: '40px',
                            background: 'linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)',
                            borderRadius: '6px',
                            color: '#ffffff',
                          }
                        : {
                            width: '210px',
                            height: '40px',
                            background: 'linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)',
                            borderRadius: '6px',
                            color: '#ffffff',
                          }
                    }
                  >
                    {status && status !== ERROR ? 'Submitted' : status === FETCHING ? <Loader style={{ color: '#ffff' }} /> : 'Submit'}
                  </CustomButton>
                )}
              </form>

              {status && status !== ERROR ? (
                <Grid container spacing={1} item xs={12}>
                  <Grid item xs={12}>
                    <Paper className={classes.response1}>
                      <Typography variant="body1" color="inherit">
                        Thank you !! we’ll get in touch with you ASAP.
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
              {status === ERROR && (
                <Grid container spacing={1} item xs={12}>
                  <Grid item xs={12}>
                    <Paper className={classes.response2}>
                      <Typography variant="body1" color="inherit">
                        {response && response.toString().includes('Network Error')
                          ? 'Sorry, can’t do that right now'
                          : response && response.toString().includes('404')
                          ? 'Sorry, can’t do that right now'
                          : response && response.toString().includes('400')
                          ? 'Email already Exists'
                          : response && response.toString().includes('408')
                          ? 'Facing timeout issues, please retry later'
                          : null}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
export default VendorsList2;
