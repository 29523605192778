import React from 'react';
import { ResourcesData, types } from '../MaterialSelectionGuideData';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import cement from '../../../../../../assets/Marketplace/material-resources/cement-resource.svg';
import cementResourceTwo from '../../../../../../assets/Marketplace/material-resources/cement-resource-2.svg';
import ImageWithSource from './components/ImageWithSource';
import TypesOfCement from './components/TypesOfCement';
import Steppercard from './components/StepperCard';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import ScrollableTable from './components/ScrollableTable';

const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

function createData(name, a, b, c, d) {
  return { name, a, b, c, d };
}
const rows = [
  createData('Environmental Impact', 'High', 'Lower', 'Lower', `Lower`),
  createData(
    'Composition of Material',
    'Clinker, Gypsum',
    'Clinker, GBFS, Gypsum',
    'Clinker (50-70%), Pozzolanic Materials (30-50%), Gypsum',
    `Clinker, Pozzolanic Materials (e.g., fly ash, silica fume, GBFS), Gypsum`
  ),
  createData(
    'Compressive Strength',
    'Varies (e.g., 33 MPa, 43 MPa, 53 MPa)',
    'Typically around 33 MPa - 60 MPa',
    `Varies (e.g., 33 MPa, 43 MPa)`,
    'Not applicable'
  ),
  createData('Initial Setting Time', 'Approximately 30 minutes', 'Approximately 45 minutes', 'Approximately 60 minutes', `Approximately 40 minutes`),
  createData('Final Setting Time', 'Approximately 240 minutes', 'Approximately 360 minutes', 'Approximately 600 minutes', `Approximately 300 minutes`),
  createData('Workability', `Good`, `Good`, `Good`, `Good`),
  createData('Durability', `High`, `High`, `High`, `High`),
  createData('Heat of Hydration', 'High', 'Lower than OPC', 'Moderate', `Moderate`),
  createData(
    'Resistance against chemical attacks',
    'Generally finer than PSC, coarser than PPC',
    'Generally finer than PCC',
    '300 to 400 m²/kg',
    `Similar to OPC`
  ),
  createData('Resistance against chemical attacks', 'Good', 'Good', 'Good', `Good`),
  createData('Cost', 'Medium to High', 'Medium to High', 'Medium to High', `1.95E+00 (kg NMVOC-eq_*`),
  createData('Market Availability in India', 'Widely Available', 'Widely Available', 'Widely Available', `Limited Available`),
  createData(
    'Application',
    `General Construction, Concrete Structures, Pre-cast Concrete Elements, Plastering and Masonry`,
    `General Construction, Mass Concrete Works, Marine Structures`,
    `General Construction, Marine Works, Mass Concrete, Plastering, Masonry, etc.`,
    `Special Applications, Infrastructure Projects, Precast Concrete Products`
  ),
];
const tableHeaders = [
  'Cement Type',
  'Ordinary Portland Cement (OPC)',
  'Portland Slag Cement (PSC)',
  'Portland Pozzolana Cement (PPC)',
  'Portland Composite Cement (PCC)',
];

const Headers = ['', 'Typically used for Residential Apartments', '', 'Sustainable Alternatives to Typically Used Cement', ''];

const Cement = (props) => {
  const classes = useStyles();
  const steppers = [];
  const history = useHistory();
  const cementData = ResourcesData.filter((d) => d.iid === 'Cement');
  cementData.forEach((d) => steppers.push(d.data.find((g) => g.type === types.steppers)));
  const icons = { Identify: Seek, Replace: Check, Choose: Inquire, Consult: Choose };

  return (
    <div>
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{cementData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>
        {/* steppers */}
        {cementData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start" style={{ marginLeft: '4%' }}>
            {/* steppers */}
            <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers[0].data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d) => (
              <>
                {/* image with source*/}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 2 && <ImageWithSource image={cement} source={d.source} imageStyle={{ width: '100%' }} />}
                </Grid>
                {/* text */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.text && d.id === 3 && (
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify', lineHeight: '35px' }}>
                      {d.text}
                    </Typography>
                  )}
                </Grid>
                {/* image */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 4 && <ImageWithSource image={cementResourceTwo} source={d.source} />}
                </Grid>
                {/* table */}
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 5 && <ScrollableTable rows={rows} tableHeaders={tableHeaders} Headers={Headers} />}
                </Grid>
                {/* Text */}
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.text && d.id === 6 && <TypesOfCement data={d} />}
                </Grid>
              </>
            ))}
          </Grid>
        ))}
      </Container>
      <MarketplaceFooter />
    </div>
  );
};

export default Cement;
