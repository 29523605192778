import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../common/textfield/Fields';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '13px 10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid red;',
        borderRadius: '20px',
      },
      '&:hover fieldset': {
        borderColor: 'red;',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red;',
      },
    },
  },
})(Fields);

function Addname(props) {
  const { duplicate, filename, setFilename, setduplicate } = props;
  return (
    <>
      <Grid xs={12} item direction="column">
        <Typography variant="h5">File Name</Typography>
        <Typography variant="h6" color="textSecondary">
          Suitable file name to indicate the type of file.<b> E.g. Plumbing DBR</b>
        </Typography>
        <Grid item xs={4} style={{ marginTop: '3%' }}>
          {duplicate === true ? (
            <>
              <CssTextField
                id="outlined-basic"
                variant="outlined"
                value={filename}
                placeholder="Enter Name"
                onChange={(e) => {
                  e.preventDefault();
                  setFilename(e.target.value);
                  setduplicate(false);
                }}
                active
                error
                inputProps={{ style: { border: 'red', color: 'red' } }}
                style={{ border: 'red' }}
                fullWidth
                required
              />
              <Typography variant="h6" style={{ color: 'red', marginLeft: '15px', marginTop: '10px' }}>
                File name not unique
              </Typography>
            </>
          ) : (
            <Fields
              id="outlined-basic"
              variant="outlined"
              value={filename}
              placeholder="Enter Name"
              onChange={(e) => {
                e.preventDefault();
                setFilename(e.target.value);
              }}
              fullWidth
              required
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Addname;
