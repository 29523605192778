import React, { useEffect, useState, createContext } from 'react';
import { withWidth } from '@material-ui/core';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import Footer from '../../footer/MarketplaceFooter';
import CategoriesSpotlight from './categories-spotlight/CategoriesSpotlight';
import MarketplaceCarousal from './hero-section/MarketplaceCarousal';
import MarketplaceSuperCategoriesSpotlight from './super-categories-spotlight/MarketplaceSuperCategoriesSpotlight';
import MarketplaceCertificationsSpotlight from './certifications-spotlight/MarketplaceCertificationsSpotlight';
import MarketplaceImpactProductsSpotlight from './impact-products-spotlight/MarketplaceImpactProductsSpotlight';
// import MarketplaceStartupsSpotlight from './startups-spotlight/MarketplaceStartupsSpotlight';
import MarketplaceSustainabilityPartners from './sustainability-partners/MarketplaceSustainabilityPartners';
import MarketplaceKnowledgeHub from './knowledgehub/MarketplaceKnowledgeHub';
import MarketplaceJoinus from './joinus/MarketplaceJoinus';
import useGraphqlRequest from '../../../catalogue/Common/SendRequest';
import { query } from './Marketplace.query';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../routing/IndexRouting';

export const Marketplace = (props) => {
  const [supercategories, setSupercategories] = useState('');
  const [load, setLoad] = useState(true);
  const { handleReset } = useContext(ProductsPageContext);

  useEffect(() => {
    handleReset();
  }, []);

  return (
    <React.Fragment>
      <MarketplaceNavbar />
      <MarketplaceCarousal />
      <CategoriesSpotlight />
      <MarketplaceSuperCategoriesSpotlight supercategories={supercategories} />
      <MarketplaceCertificationsSpotlight />
      {/* <MarketplaceImpactProductsSpotlight /> */}
      {/* now we dont have start ups linked to any kind of product to take it to products page when clicked */}
      {/* <MarketplaceStartupsSpotlight /> */}
      <MarketplaceJoinus />
      <MarketplaceSustainabilityPartners />
      <MarketplaceKnowledgeHub />
      <Footer />
    </React.Fragment>
  );
};

export default withWidth()(Marketplace);
