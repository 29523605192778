import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

export const GreenRadio = withStyles({
  root: {
    color: '#2EE1AC',
    '&$checked': {
      color: '#2EE1AC',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
