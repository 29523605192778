import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import { MarketPlaceJoinUsInput } from '../../../../common/TextField';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  padding: {
    paddingTop: '3%',
    paddingBottom: '3%',
  },
  buttonstyle: {
    backgroundColor: '#33518E',
    color: '#FFFFFF',
    borderRadius: '40px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      backgroundColor: '#33518E',
      color: '#FFFFFF',
    },
  },
  textHeadMobile: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14.8px',
    letterSpacing: '0.71px',
    color: '#000000',
    paddingTop: '3%',
    paddingBottom: '3%',
  },
  draggerStyle: {
    color: 'transparent',
    fontSize: '30px',
    background: '#666666',
    borderRadius: '10px',
    height: '6px',
  },
});

export default function JoinUsFormMobile(props) {
  const classes = useStyles();
  const { joinus, setJoinus } = props;
  const [name, setName] = useState('');
  const [websitelink, setWebsitelink] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [brochurelink, setBrochurelink] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      email: email,
      name: name,
      organisationname: organisation,
      websitelink: websitelink,
      brochurelink: brochurelink,
      location: location,
    };
    axios
      .post(`/joinus`, body)
      .then((response) => {
        if (response && response?.data?.data) {
          setName('');
          setWebsitelink('');
          setOrganisation('');
          setBrochurelink('');
          setEmail('');
          setLocation('');
          setJoinus(false);
          swal({ icon: 'success', title: 'Request Submitted Successfully' });
        }
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };
  return (
    <div>
      <SwipeableDrawer anchor="bottom" open={joinus} onClose={() => setJoinus(false)} onOpen={() => setJoinus(true)}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" style={{ paddingTop: '5%', height: '100vh' }}>
          <Grid container item xs={12} justifyContent="center">
            <DragHandleIcon className={classes.draggerStyle} onClick={() => setJoinus(false)} />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography className={classes.textHeadMobile} justifyContent="center">
              Become a Partner
            </Typography>
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput
              variant="outlined"
              fullWidth
              value={name}
              // onClick={() => {
              //   setName('');
              // }}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput
              placeholder="Website Link"
              value={websitelink}
              variant="outlined"
              fullWidth
              onChange={(e) => setWebsitelink(e.target.value)}
            />
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput
              placeholder="Organization"
              variant="outlined"
              fullWidth
              value={organisation}
              onChange={(e) => setOrganisation(e.target.value)}
            />
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput
              placeholder="Brochure Link (optional)"
              value={brochurelink}
              variant="outlined"
              fullWidth
              onChange={(e) => setBrochurelink(e.target.value)}
            />
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput value={email} placeholder="Email" variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)} />
          </Grid>
          <Grid container item xs={8} alignItems="Left" justifyContent="Left" className={classes.padding}>
            <MarketPlaceJoinUsInput
              placeholder="Location (optional)"
              value={location}
              variant="outlined"
              fullWidth
              onChange={(e) => setLocation(e.target.value)}
            />
          </Grid>
          <Grid container item xs={8} justifyContent="center" className={classes.padding}>
            <Button variant="outlined" onClick={handleSubmit} disableElevation className={classes.buttonstyle}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </div>
  );
}
