import React from 'react';
import { GridList, Typography, makeStyles, withWidth } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import supercategoryArrow from '../../../../../../assets/Marketplace/supercategoryArrow.svg';
import useScreenType from '../../../../../hooks/checkScreenType';
import { Default } from '../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '80%',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '60%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    paddingTop: '2%',
  },
}));

function MenuItems(props) {
  const classes = useStyles();
  const categoryname = ['Structure', 'Envelope', 'Interior', 'Finishes', 'Plumbing']; // 'Appliances'
  const { width, selected, setSelected, state } = props;
  const { isMobile } = useScreenType(width);

  return (
    <div className={classes.container}>
      <GridList className={classes.gridList}>
        {categoryname.map((ele) => {
          return (
            <Typography
              variant="h6"
              key={ele}
              style={{
                color: selected === ele ? MarketplaceColors.Secondary : MarketplaceColors.NonselectedCategory,
                fontWeight: 700,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '30px',
                width: 'auto',
                marginRight: isMobile ? '8%' : '9%',
              }}
              onClick={() => {
                setSelected(ele);
                state.response = null;
                // const filtereddata = supercategories && supercategories?.superCategories?.data.filter((element) => element.attributes?.Name === ele);
                // setSupercategoryProductList(filtereddata);
              }}
            >
              {`${ele} `}
              {selected === ele ? (
                <a
                  // target="_blank"
                  onclick="return false;"
                  href={`/marketplace/categoryDetails?source=SuperCategories&supercategory=${ele}&page=${Default.page}&pagesize=${Default.pagesize}`}
                >
                  <img src={supercategoryArrow} alt="..." style={{ paddingLeft: '4%', cursor: 'pointer' }} />
                </a>
              ) : (
                ''
              )}
            </Typography>
          );
        })}
      </GridList>
    </div>
  );
}

export default withWidth()(MenuItems);
