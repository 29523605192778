import React from 'react';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ResourcesData } from '../MaterialSelectionGuideData';
import { types } from '../MaterialSelectionGuideData';
import Steppercard from './components/StepperCard';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import ImageWithSource from './components/ImageWithSource';
import ScrollableTable from './components/ScrollableTable';
const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

function createData(name, a, b, c) {
  return { name, a, b, c };
}

const rows = [
  createData('U-Value  (W/m²K)', `5 - 5.8  `, `2.8 - 3.0 `, `0.7 - 1.2`),
  createData('SHGC', '0.7 - 1.2', '0.4 - 0.7', '0.2 - 0.5'),
  createData('VLT (%)', '80 - 90', '70 -80', `60 - 70`),
  createData('Sound Transmission Class (STC)', '25 - 30', '30 - 35', '35 - 40'),
  createData('Fire Resistance ', 'Low ', 'Moderate ', 'Excellent'),
  createData('Durability and Strength', `Low`, `High`, `High`),
  createData('Cost', `Affordable`, `Moderately expensive`, `Highly expensive`),
];

const tableHeaders = ['Properties', 'Single glazed unit (SGU)', 'Double glazed unit (DGU)', 'Triple glazed unit (TGU)'];

function createDataForTableTwo(name, a) {
  return { name, a };
}

const rowsForTableTwo = [
  createDataForTableTwo('Embodied energy inc. ren. Feedstock (MJ)', `17`),
  createDataForTableTwo(`GWP (Global Warming Potential)(kg CO2 eq.)`, '1.2'),
  createDataForTableTwo('Ozone depletion potential (ODP)(kg R11 eq.)', '1.4E-11'),
  createDataForTableTwo('Acidification potential (kg SO2 eq.)', '0.0092'),
  createDataForTableTwo('Eutrophication potential(kg PO4 eq.)', '6.8E-04 '),
  createDataForTableTwo('Photochemical ozone creation potential (POCP)(kg ethene eq.)', `5.4E-04`),
  createDataForTableTwo('SDG', `SDG 7: Affordable and Clean Energy `),
];

const tableTwoHeaders = ['Environmental impacts', 'Float Glass'];

const Glazing = () => {
  const steppers = [];
  const history = useHistory();
  const classes = useStyles();
  const glazingData = ResourcesData.filter((d) => d.iid === 'Glazing');
  glazingData.forEach((d) => steppers.push(d.data.find((g) => g.type === types.steppers)));
  const icons = { Identify: Seek, Check: Check, Inquire: Inquire, Choose: Choose };

  return (
    <div>
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{glazingData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>
        {glazingData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start" style={{ marginLeft: '4%' }}>
            {/* steppers */}
            <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers[0].data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d, index) => (
              <>
                {/* text */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.text && d.id === 2 && (
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      {d.text}
                    </Typography>
                  )}
                </Grid>
                {/* table heading */}
                {d.type === types.heading && d.id === 3 && (
                  <Typography variant="h5">
                    <b>{d.title}</b>
                  </Typography>
                )}
                {/* table */}
                {d.type === types.table && d.id === 4 && <ScrollableTable rows={rows} tableHeaders={tableHeaders} />}
                {d.type === types.image && d.id === 5 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '90%' }} />}
                {d.type === types.image && d.id === 6 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '90%' }} />}
                {d.type === types.heading && d.id === 7 && (
                  <Typography variant="h5">
                    <b>{d.title}</b>
                  </Typography>
                )}
                {/* glazing units*/}
                {d.type === types.points && d.id === 8 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <ol>
                      {d.points.map((point) => (
                        <li>
                          <Grid item container alignItems="flex-start" justifyContent="flex-start">
                            <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                              <b>{point.title} : </b>
                              {point.text}
                            </Typography>
                          </Grid>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                )}
                {/* glazing materials */}
                {d.type === types.points && d.id === 9 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} </b>
                                {point.text}
                              </Typography>
                            </Grid>
                            {!point.text &&
                              point.points.length !== 0 &&
                              point.points.map((g) => {
                                return (
                                  <>
                                    <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                        <b>{g.title}:</b>
                                        {g.text}
                                      </Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                {d.type === types.heading && d.id === 10 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h4">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {/* general factors */}
                {d.type === types.points && d.id === 11 && (
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5" style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <ol>
                      {d.points.map((point) => (
                        <li>
                          <Grid item container alignItems="flex-start" justifyContent="flex-start">
                            <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                              <b>{point.title} </b>
                              {point.text}
                            </Typography>
                          </Grid>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                )}
                {/* technical factors factors */}
                {d.type === types.points && d.id === 12 && (
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5" style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <ol>
                      {d.points.map((point) => (
                        <li>
                          <Grid item container alignItems="flex-start" justifyContent="flex-start">
                            <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                              <b>{point.title} </b>
                              {point.text}
                            </Typography>
                          </Grid>
                          {!point.text &&
                            point.points.length !== 0 &&
                            point.points.map((g) => {
                              return (
                                <>
                                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                      <b>{g.title}</b>
                                      {g.text}
                                    </Typography>
                                  </Grid>
                                </>
                              );
                            })}
                        </li>
                      ))}
                    </ol>
                  </Grid>
                )}
                {/* imageeeeeeeeeeee add here */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 13 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '40%' }} />}
                </Grid>

                {d.type === types.points && d.id === 14 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <ol>
                        {d.points.map((point) => (
                          <li>
                            <Grid item container alignItems="flex-start" justifyContent="flex-start">
                              <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                                <b>{point.title} </b>
                                {point.text}
                              </Typography>
                            </Grid>
                          </li>
                        ))}
                      </ol>
                    </Grid>
                  </Grid>
                )}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 15 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '90%' }} />}
                </Grid>
                {d.type === types.heading && d.id === 16 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {/* table */}
                <Grid style={{ marginBottom: '5%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 17 && <ScrollableTable rows={rowsForTableTwo} tableHeaders={tableTwoHeaders} />}
                </Grid>
              </>
            ))}
          </Grid>
        ))}
      </Container>
      <MarketplaceFooter />
    </div>
  );
};

export default Glazing;
