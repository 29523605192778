import React from 'react';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ResourcesData, types } from '../MaterialSelectionGuideData';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import Steppercard from './components/StepperCard';
import ScrollableTable from './components/ScrollableTable';
import ImageWithSource from './components/ImageWithSource';

function createData(name, a, b, c, d, e, f, g, h) {
  return { name, a, b, c, d, e, f, g, h };
}

const rows = [
  createData(
    'General size',
    `400mm x 200mm x 200mm
  400mm x 200mm x 150mm
  400mm x 200mm x 100mm  `,
    `600mm x 200mm x 150mm
    600mm x 200mm x 200mm  `,
    `230mm x 110mm x 70mm
    230mm x 110mm x 75mm`,
    `300mm x 150mm x 90mm`,
    `400mm x 200mm x 200mm`,
    '-',
    `300mm x 225mm x 150mm 
    400mm x 150mm x 100mm`,
    `300mm x 225mm x 150mm 
    400mm x 150mm x 100mm`
  ),
  createData(
    'Compressive strength',
    '3 MPa to 5 MPa',
    '3 MPa to 5 MPa',
    '3.5 MPa to 20 MPa',
    `2 Mpa - 16 MPa`,
    '3.5 MPa to 10 MPa ',
    '5 MPa to 20 MPa ',
    '5 MPa - 7.5 MPa ',
    '3.5 MPa - 5 MPa'
  ),
  createData(
    'Minimum dry density',
    '400 kg/m³ to 1,200 kg/m³',
    '1,500 kg/m³ to 2,500 kg/m³',
    `1,200 kg/m³ to 1,800 kg/m³`,
    '1800 kg/m3  to 2200 kg/m3',
    '600 kg/m³ to 900 kg/m³',
    '1600 kg/m³ to 2000 kg/m³',
    '1200 kg/m³ to 1500 kg/m³',
    '800 kg/m³ to 1000 kg/m³'
  ),

  createData('Thermal resistance', 'High', 'Moderate to high', 'Good', `Moderate`, 'Good', 'moderate', '-', '-'),
  createData(
    'Allowable shear stress',
    '0.1 MPa to 0.4 MPa ',
    '0.2 MPa to 0.5 MPa ',
    '0.5 MPa to 1.5 MPa',
    ` 0.1 MPa to 0.3 MPa`,
    '0.2 MPa to 0.5 MPa',
    '-',
    '-',
    '-'
  ),
  createData('Sound absorption', `Excellent `, `Low to moderate`, `moderate`, `Good`, 'Moderate', '-', 'Moderate', '-'),
  createData('Fire resistance', `Excellent`, `Good`, `Excellent`, `Moderate`, 'Excellent', '-', 'Moderate', '-'),
  createData('Thermal conductivity', 'Low', 'Moderate to high', 'Low', `Moderate`, 'Low to moderate', '-', '-', '-'),
  createData('Drying shrinkage', '0.04% to 0.08%', '0.04% to 0.08%', '0.5% to 1.5%', `-`, 'low', '-', '-', '-'),
];

const tableHeaders = [
  'Properties',
  'AAC',
  'Concrete (Base Case)',
  'Fly ash Bricks (Alternative)',
  'CSEB (Alternative)',
  'Porotherm (Alternative)',
  'Recycled Bricks (Alternative)',
  'Agrocrete Solid Block (Alternative)',
  'Agrocrete Hollow Block (Alternative)',
];

function createDataForTableTwo(name, a, b, c, d, e, f, g) {
  return { name, a, b, c, d, e, f, g };
}
const rowsForTableTwo = [
  createDataForTableTwo('Embodied Energy (MJ/kg)', `3.7 `, `0.83 `, `3.5`, `1.3`, `-`, '4.4', `4.4`),
  createDataForTableTwo(`Global Warming Potential (GWP) (kg CO2-Eq)`, '0.500', '0.20', '0.31', '0.16', `-0.14`, '0.39', '0.096'),
  createDataForTableTwo(
    'Minimum dry density',
    '400 kg/m³ to 1,200 kg/m³',
    '1,500 kg/m³ to 2,500 kg/m³',
    `1,200 kg/m³ to 1,800 kg/m³`,
    '1800 kg/m3  to 2200 kg/m3',
    '600 kg/m³ to 900 kg/m³',
    '1600 kg/m³ to 2000 kg/m³',
    '1200 kg/m³ to 1500 kg/m³',
    '800 kg/m³ to 1000 kg/m³'
  ),
  createDataForTableTwo('Thermal resistance', 'High', 'Moderate to high', 'Good', `Moderate`, 'Good', 'moderate', '-', '-'),
  createDataForTableTwo(
    'Allowable shear stress',
    '0.1 MPa to 0.4 MPa ',
    '0.2 MPa to 0.5 MPa ',
    '0.5 MPa to 1.5 MPa',
    ` 0.1 MPa to 0.3 MPa`,
    '0.2 MPa to 0.5 MPa',
    '-',
    '-',
    '-'
  ),
  createDataForTableTwo('Sound absorption', `Excellent `, `Low to moderate`, `moderate`, `Good`, 'Moderate', '-', 'Moderate', '-'),
  createDataForTableTwo('Fire resistance', `Excellent`, `Good`, `Excellent`, `Moderate`, 'Excellent', '-', 'Moderate', '-'),
  createDataForTableTwo('Thermal conductivity', 'Low', 'Moderate to high', 'Low', `Moderate`, 'Low to moderate', '-', '-', '-'),
];

const tableTwoHeaders = ['Environmental impacts', 'AAC block', 'Fly ash AAC', 'Porotherm', 'Concrete ', 'Agrocrete', 'Brick ', 'CSEB'];

const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

const WallBlocks = () => {
  const steppers = [];
  const history = useHistory();

  const classes = useStyles();
  const wallblocksData = ResourcesData.filter((d) => d.iid === 'Wallblocks');
  wallblocksData.forEach((d) => steppers.push(d.data.find((g) => g.type === types.steppers)));
  const icons = { Identify: Seek, Check: Check, Evaluate: Inquire, Choose: Choose };

  return (
    <div>
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{wallblocksData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>

        {wallblocksData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start" style={{ marginLeft: '4%' }}>
            {/* steppers */}
            <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers[0].data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d, index) => (
              <>
                {/* text */}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.text && d.id === 2 && (
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      {d.text}
                    </Typography>
                  )}
                </Grid>
                {/* table heading */}
                {d.type === types.heading && d.id === 3 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {/* table */}
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 4 && <ScrollableTable rows={rows} tableHeaders={tableHeaders} />}
                </Grid>
                {/* points */}
                {d.type === types.points && d.id === 5 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      {d.points.map((point) => (
                        <Grid item container alignItems="flex-start" justifyContent="flex-start">
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {d.type === types.heading && d.id === 6 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {/* points */}
                {d.type === types.points && d.id === 7 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      {d.points.map((point) => (
                        <Grid item container alignItems="flex-start" justifyContent="flex-start">
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {d.type === types.points && d.id === 8 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      {d.points.map((point) => (
                        <Grid item container alignItems="flex-start" justifyContent="flex-start">
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {d.type === types.points && d.id === 9 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      {d.points.map((point) => (
                        <Grid item container alignItems="flex-start" justifyContent="flex-start">
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {d.type === types.heading && d.id === 10 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h5">
                      <b>{d.title}</b>
                    </Typography>
                  </Grid>
                )}
                {d.type === types.points && d.id === 11 && (
                  <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                    <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                      <b>{d.title}</b>
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      {d.points.map((point) => (
                        <Grid item container alignItems="flex-start" justifyContent="flex-start">
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.image && d.id === 12 && <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '90%' }} />}
                </Grid>
                <Grid style={{ marginTop: '2%' }} container alignItems="flex-start" justifyContent="flex-start">
                  {d.type === types.table && d.id === 13 && <ScrollableTable rows={rowsForTableTwo} tableHeaders={tableTwoHeaders} />}
                </Grid>
              </>
            ))}
          </Grid>
        ))}
      </Container>
      <MarketplaceFooter />
    </div>
  );
};

export default WallBlocks;
