import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Fade from '@material-ui/core/Fade';
import Fields from '../../../../common/textfield/Fields';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import S3 from 'react-aws-s3';
import axios from '../../../../../config/Axios';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
  Typography: {
    fontWeight: 500,
    marginTop: '3%',
    marginLeft: '8%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '600px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(2, 2, 2, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  multilineColor: {
    color: 'red',
    borderColor: 'red',
  },
  filedhead: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 550,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.514286px',
    color: 'rgba(0, 0, 0, 0.83)',
    padding: theme.spacing(0, 0, 2, 0),
  },
  filedsubhead: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 350,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.514286px',
    color: 'rgba(0, 0, 0, 0.38)',
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

const UploadIGBCDocument = (props) => {
  const classes = useStyles();
  //   const [uploadError, setUploadError] = useState(false);

  const [filename, setFilename] = useState('');
  const [fileLink, setFileLink] = useState('');
  const [selectedfile, setSelectedFile] = useState('');
  const [upload, setUpload] = useState(false);
  const [asset, setAsset] = useState('');
  const [selectedFilename, setSelectedFilename] = useState('');
  const [filetype, setFiletype] = useState('');

  const handleSave = () => {
    const body = {
      fileName: filename,
      fileType: filetype,
      location: asset._id,
      projectID: props.projectid,
      igbcCategoryID: props.activeCategoryid,
      section: props.section,
      creditId: props.CreditId,
      phase: props.phase,
    };
    axios.post(`/igbc-credit-asset`, body).then((respo) => {
      axios.get(`/igbc-credit-asset/${props.projectid}?category=${props.activeCategoryid}`).then((response) => {
        if (response && response.data && response.data.data && response.data.data.data) {
          props.setCreditAssets(response.data.data.data);
        }
        axios.get(`/igbc-credit-asset/${props.projectid}/percentage?${props.source}=${props.requestid}`).then((resp) => {
          if (resp && resp.data && resp.data.data && resp.data.data.data && resp.data.data.data.percentage) {
            props.setPercentage(resp.data.data.data.percentage);
          } else {
            props.setPercentage(0);
          }
        });
      });
      setFilename('');
      setFileLink('');
      setUpload(false);
      setAsset('');
      setSelectedFilename('');
      props.setOpen(false);
      setFiletype('');
      swal({ icon: 'success', title: 'Document Uploaded Sccessfully' });
    });
  };
  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    if (event.target.files[0]) {
      setSelectedFilename(event.target.files[0].name);
      setSelectedFile(file);
      setFiletype(event.target.files[0].type.split('/')[1]);
      const config = {
        bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
        dirName: 'public/igbc/documents' /* optional */,
        region: 'us-east-2',
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(event.target.files[0].name, event.target.files[0].name).then((data) => {
        if (data.status === 204) {
          setUpload(true);
          const body = {
            URL: data.location,
          };
          axios
            .post('/sign-s3', body)
            .then((res) => {
              if (res && res.data.data) {
                setAsset(res.data.data.data);
                //   setShow(true);
                //   setShowloader(false);
              }
            })
            .catch((err) => {
              console.log(err, 'err');
            });
        } else {
          console.log('fail');
        }
      });
    }
  };
  const handleClose = () => {
    setFilename('');
    setFileLink('');
    setUpload(false);
    setAsset('');
    setSelectedFilename('');
    props.setOpen(false);
    setFiletype('');
  };
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <Grid container justify="left" direction="column" item xs={12}>
            <Grid xs={12} item>
              <Typography variant="h5">
                <b>Upload File </b>{' '}
              </Typography>
            </Grid>
            {/* ADD NAME */}
            <Grid container item xs={12}>
              <Typography className={classes.filedhead}>File Name</Typography>
            </Grid>
            <Grid container items xs={12}>
              <Typography className={classes.filedsubhead}>Suitable file name to indicate the type of file. E.g. Plumbing DBR</Typography>
            </Grid>
            <Grid container item xs={6} style={{ marginBottom: '2%' }}>
              <Fields
                id="outlined-basic"
                variant="outlined"
                value={filename}
                placeholder="Enter Name"
                onChange={(e) => {
                  e.preventDefault();
                  setFilename(e.target.value);
                }}
                fullWidth
                required
              />
            </Grid>
            {/* ADD FILE */}
            <Grid container item xs={12}>
              <Typography className={classes.filedhead}>Add file</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Typography className={classes.filedsubhead}>Sub-text</Typography>
            </Grid>
            <Grid container item xs={6} style={{ marginBottom: '2%' }}>
              <AddBoxIcon style={{ color: `${filename && !fileLink ? 'rgb(102, 227, 190)' : '#9E9E9E'}`, width: '25px', height: '70%' }} />
              {filename && !fileLink ? (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} />
              ) : fileLink ? (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} disabled />
              ) : (
                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} disabled />
              )}
              {upload === true ? (
                <label
                  htmlFor="upload-photo"
                  style={{ color: `${filename && !fileLink ? 'rgb(102, 227, 190)' : '#9E9E9E'}`, cursor: 'pointer', marginTop: '2%', fontSize: '16px' }}
                >
                  {selectedFilename}
                </label>
              ) : (
                <label
                  htmlFor="upload-photo"
                  style={{ color: `${filename && !fileLink ? 'rgb(102, 227, 190)' : '#9E9E9E'}`, cursor: 'pointer', marginTop: '2%', fontSize: '16px' }}
                >
                  Browse
                </label>
              )}
            </Grid>
            <Grid container item xs={12}>
              <Typography className={classes.filedsubhead}>Or</Typography>
            </Grid>
            {/* ADD LINK */}
            <Grid conainer item xs={12}>
              <Typography className={classes.filedhead}>Add Link</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Typography className={classes.filedsubhead}>Add a link to Google Drive, Dropbox, etc </Typography>
            </Grid>
            <Grid container item xs={6} style={{ marginBottom: '2%' }}>
              <Fields
                id="outlined-basic"
                variant="outlined"
                placeholder="Add URL"
                value={fileLink}
                onChange={(e) => {
                  e.preventDefault();
                  setFileLink(e.target.value);
                }}
                fullWidth
                required
              />
            </Grid>

            {/* BUTTON GROUP */}
            <Grid container direction="row" alignItems="end" justify="flex-end" item xs={12} style={{ marginTop: '3%' }}>
              <Grid item xs={2}>
                <Typography variant="h6" style={{ color: '#00C58A', cursor: 'pointer', marginTop: '8%' }} onClick={handleClose}>
                  Cancel
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <PrimaryButton children="Save" onClick={handleSave} />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default UploadIGBCDocument;
