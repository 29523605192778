import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
// import { categoryDetailsQuery,  } from '../graphql-queries/ProductDetails.query';
import { interventionDetailsQuery } from '../graphql-queries/InterventionDetails.query';

const useInterventionDetails = () => {
  const [productDetailsData, setProductDetailsData] = useState('');
  const [productDetails, SendRroductDetailsRequest] = useGraphqlRequest();

  const fetchData = (identifiers) => {
    SendRroductDetailsRequest(interventionDetailsQuery(identifiers));
  };
  useEffect(() => {
    if (productDetails && productDetails?.response !== null) setProductDetailsData(productDetails.response?.data?.data?.getProducts);
  }, [productDetails]);

  return { productDetailsData, fetchData };
};

export default useInterventionDetails;
