import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ResidentHealthAndWellBeingContext } from '../..';
import PrimaryButton from '../../../../../../common/button/Button';
import CustomDropDown from '../../common/CustomDropDown';
import Fields from '../../../../../../common/textfield/Fields';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Sections, Occupiedspacearr } from '../../DataTypes';
const DwellingUnitInteriorHandler = ({
  handleClose,
  editTable,
  handleEditTable,
  editableData,
  setUpdate,
  update,
  sectionName,
  mount,
  setMount,
  residentHealthAndWellBeingData,
  indexforEdit,
}) => {
  const { handleData } = useContext(ResidentHealthAndWellBeingContext);
  const [dataArr] = useState(['Sliding', 'Louvre', 'Casement']);
  const [occupiedSpace] = useState(Occupiedspacearr);
  const [occupiedSpaceData, setOccupiedSpaceData] = useState('');
  const [windowType, setWindowType] = useState('');
  const [regularOccupied, setRegularOccupied] = useState('');
  const [floorArea, setFloorArea] = useState('');
  const [openableDoorArea, setOpenableDoorArea] = useState('');
  const [skylightArea, setSkylightArea] = useState('');
  const [skylightGlazingVLT, setSkylightGlazingVLT] = useState('');
  const [twoOrientationOpening, setTwoOrientationOpening] = useState(false);
  const [connectivitytoexterior, setConnectivity] = useState(false);

  useEffect(() => {
    if (editTable === true && editableData) {
      const {
        regularlyOccupiedSpace,
        typeofWindow,
        typeOfRegularlyOccupiedSpace,
        floorArea,
        openabledoorarea,
        skylightArea,
        skylightglazingVLT,
        openinlesstwooreintation,
        connectivitytoexterior,
      } = editableData.data;
      setOccupiedSpaceData(typeOfRegularlyOccupiedSpace);
      setWindowType(typeofWindow);
      setRegularOccupied(regularlyOccupiedSpace);
      setFloorArea(parseInt(floorArea));
      setOpenableDoorArea(parseInt(openabledoorarea));
      setSkylightArea(parseInt(skylightArea));
      setSkylightGlazingVLT(parseInt(skylightglazingVLT));
      setTwoOrientationOpening(openinlesstwooreintation);
      setConnectivity(connectivitytoexterior);
    }
  }, [editTable, editableData]);

  const handleSaveData = (sectionName, data) => {
    const obj = {};
    obj['regularlyOccupiedSpace'] = regularOccupied;
    obj['typeOfRegularlyOccupiedSpace'] = occupiedSpaceData;
    obj['typeofWindow'] = windowType;
    obj['floorArea'] = floorArea;
    obj['openabledoorarea'] = openableDoorArea;
    obj['skylightArea'] = skylightArea;
    obj['skylightglazingVLT'] = skylightGlazingVLT;
    obj['openinlesstwooreintation'] = twoOrientationOpening;
    obj['connectivitytoexterior'] = connectivitytoexterior;
    const obj2 = {};
    obj2['type'] = sectionName;
    let arr = [];
    arr.push(obj);
    obj2['data'] = arr;
    const findData = data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data?.find((f) => f.type === sectionName);
    if (findData) {
      data?.customData[0]?.data
        ?.find((d) => d.section === Sections.Dwelling_Unit_Interiors)
        ?.data?.find((f) => f.type === sectionName)
        ?.data?.push(obj);
      setMount(!mount);
    } else if (sectionName && regularOccupied) {
      data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data.push(obj2);
      setMount(!mount);
    } else {
      handleClose();
    }
    let newData = data;
    handleData(newData);
    handleClose();
    setUpdate(!update);
  };

  const handleUpdateData = (data, dwellingUnitType, indexforEdit) => {
    const obj = {};
    obj['regularlyOccupiedSpace'] = regularOccupied;
    obj['typeOfRegularlyOccupiedSpace'] = occupiedSpaceData;
    obj['typeofWindow'] = windowType;
    obj['floorArea'] = floorArea;
    obj['openabledoorarea'] = openableDoorArea;
    obj['skylightArea'] = skylightArea;
    obj['skylightglazingVLT'] = skylightGlazingVLT;
    obj['openinlesstwooreintation'] = twoOrientationOpening;
    obj['connectivitytoexterior'] = connectivitytoexterior;
    data?.customData[0]?.data
      ?.find((d) => d.section === Sections.Dwelling_Unit_Interiors)
      ?.data?.find((f) => f.type === dwellingUnitType)
      ['data'].splice(indexforEdit, 1, obj);
    handleData(data);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  const inputfields = [
    { title: 'Regularly Occupied Space', unit: '', type: 'textbox', value: regularOccupied, setValue: setRegularOccupied },
    { title: 'Type of Regularly Occupied Space', unit: '', type: 'dropdown' },
    { title: 'Window Type', unit: '', type: 'dropdown' },
    { title: 'Floor Area', unit: 'sqm', type: 'textbox', value: floorArea, setValue: setFloorArea },
    { title: 'Openable Door Area', unit: 'sqm', type: 'textbox', value: openableDoorArea, setValue: setOpenableDoorArea },
    { title: 'Skylight Area', unit: 'sqm', type: 'textbox', value: skylightArea, setValue: setSkylightArea },
    { title: 'Skylight Glazing VLT', unit: '%', type: 'textbox', value: skylightGlazingVLT, setValue: setSkylightGlazingVLT },
  ];

  return (
    <Grid container style={{ paddingLeft: '4%' }} justifyContent="space-between" alignItems="center">
      <Grid item xs={12} container alignItems="center">
        <Grid>
          <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Grid>
        <Grid style={{ paddingLeft: '4%' }}>
          <Typography variant="h5">{!editTable ? <b>Add Dwelling Unit Interior</b> : <b>Edit Dwelling Unit Interior</b>}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="center" style={{ marginBottom: '10%' }}>
        {inputfields.map((field) => {
          return (
            <>
              <Grid xs={6}>
                <Grid xs={12}>
                  <Typography variant="h6">{field.title}</Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '4%', paddingBottom: '10%' }}>
                  <Grid item xs={8}>
                    {field.type === 'textbox' ? (
                      <Fields id="outlined-basic" variant="outlined" fullWidth required value={field.value} onChange={(e) => field.setValue(e.target.value)} />
                    ) : (
                      <CustomDropDown
                        dataArr={field.title === 'Window Type' ? dataArr : occupiedSpace}
                        selecteddata={field.title === 'Window Type' ? windowType : occupiedSpaceData}
                        setSelectedData={(e) => (field.title === 'Window Type' ? setWindowType(e) : setOccupiedSpaceData(e))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} style={{ paddingLeft: '10px', paddingTop: '4%' }}>
                    {field.unit && (
                      <>
                        <Typography>{field.unit}</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs style={{ marginTop: '1%' }}>
            {twoOrientationOpening ? (
              <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setTwoOrientationOpening(false)} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setTwoOrientationOpening(true)} />
            )}
          </Grid>
          <Grid item xs={11}>
            <Typography>Doors/ ventilators/ windows opening in atleast two orientations (tick box if applicable)</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs>
            {connectivitytoexterior ? (
              <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setConnectivity(false)} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setConnectivity(true)} />
            )}
          </Grid>
          <Grid item xs={11}>
            <Typography>Connectivity to exteriors (tick box if applicable)</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end" style={{ marginBottom: '5%', paddingRight: '3%' }}>
        <Grid item xs={2}>
          <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer' }}>
            Cancel
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          {!editTable && <PrimaryButton children="Save" onClick={() => handleSaveData(sectionName, residentHealthAndWellBeingData)} />}
          {editTable && <PrimaryButton children="Update" onClick={() => handleUpdateData(residentHealthAndWellBeingData, sectionName, indexforEdit)} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DwellingUnitInteriorHandler;
