import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PrimaryButton from '../../../common/button/Button';
import IGBCLevel from '../common/IGBCLevels';

const useStyles = makeStyles((theme) => ({
  Grid: {
    marginTop: '3%',
  },
}));

export default function IGBCStatus(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" className={classes.Grid}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <IGBCLevel sustainabilityLevel={10} />
        </Grid>
        <Grid item xs={10} sm={10} md={8} lg={4} xl={4} style={{ paddingLeft: '4%', paddingBottom: '3%' }}>
          <PrimaryButton children="IGBC Template Sheet" style={{ width: '70%' }} />
        </Grid>
      </Grid>
    </>
  );
}
