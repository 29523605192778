import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CustomizedBar from '../common/CustomisedBar';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  ffff: {
    minHeight: 150,
    padding: 15,
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    marginBottom: '9%',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
    fontSize: '13px',
  },
  custom: {
    fontSize: '14px',
    fontWeight: 850,
    letterSpacing: '0.71px',
    color: '#00C58A',
    cursor: 'pointer',
    textAlign: 'right',
  },
}));

export default function IGBCDataUpload({ dataUploadprogress }) {
  let matchRoute = useRouteMatch();
  let history = useHistory();
  const matches = useMediaQuery('(min-width:1280px)');

  const classes = useStyles();

  return (
    <>
      <Grid xs={!matches ? 10 : 12} item container style={{ paddingBottom: '3%' }}>
        <Grid xs item>
          <Typography variant="h6">Data Upload</Typography>
        </Grid>
        <Grid xs item>
          <Typography variant="h6" className={classes.custom} onClick={() => history.push(`${matchRoute.url}/edit?category=Sustainable Design`)}>
            <AddBoxRoundedIcon style={{ marginBottom: '-5px', fontSize: '20px' }} />
            Add More Data
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={!matches ? 10 : 12} item className={classes.ffff} container direction="column">
        <Grid xs>
          <Typography className={classes.secondaryTextcolor}>Data Upload Progress</Typography>
        </Grid>
        <Grid xs style={{ marginTop: '2%' }}>
          <CustomizedBar percentValue={true} insideColor="#E5E5E5" bgcolor="#FFCE93" value={dataUploadprogress} barvalue={dataUploadprogress} />
        </Grid>
        <Grid xs>
          <Typography style={{ lineHeight: '22px', color: '#666666', fontSize: '13px' }}>More data is required to calculate more efficiently</Typography>
        </Grid>
      </Grid>
    </>
  );
}
