import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    overflowX: 'scroll',
  },
});

export default function ScrollableTable({ rows, tableHeaders, Headers }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        className={classes.table}
        //   aria-label="simple table"
        size="small"
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            {Headers &&
              Headers.map((header, index) => (
                <TableCell align="left" style={index <= 1 ? { background: 'var(--UI-Table-Intervention-Category, #F7F7F7)' } : { background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.PageHeading}>
                    <b>{header}</b>
                  </Typography>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell
                align="left"
                style={
                  index === 0
                    ? MarketplaceTypography.PageHeading
                    : index === 1
                    ? { background: 'var(--UI-Table-Intervention-Category, #F7F7F7)' }
                    : { background: '#F1FFF3' }
                }
              >
                {index > 0 ? (
                  <Typography style={MarketplaceTypography.CaptionLarge}>{header}</Typography>
                ) : (
                  <Typography style={MarketplaceTypography.PageHeading}>
                    <b>{header}</b>
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              {row.name && (
                <TableCell scope="row">
                  <Typography style={MarketplaceTypography.PageHeading}>
                    <b> {row.name}</b>
                  </Typography>
                </TableCell>
              )}

              {row.a && (
                <TableCell align="left" style={{ background: 'var(--UI-Table-Intervention-Category, #F7F7F7)' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.a}</Typography>
                </TableCell>
              )}
              {row.b && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.b}</Typography>
                </TableCell>
              )}
              {row.c && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.c}</Typography>
                </TableCell>
              )}
              {row.d && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.d}</Typography>
                </TableCell>
              )}
              {row.e && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.e}</Typography>
                </TableCell>
              )}
              {row.f && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.f}</Typography>
                </TableCell>
              )}
              {row.g && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.g}</Typography>
                </TableCell>
              )}
              {row.h && (
                <TableCell align="left" style={{ background: '#F1FFF3' }}>
                  <Typography style={MarketplaceTypography.CaptionLarge}>{row.h}</Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
