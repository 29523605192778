import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';

import DrawerNav from '../Drawer/DrawerNav';
import SDLogo from '../../../../assets/header/SDplus Logo.png';
import NavMenu from './NavMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    flexGrow: '1',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'blue',
  },
  toolbar: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '70px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  menuButton: {
    flexGrow: 1,
    height: '5vh',
    marginLeft: '50%',
  },
  menuButtonBelow: {
    flexGrow: 1,
    height: '5vh',
    marginLeft: '40%',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      height: '5vh',
      marginLeft: '40%',
    },
  },
}));

function NavBar({ handleShowLogin, handleRequestDemo, setPurpose }) {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  let history = useHistory();

  const matches = useMediaQuery('(min-width:600px)');
  const [state, setState] = React.useState({ top: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleFeatures = () => {
    window.location.replace('/#features');
  };
  const handleHome = () => {
    if (path && path === '/team') {
      history.push('/');
    } else {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  const handleBoth = (anchor, value) => {
    setState({ ...state, [anchor]: false });
    handleFeatures();
    window.location.replace('/#features');
  };

  const handleClick = () => {
    history.push(`/`);
  };

  return (
    <>
      {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <DrawerNav
              handlepopupRequestdemo={handleRequestDemo}
              toggleDrawerClose={toggleDrawer('top', false)}
              handleFeatures={() => handleBoth('top', false)}
            />
          </Drawer>
        </React.Fragment>
      ))}
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <img src={SDLogo} alt="..." height="70vh" width="auto" style={{ cursor: 'pointer' }} onClick={handleClick} />
          <Typography className={classes.title} />
          {matches ? (
            <NavMenu path={path} handleShowLogin={handleShowLogin} handleFeatures={handleFeatures} handleHome={handleHome} setPurpose={(v) => setPurpose(v)} />
          ) : (
            <>
              <MenuIcon className={matches ? classes.menuButton : classes.menuButtonBelow} style={{ color: 'black' }} onClick={toggleDrawer('top', true)} />
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
export default NavBar;
