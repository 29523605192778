import React from 'react';
import { Grid, Typography, makeStyles, Card, CardActionArea, CardMedia, CardContent, CardActions, withWidth, Button } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import Links from '../Typography/Links';

const ResourceCardDesktop = ({ cardWidth, imageWidth, cardHeight, sourceImage, text, longText, shortCard, href }, props) => {
  const { TagPrimary, TagSecondary } = MarketplaceColors;
  const { HeaderSmall } = MarketplaceTypography;

  const useStyles = makeStyles((theme) => ({
    customText: {
      color: TagPrimary,
      backgroundColor: TagSecondary,
      fontSize: '8.57px',
      padding: '1%',
      cursor: 'pointer',
      width: '25px',
      height: '13px',
    },
    customLongText: {
      cursor: 'pointer',
      // letterSpacing: HeaderSmall.letterSpacing,
      color: MarketplaceColors.TextColorPrimary,
    },
    cardIcon: {
      color: MarketplaceColors.Secondary,
      fontSize: '120%',
      cursor: 'pointer',
    },
    root: {
      width: cardWidth,
      height: cardHeight,
      boxShadow: 'none',
      border: '1px solid #00000033',
      borderRadius: '10px',
    },
    media: {
      height: !shortCard ? '75%' : '67%',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ height: '85%', padding: !shortCard ? '2%' : '4%' }}>
        <CardMedia className={classes.media} image={sourceImage} />
        <CardContent>
          <Grid container alignItems="flex-start" justifyContent="flex-start">
            <Grid item xs={12} container alignItems="flex-start" justifyContent="flex-start">
              <Typography className={classes.customText}>{text}</Typography>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" justifyContent="flex-start">
              <Links href={href && href} variant="string" tag={longText} isBold={true} className={classes.customLongText} color="inherit" />
            </Grid>
          </Grid>
        </CardContent>
      </div>
      <CardActions disableSpacing={true}>
        <Grid container alignItems="flex-end" justifyContent="flex-end">
          <Grid xs={12} container alignItems="flex-end" justifyContent="flex-end">
            <Links
              href={href && href}
              variant="string"
              tag={<ArrowRightAltIcon className={classes.cardIcon} />}
              isBold={true}
              className={classes.customLongText}
              color="inherit"
            />
          </Grid>
        </Grid>
      </CardActions>
    </div>
  );
};

export default withWidth()(ResourceCardDesktop);
