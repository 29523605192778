import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import IntervDataCatd from './IntervDataCard';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  footerMargin: {
    padding: 10,
  },
});

const EmptyPage = (props) => {
  let water = props.data && props.data[0].subcategories.find((ele) => ele.name === 'Demand Optimization').interventions[0];
  return (
    <View style={styles.viewPage2}>
      <Header />
      {/* new view */}
      <View style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <IntervDataCatd waterdata={water} border={props.border} color={props.color} icon={props.dummyicon} />
        <IntervDataCatd waterdata={water} border={props.border} color={props.color} icon={props.dummyicon} />
        <IntervDataCatd waterdata={water} border={props.border} color={props.color} icon={props.dummyicon} />
      </View>
      {/* new view */}
      <View style={styles.footerMargin}>
        <Footer />
      </View>
    </View>
  );
};

export default EmptyPage;
