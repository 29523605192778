import React, { useEffect } from 'react';
import ListItemLink from '../ListItemLink';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from '../../../common/button/CustomButton';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import useApiRequest from '../../../hooks/useApiRequest';
import { SUCCESS } from '../../../hooks/useApiRequest/actionTypes';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
  textArea: {
    resize: 'none',
    overflow: 'hidden',
  },
}));

export default function Feedback() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFeedback('');
    setOpen(false);
  };
  const [{ status, response }, makeRequest] = useApiRequest('/feedbacks', {
    verb: 'post',
    params: { feedback: feedback },
  });
  const submitFeedback = () => {
    if (feedback) makeRequest();
  };
  useEffect(() => {
    if (status === SUCCESS && response) {
      swal('Feedback submitted Successfully!');
      handleClose();
    }
  }, [status, response]);
  return (
    <>
      <ListItemLink
        to="#"
        label={
          <Typography>
            <AnnouncementIcon /> Feedback
          </Typography>
        }
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h5">What do you like to share with us?</Typography>
        </DialogTitle>
        <Typography variant="h6">
          <DialogContent>
            <textarea autoFocus id="feedback" rows="20" className={classes.textArea} cols="60" value={feedback} onChange={(e) => setFeedback(e.target.value)} />
            <Grid container justify="flex-end" alignItems="center" item xs={12}>
              <Grid item xs={3}>
                <CustomButton onClick={submitFeedback} color="primary">
                  Submit
                </CustomButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Typography>
      </Dialog>
    </>
  );
}
