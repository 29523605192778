import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      paddingRight: '20%',
      width: '25ch',
    },
    maxWidth: 205,
    marginRight: '2%',
    marginBottom: '2%',
  },
  media: {
    height: 140,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const CategoryScheme = () => {
  const classes = useStyles();
  const [on, setOn] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [addTextField1, setAddTextField1] = useState('');
  const [addTextField2, setAddTextField2] = useState('');
  const [updateField1, setUpdateField1] = useState('');
  const [updateField2, setUpdateField2] = useState('');
  const [updateFieldNew1, setUpdateFieldNew1] = useState(null);
  const [updateFieldNew2, setUpdateFieldNew2] = useState(null);
  const [choose, setChoose] = useState('');
  const [categoryScheme, setCategoryScheme] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest('/categorySchemeV2', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  const handleaddTextField1 = (e) => setAddTextField1(e.target.value);
  const handleaddTextField2 = (e) => setAddTextField2(e.target.value);
  const handleUpdateTextField1 = (e) => setUpdateFieldNew1(e.target.value);
  const handleUpdateTextField2 = (e) => setUpdateFieldNew2(e.target.value);
  const handleCancelUpdate = () => setOn(false);
  const handleClose = () => setOn(false);
  useEffect(() => {
    if (response) {
      setCategoryScheme(response.data.data);
    }
  }, [response]);

  const handleOpen = (value, data) => {
    if (value === 'add') {
      setChoose('add');
    }
    if (value === 'update') {
      setChoose('update');
      setSelectedId(data._id);
      setUpdateField1(data.name);
      setUpdateField2(data.description);
    }
    setOn(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/categorySchemeV2/${id}`)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully deleted Category' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleAddCategorySchemeV2 = (e) => {
    e.preventDefault();
    let body = {
      name: addTextField1,
      description: addTextField2,
    };
    axios
      .post(`/categorySchemeV2`, body)
      .then(() => {
        setOn(false);
        swal({ icon: 'success', title: 'Successfully added Category' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleUpdateCategorySchemeV2 = (e) => {
    e.preventDefault();
    let body1 = { newName: updateFieldNew1 && updateFieldNew1 ? updateFieldNew1 : updateField1 };
    let body2 = { newDescription: updateFieldNew2 && updateFieldNew2 ? updateFieldNew2 : updateField2 };
    axios
      .put(`/categorySchemeV2/${selectedId}`, body1)
      .then(() => {
        axios
          .put(`/categorySchemeV2/${selectedId}`, body2)
          .then(() => {
            setOn(false);
            swal({ icon: 'success', title: 'Successfully updated Category' });
            makeRequest();
          })
          .catch((err) => {
            console.log(err, 'err');
          });
      })
      .catch((err) => console.log(err, 'err'));
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Categories Management</Typography>
        </Grid>
        <Grid item xs={5} align="left"></Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={8} align="left"></Grid>
        <Grid item xs={4} align="left">
          <Button variant="contained" onClick={() => handleOpen('add', null)}>
            Add new
          </Button>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12}>
        {categoryScheme &&
          categoryScheme.data.map((data) => {
            return (
              <>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {data.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {data.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" onClick={() => handleDelete(data._id)}>
                      Delete
                    </Button>
                    <Button size="small" color="primary" onClick={() => handleOpen('update', data)}>
                      Update
                    </Button>
                  </CardActions>
                </Card>
              </>
            );
          })}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={on}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={on}>
          {choose && choose === 'add' ? (
            <div className={classes.paper}>
              <Typography gutterBottom variant="h5" component="h2">
                Add form
              </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" label="name" value={addTextField1} onChange={handleaddTextField1} />
                <TextField id="standard-basic" label="description" value={addTextField2} onChange={handleaddTextField2} />
                <Grid item xs />
              </form>
              <Link size="small" color="primary" onClick={handleAddCategorySchemeV2}>
                Add
              </Link>
              <Link size="small" color="primary" onClick={handleCancelUpdate} style={{ marginLeft: '10%' }}>
                Cancel
              </Link>
            </div>
          ) : choose && choose === 'update' ? (
            <div className={classes.paper}>
              <Typography gutterBottom variant="h5" component="h2">
                Update form
              </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Name"
                  value={updateFieldNew1 === null ? updateField1 : updateFieldNew1}
                  onChange={handleUpdateTextField1}
                />
                <TextField
                  id="standard-basic"
                  label="Description"
                  value={updateFieldNew2 === null ? updateField2 : updateFieldNew2}
                  onChange={handleUpdateTextField2}
                />
                <Grid item xs />
              </form>
              <Link size="small" color="primary" onClick={handleUpdateCategorySchemeV2}>
                Update
              </Link>
              <Link size="small" color="primary" onClick={handleCancelUpdate} style={{ marginLeft: '10%' }}>
                Cancel
              </Link>
            </div>
          ) : null}
        </Fade>
      </Modal>
    </>
  );
};
export default CategoryScheme;
