export const certificationsQuery = `query {
  ecoLabels{
    data {
      id
      attributes{
        name
      }
    }
  }
  }`;
export const productsQuery = `{
    products(pagination:{limit:${20000}}) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
  `;
