import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useScreenType from '../../../../../../hooks/checkScreenType';
import { makeStyles } from '@material-ui/core';
import { MarketplaceTypography } from '../../../../config';
import productimage2 from '../../../../../../../assets/Marketplace/product/productcardimage2.svg';
import GreenPro from '../../../../../../../assets/databaseImages/certifications/GreenPro.png';
import GreenCo from '../../../../../../../assets/databaseImages/certifications/GreenCo.png';
import GreenLabel from '../../../../../../../assets/databaseImages/certifications/Green Label.png';
import IGBC from '../../../../../../../assets/databaseImages/certifications/IGBC.png';
import Griha from '../../../../../../../assets/databaseImages/certifications/GRIHA.png';
import notAvailable from '../../../../../../../assets/Marketplace/Image not available 1.svg';
import { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  cardDesktop: {
    width: '230px',
    height: '320px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  mediamobile: {
    height: 100,
    backgroundSize: 'contain',
  },
  image: {
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  name: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
}));

let icons = [
  { name: 'ECBC', icon: GreenPro },
  { name: 'Green Assure', icon: GreenCo },
  { name: 'GreenCo', icon: GreenLabel },
  { name: 'Green Label', icon: IGBC },
  { name: 'GreenPro', icon: Griha },
  { name: 'Griha', icon: GreenPro },
  { name: 'IGBC', icon: GreenCo },
  { name: 'LEED', icon: GreenLabel },
  { name: 'UIPC-I', icon: GreenLabel },
];

function ImpactProductCard(props) {
  const classes = useStyles();
  const { product } = props;

  const ecoLabels = product && product?.attributes?.filters['Eco Labels'];

  const getIcon = (name) => {
    return icons?.find((d) => d.name === name)?.icon;
  };
  const { isMobile, isTablet } = useScreenType();
  const [isImageAccessible, setIsImageAccessible] = useState(false);

  useEffect(() => {
    if (product?.attributes?.image && product?.attributes?.image.trim() !== '') {
      const img = new Image();
      img.src = product?.attributes?.image;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [product?.attributes?.image]);

  return (
    <Card className={isMobile && !isTablet ? classes.cardMobile : classes.cardDesktop}>
      <a
        target="_blank"
        onclick="return false;"
        href={`/marketplace/categories/${product?.attributes?.category?.data?.id}/products/${product?.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <CardActionArea>
          <CardMedia
            className={isMobile ? classes.mediamobile : classes.media}
            image={isImageAccessible ? product?.attributes?.image : notAvailable}
            title={product?.attributes?.name ? product?.attributes?.name : ''}
          />
          <CardContent>
            <Typography gutterBottom className={classes.name}>
              {product?.attributes?.name
                ? `${product?.attributes?.name.length > 21 ? `${product?.attributes?.name.slice(0, 18)}...` : product?.attributes?.name}`
                : 'dummy name'}
            </Typography>
            <Typography gutterBottom style={MarketplaceTypography.SubHeaderSmall}>
              {product?.attributes?.brand?.data?.attributes ? product?.attributes?.brand?.data?.attributes?.name : 'NA'}
            </Typography>
            <Typography
              className={classes.description}
              style={{
                color: '#9E9E9E',
                paddingTop: '5%',
                height: '40px',
              }}
              // style={MarketplaceTypography.CaptionLarge}
              color="textSecondary"
            >
              {product?.attributes?.shortDescription
                ? product?.attributes?.shortDescription.length > 50
                  ? `${product?.attributes?.shortDescription.slice(0, 50)}...`
                  : product?.attributes?.shortDescription
                : ''}
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
      <CardActions>
        {ecoLabels?.map((name) => {
          return getIcon(name) ? <img src={getIcon(name)} alt="..." height={23} width={23} style={{ textAlign: 'left' }} /> : '';
        })}
      </CardActions>
    </Card>
  );
}

export default ImpactProductCard;
