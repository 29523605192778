import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesFaucetProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesFaucet_oneBHK,
    efficientFixturesFaucet_twoBHK,
    efficientFixturesFaucet_threeBHK,
    efficientFixturesFaucet_fourBHK,
    efficientFixturesFaucet_studio,
    efficientFixturesFaucet_pentHouseOneBhk,
    efficientFixturesFaucet_pentHouseTwoBhk,
    efficientFixturesFaucet_pentHouseThreeBhk,
    efficientFixturesFaucet_pentHouseFourBhk,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesFaucet_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesFaucet_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesFaucet_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesFaucet_fourBHK);
  const studio = getQuantityValue(efficientFixturesFaucet_studio);

  const penthouse_onebhk = getQuantityValue(efficientFixturesFaucet_pentHouseOneBhk);
  const penthouse_twobhk = getQuantityValue(efficientFixturesFaucet_pentHouseTwoBhk);
  const penthouse_threebhk = getQuantityValue(efficientFixturesFaucet_pentHouseThreeBhk);
  const penthouse_fourbhk = getQuantityValue(efficientFixturesFaucet_pentHouseFourBhk);

  const onebhk_label = getQuantityLabel(efficientFixturesFaucet_oneBHK);
  const twobhk_label = getQuantityLabel(efficientFixturesFaucet_twoBHK);
  const threebhk_label = getQuantityLabel(efficientFixturesFaucet_threeBHK);
  const fourbhk_label = getQuantityLabel(efficientFixturesFaucet_fourBHK);
  const studio_label = getQuantityLabel(efficientFixturesFaucet_studio);

  const onebhk_unit = getQuantityUnit(efficientFixturesFaucet_oneBHK);
  const twobhk_unit = getQuantityUnit(efficientFixturesFaucet_twoBHK);
  const threebhk_unit = getQuantityUnit(efficientFixturesFaucet_threeBHK);
  const fourbhk_unit = getQuantityUnit(efficientFixturesFaucet_fourBHK);
  const studio_unit = getQuantityUnit(efficientFixturesFaucet_studio);

  const penthouse_onebhk_label = getQuantityLabel(efficientFixturesFaucet_pentHouseOneBhk);
  const penthouse_twobhk_label = getQuantityLabel(efficientFixturesFaucet_pentHouseTwoBhk);
  const penthouse_threebhk_label = getQuantityLabel(efficientFixturesFaucet_pentHouseThreeBhk);
  const penthouse_fourbhk_label = getQuantityLabel(efficientFixturesFaucet_pentHouseFourBhk);

  const penthouse_onebhk_unit = getQuantityUnit(efficientFixturesFaucet_pentHouseOneBhk);
  const penthouse_twobhk_unit = getQuantityUnit(efficientFixturesFaucet_pentHouseTwoBhk);
  const penthouse_threebhk_unit = getQuantityUnit(efficientFixturesFaucet_pentHouseThreeBhk);
  const penthouse_fourbhk_unit = getQuantityUnit(efficientFixturesFaucet_pentHouseFourBhk);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{studio_label && studio_label}</Typography>
          <Typography className={classes.textHeader3}>
            {studio} {studio_unit && studio_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{onebhk_label && onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {onebhk} {onebhk_unit && onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{twobhk_label && twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {twobhk} {twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {threebhk} {threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {fourbhk} {fourbhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_onebhk} {penthouse_onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_twobhk} {penthouse_twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_threebhk} {penthouse_threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_fourbhk} {penthouse_fourbhk_unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesFaucetProjectInputs;
