import { Grid, withWidth } from '@material-ui/core';
import React, { useState } from 'react';
import leftarrow from '../../../../../../assets/carousal/leftarrow.svg';
import rightarrow from '../../../../../../assets/carousal/rightarrow.svg';
import LeftArrow from '../../../common/LeftArrow';
import RightArrow from '../../../common/RightArrow';
import Carousal from '../../../common/Carousal/Carousal';
import useScreenType from '../../../../../hooks/checkScreenType';
import leftarrowmobile from '../../../../../../assets/carousal/mobileft.svg';
import rightarrowmobile from '../../../../../../assets/carousal/mobileright.svg';

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 25,
  height: 56,
  cursor: 'pointer',
};

const leftarrowStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  cursor: 'pointer',
};

const rightarrowStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  cursor: 'pointer',
};

const imageStyles = {
  margin: 'auto',
  display: 'block',
};

const ProductCarousal = (props) => {
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const [currentSlide, setCurrentSlide] = useState(0);
  const next = () => setCurrentSlide(currentSlide + 1);
  const prev = () => setCurrentSlide(currentSlide - 1);
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center">
      {(!isMobile && props.zoomed !== true) && (
        <Grid style={{ backgroundColor: props.zoomed ? 'transparent' : '', padding: '8%' }} xs={1} item container alignItems="center" justifyContent="center">
          <LeftArrow leftarrow={leftarrow} prev={prev} arrowStyles={leftarrowStyles} imageStyles={imageStyles} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} container alignItems="center" justifyContent="center">
        {!isMobile ? (
          <Carousal
            renderArrowNext={(onClickHandler, hasNext, label) => hasNext}
            renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev}
            width={width}
            next={next}
            prev={prev}
            updateCurrentSlide={updateCurrentSlide}
            currentSlide={currentSlide}
            productPage={true}
            imageUrl={props.imageUrl}
            handleClick={props.handleClick}
            zoomed={props.zoomed}
            interventionpage={props.interventionpage}
            staticassets={props.staticassets}
          />
        ) : (
          <Carousal
            productPage={true}
            next={next && next}
            prev={prev && prev}
            updateCurrentSlide={updateCurrentSlide}
            currentSlide={currentSlide}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && <img src={rightarrowmobile} alt="..." onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 1 }} />
            }
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && <img src={leftarrowmobile} alt="..." onClick={onClickHandler} title={label} style={{ ...arrowStyles }} />
            }
            imageUrl={props.imageUrl}
            zoomed={props.zoomed}
            interventionpage={props.interventionpage}
            staticassets={props.staticassets}
          />
        )}
      </Grid>
      {(!isMobile && props.zoomed !== true) && (
        <Grid style={{ backgroundColor: props.zoomed ? 'transparent' : '', padding: '8%' }} xs={1} item container alignItems="center" justifyContent="center" >
          <RightArrow rightarrow={rightarrow} next={next} arrowStyles={rightarrowStyles} imageStyles={imageStyles} />
        </Grid>
      )}
    </Grid>
  );
};

export default withWidth()(ProductCarousal);
