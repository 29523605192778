import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { alpha } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { MarketplaceColors } from '../../../../marketplace/config';
// import { MarketplaceColors } from '../../../views/marketplace/config';
const CustomInput = withStyles({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '13px 10px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid rgba(0, 0, 0, 0.38)',
                borderRadius: '10px',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.38);',
            },
            '&.Mui-focused fieldset': {
                border: "1px solid rgba(0, 0, 0, 0.38)"
            },
        },
    },
})(TextField);

export default function NoteField(props) {
    return <>
        <CustomInput id="outlined-adornment-weight" multiline rows={4} variant="outlined" {...props} required />
        {
            !props.value && props.formValidation ?
                <Typography style={{ color: MarketplaceColors.error, paddingTop: '2px' }}>
                    Oops! Missing field.
                </Typography>
                : ''
        }</>;
}
