import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { MarketPlaceJoinUsInput } from '../../../../common/TextField';
import axios from 'axios';
import swal from 'sweetalert';
import { MarketplaceColors, MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  linkText: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19.2px',
    letterSpacing: '0.7142857313156128px',
    color: '#33518E',
  },
  text: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    color: MarketplaceColors.Primary,
  },
  padding: {
    marginLeft: '2%',
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  padding2: {
    paddingRight: '2%',
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  padding3: {
    paddingTop: '2%',
    paddingBottom: '8%',
  },
  buttonstyle: {
    backgroundColor: '#33518E',
    color: '#FFFFFF',
    borderRadius: '40px',
    height: '40px',
    textTransform: 'uppercase',
    width: '80%',
    // maxWidth: 'auto',
    '&:hover': {
      backgroundColor: '#33518E',
      color: '#FFFFFF',
    },
    fontWeight: 'bold',
  },
  buttonstyle2: {
    backgroundColor: '#33518E',
    color: '#FFFFFF',
    borderRadius: '40px',
    height: '30px',
    textTransform: 'uppercase',
    width: '220px',
    maxWidth: 'auto',
    '&:hover': {
      backgroundColor: '#33518E',
      color: '#FFFFFF',
    },
  },
  errortextfiled: MarketplaceTypography.CaptionSmall,
}));

const JoinUsFormDesktop = (props) => {
  const classes = useStyles();
  const { setJoinus } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [formValidation, setFormValidation] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name !== '' && email !== '') {
      const body = {
        email: email,
        name: name,
      };
      axios
        .post(`/joinus`, body)
        .then((response) => {
          if (response && response?.data?.data) {
            setName('');
            setEmail('');
            setJoinus(false);
            swal({ icon: 'success', title: 'Thank You', text: 'Submitted Successfully.' });
          }
        })
        .catch((error) => {
          setName('');
          setEmail('');
          swal({
          icon:'warning', 
          text: 'Oops! An error occured while sending your message. Please email us directly at',
          content: {
            element: "a",
            attributes: {
              innerText: "connect@sdplus.io",
              href: "mailto:connect@sdplus.io",
              target: "_blank"
            },
          }
        });
        });
    } else {
      setFormValidation(true);
    }
  };
  return (
    <>
      {props?.compLocation === 'become-a-vendor-popup' ? (
        <Grid container xs={12} item style={{ width: 'auto', backgroundColor: '#FFFFFF' }}>
          <div style={{ width: 'auto', flexDirection: 'column', display: 'flex' }}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <b>Become a Vendor</b>
              </Typography>
            </Grid>
            <Grid container item xs={12} alignItems="flex-start" justifyContent="flex-start" style={{marginBottom:'3%'}}>
              <Typography variant="h6" style={{ color: '#33518E', fontFamily: 'Lato' }}>
                <b>Kindly provide your information</b>
              </Typography>
            </Grid>
            <Grid container item xs={12} alignItems="Center" justifyContent="Center">
              <Grid item xs={12} className={classes.padding3}>
                <MarketPlaceJoinUsInput
                  variant="outlined"
                  fullWidth
                  value={name}
                  formValidation={formValidation}
                  currentvalue={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  styleclass={classes.errortextfiled}
                  stylename={classes.errortextfiled}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="Center" justifyContent="Center">
              <Grid item xs={12} className={classes.padding3}>
                <MarketPlaceJoinUsInput
                  formValidation={formValidation}
                  currentvalue={email}
                  value={email}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  stylename={classes.errortextfiled}
                />
              </Grid>
            </Grid>
            <span className={classes.linkText}>We will get in touch with you!</span>
            <Grid container item xs={12} justifyContent="center" alignItems="center" style={{ marginTop: '2%', marginLeft: '80%', marginBottom: '4%' }}>
              <Grid item xs={5} container justifyContent="center" style={{ marginRight: '5%' }}>
                <Typography onClick={props.handleClose && props.handleClose} style={MarketplaceTypography.Link} className={classes.text}>
                  <b>CANCEL</b>
                </Typography>
              </Grid>
              <Grid item xs={5} container alignItems="center" justifyContent="center" style={{ paddingTop: '2%' }}>
                <Button variant="outlined" onClick={handleSubmit} disableElevation className={classes.buttonstyle2}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      ) : (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          <Grid container item xs={12} alignItems="flex-start" justifyContent="flex-start">
            <Typography variant="h5" style={{ color: '#33518E', fontFamily: 'Lato' }}>
              <b>Kindly provide your information</b>
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems="center" justifyContent="Center">
            <Grid item xs={10} className={classes.padding3}>
              <MarketPlaceJoinUsInput
                variant="outlined"
                fullWidth
                value={name}
                formValidation={formValidation}
                currentvalue={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                styleclass={classes.errortextfiled}
                stylename={classes.errortextfiled}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="Center" justifyContent="Center">
            <Grid item xs={10} className={classes.padding3}>
              <MarketPlaceJoinUsInput
                formValidation={formValidation}
                currentvalue={email}
                value={email}
                placeholder="Email"
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                stylename={classes.errortextfiled}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="flex-start" justifyContent="flex-start">
            <Typography className={classes.linkText}>We will reach out for next steps</Typography>
          </Grid>
          <Grid container item xs={10} alignItems="center" justifyContent="left" style={{ paddingTop: '10%' }}>
            <Button variant="outlined" onClick={handleSubmit} disableElevation className={classes.buttonstyle}>
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default JoinUsFormDesktop;
