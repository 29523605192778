import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../common/textfield/Fields';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import axios from '../../../../../config/Axios';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Typography: {
    fontWeight: 500,
    marginTop: '3%',
    marginLeft: '8%',
  },
  table: {
    minWidth: 500,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '600px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(2, 2, 2, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  multilineColor: {
    color: 'red',
    borderColor: 'red',
  },
}));

function TableData(props) {
  const classes = useStyles();
  const { projectFile, makeRequest } = props;
  return (
    <>
      <Grid xs={10} container item style={{ marginLeft: '8%', marginBottom: '5%', marginTop: '3%' }}>
        <TableContainer component={Paper} style={{ borderRadius: '10px', border: '1px solid rgba(0, 0, 0, 0.14)' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>NAME</b>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">
                    <b>TYPE</b>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {' '}
                  <Typography variant="h6">
                    <b>DATE OF UPLOAD</b>
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectFile.map((files) => (
                <TableRow key={files.name}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="h6"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        const body = {
                          projectId: props.projectID,
                          userid: props.userDetails,
                          filename: files.name,
                          type: files.type,
                        };
                        axios.put(`/s3Services/presignedResource`, body).then((respo) => {
                          if (respo.data && respo.data.data && respo.data.data.data) {
                            window.open(respo.data.data.data.uri);
                          }
                        });
                      }}
                    >
                      {files.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6"> {files.type}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6"> {files.createdAt.slice(0, 10)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="h6"
                      style={{ color: '#ED5E68', cursor: 'pointer' }}
                      color="textSecondary"
                      onClick={() => {
                        swal({
                          title: `Are you sure you want to permanently delete ${files.name}`,
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            axios.delete(`/project-asset/${files._id}`).then((res) => {
                              if (res && res.data && res.data.data && res.data.data.data) {
                                swal('Poof! Your file has been deleted!', {
                                  icon: 'success',
                                });
                                makeRequest();
                              } else {
                                swal('Your file is safe!');
                              }
                            });
                          }
                        });
                      }}
                    >
                      <b>Delete</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default TableData;
