import { useEffect, useState } from 'react';
import removeDuplicates from '../../../../utils/removeDuplicates';

export const ExtractStructuredCategoriesData = (categoryDetails, categoryData) => {
  const [save, showSave] = useState('Save');
  const [sectionBasedData, setSectionBasedData] = useState('');
  const [structuredSectionData, setStructuredSectionData] = useState('');

  useEffect(() => {
    if (categoryData && categoryData.length !== 0 && categoryDetails) {
      const extractedSections = removeDuplicates(categoryData.map((d) => d.section));
      if (extractedSections.length !== 0) {
        let obj = {};
        extractedSections.forEach((sec) => (obj[sec] = categoryData.filter((f) => f.section === sec)));
        setSectionBasedData(obj);
        showSave('Save');
      } else {
        showSave('Exit');
      }
    } else {
      setSectionBasedData([]);
    }
  }, [categoryDetails, categoryData]);

  useEffect(() => {
    if (sectionBasedData) {
      let arr = [];
      Object.keys(sectionBasedData).map((key) => {
        let obj = {};
        obj['sectionName'] = key;
        let obj2 = {};
        obj2['number'] = sectionBasedData[key].filter((d) => d.type === 'number');
        obj2['others'] = sectionBasedData[key].filter((d) => d.type === 'boolean' && d.section === key && !d.subSection);
        let obj3 = {};
        let arr2 = [];
        sectionBasedData[key]
          .filter((d) => d.type === 'string')
          .forEach((v) => {
            let ooo = {};
            ooo['label'] = v.label;
            ooo['description'] = v.description;
            let f = sectionBasedData[key].filter((d) => d.type === 'boolean').filter((e) => e.subSection === v.label);
            ooo['data'] = f;
            arr2.push(ooo);
          });
        obj3['data'] = arr2;
        obj2['boolean'] = obj3;
        obj['iips'] = obj2;
        arr.push(obj);
      });
      setStructuredSectionData(arr);
    }
  }, [sectionBasedData]);

  return { save, structuredSectionData };
};
