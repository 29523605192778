import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  labelfont: {
    textDecoration: 'none !important',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:focus, &:active, &:hover': {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.82)',
    },
  },
  labelactive: {
    color: 'rgba(0, 0, 0, 0.82)',
  },
  active: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.82)',
    background: 'linear-gradient(270deg, rgba(64, 225, 156, 0.1) 0%, rgba(64, 225, 156, 0.1) 100%)',
  },
}));

export default function ListItemLink(props) {
  let location = useLocation();
  const active = location.pathname.startsWith(props.to);
  const { label, to, onClick } = props;
  const classes = useStyles();
  return (
    <>
      <ListItem disableGutters onClick={onClick} className={active ? classes.active : {}}>
        <ListItemText
          primary={
            <NavLink className={classes.labelfont} to={to} activeClassName={classes.labelactive}>
              <Grid container>
                <Grid item xs={2} />
                <Grid item xs={9}>
                  {label}
                </Grid>
                <Grid item xs={1}>
                  {active && <ArrowRightIcon />}
                </Grid>
              </Grid>
            </NavLink>
          }
        />
      </ListItem>
    </>
  );
}
