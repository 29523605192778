import { withWidth, Grid, Typography, makeStyles, Container } from '@material-ui/core';
import React from 'react';
import { MarketplaceColors, MarketplaceStyles } from '../../../config';
import companiesDesktop from '../../../../../../assets/Marketplace/companiesDesktop.svg';
import companiesMobile from '../../../../../../assets/Marketplace/companiesMobile.svg';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
    paddingBottom: MarketplaceStyles.sectionPadding.paddingBottom,
  },
  customContainer: {
    maxWidth: 1440,
    margin: '0 auto',
  },
}));

const MarketplaceSustainabilityPartners = (props) => {
  const classes = useStyles();
  const { width } = props;
  // const { isMobile } = useScreenType(width);

  return (
    <Container className={classes.customContainer}>
      <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          <Typography variant="h5" style={{ color: MarketplaceColors.Primary }}>
            <b>Market leaders in sustainable construction using our services to make a difference</b>
          </Typography>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          {/* <Typography variant="h6" style={{ color: MarketplaceColors.Secondary }}>
            Making a difference with us
          </Typography> */}
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" style={{ padding: '3%' }}>
          {(width === 'sm' || width === 'md' || width === 'lg' || width === 'xl') && (
            <img src={companiesDesktop} alt="big" style={width === 'sm' || width === 'md' || width === 'lg' ? { width: '85%' } : { width: '100%' }} />
          )}
          {width === 'xs' && <img src={companiesMobile} alt="small" />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default withWidth()(MarketplaceSustainabilityPartners);
