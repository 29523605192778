import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import JoinUsFormMobile from '../../../views/landing/joinus/components/JoinusFormMobile';
import JoinUsFormDesktop from '../../../views/landing/joinus/components/JoinusFormDesktop';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '560px',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 5, 2, 5),
    border: 'none',
    borderRadius: '10px',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const BecomeVendor = ({ setJoinus, handleClose, joinus, isMobile }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Grid item xs={12} container style={modalStyle} className={classes.paper}>
      {/* currently mobile one is not implemented */}
      {isMobile && <JoinUsFormMobile joinus={joinus} setJoinus={setJoinus} />}
      {!isMobile && <JoinUsFormDesktop handleClose={handleClose} compLocation="become-a-vendor-popup" joinus={joinus} setJoinus={setJoinus} />}
    </Grid>
  );
};

export default BecomeVendor;
