import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Switch } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomizedTooltips from '../../common/tooltip/TooltipV2';

export const Progress = ({ progressValue, background, barBackground, style }) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 16,
      background: background,
      borderRadius: 30,
    },
    bar: {
      borderRadius: 30,
      background: barBackground,
    },
  }))(LinearProgress);
  return <BorderLinearProgress variant="determinate" value={progressValue} style={style} />;
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 29,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const AntSwitchTwo = withStyles((theme) => ({
  root: {
    width: 29,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#CCCCCC',
        borderColor: '#CCCCCC',
      },
    },
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #CCCCCC`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#CCCCCC',
  },
  checked: {},
}))(Switch);

const Default = ({
  border,
  title,
  tier,
  text,
  progressValue,
  barBackground,
  barText,
  disableBartext,
  disabledSwitch = false,
  showProgress = true,
  showValue = true,
  target,
  handleStatusUpdate = () => {},
}) => {
  return (
    <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', height: 200, width: 250, marginBottom: '10%' }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid container alignItems="center" justifyContent="center" style={{ height: 20 }}>
          <Grid xs={6} item container alignItems="center" justifyContent="flex-start" style={{ paddingLeft: '2%', paddingTop: '1%' }}>
            <AntSwitch
              disabled={disabledSwitch}
              checked={target ? target?.enabled : false}
              onChange={(event) => handleStatusUpdate(event.target.checked, target)}
              name="checkedC"
            />
          </Grid>
          <Grid xs={6} item container alignItems="center" justifyContent="flex-end" style={{ paddingRight: '5%', paddingTop: '2%' }}>
            <CustomizedTooltips
              icon="info"
              title={
                <>
                  <Typography>{target?.target?.description}</Typography>
                </>
              }
              placement="right"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" style={{ height: 100, width: '70%', paddingTop: '10%' }}>
          <Typography variant="h6" style={{ textAlign: 'center', color: '#000000', lineHeight: '20px', letterSpacing: '0.71px' }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ paddingLeft: '2%', height: 52 }}>
          <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
            <Paper
              variant="outlined"
              elevation={3}
              style={{ border: '1px solid #26E0A3', width: 40, height: 'auto', padding: '3px', backgroundColor: '#F0FBF2' }}
            >
              <Typography variant="h6" style={{ textAlign: 'center', color: '#000000', fontSize: '8px' }}>
                {tier}
              </Typography>
            </Paper>
          </Grid>
          {showValue && (
            <Grid item xs={8} container alignItems="center" justifyContent="flex-start">
              <Typography style={{ textAlign: 'center' }} variant="h6">
                <b>{text}</b>
              </Typography>
            </Grid>
          )}
        </Grid>
        {showProgress && (
          <Grid container alignItems="flex-end" justifyContent="center" style={{ paddingLeft: '1%', paddingRight: '1%', paddingTop: '2%' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <Progress variant="determinate" progressValue={progressValue} background={'#E5E5E5'} barBackground={barBackground} style={{ flexGrow: '1' }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  display: disableBartext && 'none',
                  position: 'absolute',
                  top: 5,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                  fontSize: '10px',
                }}
              >
                <b>{`${barText}%`}</b>
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const Disabled = ({
  title,
  tier,
  text,
  progressValue,
  barText,
  showProgress = true,
  disabledSwitch = false,
  showValue = true,
  target,
  handleStatusUpdate = () => {},
}) => {
  const BorderLinearProgressTwo = withStyles(() => ({
    root: {
      height: 16,
      background: '#E5E5E5',
      borderRadius: 30,
    },
    bar: {
      borderRadius: 30,
      background: '#CCCCCC',
    },
  }))(LinearProgress);

  return (
    <Paper variant="outlined" elevation={3} style={{ border: '2px solid #CCCCCC', borderRadius: '11px', height: 200, width: 250, marginBottom: '10%' }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid container alignItems="center" justifyContent="center" style={{ height: 20 }}>
          <Grid xs={6} item container alignItems="center" justifyContent="flex-start" style={{ paddingLeft: '2%', paddingTop: '1%' }}>
            <AntSwitch
              disabled={disabledSwitch}
              checked={target ? target?.enabled : false}
              onChange={(event) => handleStatusUpdate(event.target.checked, target)}
              name="checkedC"
            />{' '}
          </Grid>
          <Grid xs={6} item container alignItems="center" justifyContent="flex-end" style={{ paddingRight: '5%', paddingTop: '2%' }}>
            <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" style={{ height: 100, width: '70%', paddingTop: '10%' }}>
          <Typography variant="h6" style={{ textAlign: 'center', color: '#000000', lineHeight: '20px', letterSpacing: '0.71px' }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ paddingLeft: '2%', height: 52 }}>
          <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
            <Paper
              variant="outlined"
              elevation={3}
              style={{ border: '1px solid #CCCCCC', width: 40, height: 'auto', padding: '3px', backgroundColor: '#F0FBF2' }}
            >
              <Typography variant="h6" style={{ textAlign: 'center', color: '#000000', fontSize: '8px' }}>
                {tier}
              </Typography>
            </Paper>
          </Grid>
          {showValue && (
            <Grid item xs={8} container alignItems="center" justifyContent="flex-start">
              <Typography style={{ textAlign: 'center' }} variant="h6">
                <b>{text}</b>
              </Typography>
            </Grid>
          )}
        </Grid>
        {showProgress && (
          <Grid container alignItems="flex-end" justifyContent="center" style={{ paddingLeft: '1%', paddingRight: '1%', paddingTop: '2%' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <BorderLinearProgressTwo variant="determinate" value={progressValue} style={{ flexGrow: '1' }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                  fontSize: '10px',
                }}
              >
                <b>{`${barText}%`}</b>
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const NotAchievable = ({ title, border }) => {
  return (
    <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', height: 200, width: 250, marginBottom: '10%' }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid container alignItems="center" justifyContent="center" style={{ height: 100, width: '70%', paddingTop: '20%' }}>
          <Typography variant="h6" style={{ textAlign: 'center', color: 'rgba(158, 158, 158, 1)', lineHeight: '20px', letterSpacing: '0.71px' }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="center" style={{ marginTop: '25%' }}>
          <Typography variant="h6" style={{ textAlign: 'center', fontSize: '14px', color: '#CCCCCC', lineHeight: '24px', letterSpacing: '0.7px' }}>
            Not achievable for this project
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const GoalCard = {
  Default,
  Disabled,
  NotAchievable,
  Progress,
};

export default GoalCard;
