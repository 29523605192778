import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '0px 100px 100px 0px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '3%',
  },
  barHeadong: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.83)',
  },
  subhead: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingTop: '20px',
  },
  title: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: '#666666',
    paddingTop: '25px',
  },
}));

export default function CustomizedBar({ bgcolor, documentslength, documentUploadPercentage, activeStep, totalAssetsforCredit, source }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 20,
      background: '#D9D9D9',
      borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: '0px 100px 100px 0px',
      background: `${bgcolor}`,
    },
  }))(LinearProgress);
  return (
    <>
      <Typography className={classes.barHeadong}>{activeStep}</Typography>
      <Grid container item xs={10} className={classes.spacing}>
        <Grid item xs={8}>
          <BorderLinearProgress className={classes.margin} variant="determinate" value={documentUploadPercentage} />
        </Grid>
        <Grid item xs={3} style={{ paddingLeft: '12px' }}>
          <Typography variant="h6">
            <b>{Math.round(documentUploadPercentage)}%</b>
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.subhead}>
        Documents Uploaded{' '}
        <span style={{ paddingLeft: '15px', fontWeight: 200 }}>{`   ${
          source === 'credit details' ? (totalAssetsforCredit ? totalAssetsforCredit : 0) : documentslength
        }`}</span>
      </Typography>
      <Typography className={classes.title}>Upload documents to verify your IGBC credits </Typography>
    </>
  );
}
