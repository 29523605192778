import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MarketplaceColors } from '../../../config';

const ProductTabs = withStyles((theme) => ({
  root: {
    borderBottom: 0,
  },
  indicator: {
    backgroundColor: MarketplaceColors.Secondary,
  },
}))(Tabs);

export const ProductTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&:focus': {
      outline: 0,
    },
    '&$selected': {
      color: MarketplaceColors.Primary,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default ProductTabs;
