import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import SDLogo from '../../../../assets/header/SDplus Logo.png';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '70px',
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  appbar: {
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  shownavitem: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menuButton: {
    flexGrow: 1,
    height: '5vh',
    marginLeft: '50%',
  },
  menuButtonBelow: {
    flexGrow: 1,
    height: '5vh',
    marginLeft: '40%',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      height: '5vh',
      marginLeft: '40%',
    },
  },
  link: {
    alignItems: 'left',
    justifyContent: 'left',
    marginLeft: '10%',
    marginBottom: '4%',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: '#66E3BE',
    },
  },
  space: {
    marginTop: '20%',
  },
  typo: {
    fontStyle: 'normal',
    marginBottom: '5%',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.83)',
    '&:hover': {
      color: '#66E3BE',
      textDecoration: 'underline',
    },
  },
  typo2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.83)',
    marginBottom: '10%',
    '&:hover': {
      color: '#66E3BE',
    },
  },
}));

const DrawerNav = (props) => {
  const matchesBelow = useMediaQuery('(min-width:422px)');
  const classes = useStyles();
  let history = useHistory();

  const handleClick = () => {
    history.push(`/`);
  };
  return (
    <>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <img src={SDLogo} alt="..." height="70vh" width="auto" style={{ cursor: 'pointer' }} onClick={handleClick} />
          <Typography className={classes.title} />
          <CloseIcon className={matchesBelow ? classes.menuButton : classes.menuButtonBelow} style={{ color: 'black' }} onClick={props.toggleDrawerClose} />
        </Toolbar>
        <Grid className={classes.space} />
        <Link className={classes.link} to="/" onClick={props.toggleDrawerClose}>
          <Typography className={classes.typo}>Home</Typography>
        </Link>
        <Link className={classes.link} onClick={props.handleFeatures}>
          <Typography className={classes.typo}>Features</Typography>
        </Link>

        {/* <Link className={classes.link} to="/team" onClick={props.toggleDrawerClose}>
          <Typography className={classes.typo}>Team</Typography>
        </Link> */}
        <Link className={classes.link}>
          <Typography className={classes.typo} onClick={props.handlepopupRequestdemo}>
            Request Demo
          </Typography>
        </Link>
      </AppBar>
    </>
  );
};

export default DrawerNav;
