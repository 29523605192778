import React from 'react';
import { MarketplaceColors } from '../../config';
import MarketplaceSearchBar from '../../../../common/Searchbar/MarketplaceSearchBar';

const MarketplaceSearchBox = (props) => {
  return (
    <MarketplaceSearchBar
      placeholder={` ${props.text}`}
      width="55ch"
      searchButtonbgcolor={MarketplaceColors.Secondary}
      color="white"
      IconButton={props.IconButton}
      fontSize="180%"
    />
  );
};

export default MarketplaceSearchBox;
