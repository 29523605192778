export const UnitMap = [
  { name: 'floorarea', unit: 'Sqm' },
  { name: 'length', unit: 'Sqm' },
  { name: 'breadth', unit: 'Sqm' },
  { name: 'windowGlazingVLTpercentage', unit: 'Sqm' },
];

export const Sections = {
  Dwelling_Unit_Types: 'Dwelling Unit Types',
  Window_Types: 'Window Types',
  Dwelling_Unit_Interiors: 'Dwelling Unit Interiors',
};

export const Category = 'Resident Health And Well Being';
export const DwellingUnits = ['1BHK', '2BHK', '3BHK', '4BHK'];
export const OpenableType = ['Sliding'];
export const Occupiedspacearr = ['Bedroom', 'Kitchen', 'Bedroom/Living'];
export const TypeofWindowarr = ['Sliding', 'Louvre', 'Casement'];

export const ResponseObject = {
  name: 'resident-health-and-well-being',
  description: '',
  label: '',
  type: 'object',
  unit: '',
  valueOptions: [],
  section: 'Resident Health And Well Being',
  subSection: '',
  category: '6374fb589a7d91aaeb4679c5',
  subSectionType: '',
  customData: [
    {
      name: 'resident-health-and-well-being',
      data: [
        {
          section: 'Dwelling Unit Types',
          description: null,
          data: [],
        },
        {
          section: 'Window Types',
          description: null,
          data: [],
        },
        {
          section: 'Dwelling Unit Interiors',
          description: 'Add Dwelling Unit Types and Window Types to create Dwelling Unit Interiors',
          data: [],
        },
      ],
    },
  ],
};
