import React from 'react';
import { Grid, GridList, makeStyles } from '@material-ui/core';
import productimage2 from '../../../../../../../assets/Marketplace/product/productcardimage2.svg';
import ImpactProductCard from './ImpactProductCard';
import { MarketplaceStyles } from '../../../../config';
import { products } from '../../../../utils/DummyProducts';

const useStyles = makeStyles((theme) => ({
  gridList: MarketplaceStyles.gridList,
}));

const ImpactProductsGroupMobile = (props) => {
  const { impactProducts } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        paddingBottom: MarketplaceStyles.sectionPadding.paddingBottom,
      }}
    >
      <GridList className={classes.gridList}>
        {impactProducts && impactProducts.length !== 0
          ? impactProducts.map((d) => {
              return (
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center" style={{ height: 'auto' }}>
                  <ImpactProductCard ele={d} productimage={productimage2} product={d && d} />
                </Grid>
              );
            })
          : products.map((d) => {
              return (
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center" style={{ height: 'auto' }}>
                  <ImpactProductCard ele={d} productimage={productimage2} />
                </Grid>
              );
            })}
      </GridList>
    </div>
  );
};

export default ImpactProductsGroupMobile;
