import React, { useState, useContext } from 'react';
import AllProjectsCard from './AllProjectsCard';
import Button from '@material-ui/core/Button';
import axios from '../../../../config/Axios';
import action2 from '../../../../assets/action2.svg';
import action from '../../../../assets/action.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProjectContext } from '../../../../routing/IndexRouting';
import appartment1 from '../../../../assets/appartment-1.svg';
import appartment2 from '../../../../assets/appartment-2.svg';
import appartment3 from '../../../../assets/appartment-3.svg';
//delete project v2
const DeleteProject = (props) => {
  return (
    <Button size="small" color="primary" style={{ outline: 'none' }} onClick={props.handleDelete}>
      <img src={action2} alt="..." title="Delete The Project Permanently" />
    </Button>
  );
};
const CommonAllProjectsCard = (props) => {
  const { closeModal = () => {} } = props;
  const { handleProjectData } = useContext(ProjectContext);
  const [showLoader, setShowloader] = useState(false);

  // change this
  const handleViewInsites = () => {
    setShowloader(true);
    axios
      .put(`/userprofiles/${props?.currentProfile?._id}`, { currentproject: props?.project?._id })
      .then((response) => {
        if (response.data) {
          setShowloader(false);
          closeModal();
          props.history.push(`/projects/${props?.project?._id}/insights`);
        }
      })
      .catch(() => {
        setShowloader(false);
      });
  };
  const getAppartmentIconRand = () => {
    const val = Math.floor(Math.random() * 3 + 1);
    if (val === 1) return appartment1;
    if (val === 2) return appartment2;
    if (val === 3) return appartment3;
    return appartment1;
  };
  return (
    <>
      <AllProjectsCard
        key={props.index}
        name={props.project.name.length >= 15 ? props.project.name.slice(0, 12) + '...' : props.project.name}
        icon={getAppartmentIconRand()}
        space={8}
        builtup={
          props.project?.pips &&
          props.project?.pips.length !== 0 &&
          props.project?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea')
            ? props.project?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea').value
            : 0
        }
        location={props.project && props.project.location.name}
        unit={
          props.project?.pips &&
          props.project?.pips.length !== 0 &&
          props.project?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea')
            ? props.project?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea').pipScheme.unit
            : ''
        }
        category={props.project.category}
        cardaction={
          <>
            {showLoader ? (
              <CircularProgress size={40} />
            ) : (
              <Button size="small" onClick={handleViewInsites} color="primary">
                <img src={action} alt="..." title="Switch To Current Project" />
              </Button>
            )}

            <DeleteProject projectId={props.project._id} projectName={props.project.name} handleDelete={props.handleDelete} />
          </>
        }
      />
    </>
  );
};
export default CommonAllProjectsCard;
