export const PrimaryFont = "'Lato', sans-serif";

const defaultmode = () => {
  return {
    palette: {
      primary: {
        light: '#42E198',
        main: '#1FDEDB',
        dark: '#66E3BE',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0F6AFF',
        main: '#2157E5',
        dark: '#18a0fb',
        contrastText: '#fff',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: '#fff',
      },
      info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#fff',
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: PrimaryFont,
      fontSize: '14px',
      h1: {
        fontFamily: PrimaryFont,
        fontWeight: 900,
        fontSize: '60px',
        lineHeight: 60 / 60,
        letterSpacing: '0.01em',
      },
      h2: {
        fontFamily: PrimaryFont,
        fontWeight: 900,
        fontSize: '50px',
        lineHeight: 50 / 60,
        letterSpacing: '0.01em',
      },
      h3: {
        fontFamily: PrimaryFont,
        fontWeight: 900,
        fontSize: '40px',
        lineHeight: 40 / 55,
        letterSpacing: '1.42857px',
      },
      h4: {
        fontFamily: PrimaryFont,
        fontWeight: 900,
        fontSize: '30px',
        lineHeight: 30 / 40,
        letterSpacing: '0.571429px',
      },
      h5: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: 20 / 8,
        letterSpacing: '0.5px',
      },
      h6: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: 14 / 14,
        letterSpacing: '0.5px',
      },
      subtitle1: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '40px',
        lineHeight: 40 / 55,
        letterSpacing: '0.714286px',
      },
      subtitle2: {
        fontFamily: PrimaryFont,
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: 24 / 16,
        letterSpacing: '0.714286px',
      },
      body1: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: 14 / 10,
        letterSpacing: '0.571429px',
      },
      body2: {
        fontFamily: PrimaryFont,
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: 20 / 25,
        letterSpacing: '0.571429px',
      },
      button: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: 13 / 8,
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      caption: {
        fontFamily: PrimaryFont,
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: 13 / 18,
        letterSpacing: '0.714286px',
      },
    },
    breakpoints: {
      mobile: 960,
      tablet: 1280,
      laptop: 1440,
      desktop: 1920,
    },
  };
};
export const darkmode = () => {
  return {
    palette: {
      primary: {
        main: '#1FDEDB',
        contrastText: '#fff',
      },
      secondary: {
        main: 'rgba(0, 0, 0, 0.38)',
        contrastText: '#fff',
      },
    },

    typography: {
      fontFamily: PrimaryFont,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  };
};
export default defaultmode;
