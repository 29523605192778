import React from 'react';
import { ResourcesData, types } from '../MaterialSelectionGuideData';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import Steppercard from './components/StepperCard';
import ImageWithSource from './components/ImageWithSource';
import ScrollableTable from './components/ScrollableTable';
import ImageAndSource from './components/ImageandSource';
import TextWithPoints from './components/TextWithPoints';
import TextWithHeading from './components/TextWithHeading';
import TextWithPointsandSubpoints from './components/TextwithPointAndSubpoint';

const useStyles = makeStyles((theme) => ({
  stepperstyle: {
    height: '170px',
    width: '170px',
  },
  padding: {
    paddingLeft: '20px',
    paddingTop: '4vh',
    paddingBottom: '6vh',
  },
  stepperhead: {
    textAlign: 'center',
    color: MarketplaceColors.Secondary,
    width: '170px',
    paddingTop: '4%',
  },
  steppertext: {
    color: MarketplaceColors.steppertextcolor,
    width: '170px',
    textAlign: 'center',
  },
  textstyle: MarketplaceTypography.BodyRegular,
}));

const icons = { Identify: Seek, Choose: Check, Inquire: Inquire, Select: Choose };

const Headers = ['Properties', 'Solvent-Based Paints (Default)', 'Water-Based Paints (Recommended)'];
const secondHeader = ['Environmental Impacts', 'Steel Rebar', 'BOF Steel', 'DRI Steel', 'EAF Steel'];
function createData(name, a, b) {
  return { name, a, b };
}
const Paint = (props) => {
  const history = useHistory();
  const paintData = ResourcesData.filter((d) => d.iid === 'Paints');
  const steppers = paintData[0]?.data.find((d) => d.type === types.steppers);
  //   console.log(steppers, 'paintData');
  const classes = useStyles();
  function TableHeaders(id) {
    let tableHeaders = [];
    paintData[0]?.data.find((a) => a.id === id)?.data.map((e, index) => (index === 1 ? tableHeaders.push(e.value1, e.value2, e.value3) : ''));
    return tableHeaders;
  }
  function Tablerows(id) {
    let tablerows = [];
    paintData[0]?.data.find((a) => a.id === id)?.data.map((e, index) => (index !== 0 ? tablerows.push(createData(e.value1, e.value2, e.value3)) : ''));
    return tablerows;
  }
  return (
    <div>
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{paintData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>
        {paintData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start">
            {/* steppers */}
            <Grid style={{ marginTop: '2%', marginLeft: '4%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers.data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d) => {
              return (
                <Grid style={{ marginLeft: '4%' }} container>
                  {/* text */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.text && (d.id === 2 || d.id === 12) && (
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        {d.text}
                      </Typography>
                    )}
                  </Grid>
                  {/* Heading */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.heading && (d.id === 3 || d.id === 7 || d.id === 8 || d.id === 10 || d.id === 14 || d.id === 16 || d.id === 19) && (
                      <Typography style={MarketplaceTypography.Heading3}>{d.title}</Typography>
                    )}
                  </Grid>
                  {/* table */}
                  <Grid style={{ marginTop: '2%', justifyContent: 'center', alignItems: 'center', width: '80%', marginLeft: '10%' }} container>
                    {d.type === types.table && (d.id === 4 || d.id === 20) && (
                      <ScrollableTable rows={Tablerows(d.id === 20 ? 20 : 4)} tableHeaders={TableHeaders(d.id === 20 ? 20 : 4)} Headers={Headers} />
                    )}
                  </Grid>
                  {/* subhead */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.subhead && d.id === 5 && (
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        <span style={{ fontWeight: 'bold' }}>{d.title}</span>
                      </Typography>
                    )}
                  </Grid>
                  {/* Text with point and sub point */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.textwithpointsandsubpoints && (d.id === 6 || d.id === 17) && (
                      <TextWithPointsandSubpoints
                        image={d.image}
                        imageindex={d.imageindex}
                        data={d.data}
                        justifyContent="flex-start"
                        imageStyle={{ width: '250px' }}
                      />
                    )}
                  </Grid>
                  {/* Text with points */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.textwithpoints && (d.id === 9 || d.id === 11 || d.id === 15) && (
                      <TextWithPoints
                        classname={classes.textstyle}
                        image={d.image}
                        imageindex={d.imageindex}
                        justifyContent="flex-start"
                        imageStyle={{ width: '250px' }}
                        data={d.data}
                      />
                    )}
                  </Grid>
                  {/* image */}
                  <Grid container alignItems="flex-start" justifyContent="center" style={{ marginTop: '2%' }}>
                    {d.type === types.image && (d.id === 13 || d.id === 18) && (
                      <ImageWithSource justifyContent="center" imageStyle={{ width: d.id === 18 ? '600px' : '450px' }} image={d.image} source={d.source} />
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Container>
      <Grid style={{ marginTop: '100px' }}></Grid>
      <MarketplaceFooter />
    </div>
  );
};

export default Paint;
