import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../../../../config/Axios';
import swal from 'sweetalert';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  spacing: {
    paddingTop: '5%',
    // paddingLeft: '2%',
  },
  spacing2: {
    marginTop: '-3%',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circular: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));
const UsersTable = (props) => {
  const classes = useStyles();
  const [organisations, setOrganisations] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [changedName, setChangedName] = useState('');
  const [changedEmail, setChangedEmail] = useState('');
  const [changedPassword, setChangedPassword] = useState('');
  const [changedRole, setChangedRole] = useState('');
  const [changedOrganisation, setChangedOrganisation] = useState('');
  const [passwordModal, setPasswordModal] = useState(false);
  const handleChangeName = (e) => setChangedName(e.target.value);
  const handleChangeEmail = (e) => setChangedEmail(e.target.value);
  const handleChangeRole = (e) => setChangedRole(e.target.value);
  const handleSelectEmail = (email) => setSelectedEmail(email);
  const handleChangePassword = (e) => setChangedPassword(e.target.value);
  const handleChangeOrgaisation = (e) => setChangedOrganisation(e.target.value);
  const handleCancelUpdate = () => setPasswordModal(false);

  const handleSelectPassword = (email) => {
    setSelectedEmail(email);
    setPasswordModal(true);
  };
  const handleSelect = (e) => {
    if (e.target.value === 'none') {
      setSelectedValue('');
    } else {
      setSelectedValue(e.target.value);
    }
  };
  const handleClose = () => {
    setSelectedValue('');
    setChangedName('');
  };

  useEffect(() => {
    axios
      .get('/organisationsschemev2')
      .then((res) => {
        if (res && res.data && res.data.data) {
          setOrganisations(res.data.data.data);
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  }, []);

  //name change
  const nameChange = (e) => {
    e.preventDefault();
    let body = {
      email: selectedEmail,
      newName: changedName ? changedName : props.name,
    };
    axios
      .put(`/users/update`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Name' });
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //email change
  const emailChange = (e) => {
    e.preventDefault();
    let body = {
      email: selectedEmail,
      newEmail: changedEmail ? changedEmail : props.email,
    };
    axios
      .put(`/users/update`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Email' });
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //role change
  const roleChange = (e) => {
    e.preventDefault();
    let body = {
      email: selectedEmail,
      newType: changedRole ? changedRole : props.role,
    };
    axios
      .put(`/users/update`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Role' });
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  //organisation change
  const updateOrganisation = (e) => {
    e.preventDefault();
    let body = {
      email: selectedEmail,
      organisationId: changedOrganisation && changedOrganisation,
    };
    axios
      .put(`/users/update`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Organiation' });
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    let body = {
      email: selectedEmail,
      newPassword: changedPassword,
    };
    axios
      .put(`/users/update`, body)
      .then((response) => {
        swal({ icon: 'success', title: 'Successfully updated Password' });
        setPasswordModal(false);
        setSelectedValue('');
        setChangedName('');
        props.handleUpdate();
      })
      .catch((err) => console.log(err, 'err'));
  };
  const organisationName = organisations && props.organisation ? organisations.filter((org) => org._id === props.organisation) : null;
  return (
    <>
      {' '}
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '2%' }}>
        <Grid item xs={12} align="left">
          <>
            <Accordion>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={2}>
                    <Typography className={classes.heading}>
                      <b>Username</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'name' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6} align="left">
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="Username" value={changedName} onChange={handleChangeName} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={nameChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>{' '}
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.name}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.heading}>
                      <b>Email</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'email' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6}>
                            <form className={classes.root} noValidate autoComplete="off">
                              <TextField id="standard-basic" label="Email" value={changedEmail} onChange={handleChangeEmail} />
                            </form>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={emailChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.email}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.heading}>
                      <b>Role</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'role' ? (
                      <>
                        <Grid container>
                          <Grid item xs={6}>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleChangeRole}>
                              <MenuItem value="normal">Normal</MenuItem>
                              <MenuItem value="super">Super</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <DoneIcon className={classes.doneicon} onClick={roleChange} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>{props.role}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.heading2}>
                      <b>Organisation</b>
                    </Typography>
                    <br />
                    {selectedValue && selectedValue === 'organisation' ? (
                      <>
                        <Grid container>
                          <Grid item xs={8}>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" defaultValue="none" onChange={handleChangeOrgaisation}>
                              {organisations &&
                                organisations.map((organisation) => {
                                  return (
                                    <MenuItem value={organisation._id} onClick={() => handleSelectEmail(props.email)}>
                                      {organisation.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </Grid>
                          <Grid item xs={4} align="left">
                            <DoneIcon className={classes.doneicon} onClick={updateOrganisation} />
                            <br />
                            <CloseIcon className={classes.closeicon} onClick={handleClose} />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Typography className={classes.secondaryHeading}>
                        {organisations && props.organisation && organisationName ? (
                          organisationName[0].name
                        ) : (
                          <>
                            <div className={classes.circular}>
                              <CircularProgress />
                            </div>
                          </>
                        )}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={1} align="center">
                    <Typography className={classes.heading2}>
                      <b>Update</b>
                    </Typography>
                    <br />
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue="none">
                      <MenuItem value="name" onClick={() => handleSelectEmail(props.email)}>
                        Name
                      </MenuItem>
                      <MenuItem value="email" onClick={() => handleSelectEmail(props.email)}>
                        Email
                      </MenuItem>
                      <MenuItem value="role" onClick={() => handleSelectEmail(props.email)}>
                        Role
                      </MenuItem>
                      <MenuItem value="password" onClick={() => handleSelectPassword(props.email)}>
                        password
                      </MenuItem>
                      <MenuItem value="organisation">Organisation</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <Typography className={classes.heading2}>
                      <b>Delete</b>
                    </Typography>
                    <br />
                    <DeleteIcon onClick={props.delete} />
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          </>
        </Grid>
        <Grid item xs />
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={passwordModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={passwordModal}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Add New Password
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="standard-basic"
                label="new password"
                type="password"
                value={changedPassword}
                onChange={handleChangePassword}
                className={classes.inputField}
              />{' '}
            </form>
            <br />
            <Link size="small" color="primary" onClick={handleUpdatePassword}>
              Update
            </Link>
            <Link size="small" color="primary" onClick={handleCancelUpdate} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default UsersTable;
