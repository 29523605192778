import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import silver from '../../../../assets/IGBC/silver_5.svg';
import CustomSlider from './IGBCSlider';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    padding: theme.spacing(2),
  },

  customSliderGridPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));

const IGBCLevel = (props) => {
  const classes = useStyles();
  let sustainabilityLevel = props.sustainabilityLevel;

  return (
    <>
      <Container className={classes.gridContainer} disableGutters>
        <Grid container xs={12} style={{ marginBottom: '1%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" container>
            <Typography variant="h6">
              <b>Status of IGBC certification</b>
            </Typography>
            <CustomizedTooltips
              icon="info"
              title={
                <>
                  <Typography>Total 65 points achieved. 71-80 points required for IGBC Gold Certification</Typography>
                </>
              }
              placement="right"
            />
          </Grid>
          <Grid item container xs={10} sm={10} md={10} lg={10} xl={10} alignItems="center" justifyContent="center" className={classes.customSliderGridPadding}>
            {sustainabilityLevel && (
              <CustomSlider
                curr={5}
                max={
                  sustainabilityLevel.value === 1
                    ? 25
                    : sustainabilityLevel.value === 2
                    ? 50
                    : sustainabilityLevel.value === 3
                    ? 75
                    : sustainabilityLevel.value === 4
                    ? 95
                    : 80
                }
              />
            )}
          </Grid>
          <Grid item container alignItems="center" justifyContent="center" xs={2} sm={2} md={2} lg={2} xl={2} style={{ paddingLeft: 10 }}>
            <img src={silver} alt="..." className="img-fluid" style={{ height: 'auto' }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IGBCLevel;
