import React, { useState, useRef } from 'react';
import InquiryNavBar from '../../common/header/InquiryNavBar';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../common/button/CustomButton';
import CustomIconButton from '../../common/button/CustomIconButton';
import CustomOutlinedInput from '../../common/textfield/CustomOutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 826,
    padding: theme.spacing(20),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: '#000000',
  },
  desc: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
    marginBottom: '-10px',
  },
  spacing: {
    marginLeft: '10%',
  },
  spacing2: {
    marginTop: '3%',
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const [errorField, setErrorField] = useState(false);
  let history = useHistory();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handlePassword = (e) => {
    setError(false);
    setSuccess(false);
    setErrorField(false);
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setError(false);
    setSuccess(false);
    setErrorField(false);
    setConfirmPassword(e.target.value);
  };

  const [selected, setSelected] = useState(false);
  const pswdRef = useRef(null);
  let { id } = useParams();
  let { token } = useParams();
  let { email } = useParams();

  const handleClick = () => {
    if (password === confirmPassword) {
      let body = {
        id: id,
        token: token,
        newPassword: password,
      };
      axios.post('/users/verify-reset-token-and-update', body).then((res) => {
        if (res.data && res.data.data && res.data.data.data) {
          if (res.data.data.data === 'Your Password has been updated.') {
            setSuccess(true);
          } else if (res.data.data.data === 'Token Expired') {
            setError(true);
          }
        }
      });
    } else {
      setErrorField(true);
    }
  };

  return (
    <>
      <InquiryNavBar />
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column" wrap="nowrap" spacing={3}>
          <Grid container justifyContent="center" alignItems="center" item xs={12}>
            <Typography className={classes.title}>Reset Password</Typography>
          </Grid>
          <Grid container direction="column" item xs={12} className={classes.spacing}>
            <Typography className={classes.desc}>
              Please enter your new password below to reset password for <span>{email && email}</span>
            </Typography>
            <Grid item xs={5} style={{ paddingBottom: '3%' }}>
              <Typography className={classes.text}>New Password</Typography>
              <FormControl margin="normal" fullWidth variant="outlined" required>
                <InputLabel ref={pswdRef} htmlFor="outlined-adornment-password">
                  Type Here
                </InputLabel>
                {errorField || error ? (
                  <CustomOutlinedInput
                    id="outlined-adornment-password"
                    error
                    value={password}
                    onChange={handlePassword}
                    style={{ borderRadius: '40px' }}
                    name="password"
                    type={selected ? 'text' : 'password'}
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <CustomIconButton
                          onClick={() => {
                            setSelected(!selected);
                            pswdRef.current.focus();
                          }}
                          edge="end"
                        >
                          <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                            Show
                          </Typography>
                        </CustomIconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                ) : (
                  <CustomOutlinedInput
                    id="outlined-adornment-password"
                    value={password}
                    onChange={handlePassword}
                    style={{ borderRadius: '40px' }}
                    name="password"
                    type={selected ? 'text' : 'password'}
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <CustomIconButton
                          onClick={() => {
                            setSelected(!selected);
                            pswdRef.current.focus();
                          }}
                          edge="end"
                        >
                          <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                            Show
                          </Typography>
                        </CustomIconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.text}>Confirm Password</Typography>
              <FormControl margin="normal" fullWidth variant="outlined" required>
                <InputLabel ref={pswdRef} htmlFor="outlined-adornment-password">
                  Type Here
                </InputLabel>
                {errorField || error ? (
                  <CustomOutlinedInput
                    id="outlined-adornment-password"
                    error
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    style={{ borderRadius: '40px' }}
                    name="password"
                    type={selected ? 'text' : 'password'}
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <CustomIconButton
                          onClick={() => {
                            setSelected(!selected);
                            pswdRef.current.focus();
                          }}
                          edge="end"
                        >
                          <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                            Show
                          </Typography>
                        </CustomIconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                ) : (
                  <CustomOutlinedInput
                    id="outlined-adornment-password"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    style={{ borderRadius: '40px' }}
                    name="password"
                    type={selected ? 'text' : 'password'}
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <CustomIconButton
                          onClick={() => {
                            setSelected(!selected);
                            pswdRef.current.focus();
                          }}
                          edge="end"
                        >
                          <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                            Show
                          </Typography>
                        </CustomIconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                )}
              </FormControl>
            </Grid>
            {errorField ? (
              <Typography className={classes.desc} style={{ color: 'red' }}>
                Passwords do not match
              </Typography>
            ) : (
              ''
            )}
            {success ? (
              <Typography className={classes.desc} style={{ color: '#26E0A3' }}>
                Password reset successfully!{' '}
                <span>
                  <Link
                    onClick={() => {
                      history.push('/');
                    }}
                    style={{ color: 'blue' }}
                  >
                    Click Here
                  </Link>
                </span>{' '}
                to Login
              </Typography>
            ) : error ? (
              <Typography className={classes.desc} style={{ color: 'red' }}>
                Link has expired.{' '}
                <span>
                  <Link
                    onClick={() => {
                      history.push('/forgotpassword');
                    }}
                    style={{ color: '#2EE1AC', textDecoration: 'none' }}
                  >
                    Resend link
                  </Link>
                </span>{' '}
              </Typography>
            ) : (
              ''
            )}
            <Grid item xs={3} className={classes.spacing2}>
              <CustomButton type="submit" variant="contained" onClick={handleClick} style={{ borderRadius: '40px' }}>
                confirm
              </CustomButton>
            </Grid>
            <Typography className={classes.text} style={{ paddingTop: '10%' }}>
              Contact
              <span>
                <Link style={{ color: 'blue' }}> connect@sdplus.io</Link>
              </span>{' '}
              if your issue is not resolved
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ResetPassword;
