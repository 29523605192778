import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ResidentHealthAndWellBeingContext } from '../..';
import PrimaryButton from '../../../../../../common/button/Button';
import CustomDropDown from '../../common/CustomDropDown';
import Fields from '../../../../../../common/textfield/Fields';
import { Sections, DwellingUnits, UnitMap } from '../../DataTypes';
import { GetUnit } from '../../utils';

const DwellingUnitTypesHandler = ({ handleClose, editTable, editableData, setUpdate, update, indexforEdit, mount, setMount }) => {
  const { data, handleData } = useContext(ResidentHealthAndWellBeingContext);

  const [dwellingUnitType, setDwellingUnitType] = useState('');
  const [name, setName] = useState('');
  const [floorArea, setFloorArea] = useState('');

  useEffect(() => {
    if (editTable === true && editableData) {
      const { data, type } = editableData;
      const { name, floorarea } = data;
      setDwellingUnitType(type);
      setName(name);
      setFloorArea(parseInt(floorarea));
    }
  }, [editTable, editableData]);

  const handleSaveData = (data, dwellingUnitType, name, floorArea) => {
    const obj = {};
    obj['name'] = name;
    obj['floorarea'] = floorArea;
    const obj2 = {};
    obj2['type'] = dwellingUnitType;
    let arr = [];
    arr.push(obj);
    obj2['data'] = arr;
    const findData = data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Types)?.data?.find((f) => f.type === dwellingUnitType);
    if (findData) {
      data?.customData[0]?.data
        ?.find((d) => d.section === Sections.Dwelling_Unit_Types)
        ?.data?.find((f) => f.type === dwellingUnitType)
        ?.data?.push(obj);
    } else if (dwellingUnitType && name) data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Types)?.data.push(obj2);
    // currently the drawer is closed but show user an error
    else handleClose();
    let newData = data;
    handleData(newData);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  // update data handler
  const handleUpdateData = (data, dwellingUnitType, name, floorArea, indexforEdit) => {
    const obj = {};
    obj['name'] = name;
    obj['floorarea'] = floorArea;
    data?.customData[0]?.data
      ?.find((d) => d.section === Sections.Dwelling_Unit_Types)
      ?.data?.find((f) => f.type === dwellingUnitType)
      ['data'].splice(indexforEdit, 1, obj);
    handleData(data);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  return (
    <Grid container style={{ paddingLeft: '4%' }} justifyContent="space-between" alignItems="center">
      <Grid item xs={12} container alignItems="center">
        <Grid>
          <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Grid>
        <Grid style={{ paddingLeft: '4%' }}>
          <Typography variant="h5">{!editTable ? <b>Add Dwelling Unit Type</b> : <b>Edit Dwelling Unit Type</b>}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="center" style={{ marginBottom: '10%' }}>
        <Grid xs={12} container style={{ padding: '2%' }}>
          <Grid xs={12}>
            <Typography variant="h6">Dwelling Unit</Typography>
          </Grid>
          <Grid xs={5} style={{ marginTop: '4%' }}>
            <CustomDropDown
              dataArr={DwellingUnits}
              selecteddata={dwellingUnitType}
              setSelectedData={(e) => setDwellingUnitType(e)}
              disabled={!editTable ? false : true}
            />
          </Grid>
        </Grid>
        <Grid xs={6} style={{ padding: '2%' }}>
          <Grid xs={12}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid xs={10} style={{ marginTop: '4%' }}>
            <Fields id="outlined-basic" variant="outlined" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid xs={10} style={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: '12px' }}>Max 20 characters</Typography>
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid xs={12}>
            <Typography variant="h6">Floorarea(sqm)</Typography>
          </Grid>
          <Grid xs={12} container style={{ marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
            <Grid xs={9}>
              <Fields id="outlined-basic" variant="outlined" fullWidth required value={floorArea} onChange={(e) => setFloorArea(e.target.value)} />
            </Grid>
            <Grid xs={3} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{GetUnit(UnitMap, 'floorarea')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end" style={{ marginBottom: '5%', paddingRight: '3%' }}>
        <Grid item xs={2}>
          <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer' }}>
            Cancel
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          {!editTable && <PrimaryButton children="Save" onClick={() => handleSaveData(data, dwellingUnitType, name, floorArea)} />}
          {editTable && <PrimaryButton children="Update" onClick={() => handleUpdateData(data, dwellingUnitType, name, floorArea, indexforEdit)} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DwellingUnitTypesHandler;
