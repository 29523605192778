import React from 'react';
import { Grid, Typography, makeStyles, Card, CardActionArea, withWidth } from '@material-ui/core';
import { MarketplaceColors } from '../../../../config';
import greenprocertification from '../../../../../../../assets/Marketplace/greenprocertification.svg';
import certificationfallback from '../../../../../../../assets/Marketplace/certificationfallback.svg';

import useScreenType from '../../../../../../hooks/checkScreenType';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Default } from '../../../../utils/Defaults';
import { getIcon } from '../../../../common/ProductCard';

const CertificationCard = (props) => {
  const { index, width, cardstyle, selectedStyle, certification } = props;
  const { isMobile } = useScreenType(width);
  let history = useHistory();

  const useStyles = makeStyles((theme) => ({
    card: {
      boxSizing: 'border-box',
      width: '160px',
      height: '160px',
      background: '#FFFFFF',
      borderRadius: '50%',
      border: `3px solid ${MarketplaceColors.Primary}`,
    },
    cardMobile: {
      boxSizing: 'border-box',
      width: '80px',
      height: '80px',
      background: '#FFFFFF',
      borderRadius: '50%',
      border: `3px solid ${MarketplaceColors.Primary}`,
    },
    cardActionArea: {
      height: '100%',
      textAlign: 'center',
    },
    categorynametext: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: isMobile ? '10px' : '16px',
      lineHeight: '19px',
      display: 'flex',
      color: MarketplaceColors.Primary,
      justifyContent: 'center',
      paddingTop: '16px',
    },
  }));
  const classes = useStyles();
  const [isselected, setSelected] = useState(false);

  return (
    <Grid xs={3} sm={3} md={3} lg={3} xl={2} container key={index} style={{ marginLeft: width === 'xl' ? '3%' : '' }}>
      <Grid xs={12} item container justifyContent="center">
        <Card
          className={!isMobile ? classes.card : classes.cardMobile}
          style={isselected ? selectedStyle : cardstyle}
          onClick={() => {
            history.push(
              `/marketplace/categorydetails?source=Ecolabels&page=${Default.page}&pagesize=${Default.pagesize}&filters=${certification?.attributes?.name}`
            );
            setSelected(!isselected);
          }}
        >
          <CardActionArea className={classes.cardActionArea}>
            <img src={getIcon(certification?.attributes?.name)} alt="imm" style={{ height: '60%' }} />
          </CardActionArea>
        </Card>
      </Grid>
      <Grid xs={12} container justifyContent="center">
        <Typography className={classes.categorynametext}>
          {certification?.attributes?.name.includes(':') ? certification?.attributes?.name.split(':')[0] : certification?.attributes?.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withWidth()(CertificationCard);
