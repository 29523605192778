import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { MarketplaceTypography, MarketplaceColors } from '../config/index';
import { Grid, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default function BreadCrumbs(props) {
  const { links, stage } = { ...props };
  const useStyles = makeStyles((theme) => ({
    breadcrumbtext: MarketplaceTypography.AllCapsSmall,
    breadCrumbicon: MarketplaceColors.Breadcrumb,
    arrowstyle: {
      fontSize: '180%',
      color: MarketplaceColors.Secondary,
      cursor: 'pointer',
    },
    headerStyle: {
      paddingLeft: props.paddingLeft,
      paddingRight: '2%',
    },
  }));

  const classes = useStyles();

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="left" justifyContent="left" className={classes.headerStyle}>
      <ArrowBackIosIcon className={classes.arrowstyle} style={{ color: 'transparent' }} />{' '}
      <Grid item xs={11}>
        <Breadcrumbs separator={<ArrowForwardIosOutlinedIcon fontSize="medium" className={classes.breadCrumbicon} />} aria-label="breadcrumb">
          {links.map((link, index) => {
            if (link.text) {
              return (
                <Link key={index} className={classes.breadcrumbtext} style={{ color: 'black' }} to={link.href}>
                  {link.text}
                </Link>
              );
            } else {
              return <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />;
            }
          })}
          <Typography className={classes.breadcrumbtext} style={{ color: 'black' }}>
            {stage}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}
