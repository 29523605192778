import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from './IndexRouting';

const RedirectComponent = () => {
  const { loginToken } = useContext(UserContext);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (loginToken) setRedirect('/projects');
  }, [loginToken]);

  return <>{redirect && <Redirect to={redirect} />}</>;
};

export default RedirectComponent;
