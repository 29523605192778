import React, { useContext } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';

import closedpolygon from '../../../../../../assets/Marketplace/closedpolygon.svg';
import DrawerLogicalItem from './DrawerLogicalItem';
import { useHistory } from 'react-router-dom';
import CustomCheckBox from '../../../../../common/checkbox/CustomCheckBox';
import { FilterSubSections } from '../../../views/certification-and-super-categories/components/desktopcomponents/sidebar/FilterSidebar';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { Default, FilterSections, NavigatorSource } from '../../../utils/Defaults';
import { Accordion, AccordionSummary } from './DrawerLogicalList';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
  checkboxpadding: {
    paddingLeft: '2px',
  },
}));

const DrawerFiltersLogicalList = ({ data, handleClickAway, handleRouting, source, categoryname }) => {
  const { URLS, filtersData, handleFilterData, handleCategory, handleFilterArr, filterarr } = useContext(ProductsPageContext);
  // const { source } = URLS;
  // const {source}

  const { Primary: marketplacePrimary } = MarketplaceColors;
  const [expanded, setExpanded] = React.useState(true);
  const handleChange = () => setExpanded(!expanded);
  let history = useHistory();
  const classes = useStyles();
  const getCheckboxState = (label, name) => {
    const checkboxData = filtersData.find((d) => d.value === label);
    const value = checkboxData ? checkboxData.checked : false;
    let getdata = false;
    // if (value && !getdata) {
    //   handleFilterArr(name, label, true);
    //   getdata = true;
    // }
    return value;
  };

  const updateData = (label, name, value) => {
    handleFilterArr(name, label, true);
    return value;
  };

  const isFilter = (name) => {
    // const checkboxData = filtersData.find((d) => d.value === label);
    // const value = checkboxData ? checkboxData.checked : false;
    // handleFilterArr(name, label, true);

    return filterarr && filterarr.filter((ele) => ele.name === name && ele.checked === true);
  };
  console.log(filterarr, 'filterarr');
  return (
    <Accordion square expanded={expanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Typography
              style={{ color: marketplacePrimary, fontSize: '16px' }}
              onClick={() => {
                // handleRouting();
                // handleClickAway();
              }}
            >
              {isFilter(data?.attributes?.name).length !== 0 && (
                <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary, paddingRight: '10px' }} />
              )}{' '}
              {data?.attributes?.name}
            </Typography>
          </Grid>
          <Grid item style={{ color: marketplacePrimary }}>
            <img src={closedpolygon} alt="closedpolygon" />
          </Grid>
        </Grid>
      </AccordionSummary>
      <Grid container direction="row" style={{ marginTop: 0, marginLeft: '5%' }}>
        {data?.attributes?.options?.map((option) => {
          return (
            <Grid container className={classes.checkboxpadding}>
              <CustomCheckBox
                handleChange={(e) => {
                  handleCategory(categoryname, option?.option, e.target.checked);
                  handleFilterArr(data?.attributes?.name, option?.option, e.target.checked);
                  handleFilterData(FilterSections.Filters, option?.option, e.target.checked);
                }}
                checkState={getCheckboxState(option?.option, data?.attributes?.name)}
                color={MarketplaceColors.Secondary}
                checkedcolor={MarketplaceColors.Secondary}
                label={option?.option}
                name={data?.attributes?.name}
              />
            </Grid>
          );
        })}
      </Grid>
    </Accordion>
  );
};

export default DrawerFiltersLogicalList;
