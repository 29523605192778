import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MarketplaceColors } from '../config';

const MarketplaceButton = ({ onClick, style, children, className, type }, props) => {
  const { Secondary, CommonWhite } = MarketplaceColors;

  const StyledButton = withStyles({
    root: {
      backgroundColor: Secondary,
      color: CommonWhite,
      borderRadius: '40px',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: Secondary,
        color: CommonWhite,
      },
      fontWeight: 'bold',
    },
  })(Button);

  return (
    <Fragment>
      <StyledButton type={type} fullWidth variant="contained" style={style} className={className} onClick={onClick} {...props}>
        {children}
      </StyledButton>
    </Fragment>
  );
};
export default MarketplaceButton;
