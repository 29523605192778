import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../common/textfield/Fields';

function Addlink(props) {
  const { selectedFile, filename, fileLink, projectFile, setduplicate, setStartUpload, setFileLink } = props;
  return (
    <>
      <Grid xs={12} item direction="column" style={{ marginTop: '2%' }}>
        <Typography variant="h5">Add Link</Typography>
        <Typography variant="h6" color="textSecondary">
          Add a link to Google Drive, Dropbox, etc
        </Typography>
        <Grid item xs={4} style={{ marginTop: '3%' }}>
          {selectedFile || !filename ? (
            <Fields
              id="outlined-basic"
              variant="outlined"
              placeholder="Add URL"
              onChange={(e) => {
                e.preventDefault();
              }}
              fullWidth
              required
              disabled
            />
          ) : (
            <Fields
              id="outlined-basic"
              variant="outlined"
              placeholder="Add URL"
              value={fileLink}
              onChange={(e) => {
                e.preventDefault();
                if (filename) {
                  projectFile.map((files) => {
                    if (files.name.toLowerCase() === filename.toLowerCase()) {
                      setduplicate(true);
                      setStartUpload(false);
                    }
                  });
                }
                setFileLink(e.target.value);
              }}
              fullWidth
              required
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Addlink;
