import { Grid, makeStyles, Typography, withWidth } from '@material-ui/core';
import React from 'react';
import joinwithusimage from '../../../../../../assets/Marketplace/joinwithusimage.svg';
import marketreachicon from '../../../../../../assets/Marketplace/marketreachicon.svg';
import brandreputationicon from '../../../../../../assets/Marketplace/brandreputationicon.svg';
import joinusicon from '../../../../../../assets/Marketplace/joinusicon.svg';
import JoinUsFormMobile from './components/JoinusFormMobile';

const useStyles = makeStyles((theme) => ({
  imagestylemobile: {
    height: '183px',
    width: '183px',
  },
  text1Mobile: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14px',
    letterSpacing: '0.4px',
    textAlign: 'left',
    color: '#213162',
    margin: '2%',
  },
  text2Mobile: {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '12px',
    letterSpacing: '0.4px',
    color: '#33518E',
    margin: '2%',
  },
  textHeadMobile: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14.8px',
    letterSpacing: '0.71px',
    color: '#33518E',
    paddingTop: '5%',
  },
  paraStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '5%',
    paddingBottom: '5%',
    paddingLeft: '3%',
  },
}));

const JoinusMobile = (props) => {
  const classes = useStyles();
  const { joinus, setJoinus } = props;
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" justifyContent="center">
      <Grid container item xs={12} justifyContent="center">
        <Typography className={classes.textHeadMobile} justifyContent="center">
          Become a Partner
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <img src={joinwithusimage} alt="..." className={classes.imagestylemobile} />
      </Grid>
      <Grid container item xs={12} className={classes.paraStyle}>
        <Grid item xs={1}>
          <img src={marketreachicon} alt="..." height="18px" width="18px" />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.text1Mobile}>Expand market reach, increase sales</Typography>
          <Typography className={classes.text2Mobile}>Access a growing customer base seeking sustainable solutions.</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.paraStyle}>
        <Grid item xs={1}>
          <img src={brandreputationicon} alt="..." height="18px" width="18px" />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.text1Mobile}> Enhance brand reputation, differentiate yourself.</Typography>
          <Typography className={classes.text2Mobile}>Stand out as a leader in sustainability, build trust among eco-conscious buyers..</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.paraStyle}>
        <Grid item xs={1}>
          <img src={joinusicon} alt="..." height="18px" width="18px" />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.text1Mobile}> Join us, get discovered today</Typography>
          <Typography className={classes.text2Mobile}>Unlock new business opportunities and visibility in the green building sector.</Typography>
        </Grid>
      </Grid>
      {joinus && <JoinUsFormMobile joinus={joinus} setJoinus={setJoinus} />}
    </Grid>
  );
};

export default withWidth()(JoinusMobile);
