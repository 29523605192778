import React from 'react';
import Header from '../../common/header/Header';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import RequiredFields from './RequiredFields';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginTop: '8%',
    marginLeft: '13%',
    marginRight: '13%',
    padding: theme.spacing(3),
  },
}));

function CreateProjectV2() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <main className={classes.content}>
        <Grid container justify="flex-start">
          <RequiredFields />
        </Grid>
      </main>
    </>
  );
}

export default CreateProjectV2;
