import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesFaucetCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const {
    efficientFixturesFaucet__pi_waterTankerPricePerKL,
    project__pi_userHousekeepingRefOccupancy,
    project__pi_clubhouseRefOccupancy,
    efficientFixturesFaucet_cityEmissionFactor,
    efficientFixturesFaucet_vendorCapex,
    efficientFixturesFaucet__pi_capexMultiplierSource,
    efficientFixturesFaucet__pi_customCapex,
  } = schema;

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader1}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
      <Grid container item xs={12}>
        <Grid container item xs={4} style={{ paddingBottom: '10px' }}>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource) === 'VENDOR' ? (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => updateQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource, '')}
              />
            ) : (
              <RadioButtonUncheckedIcon
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource, 'VENDOR');
                }}
              />
            )}
            {getQuantityLabel(efficientFixturesFaucet_vendorCapex)}
          </Typography>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>{numberWithCommas(getQuantityValue(efficientFixturesFaucet_vendorCapex))} Rs</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={4} style={{ paddingBottom: '10px' }}>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource) === 'CUSTOM' ? (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource, '');
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            {getQuantityLabel(efficientFixturesFaucet__pi_customCapex)}
          </Typography>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(efficientFixturesFaucet__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(efficientFixturesFaucet__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() => updateQuantityValue(efficientFixturesFaucet__pi_customCapex, getQuantityDefaultValue(efficientFixturesFaucet__pi_customCapex))}
                disabled={getQuantityValue(efficientFixturesFaucet__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(efficientFixturesFaucet__pi_customCapex)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Project Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>{getQuantityLabel(efficientFixturesFaucet__pi_waterTankerPricePerKL)}</Typography>
      <Grid item xs={4} direction="column" style={{ paddingTop: '10px' }}>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(efficientFixturesFaucet__pi_waterTankerPricePerKL)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(efficientFixturesFaucet__pi_waterTankerPricePerKL, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                updateQuantityValue(
                  efficientFixturesFaucet__pi_waterTankerPricePerKL,
                  getQuantityDefaultValue(efficientFixturesFaucet__pi_waterTankerPricePerKL)
                );
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(efficientFixturesFaucet__pi_waterTankerPricePerKL)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(project__pi_userHousekeepingRefOccupancy)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(project__pi_userHousekeepingRefOccupancy)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(project__pi_userHousekeepingRefOccupancy, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(project__pi_userHousekeepingRefOccupancy, getQuantityDefaultValue(project__pi_userHousekeepingRefOccupancy));
                }}
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(project__pi_clubhouseRefOccupancy)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(project__pi_clubhouseRefOccupancy)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(project__pi_clubhouseRefOccupancy, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(project__pi_clubhouseRefOccupancy, getQuantityDefaultValue(project__pi_clubhouseRefOccupancy));
                }}
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(efficientFixturesFaucet_cityEmissionFactor)}</Typography>
          </Grid>
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>
              <b>
                {getQuantityValue(efficientFixturesFaucet_cityEmissionFactor)} {getQuantityUnit(efficientFixturesFaucet_cityEmissionFactor)}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesFaucetCustomAdvancedParameters;
