import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesShowerProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesShower_oneBHK,
    efficientFixturesShower_twoBHK,
    efficientFixturesShower_threeBHK,
    efficientFixturesShower_fourBHK,
    efficientFixturesShower_studio,
    efficientFixturesShower_pentHouseOneBhk,
    efficientFixturesShower_pentHouseTwoBhk,
    efficientFixturesShower_pentHouseThreeBhk,
    efficientFixturesShower_pentHouseFourBhk,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesShower_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesShower_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesShower_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesShower_fourBHK);
  const studio = getQuantityValue(efficientFixturesShower_studio);

  const penthouse_onebhk = getQuantityValue(efficientFixturesShower_pentHouseOneBhk);
  const penthouse_twobhk = getQuantityValue(efficientFixturesShower_pentHouseTwoBhk);
  const penthouse_threebhk = getQuantityValue(efficientFixturesShower_pentHouseThreeBhk);
  const penthouse_fourbhk = getQuantityValue(efficientFixturesShower_pentHouseFourBhk);

  const onebhk_label = getQuantityLabel(efficientFixturesShower_oneBHK);
  const twobhk_label = getQuantityLabel(efficientFixturesShower_twoBHK);
  const threebhk_label = getQuantityLabel(efficientFixturesShower_threeBHK);
  const fourbhk_label = getQuantityLabel(efficientFixturesShower_fourBHK);
  const studio_label = getQuantityLabel(efficientFixturesShower_studio);

  const onebhk_unit = getQuantityUnit(efficientFixturesShower_oneBHK);
  const twobhk_unit = getQuantityUnit(efficientFixturesShower_twoBHK);
  const threebhk_unit = getQuantityUnit(efficientFixturesShower_threeBHK);
  const fourbhk_unit = getQuantityUnit(efficientFixturesShower_fourBHK);
  const studio_unit = getQuantityUnit(efficientFixturesShower_studio);

  const penthouse_onebhk_label = getQuantityLabel(efficientFixturesShower_pentHouseOneBhk);
  const penthouse_twobhk_label = getQuantityLabel(efficientFixturesShower_pentHouseTwoBhk);
  const penthouse_threebhk_label = getQuantityLabel(efficientFixturesShower_pentHouseThreeBhk);
  const penthouse_fourbhk_label = getQuantityLabel(efficientFixturesShower_pentHouseFourBhk);

  const penthouse_onebhk_unit = getQuantityUnit(efficientFixturesShower_pentHouseOneBhk);
  const penthouse_twobhk_unit = getQuantityUnit(efficientFixturesShower_pentHouseTwoBhk);
  const penthouse_threebhk_unit = getQuantityUnit(efficientFixturesShower_pentHouseThreeBhk);
  const penthouse_fourbhk_unit = getQuantityUnit(efficientFixturesShower_pentHouseFourBhk);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{studio_label && studio_label}</Typography>
          <Typography className={classes.textHeader3}>
            {studio} {studio_unit && studio_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{onebhk_label && onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {onebhk} {onebhk_unit && onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{twobhk_label && twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {twobhk} {twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {threebhk} {threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {fourbhk} {fourbhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_onebhk} {penthouse_onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_twobhk} {penthouse_twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_threebhk} {penthouse_threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_fourbhk} {penthouse_fourbhk_unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesShowerProjectInputs;
