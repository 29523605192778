import React, { useState } from 'react';
import leftarrow from '../../../../../../assets/carousal/leftarrow.svg';
import rightarrow from '../../../../../../assets/carousal/rightarrow.svg';
import useScreenType from '../../../../../hooks/checkScreenType';
import leftarrowmobile from '../../../../../../assets/carousal/mobileft.svg';
import rightarrowmobile from '../../../../../../assets/carousal/mobileright.svg';
import CarousalMobile from './carousal/CarousalMobile';
import CarousalDesktop from './carousal/CarousalDesktop';
import Features from './FeaturesSpotlight/Feature';
import { makeStyles, withWidth } from '@material-ui/core';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  customContainer: {
    maxWidth: 1440,
    margin: '0 auto',
  },
}));

function MarketplaceCarousal(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const classes = useStyles();

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 25,
    height: 56,
    cursor: 'pointer',
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  return (
    <>
      {isMobile ? (
        <CarousalMobile
          rightarrowmobile={rightarrowmobile}
          leftarrowmobile={leftarrowmobile}
          arrowStyles={arrowStyles}
          updateCurrentSlide={updateCurrentSlide}
          next={next}
          prev={prev}
          currentSlide={currentSlide}
        />
      ) : (
        <CarousalDesktop
          arrowStyles={arrowStyles}
          updateCurrentSlide={updateCurrentSlide}
          next={next}
          prev={prev}
          currentSlide={currentSlide}
          leftarrow={leftarrow}
          rightarrow={rightarrow}
        />
      )}
      <Container className={classes.customContainer}>
        <Features isMobile={isMobile} />
      </Container>
    </>
  );
}

export default withWidth()(MarketplaceCarousal);
