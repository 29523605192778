import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { FilterSections } from '../FilterSidebar';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  texttitle: MarketplaceTypography.BodySamiBold,
  arrowstyle: {
    color: MarketplaceColors.Secondary,
    fontSize: '180%',
  },
  padding: {
    paddingTop: '5%',
  },
}));

const FilterSidebarBrands = (props) => {
  const classes = useStyles();

  const { URLS } = useContext(ProductsPageContext);
  const { source } = URLS;
  const { section, setSection, categories, CheckboxComponent, allbrands, uniqueBrands, userSelectedFirstSection } = props;
  const brandsArr = [];
  categories && categories.map((category) => category?.attributes?.brands?.data.map((brand) => brandsArr.push(brand?.attributes?.name)));

  const handleSection = (section) => (section === FilterSections.Brands ? setSection(FilterSections.NoSection) : setSection(FilterSections.Brands));

  const Brand = (name) => CheckboxComponent(section, name);
  const Brands = (brands) => <>{brands.map((brand) => CheckboxComponent(section, brand))}</>;
  const UniqueBrands = (uniqueBrands) => <>{uniqueBrands.map((brand) => CheckboxComponent(section, brand))}</>;
  const AllBrands = (allbrands) => <>{allbrands.map((brand) => Brand(brand?.attributes?.name))}</>;

  return (
    <Grid container>
      <Typography
        className={`${classes.texttitle} ${classes.padding}`}
        onClick={() => handleSection(section)}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        Brands
        {section === FilterSections.Brands && <ArrowDropUpIcon className={classes.arrowstyle} />}
        {section != FilterSections.Brands && <ArrowDropDownIcon className={classes.arrowstyle} />}
      </Typography>

      {/* brands */}
      {section === FilterSections.Brands && source !== NavigatorSource.ecolabels && userSelectedFirstSection != section
        ? UniqueBrands(uniqueBrands.sort())
        : section === FilterSections.Brands && Brands(brandsArr.sort())}
      {section === FilterSections.Brands && source === NavigatorSource.ecolabels && userSelectedFirstSection === ('Ecolabels' || 'Filters')
        ? UniqueBrands(uniqueBrands.sort())
        : section === FilterSections.Brands && source === NavigatorSource.ecolabels && AllBrands(allbrands)}
      {section === FilterSections.Brands && source === NavigatorSource.search && AllBrands(allbrands)}
    </Grid>
  );
};

export default FilterSidebarBrands;

// const getIsCategorythere = (categoryname) => appliedfilters.find((ele) => ele.value === categoryname);
// if (source === NavigatorSource.supercategories) {
//   //   if (category === null && getIsCategorythere(category?.attributes?.name) === undefined) return CheckboxComponent(section, brand?.attributes?.name);
//   //   else if (category !== null && getIsCategorythere(category?.attributes?.name) !== undefined) return CheckboxComponent(section, brand?.attributes?.name);
//   // } else
//   // if (source === NavigatorSource.categories)
//   return CheckboxComponent(section, brand?.attributes?.name);
// }
