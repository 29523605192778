import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import WaterRounded from '../../../../../assets/new-pdf/WaterRounded.png';
import IntervDataCatd from './IntervDataCard';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';

import rooftopRunoff from '../../../../../assets/new-pdf/rooftop.png';
import surfaceRunoff from '../../../../../assets/new-pdf/surfacerwh.png';
import solarwaterheater from '../../../../../assets/new-pdf/solarwaterheater.png';
import solarPV from '../../../../../assets/new-pdf/solarpv.png';
import smartWaterMeter from '../../../../../assets/new-pdf/smartmeter.png';
import EfficientFixtures from '../../../../../assets/new-pdf/efficientfixture.png';
import dualflush from '../../../../../assets/new-pdf/dualflush.png';
import CentralisedRO from '../../../../../assets/new-pdf/cro.png';
import blackwaterReuse from '../../../../../assets/new-pdf/bwr.png';
import biogasPlant from '../../../../../assets/new-pdf/wetwaste.png';
import greywaterReuse from '../../../../../assets/new-pdf/gwr.png';
import incinaretor from '../../../../../assets/new-pdf/sanitary.png';
import toxicNew from '../../../../../assets/new-pdf/collection.png';

import energycustom from '../../../../../assets/new-pdf/energycustom.png';
import wastecustom from '../../../../../assets/new-pdf/wastecustom.png';
import watercustom from '../../../../../assets/new-pdf/watercustom.png';
import { sum } from 'lodash';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    // padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const SUBCATEGORIES = {
  energy_renewablesources: 'Renewable Sources',
  waste_management: 'Management',
  waste_reduction: 'Reduction',
  water_sourcecreation: 'Source Creation',
  water_demandoptimisation: 'Demand Optimization',
  water_treatmentandresue: 'Treatment And Re-use',
};

const CATEGORIES = {
  energy: 'Energy',
  water: 'Water',
  waste: 'Waste',
};

const INTERVENTIONS = {
  solarpv: 'Solar PV',
  solarwaterheater: 'Solar Water Heater',
  wetwastemanagement: 'Wet Waste Management',
  sanitarynapkinandincinerator: 'Sanitary Napkin Incinerator',
  collectionandsegregationpoint: 'Collection & Segregation Point',
  cro: 'Centralized RO',
  efficientfixtures: 'Efficient Fixtures',
  swm: 'Smart Water Meters',
  dualflush: 'Dual Flush',
  rrwh: 'Rooftop RWH',
  srwh: 'Surface RWH',
  bwr: 'Black Water Re-use',
  gwr: 'Grey Water Re-use',
};

const LABELS = {
  capexpre: 'CAPEX Pre SDplus',
  capexpost: 'CAPEX Post SDplus',
  savings: 'Savings (Annual)',
  capacity: 'System Capacity',
  efkitchenpre: 'EF Kitchensink Pre SD Plus',
  efkitchenpost: 'EF Kitchensink Post SD Plus',
  capacitypre: 'Capacity Pre SD Plus',
  resourcepre: 'Annual Generation Pre SD Plus',
  energy: {
    consumptionpre: 'Annual Baseline Energy Consumption',
    percentage: '% Total Energy Saving',
  },
  waste: {
    endusepre: 'End use Pre SDPlus',
    endusepost: 'End Use',
    wwmanagedbypre: 'WWB Process Pre SD Plus',
    wwmanagedbypost: 'WWB Process Post SD Plus',
    dryWastePre: 'Dry Waste Annual Pre SD Plus',
    drywastepost: 'Dry Waste Annual Post SD Plus',
    ewastepre: 'E Waste Annual Pre SD Plus',
    ewastepost: 'E Waste Annual Post SD Plus',
    mediwastepre: 'Medical Waste Annual Pre SD Plus',
    mediwastepost: 'Medical Waste Annual post SD Plus',
    consumptionpre: 'Annual Baseline Waste Generation',
  },
};

function Improvement(post, pre) {
  let percentage = Math.abs((pre - post) * 100) / pre;
  // ((post - pre) / pre) * 100;
  return isNaN(percentage) ? `${0} %` : `${Math.round(percentage !== Infinity ? percentage : 0)} %`;
}

function findData(category, intervention, label) {
  return category && category?.interventions?.find((ele) => ele?.name === intervention)?.quantities?.details?.find((element) => element?.label === label);
}

function findCategoryData(projectData, category, element) {
  return (
    projectData && projectData?.interventions?.categorized?.filter((data) => data?.name === category)[0].subcategories.find((ele) => ele?.name === element)
  );
}

const Page5 = (props) => {
  const { projectdata } = props;
  let project = projectdata && projectdata;

  const energy_renewablesources = findCategoryData(project, CATEGORIES.energy, SUBCATEGORIES.energy_renewablesources);
  const waste_management = findCategoryData(project, CATEGORIES.waste, SUBCATEGORIES.waste_management);
  const waste_reduction = findCategoryData(project, CATEGORIES.waste, SUBCATEGORIES.waste_reduction);
  const water_sourcecreation = findCategoryData(project, CATEGORIES.water, SUBCATEGORIES.water_sourcecreation);
  const water_demandoptimisation = findCategoryData(project, CATEGORIES.water, SUBCATEGORIES.water_demandoptimisation);
  const water_treatmentandresue = findCategoryData(project, CATEGORIES.water, SUBCATEGORIES.water_treatmentandresue);

  //category
  const energybefore = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS?.energy?.consumptionpre);
  const improvement = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS?.energy?.percentage);
  const wastebefore = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.consumptionpre);
  const waterbefore = findData(water_demandoptimisation, INTERVENTIONS.cro, 'Annual Baseline Water Consumption');

  // energy
  // SPV
  const spv_capexpre = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS.capexpre);
  const spv_capexpost = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS.capexpost);
  const spv_resourcesavingafter = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS.savings);
  const spv_capacitypost = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS.capacity);
  const spv_capacitypre = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS.capacitypre);
  const spv_resourcesavingbefore = findData(energy_renewablesources, INTERVENTIONS.solarpv, LABELS?.resourcepre);

  // SWH
  const swh_capexpre = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS.capexpre);
  const swh_capexpost = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS.capexpost);
  const swh_resourcesavingafter = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS.savings);
  const swh_capacitypost = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS.capacity);
  const swh_capacitypre = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS.capacitypre);
  const swh_resourcesavingbefore = findData(energy_renewablesources, INTERVENTIONS.solarwaterheater, LABELS?.resourcepre);

  // Waste
  // WWM
  const wwm_capexpre = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS.capexpre);
  const wwm_capexpost = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS.capexpost);
  const wwm_systemcapacitypost = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS.capacity);
  const wwm_savingpost = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS.savings);
  const wwm_usedforpre = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS?.waste?.endusepre);
  const wwm_usedforpost = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS?.waste?.endusepost);
  const wwmanagedbypre = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS?.waste?.wwmanagedbypre);
  const wwmanagedbypost = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS?.waste?.wwmanagedbypost);
  const wwm_resourcesavingbefore = findData(waste_reduction, INTERVENTIONS.wetwastemanagement, LABELS?.resourcepre);

  // SNI
  const sni_capexpre = findData(waste_management, INTERVENTIONS.sanitarynapkinandincinerator, LABELS.capexpre);
  const sni_capexpost = findData(waste_management, INTERVENTIONS.sanitarynapkinandincinerator, LABELS.capexpost);
  const sni_systemcapacitypost = findData(waste_management, INTERVENTIONS.sanitarynapkinandincinerator, LABELS.capacity);
  const sni_savingpost = findData(waste_management, INTERVENTIONS.sanitarynapkinandincinerator, LABELS.savings);
  const sni_resourcesavingbefore = findData(waste_management, INTERVENTIONS.sanitarynapkinandincinerator, LABELS?.resourcepre);

  // SASP
  const casp_capexpre = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS.capexpre);
  const casp_capexpost = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS.capexpost);
  const casp_savingpost = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS.savings);
  const casp_drywastepre = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.dryWastePre);
  const casp_drywastepost = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.drywastepost);
  const casp_ewastepre = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.ewastepre);
  const casp_ewastepost = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.ewastepost);
  const casp_mediwastepre = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.mediwastepre);
  const casp_mediwastepost = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.waste?.mediwastepost);
  const casp_savingspre = findData(waste_management, INTERVENTIONS.collectionandsegregationpoint, LABELS?.resourcepre);

  // CRO
  const cro_capexpre = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS.capexpre);
  const cro_capexpost = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS.capexpost);
  const cro_systemcapacitypost = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS.capacity);
  const cro_savingpost = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS.savings);
  const cro_capacitypre = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS.capacitypre);
  const cro_resourcepre = findData(water_demandoptimisation, INTERVENTIONS.cro, LABELS?.resourcepre);

  const RedidentialDrinking = findData(water_demandoptimisation, INTERVENTIONS.cro, 'Redidential Drinking');
  const ResdidentialDomestic = findData(water_demandoptimisation, INTERVENTIONS.cro, 'Resdidential Domestic');
  const Residentialflushing = findData(water_demandoptimisation, INTERVENTIONS.cro, 'Residential flushing');
  const sumOfBaseCase = sum([RedidentialDrinking?.value, ResdidentialDomestic?.value, Residentialflushing?.value]);

  const currentWaterTableResidentialDrinking = findData(water_demandoptimisation, INTERVENTIONS.cro, 'current Water Table Residential Drinking');
  const currentWaterTableResidentialDomestic = findData(water_demandoptimisation, INTERVENTIONS.cro, 'current Water Table Residential Domestic');
  const currentWaterTableResidentialFlushing = findData(water_demandoptimisation, INTERVENTIONS.cro, 'current Water Table Residential Flushing');
  const sumOfRecommendedCase = sum([
    currentWaterTableResidentialDrinking?.value,
    currentWaterTableResidentialDomestic?.value,
    currentWaterTableResidentialFlushing?.value,
  ]);

  // EF
  const ef_capexpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, LABELS.capexpre);
  const ef_capexpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, LABELS.capexpost);
  const ef_kitchensinkpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF Kitchensink Pre SD Plus');
  const ef_kitchensinkpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF Kitchensink Post SD Plus');
  const ef_showerheadpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF shower Pre SD Plus');
  const ef_showerheadpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF shower Post SD Plus');
  const ef_washbasinpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF faucet Pre SD Plus');
  const ef_washbasinpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF faucet Post SD Plus');
  const ef_healthfaucetpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF Health faucet Pre SD Plus');
  const ef_healthfaucetpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'EF Health faucet Post SD Plus');
  const lpcd_ef_pre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'client Base Case Residential Domestic');
  const lpcd_ef_post = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'Efficient Fixtures Case Residential Domestic');
  const ef_savingpre = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, 'ef annual water pre sd plus');
  const ef_savingpost = findData(water_demandoptimisation, INTERVENTIONS.efficientfixtures, LABELS.savings);

  //  DUAL FLUSH
  const df_capexpre = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.capexpre);
  const df_capexpost = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.capexpost);
  const df_FlushingFullWCFlowPreSDplus = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_FlushingFullWCFlow = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_FlushingHalfWCFlowPreSDplus = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_FlushingHalfWCFlow = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_clientBaseCaseResidentialFlushing = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_dualFlushResidentialFlushing = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);
  const df_savingpre = findData(water_demandoptimisation, INTERVENTIONS.dualflush, 'DF Annual Water Pre Sd plus');
  const df_savingpost = findData(water_demandoptimisation, INTERVENTIONS.dualflush, LABELS.savings);

  // SWM
  const swm_capexpre = findData(water_demandoptimisation, INTERVENTIONS.smartWaterMeter, LABELS.capexpre);
  const swm_capexpost = findData(water_demandoptimisation, INTERVENTIONS.smartWaterMeter, LABELS.capexpost);
  const swm_savingpost = findData(water_demandoptimisation, INTERVENTIONS.smartWaterMeter, LABELS.savings);
  const swm_resourcepre = findData(water_demandoptimisation, INTERVENTIONS.smartWaterMeter, LABELS?.resourcepre);

  // rrwh
  const rrwh_capexpre = findData(water_sourcecreation, INTERVENTIONS.rrwh, LABELS.capexpre);
  const rrwh_capexpost = findData(water_sourcecreation, INTERVENTIONS.rrwh, LABELS.capexpost);
  const rrwh_tanksizepre = findData(water_sourcecreation, INTERVENTIONS.rrwh, 'RRWH Tank Size Pre SD Plus');
  const rrwh_tanksizepost = findData(water_sourcecreation, INTERVENTIONS.rrwh, 'Tank Size');
  const rrwh_savingpre = findData(water_sourcecreation, INTERVENTIONS.rrwh, 'RRWH Annual Water Pre SD Plus');
  const rrwh_savingpost = findData(water_sourcecreation, INTERVENTIONS.rrwh, LABELS.savings);
  const rrwh_usedforpost = findData(water_sourcecreation, INTERVENTIONS.rrwh, 'Rainwater Used For');

  // srwh
  const srwh_capexpre = findData(water_sourcecreation, INTERVENTIONS.srwh, LABELS.capexpre);
  const srwh_capexpost = findData(water_sourcecreation, INTERVENTIONS.srwh, LABELS.capexpost);
  const srwh_tanksizepost = findData(water_sourcecreation, INTERVENTIONS.srwh, 'Tank Size');
  const srwh_savingpre = findData(water_sourcecreation, INTERVENTIONS.srwh, 'SRWH Annual Water Pre SD Plus');
  const srwh_savingpost = findData(water_sourcecreation, INTERVENTIONS.srwh, LABELS.savings);
  const srwh_usedforpost = findData(water_sourcecreation, INTERVENTIONS.srwh, 'Rainwater Used For');

  // bwr
  const bwr_capexpre = findData(water_treatmentandresue, INTERVENTIONS.bwr, LABELS.capexpre);
  const bwr_capexpost = findData(water_treatmentandresue, INTERVENTIONS.bwr, LABELS.capexpost);
  const bwr_savingpre = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'BWR Annual Water Pre SD Plus');
  const bwr_savingpost = findData(water_treatmentandresue, INTERVENTIONS.bwr, LABELS.savings);
  const bwr_CoSS_Before = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Combined Or Separate Black Water STP Before');
  const bwr_CoSS_After = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Combined Or Separate Black Water STP After');
  const bwr_DualPiping_Before = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Dual Piping Before');
  const bwr_DualPiping_After = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Dual Piping After');
  const bwr_TWUF_Before = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Reuse Of Treated Water Before');
  const bwr_TWUF_After = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Reuse Of Treated Water After');
  const bwr_TTR_After = findData(water_treatmentandresue, INTERVENTIONS.bwr, 'Tertiary Treatment Required After');

  // gwr
  const gwr_capexpre = findData(water_treatmentandresue, INTERVENTIONS.gwr, LABELS.capexpre);
  const gwr_capexpost = findData(water_treatmentandresue, INTERVENTIONS.gwr, LABELS.capexpost);
  const gwr_usedforpost = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Grey Water Reuse Towards');
  const gwr_savingpost = findData(water_treatmentandresue, INTERVENTIONS.gwr, LABELS.savings);
  const gwr_savingpre = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'GWR Annual Water Pre SD plus');
  const gwr_CoSS_Before = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Combined Or Separate Black Water STP Before');
  const gwr_CoSS_After = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Combined Or Separate Black Water STP After');
  const gwr_DualPiping_Before = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Dual Piping Before');
  const gwr_DualPiping_After = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Dual Piping After');
  const gwr_TWUF_Before = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Reuse Of Treated Water Before');
  const gwr_TTR_After = findData(water_treatmentandresue, INTERVENTIONS.gwr, 'Tertiary Treatment Required After');

  const Energy = [
    {
      intervention: INTERVENTIONS.solarpv,
      icon: solarPV,
      data: [
        {
          name: 'Capacity',
          before: numberWithCommas(spv_capacitypre && spv_capacitypre?.value),
          after: numberWithCommas(spv_capacitypost && spv_capacitypost.value),
          Improvement: Improvement(spv_capacitypost.value, spv_capacitypre?.value),
          unit: 'kWp',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', spv_capexpre && spv_capexpre?.value),
          after: rsToCr('RS', spv_capexpost && spv_capexpost?.value),
          Improvement: Improvement(spv_capexpost?.value, spv_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(spv_resourcesavingbefore && spv_resourcesavingbefore?.value),
          after: numberWithCommas(spv_resourcesavingafter && spv_resourcesavingafter?.value),
          Improvement: Improvement(spv_resourcesavingafter?.value, spv_resourcesavingbefore?.value),
          unit: 'kWh',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.solarwaterheater,
      icon: solarwaterheater,
      data: [
        {
          name: 'Capacity',
          before: numberWithCommas(swh_capacitypre && swh_capacitypre?.value),
          after: numberWithCommas(swh_capacitypost && swh_capacitypost?.value),
          Improvement: Improvement(swh_capacitypost?.value, swh_capacitypre?.value),
          unit: 'LPD',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', swh_capexpre && swh_capexpre?.value),
          after: rsToCr('Rs', swh_capexpost && swh_capexpost?.value),
          Improvement: Improvement(swh_capexpost?.value, swh_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(swh_resourcesavingbefore && swh_resourcesavingbefore?.value),
          after: numberWithCommas(swh_resourcesavingafter && swh_resourcesavingafter?.value),
          Improvement: Improvement(swh_resourcesavingafter?.value, swh_resourcesavingbefore?.value),
          unit: 'kWh',
        },
      ],
    },
  ];

  const Waste = [
    {
      intervention: INTERVENTIONS.wetwastemanagement,
      icon: biogasPlant,
      data: [
        {
          name: 'Wet Waste Used for ',
          before: wwm_usedforpre && wwm_usedforpre?.value,
          after: wwm_usedforpost && wwm_usedforpost?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Wet Waste Managed By ',
          before: wwmanagedbypre && wwmanagedbypre?.value,
          after: wwmanagedbypost && wwmanagedbypost?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Capacity',
          before: numberWithCommas(wwm_systemcapacitypost && wwm_systemcapacitypost?.value),
          after: numberWithCommas(wwm_systemcapacitypost && wwm_systemcapacitypost?.value),
          Improvement: Improvement(wwm_systemcapacitypost?.value, wwm_systemcapacitypost?.value),
          unit: 'kg',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', wwm_capexpre && wwm_capexpre?.value),
          after: rsToCr('Rs', wwm_capexpost && wwm_capexpost?.value),
          Improvement: Improvement(wwm_capexpost?.value, wwm_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(wwm_resourcesavingbefore && wwm_resourcesavingbefore?.value),
          after: numberWithCommas(wwm_savingpost && wwm_savingpost?.value),
          Improvement: Improvement(wwm_savingpost?.value, wwm_resourcesavingbefore?.value),
          unit: 'kg',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.sanitarynapkinandincinerator,
      icon: incinaretor,
      data: [
        {
          name: 'Capacity',
          before: '-',
          after: numberWithCommas(sni_systemcapacitypost && sni_systemcapacitypost?.value),
          Improvement: Improvement(sni_systemcapacitypost?.value, sni_systemcapacitypost?.value),
          unit: 'kg',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', sni_capexpre && sni_capexpre?.value),
          after: rsToCr('Rs', sni_capexpost && sni_capexpost?.value),
          Improvement: Improvement(sni_capexpost?.value, sni_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(sni_resourcesavingbefore && sni_resourcesavingbefore?.value),
          after: numberWithCommas(sni_savingpost && sni_savingpost?.value),
          Improvement: Improvement(sni_savingpost?.value, sni_resourcesavingbefore?.value),
          unit: 'kg',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.collectionandsegregationpoint,
      icon: toxicNew,
      data: [
        {
          name: 'Dry Waste sustainably managed',
          before: numberWithCommas(casp_drywastepre && casp_drywastepre?.value),
          after: numberWithCommas(casp_drywastepost && casp_drywastepost?.value),
          Improvement: Improvement(casp_drywastepost?.value, casp_drywastepre?.value),
          unit: 'kg',
        },
        {
          name: 'E-Waste sustainably managed',
          before: numberWithCommas(casp_ewastepre && casp_ewastepre?.value),
          after: numberWithCommas(casp_ewastepost && casp_ewastepost?.value),
          Improvement: Improvement(casp_ewastepost?.value, casp_ewastepre?.value),
          unit: 'kg',
        },
        {
          name: 'Medical Waste sustainably managed',
          before: numberWithCommas(casp_mediwastepre && casp_mediwastepre?.value),
          after: numberWithCommas(casp_mediwastepost && casp_mediwastepost?.value),
          Improvement: Improvement(casp_mediwastepost?.value, casp_mediwastepre?.value),
          unit: 'kg',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', casp_capexpre && casp_capexpre?.value),
          after: rsToCr('Rs', casp_capexpost && casp_capexpost?.value),
          Improvement: Improvement(casp_capexpost?.value, casp_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: casp_savingspre && casp_savingspre?.value,
          after: numberWithCommas(casp_savingpost && casp_savingpost?.value),
          Improvement: Improvement(casp_savingpost && casp_savingpost?.value, casp_savingspre?.value),
          unit: 'kg',
        },
      ],
    },
  ];

  const Water = [
    {
      intervention: INTERVENTIONS.cro,
      icon: CentralisedRO,
      data: [
        {
          name: 'Capacity',
          before: cro_capacitypre && cro_capacitypre?.value,
          after: numberWithCommas(cro_systemcapacitypost && cro_systemcapacitypost?.value),
          Improvement: Improvement(cro_systemcapacitypost && cro_systemcapacitypost?.value, cro_capacitypre && cro_capacitypre?.value),
          unit: 'kL',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', cro_capexpre && cro_capexpre?.value),
          after: rsToCr('Rs', cro_capexpost && cro_capexpost?.value),
          Improvement: Improvement(cro_capexpost?.value, cro_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(cro_resourcepre && cro_resourcepre?.value),
          after: numberWithCommas(cro_savingpost && cro_savingpost?.value),
          Improvement: Improvement(cro_savingpost?.value, cro_resourcepre?.value),
          unit: 'kL',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.efficientfixtures,
      icon: EfficientFixtures,
      data: [
        {
          name: 'Kitchen Sink',
          before: numberWithCommas(ef_kitchensinkpre && ef_kitchensinkpre?.value),
          after: numberWithCommas(ef_kitchensinkpost && ef_kitchensinkpost?.value),
          Improvement: Improvement(ef_kitchensinkpost?.value, ef_kitchensinkpre?.value),
          unit: 'lpm',
        },
        {
          name: 'Shower Head',
          before: numberWithCommas(ef_showerheadpre && ef_showerheadpre?.value),
          after: numberWithCommas(ef_showerheadpost && ef_showerheadpost?.value),
          Improvement: Improvement(ef_showerheadpost?.value, ef_showerheadpre?.value),
          unit: 'lpm',
        },
        {
          name: 'Wash Basin Faucet',
          before: numberWithCommas(ef_washbasinpre && ef_washbasinpre?.value),
          after: numberWithCommas(ef_washbasinpost && ef_washbasinpost?.value),
          Improvement: Improvement(ef_washbasinpost?.value, ef_washbasinpre?.value),
          unit: 'lpm',
        },
        {
          name: 'Health Faucet',
          before: numberWithCommas(ef_healthfaucetpre && ef_healthfaucetpre?.value),
          after: numberWithCommas(ef_healthfaucetpost && ef_healthfaucetpost?.value),
          Improvement: Improvement(ef_healthfaucetpost?.value, ef_healthfaucetpre?.value),
          unit: 'lpm',
        },
        {
          name: 'LPCD of EF',
          before: numberWithCommas(lpcd_ef_pre && lpcd_ef_pre?.value),
          after: numberWithCommas(lpcd_ef_post && lpcd_ef_post?.value),
          Improvement: Improvement(lpcd_ef_post?.value, lpcd_ef_pre?.value),
          unit: '-',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', ef_capexpre && ef_capexpre?.value),
          after: rsToCr('Rs', ef_capexpost && ef_capexpost?.value),
          Improvement: Improvement(ef_capexpost?.value, ef_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: ef_savingpre && ef_savingpre?.value,
          after: ef_savingpost && ef_savingpost?.value,
          Improvement: Improvement(ef_savingpost?.value, ef_savingpre?.value),
          unit: 'Kg',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.dualflush,
      icon: dualflush,
      data: [
        {
          name: 'Full Flush',
          before: numberWithCommas(df_FlushingFullWCFlowPreSDplus && df_FlushingFullWCFlowPreSDplus?.value),
          after: numberWithCommas(df_FlushingFullWCFlow && df_FlushingFullWCFlow?.value),
          Improvement: Improvement(df_FlushingFullWCFlow?.value, df_FlushingFullWCFlowPreSDplus?.value),
          unit: '-',
        },
        {
          name: 'Half Flush',
          before: numberWithCommas(df_FlushingHalfWCFlowPreSDplus && df_FlushingHalfWCFlowPreSDplus?.value),
          after: numberWithCommas(df_FlushingHalfWCFlow && df_FlushingHalfWCFlow?.value),
          Improvement: Improvement(df_FlushingHalfWCFlowPreSDplus?.value, df_FlushingHalfWCFlow?.value),
          unit: '-',
        },
        {
          name: 'LPCD of DF',
          before: numberWithCommas(df_clientBaseCaseResidentialFlushing && df_clientBaseCaseResidentialFlushing?.value),
          after: numberWithCommas(df_dualFlushResidentialFlushing && df_dualFlushResidentialFlushing?.value),
          Improvement: Improvement(df_clientBaseCaseResidentialFlushing?.value, df_dualFlushResidentialFlushing?.value),
          unit: '-',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', df_capexpre && df_capexpre?.value),
          after: rsToCr('Rs', df_capexpost && df_capexpost?.value),
          Improvement: Improvement(df_capexpost?.value, df_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(df_savingpre && df_savingpre?.value),
          after: numberWithCommas(df_savingpost && df_savingpost?.value),
          Improvement: Improvement(df_savingpost?.value, df_savingpost?.value),
          unit: 'kL',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.swm,
      icon: smartWaterMeter,
      data: [
        {
          name: 'CAPEX',
          before: rsToCr('Rs', swm_capexpre && swm_capexpre?.value),
          after: rsToCr('Rs', swm_capexpost && swm_capexpost?.value),
          Improvement: Improvement(swm_capexpost?.value, swm_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(swm_resourcepre && swm_resourcepre?.value),
          after: numberWithCommas(swm_savingpost && swm_savingpost?.value),
          Improvement: Improvement(swm_savingpost?.value, swm_resourcepre?.value),
          unit: 'kL',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.rrwh,
      icon: rooftopRunoff,
      data: [
        {
          name: 'Tank Size',
          before: numberWithCommas(rrwh_tanksizepre && rrwh_tanksizepre?.value),
          after: numberWithCommas(rrwh_tanksizepost && rrwh_tanksizepost?.value),
          Improvement: Improvement(rrwh_tanksizepost?.value, rrwh_tanksizepre?.value),
          unit: 'kL',
        },
        {
          name: 'Mandated Tank Size',
          before: numberWithCommas(rrwh_tanksizepre && rrwh_tanksizepre?.value),
          after: '-',
          Improvement: Improvement(0, rrwh_tanksizepre?.value),
          unit: 'kL',
        },
        {
          name: 'Harvested Rainwater used for (Domestic or Drinking)',
          before: '-',
          after: numberWithCommas(rrwh_usedforpost && rrwh_usedforpost?.value),
          Improvement: Improvement(rrwh_usedforpost?.value, rrwh_tanksizepre?.value),
          unit: 'kL',
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', rrwh_capexpre && rrwh_capexpre?.value),
          after: rsToCr('Rs', rrwh_capexpost && rrwh_capexpost?.value),
          Improvement: Improvement(rrwh_capexpost?.value, rrwh_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(rrwh_savingpre && rrwh_savingpre?.value),
          after: numberWithCommas(rrwh_savingpost && rrwh_savingpost?.value),
          Improvement: Improvement(rrwh_savingpost?.value, rrwh_savingpre?.value),
          unit: 'Cr',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.srwh,
      icon: surfaceRunoff,
      data: [
        {
          name: 'Tank Size',
          before: '-',
          after: numberWithCommas(srwh_tanksizepost && srwh_tanksizepost?.value),
          Improvement: Improvement(srwh_tanksizepost?.value, srwh_tanksizepost?.value),
          unit: 'kL',
        },
        { name: 'Mandated Tank Size', before: '-', after: '-', Improvement: '-', unit: '-' },
        {
          name: 'Harvested Rainwater used for (Domestic or Drinking)',
          before: '-',
          after: srwh_usedforpost && srwh_usedforpost?.value,
          Improvement: '-',
          unit: '-',
        },

        {
          name: 'CAPEX',
          before: rsToCr('Rs', srwh_capexpre && srwh_capexpre?.value),
          after: rsToCr('Rs', srwh_capexpost && srwh_capexpost?.value),
          Improvement: Improvement(srwh_capexpost?.value, srwh_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(srwh_savingpre && srwh_savingpre?.value),
          after: numberWithCommas(srwh_savingpost && srwh_savingpost?.value),
          Improvement: Improvement(srwh_savingpost?.value, srwh_savingpre?.value),
          unit: 'kL',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.bwr,
      icon: blackwaterReuse,
      data: [
        {
          name: 'Combined or Separate STP',
          before: bwr_CoSS_Before && bwr_CoSS_Before?.value,
          after: bwr_CoSS_After && bwr_CoSS_After?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Dual Piping',
          before: bwr_DualPiping_Before && bwr_DualPiping_Before?.value,
          after: bwr_DualPiping_After && bwr_DualPiping_After?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Treated Water used for ',
          before: bwr_TWUF_Before && bwr_TWUF_Before?.value,
          after: bwr_TWUF_After && bwr_TWUF_After?.value,
          Improvement: '-',
          unit: '-',
        },
        { name: 'Tertiary Treatment required?', before: '-', after: bwr_TTR_After && bwr_TTR_After?.value, Improvement: '-', unit: '-' },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', bwr_capexpre && bwr_capexpre?.value),
          after: rsToCr('Rs', bwr_capexpost && bwr_capexpost?.value),
          Improvement: Improvement(bwr_capexpost?.value, bwr_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(bwr_savingpre && bwr_savingpre?.value),
          after: numberWithCommas(bwr_savingpost && bwr_savingpost?.value),
          Improvement: Improvement(bwr_savingpost?.value, bwr_savingpre?.value),
          unit: 'kL',
        },
      ],
    },
    {
      intervention: INTERVENTIONS.gwr,
      icon: greywaterReuse,
      data: [
        {
          name: 'Combined or Separate STP',
          before: gwr_CoSS_Before && gwr_CoSS_Before?.value,
          after: gwr_CoSS_After && gwr_CoSS_After?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Dual Piping',
          before: gwr_DualPiping_Before && gwr_DualPiping_Before?.value,
          after: gwr_DualPiping_After && gwr_DualPiping_After?.value,
          Improvement: '-',
          unit: '-',
        },
        {
          name: 'Treated Water used for ',
          before: gwr_TWUF_Before && gwr_TWUF_Before?.value,
          after: gwr_usedforpost && gwr_usedforpost?.value,
          Improvement: '-',
          unit: '-',
        },
        { name: 'Tertiary Treatment required?', before: '-', after: gwr_TTR_After && gwr_TTR_After?.value, Improvement: '-', unit: '-' },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', gwr_capexpre && gwr_capexpre?.value),
          after: rsToCr('Rs', gwr_capexpost && gwr_capexpost?.value),
          Improvement: Improvement(gwr_capexpost?.value, gwr_capexpre?.value),
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(gwr_savingpre && gwr_savingpre?.value),
          after: numberWithCommas(gwr_savingpost && gwr_savingpost?.value),
          Improvement: Improvement(gwr_savingpost?.value, gwr_savingpre?.value),
          unit: 'kL',
        },
      ],
    },
  ];

  const overallEnergy = props?.totalressaving && energybefore ? energybefore?.value - props?.totalressaving?.value : 0;
  return (
    <View>
      <View style={{ backgroundColor: '#15B7B9', height: '201px' }}>
        <View style={{ padding: '30px 20px 10px 20px' }}>
          <Header companyname={props.companyname} />
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '5px', width: '50%' }}>
            <Text
              style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}
            >{`SD+ ${props.category} impact`}</Text>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>Summary</Text>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '20px', color: '#FFFFFF', paddingTop: '15px' }}>{`${numberWithCommas(
              props.totalressaving && props.totalressaving.value
            )} ${props.totalressaving && props.totalressaving.unit} Annual Savings`}</Text>
          </View>
          <View style={{ width: '20%' }} />
          <View style={{ width: '20%' }}>
            <Image style={{ height: '100px', width: '100px' }} src={props.headingIcon} />
          </View>
        </View>
      </View>
      <View style={styles.viewPage2}>
        <View style={{ border: '0.5625px solid #9E9E9E', height: '83px', flexDirection: 'row', display: 'flex', marginTop: '10px', borderRadius: '10px' }}>
          <View style={{ paddingTop: '35px', paddingLeft: '10px', width: '40%' }}>
            {props.category === 'Energy' && <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px' }}>Overall Energy Consumption</Text>}
            {props.category === 'Waste' && <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px' }}>Overall Waste Consumption</Text>}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px' }}>Overall Water Consumption</Text>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>Liters per Capita per Day (LPCD)</Text>
              </>
            )}
          </View>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', paddingLeft: '10px', width: '30%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>Before</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                energybefore && energybefore?.value
              )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                wastebefore && wastebefore?.value
              )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  waterbefore && waterbefore?.value
                )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  sumOfBaseCase && sumOfBaseCase
                )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
              </>
            )}
          </View>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', paddingLeft: '10px', width: '30%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>After</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                overallEnergy && overallEnergy
              )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                props.totalressaving && props.totalressaving.value
              )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  props.totalressaving && props.totalressaving.value
                )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
                <Text style={{ color: '#666666', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  sumOfRecommendedCase && sumOfRecommendedCase
                )} ${props.totalressaving && props.totalressaving.unit}`}</Text>
              </>
            )}
          </View>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', paddingLeft: '10px', width: '30%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>Improvement</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: '#F28D14', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>{improvement && improvement?.value} %</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: '#BC8A5F', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>
                {Improvement(props.totalressaving && props.totalressaving.value, wastebefore && wastebefore?.value)}
              </Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#3BC7EE', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>
                  {Improvement(props.totalressaving && props.totalressaving.value, waterbefore && waterbefore?.value)}
                </Text>
                <Text style={{ color: '#3BC7EE', fontFamily: 'Open Sans', fontSize: '9px', paddingTop: '10px' }}>
                  {Improvement(sumOfRecommendedCase && sumOfRecommendedCase, sumOfBaseCase && sumOfBaseCase)}
                </Text>
              </>
            )}
          </View>
        </View>
        {/* common card */}
        {props.category === 'Energy' && Energy.map((d) => <IntervDataCatd waterdata={d} border={props.border} color={props.color} icon={d.icon} />)}
        {props.category === 'Waste' && Waste.map((d) => <IntervDataCatd waterdata={d} border={props.border} color={props.color} icon={d.icon} />)}
        {props.category === 'Water' && (
          <>
            <IntervDataCatd waterdata={Water[0]} border={props.border} color={props.color} icon={Water[0].icon} />
            <IntervDataCatd waterdata={Water[1]} border={props.border} color={props.color} icon={Water[0].icon} />
            <IntervDataCatd waterdata={Water[3]} border={props.border} color={props.color} icon={Water[0].icon} />
            <View style={{ marginTop: '50px', marginBottom: '50px' }}>
              <Footer year={props.year} />
            </View>
            <View style={{ padding: '30px 20px 10px 20px' }}>
              <Header companyname={props.companyname} />
            </View>
            <IntervDataCatd waterdata={Water[2]} border={props.border} color={props.color} icon={Water[0].icon} />
            <IntervDataCatd waterdata={Water[4]} border={props.border} color={props.color} icon={Water[0].icon} />
            <IntervDataCatd waterdata={Water[5]} border={props.border} color={props.color} icon={Water[0].icon} />
            <View style={{ marginTop: '30%', marginBottom: '50px' }}>
              <Footer year={props.year} />
            </View>
            <View style={{ padding: '30px 20px 10px 20px' }}>
              <Header companyname={props.companyname} />
            </View>
            <IntervDataCatd waterdata={Water[6]} border={props.border} color={props.color} icon={Water[0].icon} />
            <IntervDataCatd waterdata={Water[7]} border={props.border} color={props.color} icon={Water[0].icon} />
          </>
        )}
      </View>
      {props.category === 'Energy' ? (
        <View style={{ marginTop: '30%' }}>
          <Footer year={props.year} />
        </View>
      ) : (
        ''
      )}
      {props.category === 'Waste' ? (
        <View style={{ marginTop: '15%' }}>
          <Footer year={props.year} />
        </View>
      ) : (
        ''
      )}
      {props.category === 'Water' ? (
        <View style={{ marginTop: '45%' }}>
          <Footer year={props.year} />
        </View>
      ) : (
        ''
      )}
    </View>
  );
};

export default Page5;
