import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const CasaSkeleton = withStyles((theme) => ({
    root: {
        borderRadius: 10,
        marginLeft: 5,
        marginRight: 5,
    },
}))(Skeleton);

export default CasaSkeleton