import React, { useEffect } from 'react';
import Routes from './routing/IndexRouting';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import defaultmode from './config/themeconfig';
import { Container } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { GlobalDebug } from './utils/removeConsoles';

let theme = createTheme(defaultmode());
theme = responsiveFontSizes(theme);

function App() {
  useEffect(() => {
    /* when running on development to see your console.logs change NODE_ENV === 'development' and  GlobalDebug(true) */
    if (process.env.NODE_ENV === 'development') {
      GlobalDebug(true);
      console.log('I am just another dummy console log, suppose to be suppressed');
    }
  }, []);
  return (
    <>
      <Container maxWidth={false} disableGutters={true}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Container>
    </>
  );
}

export default App;
