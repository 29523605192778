import React from 'react';

export const AllCementIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={33} height={46} fill="none" {...props}>
        <g fill={color}>
          <path d="M1.452 7.156C.422 5.05.01 2.799.289.786a.91.91 0 0 1 .9-.786h29.66a.91.91 0 0 1 .901.786c.28 2.014-.134 4.265-1.162 6.37a84.488 84.488 0 0 1 0 31.19c1.028 2.103 1.441 4.354 1.162 6.369a.91.91 0 0 1-.901.785H1.189a.91.91 0 0 1-.9-.786c-.28-2.013.133-4.264 1.162-6.37a84.498 84.498 0 0 1 0-31.189Zm28.56 36.524c.04-1.376-.304-2.859-.964-4.291h-3.996a.91.91 0 1 1 0-1.82h3.813a82.629 82.629 0 0 0 0-29.637h-3.813a.91.91 0 1 1 0-1.82h3.997c.659-1.433 1.003-2.915.964-4.292H2.025c-.04 1.376.305 2.859.965 4.292h3.997a.91.91 0 1 1 0 1.82H3.174a82.628 82.628 0 0 0 0 29.637h3.813a.91.91 0 1 1 0 1.82H2.99c-.66 1.433-1.005 2.915-.965 4.291h27.988Z" />
          <path d="M4.898 26.301a.91.91 0 0 1 .91-.91h20.425a.91.91 0 1 1 0 1.82H5.808a.91.91 0 0 1-.91-.91ZM26.233 20.11H5.808a.91.91 0 1 1 0-1.82h20.425a.91.91 0 1 1 0 1.82Z" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={65} height={91} fill="none" {...props}>
        <g fill={color}>
          <path d="M2.903 14.311C.845 10.1.02 5.6.577 1.571A1.819 1.819 0 0 1 2.38 0h59.319A1.82 1.82 0 0 1 63.5 1.571c.559 4.03-.268 8.531-2.325 12.74a168.977 168.977 0 0 1 0 62.38c2.057 4.208 2.884 8.709 2.325 12.738A1.82 1.82 0 0 1 61.698 91H2.378a1.82 1.82 0 0 1-1.802-1.571c-.558-4.028.268-8.53 2.327-12.74a168.995 168.995 0 0 1 0-62.378Zm57.122 73.05c.08-2.753-.61-5.719-1.928-8.583h-7.992a1.82 1.82 0 1 1 0-3.64h7.625a165.253 165.253 0 0 0 0-59.275h-7.625a1.82 1.82 0 1 1 0-3.639h7.992c1.318-2.866 2.008-5.831 1.928-8.584H4.05c-.08 2.751.611 5.717 1.93 8.583h7.993a1.82 1.82 0 1 1 0 3.64H6.347a165.256 165.256 0 0 0 0 59.274h7.626a1.82 1.82 0 1 1 0 3.64H5.98c-1.32 2.866-2.01 5.832-1.93 8.584h55.975Z" />
          <path d="M9.797 52.606a1.82 1.82 0 0 1 1.82-1.82h40.849a1.82 1.82 0 1 1 0 3.64h-40.85a1.82 1.82 0 0 1-1.82-1.82ZM52.458 40.219h-40.85a1.82 1.82 0 1 1 0-3.64h40.85a1.82 1.82 0 1 1 0 3.64Z" />
        </g>
      </svg>
    );
  }
};
