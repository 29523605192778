import React from 'react';

export const AllGlazingIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={45} fill="none" {...props}>
        <path
          fill={color}
          stroke={color}
          strokeWidth={0.5}
          d="M32.115 27.633h.25V2.239H18.211v25.394h13.904ZM16.212 2.489v-.25H2.25v25.394h13.962V2.489ZM2.25 42.702v.25h30.115v-13.33H2.25v13.08ZM1.154.25h32.308c.206 0 .44.105.683.303.222.18.43.422.605.673V43.66c0 .627-.464 1.09-1.096 1.09H1.346c-.347 0-.622-.087-.806-.233-.175-.14-.29-.353-.29-.666V1.15C.25.943.356.717.537.536.718.356.947.25 1.154.25Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
        <path
          fill={color}
          d="M16.133 5.5v89h67.74v-89h-67.74Zm64.74 43.24h-30.34V8.5h30.34v40.24ZM19.133 8.5h28.4v83h-28.4v-83Zm31.4 83V51.74h30.34V91.5h-30.34Z"
        />
      </svg>
    );
  }
};
