import React from 'react';
import { Grid } from '@material-ui/core';

function RightArrow({ rightarrow, next, arrowStyles, imageStyles }, props) {
  return (
      <img src={rightarrow} alt="..." onClick={next} style={{opacity:1,...imageStyles}} />
  );
}

export default RightArrow;
