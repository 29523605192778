export const initialState = { response: null };

const reducer = (state, action) => {
  switch (action.type) {
    case 'success':
      return { ...state, response: action.response };
    case 'failure':
      return { ...state, response: action.error };
    default:
      return state;
  }
};

export default reducer;
