import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { types } from '../../MaterialSelectionGuideData';
import ImageWithSource from './ImageWithSource';
import BasicTable from './Table';
import { MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

function createData(name, a, b, c, d) {
  return { name, a, b, c, d };
}
const rows = [
  createData('Global Warming Potential (GWP) (kg CO2-Eq)', 0.91, 0.69, 0.64, `540.793`),
  createData('Ozone Layer Depletion Potential (ODP) (kg CFC11-Eq)', 2.8e-12, 2.0e-13, 1.9e-12, `8.91e-11`),
  createData('Acidification Potential (AP) (kg SO2-Eq)', 0.0027, 0.11, 0.002, `2.64`),
  createData('Eutrophication Potential (EP) (kg PO4 eq)', 3.4e-4, 5.9e-4, 2.4e-4, `2.23E-04 (kg Phosphate eq.)*`),
  createData('Photochemical Ozone Creation Potential (POCP) (kg Ethene-Eq)', 1.09e-4, 5.0e-4, 1.3e-4, `1.95E+00 (kg NMVOC-eq_*`),
  createData(
    'SDG',
    `SDG 11 -Sustainable Cities and Communities`,
    `SDG 11 -Sustainable Cities and Communities`,
    `SDG 11 -Sustainable Cities and Communities`,
    `SDG 11 -Sustainable Cities and Communities`
  ),
];
const tableHeaders = [
  'Environmental Impacts',
  'Ordinary Portland Cement (OPC)',
  'Portland Slag Cement (PSC)',
  'Portland Pozzolana Cement (PPC)',
  'Portland Composite Cement (PCC)',
];

const TypesOfCement = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-start">
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
        <Typography variant="h5">
          <b>{data.title}</b>
        </Typography>
      </Grid>
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
        <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
          {data.description}
        </Typography>
      </Grid>
      {data?.data.map((d, index) => {
        return (
          <>
            {d.type === types.text && index === 0 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title} : </b>
                  {d.text}
                </Typography>
              </Grid>
            )}
            {d.type === types.text && index === 1 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  {d.text}
                </Typography>
              </Grid>
            )}
            <ul style={{ margin: 0 }}>
              {d.type === types.points &&
                index === 2 &&
                d.points.map((point) => {
                  return (
                    <li>
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        <b>{point.title} : </b>
                        {point.text}
                      </Typography>
                    </li>
                  );
                })}
            </ul>
            {d.type === types.text && index === 3 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title} : </b>
                  {d.text}
                </Typography>
              </Grid>
            )}
            {d.type === types.text && index === 4 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title} : </b>
                  {d.text}
                </Typography>
              </Grid>
            )}
            {d.type === types.text && index === 5 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title} : </b>
                  {d.text}
                </Typography>
              </Grid>
            )}
            {d.type === types.image && index === 6 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '94%' }} />
              </Grid>
            )}
            {d.type === types.heading && index === 7 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography variant="h5">
                  <b>{d.title}</b>
                </Typography>
              </Grid>
            )}
            {d.type === types.points && index === 8 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title}</b>
                </Typography>
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.points.map((point) => (
                    <Grid item container alignItems="flex-start" justifyContent="flex-start">
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        <b>{point.title} : </b>
                        {point.text}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {d.type === types.points && index === 9 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title}</b>
                </Typography>
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.points.map((point) => (
                    <Grid item container alignItems="flex-start" justifyContent="flex-start">
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        <b>{point.title} : </b>
                        {point.text}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {d.type === types.image && index === 10 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <ImageWithSource image={d.image} source={d.source} imageStyle={{ width: '100%' }} />
              </Grid>
            )}
            {d.type === types.heading && index === 11 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                <Typography variant="h5">
                  <b>{d.title}</b>
                </Typography>
              </Grid>
            )}
            {d.type === types.points && index === 12 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
                <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                  <b>{d.title}</b>
                </Typography>
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  {d.points.map((point) => (
                    <>
                      {point.icon && (
                        <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
                          <ImageWithSource image={point.icon} source={null} justifyContent="flex-start" />
                        </Grid>
                      )}
                      <Grid item container alignItems="flex-start" justifyContent="flex-start">
                        {point.icon ? (
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                            <b>{point.title} : </b>
                            {point.text}
                          </Typography>
                        ) : (
                          <Typography className={classes.textstyle} style={{ textAlign: 'justify', marginTop: '2%' }}>
                            {point.text}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
            )}
            {d.type === types.points && index === 12 && (
              <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '2%' }}>
                <BasicTable rows={rows} tableHeaders={tableHeaders} />
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default TypesOfCement;
