import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Heading from './Heading';
import Header from '../../../common/header/Header';
import CurrentProjectComponent from './CurrentProject';
import { Grid, GridList, IconButton, Typography } from '@material-ui/core';
import appartment from '../../../../assets/appartment.svg';
import axios from '../../../../config/Axios';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import cookie from 'react-cookies';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { PreviousPageContext, ProjectContext } from '../../../../routing/IndexRouting';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import SearchBar from '../../../common/Searchbar/Search';
import img123 from '../../../../assets/Vector 223.svg';
import CommonAllProjectsCard from './CommonAllProjectsCard';
import { UserContext } from '../../../../routing/IndexRouting';
import SDBasics from '../basics/Basics';
import { projectQuery } from '../graphql-queries/Projects.query';
import useGraphqlRequest from '../SendRequestCalculess';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '6%',
    paddingLeft: '3%',
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  line: {
    width: '90%',
  },
  spacing: {
    marginTop: '2%',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflowX: 'scroll',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
const MyProjects = (props) => {
  const token = cookie.load('SDPLUS_TOKEN');
  const classes = useStyles();
  let history = useHistory();
  const { handleProjectData } = useContext(ProjectContext);
  const { setPreviousPage } = useContext(PreviousPageContext);

  const { userDetails } = useContext(UserContext);
  const [currentprojects, setCurrentproject] = useState('');
  const [projects, setProjects] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, SetSearchedData] = useState([]);
  const [currentProfile, setCurrentProfile] = useState(null);

  //on click view insights button
  const handleInsiteButton = (value) => history.push(`/projects/${value._id}/insights`);
  //get response and set projects from graph ql
  const [myProjectData, setMyProjectData] = useState('');
  const [projectRequest, setProjectRequest] = useState(false);
  const [width, setWidth] = useState(window.innerWidth || 0);
  const handleResize = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setPreviousPage('projects');
  }, []);

  useEffect(async () => {
    if (userDetails && userDetails._id && token) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setMyProjectData((await useGraphqlRequest(projectQuery(userDetails._id))).data.getProjectsbyUserID);
    }
  }, [token, userDetails, projectRequest]);

  /*For Deletion of a project*/
  const handleDelete = (project) => {
    swal({
      title: `Are you sure you want to delete the project ${project.name}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/HouseKeeping/removeproject/${project._id}`).then((re) => {
          if (re.data && re.data.data) {
            swal(`Successfully deleted Project - ${project.name}`, {
              icon: 'success',
            });
            setProjectRequest(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (myProjectData) {
      axios
        .get(`/userprofiles`)
        .then((response) => {
          setCurrentProfile(response.data.data.data);
          setProjects(myProjectData.otherProjects);
          if (myProjectData.currentProject) setCurrentproject(myProjectData.currentProject);
          else setCurrentproject(null);
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [myProjectData]);
  // handle Edit project
  const handleEditCurrentProject = () => {
    if (currentprojects) history.push(`/projects/${currentprojects._id}/edit?type=basic`);
  };

  //handle search bar
  const handleTextField = (e) => {
    if (e.target.value) {
      setSearchValue(e.target.value);
      const newData = projects && projects.filter((project) => project.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
      SetSearchedData(newData);
    } else {
      setSearchValue('');
      SetSearchedData([]);
    }
  };

  return (
    <>{width > 1279 ? (<>
      <Header currentprojects={currentprojects && currentprojects} />
      {/* header and current project */}
      <Grid container alignItems="center" className={classes.root}>
        <Heading style={{ position: 'fixed', marginTop: '3%' }} />
        <Grid item xs={12} className={classes.spacing}></Grid>
        <CurrentProjectComponent
          handleEditCurrentProject={handleEditCurrentProject}
          currentproject={currentprojects}
          handleInsiteButton={() => handleInsiteButton(currentprojects)}
          width={width}
        />
      </Grid>
      {/* other projects and filter */}
      <Grid container xs={12}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} >
          <Grid >
            <div style={{ width: '600px', display: 'flex', marginLeft: 'auto' }}>
              <button onClick={() => { history.push(`/projects/create`); }} style={{ background: 'transparent', border: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant="h5" style={{ color: '#29E1AC', cursor: 'pointer' }} >
                  CREATE PROJECT
                </Typography>
              </button>
              <IconButton onClick={() => { history.push(`/projects/create`); }} className={classes.disableRipple} aria-label="delete" style={{ padding: 0, cursor: 'pointer' }}>
                <AddCircleRoundedIcon style={{ fontSize: '32px', color: '#29E1AC' }} />
              </IconButton>
            </div>
          </Grid>
          <Typography style={{ fontWeight: 'bold' }} variant="h5">Other Projects</Typography>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by project name" />
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
          <Grid item xs={12} className={classes.space}></Grid>
          <br />
          <br />
          {searchValue && projects ? (
            searchedData.map((project, index) => {
              return (
                <>
                  <GridList className={classes.gridList}>
                    <CommonAllProjectsCard
                      index={index}
                      project={project}
                      handleProjectData={handleProjectData}
                      currentprojects={currentprojects}
                      history={history}
                      userDetails={userDetails && userDetails}
                      currentProfile={currentProfile !== null && currentProfile}
                      handleDelete={() => handleDelete(project)}
                    />
                  </GridList>
                  <br />
                </>
              );
            })
          ) : !searchValue && projects ? (
            projects.map((project, index) => {
              return (
                <>
                  <GridList className={classes.gridList}>
                    <CommonAllProjectsCard
                      index={index}
                      project={project}
                      handleProjectData={handleProjectData}
                      currentprojects={currentprojects}
                      history={history}
                      userDetails={userDetails && userDetails}
                      currentProfile={currentProfile !== null && currentProfile}
                      handleDelete={() => handleDelete(project)}
                    />
                  </GridList>
                  <br />
                </>
              );
            })
          ) : (
            <>
              <Grid item xs={12}>
                <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
              </Grid>
              <br />
              <Grid item xs={12}>
                <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
              </Grid>
              <br />
              <Grid item xs={12}>
                <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {currentProfile && currentProfile.showbasics === true && <SDBasics source="Projects" basicstatus={true} currentProfile={currentProfile} />}
    </>) : (<>
      <Header currentprojects={currentprojects && currentprojects} />
      {/* header and current project */}
      <Grid container alignItems="center" className={classes.root}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <Heading style={{ marginTop: '3%', marginBottom: '6%' }} width={width} />

            <CurrentProjectComponent
              handleEditCurrentProject={handleEditCurrentProject}
              currentproject={currentprojects}
              handleInsiteButton={() => handleInsiteButton(currentprojects)}
            />
          </div>
          <div style={{ marginTop: '8%' }}>
            <Grid style={{}} container xs={12}>
              <Grid item xs={12} >
                <Grid >
                </Grid>
                <Typography style={{ fontWeight: 'bold' }} variant="h5">Other Projects</Typography>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by project name" />
                  </Grid>
                  <Grid xs={2}></Grid>
                </Grid>
                <Grid item xs={12} className={classes.space}></Grid>
                <br />
                <br />
                {searchValue && projects ? (
                  searchedData.map((project, index) => {
                    return (
                      <>
                        <GridList className={classes.gridList}>
                          <CommonAllProjectsCard
                            index={index}
                            project={project}
                            handleProjectData={handleProjectData}
                            currentprojects={currentprojects}
                            history={history}
                            userDetails={userDetails && userDetails}
                            currentProfile={currentProfile !== null && currentProfile}
                            handleDelete={() => handleDelete(project)}
                          />
                        </GridList>
                        <br />
                      </>
                    );
                  })
                ) : !searchValue && projects ? (
                  projects.map((project, index) => {
                    return (
                      <>
                        <GridList className={classes.gridList}>
                          <CommonAllProjectsCard
                            index={index}
                            project={project}
                            handleProjectData={handleProjectData}
                            currentprojects={currentprojects}
                            history={history}
                            userDetails={userDetails && userDetails}
                            currentProfile={currentProfile !== null && currentProfile}
                            handleDelete={() => handleDelete(project)}
                          />
                        </GridList>
                        <br />
                      </>
                    );
                  })
                ) : (
                  <>
                    <Grid item xs={12}>
                      <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        {currentProfile && currentProfile.showbasics === true && <SDBasics source="Projects" basicstatus={true} currentProfile={currentProfile} />}
      </Grid >
    </>)}</>
  );
};

export default MyProjects;
