import React, { useContext, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ComponentNavigator from '../../../common/ComponentNavigator';
import DrawerLogicalList from './DrawerLogicalList';
import DrawerNavigationList from './DrawerNavigationList';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import UseNavigatorData from '../../../hooks/useNavigatorData';
import { DummyNavigatorData } from '../../StaticNavigatorData';
import CertificationDrawerList from './CertificationDrawerList';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import MobileFilterNavigationList from './MobileFilterNavigationList';
import MobileNavigatorBrands from './MobileNavigatorBrands';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { Default, FilterSections, FilterSubSections, NavigatorSource } from '../../../utils/Defaults';
import { usePrevious } from '../../../hooks/useCategoryDetailsData';

export const drawerWidth = 160;

const useStyles = makeStyles((theme) => ({
  responsivemenuIcon: {
    fontSize: '230%',
    cursor: 'pointer',
  },
  root: {
    display: 'flex',
    zIndex: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#F3F3F3',
    border: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  textfont: {
    textDecoration: 'none !important',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: MarketplaceColors.Secondary,
  },
  buttonText: MarketplaceTypography.ButtonSmall,
  stickypaper: {
    position: 'fixed',
    bottom: theme.spacing(0),
    backgroundColor: MarketplaceColors.CommonWhite,
    height: '56px',
    boxShadow: '0px -2px 10px 0px #00000012',
    width: '100%',
    zIndex: 0,
    display: 'flex',
  },
  buttonstyle: {
    background: 'transparent',
    height: '100%',
    color: MarketplaceColors.TextColorPrimary,
    borderRight: '1px solid #9E9E9E',
    boxShadow: 'none',
    height: '56px',
    borderRadius: '0px',
  },
  buttonstyle2: {
    background: MarketplaceColors.Secondary,
    height: '100%',
    color: MarketplaceColors.CommonWhite,
    borderRight: '1px solid #9E9E9E',
    boxShadow: 'none',
    height: '56px',
    borderRadius: '0px',
  },
  link: MarketplaceTypography.CaptionSmall,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const filterSource = {
  filter: 'filter',
};

export const DrawerItems = {
  products: 'Products',
  certifications: 'Certifications',
  services: 'Services',
  supercategories: 'Supercategories',
  brands: 'Brands',
  filters: 'FIlters',
  ecolabel: 'Eco Label',
};

function FilterComponent({ handleClick, appliedfilters, filtersData }) {
  const classes = useStyles();
  return (
    <Typography className={classes.buttonText} onClick={handleClick}>
      {filtersData && filtersData.length !== 0 ? <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary }} /> : ''} Filters
    </Typography>
  );
}

function NavigatorComponent({ handleClick }) {
  const classes = useStyles();
  return (
    <IconButton edge="start" color="inherit" onClick={handleClick} aria-label="close">
      <MenuIcon className={classes.responsivemenuIcon} />
    </IconButton>
  );
}

function Navigations(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} style={{ marginTop: '80%', marginBottom: '10%' }}>
      <List>
        {['Become a Vendor', 'Resources', 'Contact Us'].map((name, index) => (
          <ListItem key={index} className={classes.textfont}>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

export default function MarketplaceNavigatorMobile(props) {
  const classes = useStyles();
  let history = useHistory();
  //for cancel
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const [sourceurl, setSource] = useState(query.get('source'));
  const [category, setCategory] = useState(query.get('category'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  const [page, setpage] = useState(query.get('page'));
  const [pagesize, setpagesize] = useState(query.get('pagesize'));
  const [subcategory, setSubcategory] = useState(query.get('subcategory'));
  const [brands, setBrands] = useState(query.get('brands'));
  const [filters, setFilters] = useState(query.get('filters'));

  const { Primary: marketplacePrimary } = MarketplaceColors;

  const { categories, source } = props;
  const { appliedfilters, handleURL, filtersData, handleReset } = useContext(ProductsPageContext);

  // console.log(category, categories, 'sourcesourcesourcesourcesourcesourcesource');
  // navigator data
  const { navigatorData } = UseNavigatorData();
  const { columns: navigatorColumns } = navigatorData && navigatorData;
  const superCategory_collections = navigatorColumns?.find((column) => column.source === NavigatorSource.supercategories)?.collections;

  // Use of Dummy Navigator Data
  const { columns } = DummyNavigatorData;

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(DrawerItems.products);

  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => setOpen(false);

  // Show filters data in mobile view
  const [selectedCategory, setSelectedCategory] = React.useState(category && category);
  const [selectedCategoryCollectionsData, setSelectedCategoryCollectionsData] = useState(
    source === NavigatorSource.categories
      ? columns?.find((s) => s.source === NavigatorSource.categories)?.collections.find((d) => d.name === selectedCategory)?.subcategories
      : []
  );
  // console.log(selectedCategory, selected, 'filtersData');

  //go to the base urls when cancel button clicked with clearing all the filter
  useEffect(() => {
    if (previousLocation !== location) {
      const source = query.get('source');
      const category = query.get('category');
      setSource(source);
      setCategory(category);
      setSuperCategory(query.get('supercategory'));
      setpage(query.get('page'));
      setpagesize(query.get('pagesize'));
      setSelectedCategoryCollectionsData(
        columns?.find((s) => s.source === NavigatorSource.categories)?.collections?.find((d) => d.name === category)?.subcategories
      );
    }
  }, [previousLocation, location]);

  const handleCancel = () => {
    handleClickAway();
    handleReset();

    if (sourceurl === NavigatorSource.categories)
      history.push(`/marketplace/categoryDetails?source=${sourceurl}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}`);
    if (sourceurl === NavigatorSource.supercategories)
      history.push(`/marketplace/categoryDetails?source=${sourceurl}&supercategory=${supercategory}&page=${Default.page}&pagesize=${Default.pagesize}`);
  };

  function ApplyFilter(props) {
    const classes = useStyles();

    const { handleCancel, handleClickAway, category, supercategory, page, pagesize, sourceurl } = props;
    const { filtersData, handleAppliedFilters, setIsDefault } = useContext(ProductsPageContext);
    // console.log(filtersData, 'filtersData');
    /*Apply Filters*/
    const handleApply = () => {
      setIsDefault(true);
      filtersData && filtersData.forEach((element) => handleAppliedFilters(element.id, element.value, element.checked));
      handleClickAway();
      //try to fix when page is not 1 and applied filter navigate the user to 1st page
      // handleURL(category, subcategory, brands, supercategory, Default.page, Default.pagesize, sourceurl, filters);
      if (filtersData.length === 0) {
        if (sourceurl === NavigatorSource.categories)
          history.push(`/marketplace/categoryDetails?source=${sourceurl}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}`);
        else if (sourceurl === NavigatorSource.supercategories)
          history.push(`/marketplace/categoryDetails?source=${sourceurl}&supercategory=${supercategory}&page=${Default.page}&pagesize=${Default.pagesize}`);
      }
    };

    return (
      <Paper className={classes.stickypaper}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
            <Button variant="contained" fullWidth className={classes.buttonstyle}>
              <Typography className={classes.buttonText} onClick={handleCancel}>
                Cancel
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
            <Button variant="contained" className={classes.buttonstyle2} fullWidth>
              <Typography className={classes.buttonText} onClick={handleApply}>
                Apply
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <>
      {source === filterSource.filter && <FilterComponent handleClick={handleClick} appliedfilters={appliedfilters} filtersData={filtersData} />}
      {source !== filterSource.filter && <NavigatorComponent handleClick={handleClick} />}

      <Dialog fullScreen open={open} onClose={handleClickAway} TransitionComponent={Transition}>
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <ComponentNavigator onClick={handleClickAway} color={marketplacePrimary} text={source !== filterSource.filter ? 'Back' : 'Filters'} />

              {source !== filterSource.filter && (
                <DrawerNavigationList
                  drawerData={superCategory_collections && superCategory_collections}
                  handleSelected={(a) => setSelected(a)}
                  selected={selected && selected}
                  setOpen={(a) => setOpen(a)}
                  categories={categories && categories}
                  setSelectedCategory={(a) => setSelectedCategory(a)}
                />
              )}
              {source !== filterSource.filter && <Navigations />}
              {source === filterSource.filter && (
                <MobileFilterNavigationList
                  categories={categories && categories}
                  setSelectedCategory={(a) => setSelectedCategory(a)}
                  handleSelected={(a) => setSelected(a)}
                  selectedCategory={selectedCategory && selectedCategory}
                  selected={selected && selected}
                />
              )}
            </Grid>
            {source === filterSource.filter && (
              <ApplyFilter
                category={category}
                supercategory={supercategory}
                page={page}
                pagesize={pagesize}
                sourceurl={sourceurl}
                handleCancel={handleCancel}
                handleClickAway={handleClickAway}
              />
            )}
          </Drawer>

          {/* /////////////////////Drawer Data or Filter Data///////////////////////////////////////////////////// */}

          {source !== filterSource.filter && (
            <main>
              <div>
                {columns &&
                  columns.map(({ id, source, collections }) => {
                    if (selected === DrawerItems.products && source === NavigatorSource.categories) {
                      return collections.map((collection) => (
                        <DrawerLogicalList
                          source={source}
                          data={collection}
                          handleClickAway={() => handleClickAway()}
                          handleRouting={() => {
                            history.push(
                              `/marketplace/categoryDetails?source=${NavigatorSource.categories}&category=${collection?.name}&page=${Default.page}&pagesize=${Default.pagesize}`
                            );
                          }}
                        />
                        // <></>
                      ));
                    }
                    if (selected === DrawerItems.certifications && source === NavigatorSource.certifications)
                      return <CertificationDrawerList collections={collections.slice(0, 5)} />;
                  })}
              </div>
            </main>
          )}

          {/* for products page mobile sidebar filters */}

          {/* categories */}
          {source === filterSource.filter && category && selected !== DrawerItems.brands && (
            <main>
              <DrawerLogicalList
                data={{
                  name: category,
                  subcategories: selectedCategoryCollectionsData,
                }}
                filters={categories && categories.find((s) => s.attributes?.name === category)?.attributes?.filters?.data}
                handleClickAway={() => handleClickAway()}
                source={source}
              />
            </main>
          )}
          {/* brands */}
          {source === filterSource.filter && selected === DrawerItems.brands && (
            <main>
              <MobileNavigatorBrands
                brands={categories && categories.map((category) => category?.attributes?.brands?.data.map((brand) => brand))}
                handleClickAway={() => handleClickAway()}
              />
            </main>
          )}
        </div>
      </Dialog>
    </>
  );
}
