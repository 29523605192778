import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Modal, Paper, TableContainer } from '@material-ui/core';
import PrimaryButton from '../../../../../common/button/Button';
import circularity from '../../../../../../assets/Marketplace/product/circularity.svg';
import climate from '../../../../../../assets/Marketplace/product/climatehealth.svg';
import human from '../../../../../../assets/Marketplace/product/humanhealth.svg';
import ecosystem from '../../../../../../assets/Marketplace/product/ecosystem.svg';
import CasaSkeleton from '../../../../../common/skeleton/CasaSkeleton';
// <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />;
const icons = {
  Circularity: circularity,
  'Climate Health': climate,
  'Ecosystem Health': ecosystem,
  'Human Health': human,
};
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ProductDetailsTableData(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 500,
      outline: 'none',
      maxHeight: 600,
      backgroundColor: '#FFFFFF',
      borderRadius: '15px',
      padding: theme.spacing(2, 3, 2, 4),
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    headerstyle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28.8px',
      letterSpacing: '0.86px',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '1%',
    },
    subheaderstyle: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.71px',
      paddingBottom: '1%',
    },
    tablestyle: {
      borderRadius: '10px',
      border: '1px solid #00000024',
      boxShadow: 'none',
      marginBottom: '1%',
    },
    aboutus: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0.71px',
      padding: '1%',
    },
  }));

  const classes = useStyles();
  const { tableData, tab, implement, newValue } = props;

  const type_1 = { type: 'table', tableData: tableData && tableData.filter((data) => data.type === 'table') };
  const type_2 = { type: 'text', tableData: tableData && tableData.filter((data) => data.type === 'text') };

  const [modalStyle] = useState(getModalStyle);
  const [viewMultipleLinks, setViewMultipleLinks] = useState(false);

  const handleClose = () => setViewMultipleLinks(false);

  const isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  const extractURLs = (inputString) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = inputString.match(urlRegex);
    const linksArray = links.map((s) => s.replace(',', '')) || [];
    return linksArray;
  };

  const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {implement !== 'new' && type_1 && type_1.tableData != 0
        ? type_1?.tableData.map(({ items }, index) => {
            if (Object.keys(items)?.length !== 0) {
              return (
                <>
                  {!Array.isArray(items) ? (
                    <TableContainer component={Paper} className={classes.tablestyle}>
                      {Object.entries(items).map((t, k) => {
                        return (
                          <Table aria-label="caption table">
                            <TableBody key={k}>
                              <TableRow
                                style={{
                                  border: '1px solid #00000024',
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="h6">{t[0] && t[0].includes('_') ? t[0].split('_').join(' ') : t[0]}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                  {t[1] && isValidHttpUrl(t[1]) === true ? (
                                    <a href={t[1]} target="_blank" style={{ color: '#33518E' }}>
                                      <Typography variant="h6">Link</Typography>
                                    </a>
                                  ) : (
                                    <Typography variant="h6">{t[1]}</Typography>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        );
                      })}
                    </TableContainer>
                  ) : (
                    items &&
                    items.map((d, index) => {
                      if (d.split(':').length > 2) {
                        return (
                          <TableContainer component={Paper} className={classes.tablestyle} style={{}}>
                            <Table aria-label="caption table">
                              <TableBody key={index}>
                                <TableRow
                                  style={{
                                    border: '1px solid rgba(0, 0, 0, 0.14)',
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Typography variant="h6">
                                      {d.split(':')[0] && d.split(':')[0].includes('_') ? d.split(':')[0].split('_').join(' ') : d.split(':')[0]}
                                    </Typography>
                                  </TableCell>
                                  {d.split(':').length > 3 ? (
                                    <TableCell align="right">
                                      <Typography
                                        variant="h6"
                                        style={{ color: 'rgba(0, 219, 153, 0.82)', cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => setViewMultipleLinks(true)}
                                      >
                                        View Links
                                      </Typography>
                                    </TableCell>
                                  ) : (
                                    d.split(':').length >= 2 && (
                                      <TableCell align="right">
                                        {d.split(':')[1] && d.split(':')[2] && isValidHttpUrl(d.split(':')[1] + `:` + d.split(':')[2]) === true ? (
                                          <a href={d.split(':')[1] + `:` + d.split(':')[2]} target="_blank" style={{ color: '#33518E' }}>
                                            <Typography variant="h6">Link</Typography>
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        );
                      } else if (d.split(':')[1] === ('null' || '')) {
                        return '';
                      } else {
                        return (
                          <Table aria-label="caption table">
                            <TableBody key={index}>
                              <TableRow
                                style={{
                                  border: '1px solid rgba(0, 0, 0, 0.14)',
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="h6">
                                    {d.split(':')[1] === ('null' || '')
                                      ? ''
                                      : d.split(':')[0] && d.split(':')[0].includes('_')
                                      ? d.split(':')[0].split('_').join(' ')
                                      : d.split(':')[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="h6">{d.split(':')[1]}</Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        );
                      }
                    })
                  )}
                  <Modal open={viewMultipleLinks} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    <div style={modalStyle} className={classes.paper}>
                      <Grid container alignItems="flex-start" justifyContent="flex-start">
                        <Grid xs={12}>
                          <Typography variant="h5">
                            <b>Brochures</b>
                          </Typography>
                        </Grid>
                        {items && Array.isArray(items)
                          ? items.map((d, index) => {
                              if (d.split(':').length > 3) {
                                return extractURLs(d).map((e, i) => (
                                  <Grid item xs={12} style={{ marginTop: '4%' }}>
                                    <a href={e} target="_blank" style={{ color: '#33518E' }}>
                                      <Typography variant="h6">Link{i + 1} </Typography>
                                    </a>
                                  </Grid>
                                ));
                              }
                            })
                          : ''}

                        <Grid
                          xs={12}
                          container
                          justifyContent="space-around"
                          alignItems="center"
                          style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}
                        >
                          <Grid xs={7}></Grid>
                          <Grid xs={5} container alignItems="center">
                            <Grid xs={4}></Grid>
                            <Grid xs={8}>
                              <PrimaryButton children="DONE" onClick={handleClose} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                </>
              );
            } else if (items.length === 0) {
              return (
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h6">No {tab} data is available for this product</Typography>
                </Grid>
              );
            } else {
              return (
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h6">No {tab} data is available for this product</Typography>
                </Grid>
              );
            }
          })
        : ''}

      {implement !== 'new' && type_2 && type_2.tableData != 0
        ? type_2?.tableData.map(({ items }) => {
            if (items?.length === 0) {
              return '';
            } else if (items && items?.length !== 0) {
              return (
                <Table
                  aria-label="caption table"
                  style={{
                    marginTop: '4%',
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ border: '1px solid rgba(0, 0, 0, 0.14)' }}>
                        {items && items?.length !== 0
                          ? items.map((d) => (
                              <Typography variant="h6" style={{ lineHeight: '32px' }} color="textSecondary">
                                {d}
                              </Typography>
                            ))
                          : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            } else {
              return '';
            }
          })
        : ''}
      {implement === 'new' &&
        newValue &&
        newValue.data.map((ele) => {
          return (
            <Grid container item xs={12} direction="column">
              {ele.header && (
                <Typography className={classes.headerstyle} style={{}}>
                  {icons[ele.header] ? <img src={icons[ele.header]} alt="..." style={{ marginRight: '8px' }} /> : ''}
                  {ele.header}
                </Typography>
              )}
              {ele?.table &&
                ele?.table.map((element) => {
                  return (
                    <>
                      {element.data !== '' && (
                        <>
                          {element.title && <Typography className={classes.subheaderstyle}>{element.title}</Typography>}
                          {element?.data ? (
                            <TableContainer
                              component={Paper}
                              className={classes.tablestyle}
                              style={{ border: element.type === 'object' ? 'hidden' : '1px solid #00000024', overflowX: 'visible' }}
                            >
                              {element?.type === 'string' &&
                                element?.data?.split(',').map((d, v) => {
                                  return (
                                    <Table aria-label="customized table">
                                      <TableBody key={v}>
                                        <TableRow style={{ borderBottomStyle: v === element.data.length - 1 ? 'hidden' : '' }}>
                                          <TableCell component="th" scope="row">
                                            <Typography variant="h6">{d.trim().split(':')[0]}</Typography>
                                          </TableCell>
                                          <TableCell align="right">
                                            <Typography variant="h6">
                                              {d.split(':')[1] !== '' && d.split(':')[1] !== ' ' && d.split(':')[1] !== undefined ? (
                                                element?.subtype === 'url' ? (
                                                  <a href={d.split(':')[1] + `:` + d.split(':')[2]} target="_blank" style={{ color: '#33518E' }}>
                                                    <Typography variant="h6">Link</Typography>
                                                  </a>
                                                ) : (
                                                  d.split(':')[1]
                                                )
                                              ) : element?.subtype === 'nodash' ? (
                                                ''
                                              ) : (
                                                '-'
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  );
                                })}
                              {element?.type === 'special' &&
                                element?.data?.split(': ,').map((d, v) => {
                                  return (
                                    <Table aria-label="customized table">
                                      <TableBody key={v}>
                                        <TableRow style={{ borderBottomStyle: v === element.data.length - 1 ? 'hidden' : '' }}>
                                          <TableCell component="th" scope="row">
                                            <Typography variant="h6">{d.trim().split(':')[0]}</Typography>
                                          </TableCell>
                                          <TableCell align="right">
                                            <Typography variant="h6">
                                              {d.split(':')[1] !== '' && d.split(':')[1] !== ' ' && d.split(':')[1] !== undefined ? (
                                                element?.subtype === 'url' ? (
                                                  <a href={d.split(':')[1] + `:` + d.split(':')[2]} target="_blank" style={{ color: '#33518E' }}>
                                                    <Typography variant="h6">Link</Typography>
                                                  </a>
                                                ) : (
                                                  d.split(':')[1]
                                                )
                                              ) : (
                                                '-'
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  );
                                })}
                              {element?.type === 'table' &&
                                element?.data?.map((d, v) => {
                                  return (
                                    <Table aria-label="customized table">
                                      <TableBody key={v}>
                                        {d.value !== '' && d.value !== ' ' && (
                                          <TableRow style={{ borderBottomStyle: v === element.data.length - 1 ? 'hidden' : '' }}>
                                            <TableCell component="th" scope="row">
                                              <Typography variant="h6">{d.name}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                              <Typography variant="h6">{d.value !== '' && d.value !== ' ' ? d.value : '-'}</Typography>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  );
                                })}
                              {element?.type === 'object' && (
                                <>
                                  {Object.entries(element.data).map(([section, items], sectionIndex) => (
                                    <React.Fragment key={sectionIndex}>
                                      <Typography className={classes.subheaderstyle}>{section}</Typography>

                                      <TableContainer component={Paper} className={classes.tablestyle}>
                                        <Table aria-label="customized table" style={{ overflowX: 'visible' }}>
                                          <TableBody>
                                            {Object.entries(items).map(([key, value], index) => (
                                              <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                  <Typography variant="h6">{key}</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                  <Typography variant="h6">{value !== '' && value !== ' ' && value !== undefined ? value : '-'}</Typography>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </React.Fragment>
                                  ))}
                                </>
                              )}
                            </TableContainer>
                          ) : (
                            <CasaSkeleton variant="rect" height="30vh" width="100%" animation="wave" style={{ marginTop: '1%' }} />
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              {ele?.data && (
                <Paper className={classes.tablestyle}>
                  <Typography className={classes.aboutus}>{ele?.data}</Typography>
                </Paper>
              )}
            </Grid>
          );
        })}
    </>
  );
}
