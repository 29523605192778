import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import VerticalLinearStepper from '../commmon/VerticalLinearStepper';
const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '8%',
  },
  typo2: {
    color: '#00CEFF',
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '5%',
  },
  button2: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '100%',
    height: '8vh',
    color: '#FFFFFF',
  },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
}));

const CustomColor = withStyles({
  root: {
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '12%',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
})(Typography);

function ResponsiveHome2() {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" alignContent="center">
        <VerticalLinearStepper />
      </Grid>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11} style={{ textAlign: 'center' }}>
        <div data-aos="fade-right">
          <CustomColor variant="h3">
            SD+ <br />
          </CustomColor>
        </div>
        <div data-aos="fade-left">
          <Typography className={classes.typo} variant="body2">
            Sustainable Resource Planning Platform
            <br />
            <br />
            <br />
          </Typography>
        </div>
        <div data-aos="fade-right">
          <Typography className={classes.typo3} variant="h5">
            SD+ makes designing a sustainable building a smooth experience by presenting you with all the financial & environmental data required to make an
            informed decision. <br /> <br />
            The platform auto generates sustainable solutions tailored to your project’s needs & seamlessly integrates them into the building design.
          </Typography>
        </div>
      </Grid>
    </>
  );
}
export default ResponsiveHome2;
