import React from 'react';
import { withWidth, Grid } from '@material-ui/core';
import CategoryCard from '../../../../common/CategoryCard/CategoryCard';
import useCategorieSpotlight from '../../../../hooks/useCategoriesSpotlightData';
import CategoriesSkeletons from '../../../../common/Skeletons/CategoriesSkeletons';

const Categories = (props) => {
  const { width } = props;
  const { categoriesWithProductsCount } = useCategorieSpotlight();

  const dummySkeletonCount = width === 'xs' ? [1, 1, 1] : width === 'sm' || width === 'md' ? [1, 2, 3, 3] : [1, 2, 2, 2, 2];
  const categories =
    width === 'xs'
      ? categoriesWithProductsCount?.slice(0, 3)
      : width === 'sm' || width === 'md'
      ? categoriesWithProductsCount?.slice(0, 4)
      : categoriesWithProductsCount?.slice(0, 5);

  return (
    <>
      {!categories
        ? dummySkeletonCount.map((ske) => <CategoriesSkeletons />)
        : categories.map((category, index) => (
            <Grid
              key={index}
              container
              direction="row"
              xs={4}
              sm={3}
              md={3}
              lg={2}
              xl={2}
              alignItems="center"
              justifyContent="center"
              style={width === 'xl' || width === 'lg' ? { marginLeft: '1%', marginBottom: '2%' } : { marginBottom: '2%' }}
            >
              <CategoryCard index={index} category={category && category} />
            </Grid>
          ))}
    </>
  );
};

export default withWidth()(Categories);
