import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import back from '../../../../assets/vendors/Back.svg';
import { useHistory } from 'react-router-dom';
import { ProjectContext } from '../../../../routing/IndexRouting';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '20vh',
  },
  container: {
    marginTop: '1%',
  },
}));

const DetailsHead = (props) => {
  const classes = useStyles();
  const vendor = props.vendor;
  let history = useHistory();
  const { projectData } = useContext(ProjectContext);
  let param = props.param;

  return (
    <>
      <>
        <Grid container alignItems="left" item xs={12} className={classes.container} spacing={2}>
          <Grid item xs={0} align="left">
            {projectData && (
              <img
                src={back}
                alt="..."
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/projects/${projectData.id}/interventions/${param.iid}`);
                }}
              />
            )}
          </Grid>
          <Grid item xs={10} align="left">
            <Typography variant="h3">{vendor ? vendor.vendor.vendorname : ''}</Typography>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default DetailsHead;
