import React from 'react';

export const AllFlooringIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={43} height={43} fill="none" {...props}>
        <path
          fill={color}
          stroke={color}
          strokeWidth={0.495}
          d="M42.69 42.515v.002H.66V.484h42.03v.001h.002v42.03h-.002ZM20.994 41.49h10.07l2.756-6.556-10.153-4.269-4.269 10.154 1.596.67ZM14.46 21.5l-1.494 3.554 8.71 3.662 1.444.607 3.288-7.822.98-2.332-5.713-2.401-4.44-1.867-2.775 6.6Zm1.83-6.996L6.136 10.235 1.867 20.387l10.154 4.27 1.327-3.156 2.942-6.997Zm7.774 15.217 10.153 4.268 4.268-10.154-10.152-4.268-.813 1.934-3.456 8.22Zm-1.342.547-1.047-.44-9.107-3.829L8.3 36.153l10.153 4.27 4.27-10.155ZM18.004 41.49l.052-.123-10.154-4.269-1.846 4.392h11.948Zm-16.319 0h3.258l2.014-4.79-5.272-2.216v7.006Zm0-8.118 5.67 2.383 4.268-10.153-9.937-4.179v11.949Zm0-15.198L5.19 9.837 1.685 8.364v9.81Zm0-16.662v5.74l3.903 1.641 3.104-7.381H1.685Zm14.76 0H9.804L6.533 9.29l10.154 4.268 4.268-10.152-4.51-1.895Zm2.646 0 2.262.95.4-.95H19.09Zm-1.459 12.444 4.043 1.7 6.11 2.569 4.269-10.153-9.533-4.007-.621-.261-4.268 10.152Zm5.232-12.444-.566 1.348 10.154 4.267 2.36-5.616H22.864Zm18.8 0h-5.74l-2.528 6.013L41.665 11V1.512Zm0 10.6L33 8.47 28.73 18.623l10.153 4.268 2.783-6.619v-4.16h-.001Zm0 6.806-1.836 4.37 1.837.772v-5.142Zm0 6.255-2.234-.94-4.267 10.154 6.502 2.733V25.172Zm0 13.06-6.9-2.901-2.589 6.159h9.49v-3.258Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
        <path
          fill={color}
          d="M89 10H11a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1h78a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1Zm-1 39H71.728L88 32.728V49Zm-39 0H32.824L49 32.824V49Zm2-16.018L67.018 49H51V32.982ZM49 51v16.176L32.824 51H49Zm2 0h16.018L51 67.018V51Zm18.373-2.473L51.414 30.568 69.373 12.61l17.959 17.96-17.959 17.958ZM51 28.154V12h16.154L51 28.154Zm-2 .159L32.687 12H49v16.313Zm-.573 2.255-17.959 17.96-17.959-17.96L30.47 12.61l17.958 17.96ZM28.113 49H12V32.887L28.113 49ZM12 51h16.113L12 67.113V51Zm18.468.473 17.96 17.959-17.96 17.959L12.51 69.43l17.96-17.958ZM49 71.687V88H32.687L49 71.687Zm2 .159L67.154 88H51V71.846Zm.414-2.414 17.959-17.96 17.959 17.96L69.373 87.39 51.414 69.43ZM71.728 51H88v16.272L71.728 51ZM88 28.408 71.592 12H88v16.408ZM28.25 12 12 28.25V12h16.25ZM12 71.75 28.25 88H12V71.75ZM71.592 88 88 71.592V88H71.592Z"
        />
      </svg>
    );
  }
};
