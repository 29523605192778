import React from 'react';
import { Grid, Typography, makeStyles, withWidth } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MarketplaceTypography, MarketplaceColors } from '../config/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  textHead: MarketplaceTypography.PageHeader,
  arrowstyle: {
    fontSize: '180%',
    color: MarketplaceColors.Secondary,
    cursor: 'pointer',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '3%',
  },
}));

const Title = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { name } = props;
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="left" justifyContent="left" className={classes.headerStyle}>
      <ArrowBackIosIcon className={classes.arrowstyle} onClick={() => history.push('/marketplace')} />
      <Grid item xs={11}>
        <Typography className={classes.textHead}>{name}</Typography>
      </Grid>
    </Grid>
  );
};

export default withWidth()(Title);
