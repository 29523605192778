import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import Fields from '../../../../../common/textfield/Fields';
import MarketplaceButton from '../../../common/Button';
import { useState } from 'react';
import axios from '../../../../../../config/Axios';
import swal from 'sweetalert';

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      background: '#ffff',
      borderRadius: '5px',
      fontWeight: '500',
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #79747E',
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        borderColor: '#00000061',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #6750A4',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ED5E68',
      },
    },
  },
})(TextField);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '695px',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    border: 'none',
    borderRadius: '10px',
  },
}));

const ContactUs = ({ closeModal }) => {
  const [name, setName] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');
  const [formValidation, SetFormValidation] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  const sendEmail = () => {
    if(email!=='' || name!=='') {
      const body = {
        email: email,
        name: name,
        organisation: organisation,
        project: undefined,
        page: undefined,
        message: query,
      };
      axios.post('/feedbacks/contact-us', body).then((res) => {
        if (res && res.data && res.data.data && res.data.data.body.data.feedbackAcknowledgement === true) {
          closeModal();
          swal('Thank you!', 'Our team will get in touch with you shortly.', 'success');
        } else {
          closeModal();
          swal('Oops! An error occured while sending your message.', 'Please email connect@sdplus.io directly.', 'error');
        }
      });
    } else {
      SetFormValidation(true);
    } 
  };

  return (
    <Grid container style={modalStyle} className={classes.paper}>
      <Grid xs={12} item>
        <Typography variant="h5">
          <b>Contact Us</b>
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="body1" color="textSecondary">
          This message will be sent to <span style={{ color: MarketplaceColors.Tertiary }}>connect@sdplus.io</span>
        </Typography>
      </Grid>
      <Grid container xs={12} item>
        <Grid xs={4} container style={{ paddingTop: '3%' }}>
          <Grid xs={12}>
            <Typography variant="body1">Name</Typography>
          </Grid>
          <Grid xs={12} style={{ paddingRight: '10%', paddingTop: '3%' }}>
            <Fields
              id="outlined-basic"
              variant="outlined"
              value={name}
              fullWidth
              formValidation={formValidation}
              required
              onChange={(e) => {
                e.preventDefault();
                setName(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={4} container style={{ paddingTop: '3%' }}>
          <Grid xs={12}>
            <Typography variant="body1">Organisation (optional)</Typography>
          </Grid>
          <Grid xs={12} style={{ paddingRight: '8%', paddingTop: '3%' }}>
            <Fields
              id="outlined-basic"
              variant="outlined"
              value={organisation}
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                setOrganisation(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={4}></Grid>
        <Grid xs={4} container style={{ paddingTop: '3%' }}>
          <Grid xs={12}>
            <Typography variant="body1">Email Address</Typography>
          </Grid>
          <Grid xs={12} style={{ paddingRight: '8%', paddingTop: '3%' }}>
            <Fields
              id="outlined-basic"
              variant="outlined"
              value={email}
              formValidation={formValidation}
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item style={{ paddingTop: '3%' }}>
        <Typography variant="body1">Type your comment/query here</Typography>
      </Grid>
      <Grid xs={12} item style={{ paddingTop: '2%' }}>
        <StyledTextField
          id="outlined-multiline-static"
          multiline
          variant="outlined"
          rows={10}
          style={{ width: '620px' }}
          value={query}
          onChange={(e) => {
            e.preventDefault();
            setQuery(e.target.value);
          }}
        />
      </Grid>
      <Grid xs={12} container direction="row-reverse" style={{ paddingTop: '3%' }}>
        <Grid xs={4}>
          <MarketplaceButton children="send message" onClick={sendEmail} />
        </Grid>
        <Grid xs={2} container alignItems="center" style={{ cursor: 'pointer' }} onClick={() => closeModal()}>
          <Typography variant="body1">Cancel</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
