import React from 'react';
import { MarketplaceColors } from '../../../config';
import { Grid, Typography } from '@material-ui/core';

const DrawerLogicalItem = ({ name, index, handleRouting }) => {
  const { Primary: marketplacePrimary } = MarketplaceColors;

  return (
    <Grid xs={12}>
      <Typography variant="h6" style={index > 0 ? { color: marketplacePrimary, marginTop: '6%' } : { color: marketplacePrimary }} onClick={handleRouting}>
        {name}
      </Typography>
    </Grid>
  );
};

export default DrawerLogicalItem;
