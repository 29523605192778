import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grow, Grid } from '@material-ui/core';
import NavBar from '../commmon/NavBar';
import homepageBigOne from '../../../../assets/VERSION-TWO/landingPage/homepageBigOne.svg';

function Home1(props) {
  const useStyles = makeStyles((theme) => ({
    navbar: {
      marginBottom: '6%',
      [theme.breakpoints.down('md')]: {
        marginBottom: '10%',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10%',
      },
    },
    button: {
      background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
      width: '50%',
      height: '6vh',
      marginTop: '8%',
      marginLeft: '12%',
      [theme.breakpoints.down('md')]: {
        width: '70%',
        height: '4vh',
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        height: '4vh',
      },
    },
    buttonText: {
      fontStyle: 'normal',
      fontSize: '17px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: '#FFFFFF',
      WebkitTextFillColor: '#FFFFFF',
    },
    mainText: {
      lineHeight: '40px',
      letterSpacing: '1px',
      paddingLeft: '12%',
      background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  }));
  const [checked, setChecked] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);

  return (
    <>
      <Grid item xs={12} className={classes.navbar}>
        <NavBar handleShowLogin={props.handleShowLogin} setPurpose={(v) => props.setPurpose(v)} />
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <div data-aos="fade-right">
            <Typography variant="h3" className={classes.mainText}>
              Join us on our <br /> mission towards <br /> building a <br /> data-driven <br /> sustainable future
            </Typography>
          </div>
          <div data-aos="fade-left">
            <Button variant="contained" className={classes.button} onClick={props.onModalOpen}>
              <Typography className={classes.buttonText}>request demo</Typography>
            </Button>
          </div>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Grow in={checked} style={{ transformOrigin: '10 0 0' }} {...(checked ? { timeout: 2000 } : {})}>
            <img src={homepageBigOne} style={{ width: '92%' }} alt="homepageBigOne" />
          </Grow>
        </Grid>
      </Grid>
    </>
  );
}
export default Home1;
