import React from 'react';
import { NavigatorSource } from '../../../utils/Defaults';
import EcolabelsLogicalGroup from './CertificationsLogicalGroup';
import CategoriesLogicalGroup from './CategoriesLogicalGroup';
import { withWidth } from '@material-ui/core';

const NavigatorColumnsAndCollections = (props) => {
  const { width, source, handleReset, data, superCategory_collections } = props;
  return (
    <React.Fragment>
      {source === NavigatorSource.categories && (
        <CategoriesLogicalGroup data={data} source={source} from={0} to={width === 'md' || width === 'lg' ? 4 : 5} handleReset={() => handleReset()} />
      )}
      {source === NavigatorSource.categories && (
        <CategoriesLogicalGroup data={data} source={source} from={width === 'md' || width === 'lg' ? 4 : 5} to={7} handleReset={() => handleReset()} />
      )}
      {source === NavigatorSource.ecolabels && (
        <EcolabelsLogicalGroup
          data={data}
          source={source}
          superCategoriesData={superCategory_collections}
          supercategoriesSource={NavigatorSource.supercategories}
          handleReset={() => handleReset()}
          // page={page}
          // perpageresult={pagesize}
        />
      )}
    </React.Fragment>
  );
};

export default withWidth()(NavigatorColumnsAndCollections);
