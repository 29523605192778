import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import marketreachicon from '../../../../../../assets/Marketplace/marketreachicon.svg';
import brandreputationicon from '../../../../../../assets/Marketplace/brandreputationicon.svg';
import joinusicon from '../../../../../../assets/Marketplace/joinusicon.svg';
import joinwithusimage from '../../../../../../assets/Marketplace/joinwithusimage.svg';
import JoinUsFormDesktop from './components/JoinusFormDesktop';

const useStyles = makeStyles((theme) => ({
  imagestyle: {
    height: '60vh',
    paddingLeft: '6%',
    width: '500px',
  },
  text1: {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
    color: '#213162',
    margin: '2%',
  },
  text2: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19.2px',
    letterSpacing: '0.7142857313156128px',
    color: '#33518E',
    margin: '2%',
  },
  textHead: {
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28.8px',
    letterSpacing: '0.8642857313156128px',
    color: '#213162',
  },
  gridStyle: {
    paddingTop: '3%',
    paddingBottom: '1%',
  },
  paraStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '5%',
    paddingBottom: '4%',
  },
}));

const JoinusDeskTop = (props) => {
  const classes = useStyles();
  const { joinus, setJoinus } = props;
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
      <Grid container item xs={12} className={classes.gridStyle} justifyContent={'center'}>
        <Typography className={classes.textHead}>Become a Vendor</Typography>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '2%' }}>
        <Grid container item xs={7}>
          <Grid container item xs={12} className={classes.paraStyle}>
            <Grid item xs={1}>
              <img src={marketreachicon} alt="..." />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.text1}>Expand market reach, increase sales</Typography>
              <Typography className={classes.text2}>Access a growing customer base seeking sustainable solutions.</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.paraStyle}>
            <Grid item xs={1}>
              <img src={brandreputationicon} alt="..." />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.text1}>Enhance brand reputation, differentiate yourself.</Typography>
              <Typography className={classes.text2}>Stand out as a leader in sustainability, build trust among eco-conscious buyers.</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.paraStyle}>
            <Grid item xs={1}>
              <img src={joinusicon} alt="..." />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.text1}> Join us, get discovered today</Typography>
              <Typography className={classes.text2}>Unlock new business opportunities and visibility in the green building sector.</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3} style={{ paddingBottom: '1%' }}>
          <JoinUsFormDesktop setJoinus={setJoinus} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JoinusDeskTop;
