import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '5%',
  },
}));

export default function ProgressBar({ value, progress }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 15,
      background: '#E5E5E5',
    },
    bar: {
      borderRadius: 20,
      background: `linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)`,
    },
  }))(LinearProgress);

  return (
    <>
      <Grid container item xs={12} style={{ paddingTop: '4%' }}>
        <Grid item xs={3}>
          <Typography variant="h6" color="textSecondary">
            Your Goal
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <BorderLinearProgress className={classes.margin} variant="determinate" value={`${progress}`} style={{ backgroundColor: `#E5E5E5` }} />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{value}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
