import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

import { Sections } from '../../DataTypes';
import CustomPopover from '../../common/CustomPopover';
import { ResidentHealthAndWellBeingContext } from '../..';

export default function CustomTableForWindowType({ handleEdit, update, setUpdate, tableData, mount, setMount }) {
  const classes = useStyles();
  const { data, handleData } = useContext(ResidentHealthAndWellBeingContext);

  const handleDelete = (data, row, index, update) => {
    data['customData'] && data['customData'][0] && data['customData'][0]['data'].find((d) => d.section === Sections.Window_Types)?.data?.splice(index, 1);
    handleData(data);
    setUpdate(!update);
    setMount(!mount);
  };

  return (
    <>
      {tableData && tableData.length !== 0 && (
        <Grid container xs={9} style={{ marginBottom: '3%' }}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Length</Typography>
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Breadth(sqm)</Typography>
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Openable Type</Typography>
                  </TableCell>

                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Window Glazing VLT%</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData &&
                  tableData.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <Typography variant="h6">{row.name}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h6">{row.length}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h6">{row.breadth}</Typography>
                      </TableCell>{' '}
                      <TableCell align="left">
                        <Typography variant="h6">{row.openableType}</Typography>
                      </TableCell>{' '}
                      <TableCell align="left">
                        <Typography variant="h6">{row.windowGlazing}</Typography>
                      </TableCell>
                      <TableCell align="left" style={{ border: 'none' }}>
                        <CustomPopover onEdit={() => handleEdit(row, index)} onDelete={() => handleDelete(data, row, index, update)} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 10,
  },
});
