import React from 'react';

export const AllSteelIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg width="61" height="23" viewBox="0 0 61 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M58.219 0.868188H2.83545C2.07052 0.868188 1.45068 1.48802 1.45068 2.25248V7.79106C1.45068 8.15824 1.59672 8.51056 1.85633 8.77018C2.11595 9.02979 2.46828 9.17583 2.83545 9.17583H8.37357V13.3297H2.83545C2.07052 13.3297 1.45068 13.9495 1.45068 14.7139V20.2525C1.45068 20.6197 1.59672 20.972 1.85633 21.2316C2.11595 21.4913 2.46828 21.6373 2.83545 21.6373H58.219C58.5862 21.6373 58.9385 21.4913 59.1981 21.2316C59.4577 20.972 59.6038 20.6197 59.6038 20.2525V14.7139C59.6038 14.3468 59.4577 13.9949 59.1981 13.7348C58.9385 13.4752 58.5862 13.3296 58.219 13.3296H54.0652V9.17582H58.219C58.5862 9.17582 58.9385 9.02978 59.1981 8.77017C59.4577 8.51055 59.6038 8.15823 59.6038 7.79105V2.25247C59.6038 1.88529 59.4577 1.53343 59.1981 1.27335C58.9385 1.01374 58.5862 0.868164 58.219 0.868164V0.868188ZM4.21927 3.63724H20.8346V6.4063H4.21927V3.63724ZM11.1422 9.17583H13.9112V13.3297H11.1426L11.1422 9.17583ZM4.21927 16.0987H20.8346V18.8678H4.21927V16.0987ZM56.834 18.8678H23.6034V16.0987H56.834V18.8678ZM51.2954 13.3292L16.681 13.3296V9.17582H51.2966L51.2954 13.3292ZM56.834 6.4063H23.6034V3.63724H56.834V6.4063Z"
          fill={color}
          stroke="white"
          stroke-width="0.989011"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={120} height={44} fill="none" {...props}>
        <path
          fill={color}
          stroke="#fff"
          strokeWidth={2}
          d="M115.798 43H3.8A2.8 2.8 0 0 1 1 40.2V29a2.8 2.8 0 0 1 2.8-2.8H15v-8.4H3.8A2.8 2.8 0 0 1 1 15V3.8A2.8 2.8 0 0 1 3.8 1h111.998a2.8 2.8 0 0 1 2.8 2.8V15a2.802 2.802 0 0 1-2.8 2.8h-8.4v8.4h8.4a2.8 2.8 0 0 1 2.8 2.8v11.2a2.802 2.802 0 0 1-2.8 2.8Zm-109.2-5.6h33.6v-5.6h-33.6v5.6Zm14-11.2h5.6v-8.4h-5.599v8.4Zm-14-14h33.6V6.6h-33.6v5.6Zm106.399-5.6h-67.2v5.6h67.2V6.6Zm-11.2 11.201H31.799v8.4h70l-.002-8.4Zm11.2 14h-67.2v5.6h67.2v-5.6Z"
        />
      </svg>
    );
  }
};
