import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CustomLink from '../link/CustomLink';
import SDLogo from '../../../assets/header/SDplus Logo.png';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '70px',
  },
}));

export default function InquiryNavBar() {
  const classes = useStyles();
  return (
    <>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <img src={SDLogo} alt="..." height="70vh" width="auto" />
          {/* <CustomLink
            label="Create an account"
            to="/"
            activeOnlyWhenExact={true}
            style={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '19px',
              letterSpacing: '1.42857px',
              color: '#0F6AFF',
            }}
          /> */}
        </Toolbar>
      </AppBar>
    </>
  );
}
