import React from 'react';
import { withWidth } from '@material-ui/core';
import useScreenType from '../../../../../hooks/checkScreenType';
import KnowledgeHubListDesktop from './components/KnowledgeHubDesktop';
import KnowledgeHubListMobile from './components/KnowledgeHubListMobile';

const KnowledgeHubList = (props) => {
  const { width } = props;
  const { isMobile } = useScreenType(width);

  return (
    <>
      {!isMobile && <KnowledgeHubListDesktop />}
      {isMobile && <KnowledgeHubListMobile />}
    </>
  );
};

export default withWidth()(KnowledgeHubList);
