import productimage1 from '../../../../assets/Marketplace/product/productcardimage1.svg';
import productimage2 from '../../../../assets/Marketplace/product/productcardimage2.svg';
import greenpro from '../../../../assets/Marketplace/product/certification/Green pro 2.svg';

export let dummyCategories = [
  'Cement',
  'Concrete',
  'Steel',
  'Wall Blocks',
  'Flooring',
  'Glazing',
  'Plumbing Fixtures',
  'Fans',
  'Wall Panels',
  'Furniture',
  'Finishes',
  'Cleaning Products',
  'Cement',
  'Concrete',
  'Steel',
  'Wall Blocks',
  'Flooring',
  'Glazing',
  'Plumbing Fixtures',
  'Fans',
  'Wall Panels',
  'Furniture',
  'Finishes',
];

export const products = [
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage2,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage1,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage2,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage1,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage2,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage1,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage2,
    certifications: [greenpro, greenpro],
  },
  {
    name: 'Portland Slag Cement',
    company: 'JSW Cement',
    description:
      'JSW portland slag cement (PSC) is a blended cement, wherein some portion of OPC is replaced with ground granulated blast furnace slag (GGBS), to make the structures more lasting.',
    productimage: productimage2,
    certifications: [greenpro, greenpro],
  },
];
