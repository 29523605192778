import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ResponsiveHome5MobileBigImage from '../../../../assets/bigSVGs/ResponsiveHome5MobileBigImage.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '10%',
  },
  typo: {
    lineHeight: '20px',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  card: {
    maxWidth: 650,
  },
  media: {
    height: 240,
  },
  newRoot: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  cardwrapper: { marginTop: '30px', marginLeft: '5%' },
}));

function ResponsiveHome5() {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" alignContent="center">
        <div data-aos="fade-left" style={{ textAlign: 'center' }}>
          <Typography
            style={{
              lineHeight: '40px',
              letterSpacing: '1px',
              background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: '12%',
            }}
            variant="h3"
          >
            Building Certificates
            <br />
            <br />
          </Typography>
        </div>
        <div data-aos="fade-right" style={{ textAlign: 'center' }}>
          <Typography className={classes.typo3} variant="h5">
            Instantly check feasibility of different building certifications at any time of project design.
            <br />
            <br />
            Make informed decisions to pursue the certification based on cost-benefit analysis of implementation.
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div data-aos="fade-up" style={{ textAlign: 'center' }}>
          <img src={ResponsiveHome5MobileBigImage} style={{ height: 'auto', width: '100%' }} alt="Earth" />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.navbar2}></Grid>
    </>
  );
}
export default ResponsiveHome5;
