import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid, Typography } from '@material-ui/core';
import AdminList from './AdminList';
import AdminProjectBar from './AdminProjectBar';
import sidebarline from '../../../assets/sidebarline.svg';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '14%',
    flexShrink: 0,
    zIndex: 1,
    border: 'none',
  },
  drawerPaper: {
    width: '13%',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  drawerContainer: {
    overflowX: 'hidden',
    // '&::-webkit-scrollbar': {
    //   width: 0,
    //   height: 0,
    // },
  },
  line: {
    paddingTop: '10px',
    paddingBottom: '0%',
  },
}));

export default function AdminSidebar(props) {
  const classes = useStyles();
  const link = props.adminlinks;
  const management = props.management;
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <AdminProjectBar />
        <Typography variant="body2" style={{ paddingLeft: '6%' }}>
          Management
        </Typography>
        <img src={sidebarline} alt="..." className={classes.line} style={{ width: '100%' }} />
        <div className={classes.drawerContainer}>
          <Grid container direction="column" justify="center" item xs>
            <List>
              {management.map((item, index) => (
                <AdminList key={index} to={item.url} label={item.name} />
              ))}
            </List>
          </Grid>
        </div>
        <Typography variant="body2" style={{ paddingLeft: '6%', marginTop: '30px' }}>
          Platform
        </Typography>
        <img src={sidebarline} alt="..." className={classes.line} style={{ width: '100%' }} />
        <div className={classes.drawerContainer}>
          <Grid container direction="column" justify="center" item xs>
            <List>
              {link.map((item, index) => (
                <AdminList key={index} to={item.url} label={item.name} />
              ))}
            </List>
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
