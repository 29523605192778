import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMVillaProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const onebhk = getQuantityValue(schema.hwmVilla_villaOneBHK);
  const twobhk = getQuantityValue(schema.hwmVilla_villaTwoBHK);
  const threebhk = getQuantityValue(schema.hwmVilla_villaThreeBHK);
  const fourbhk = getQuantityValue(schema.hwmVilla_villaFourBHK);
  const rooftoparea = getQuantityValue(schema.hwmVilla_availableVillaRooftopArea);
  const floorheight = getQuantityValue(schema.hwmVilla_floorToFloorHeight);
  const unavailablerooftop = getQuantityValue(schema.hwmVilla_unavailableRooftopArea);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Villa 1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Villa 2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Villa 3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Villa 4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(Number(rooftoparea))} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Unavailable Rooftop Area </Typography>
          <Typography className={classes.textHeader3}>{unavailablerooftop} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Floor Height</Typography>
          <Typography className={classes.textHeader3}>{floorheight} m</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HWMVillaProjectInputs;
