import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CustomLink from '../../../common/link/CustomLink';

const StyledTabs = withStyles({
  indicator: {
    height: 0,
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: '70px',
    minHeight: '70px',
    color: '#000',
  },
}))((props) => <Tab disableRipple {...props} />);

const StyledActiveTab = withStyles((theme) => ({
  root: {
    minWidth: '70px',
    minHeight: '70px',
    color: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
  },
}))((props) => <Tab disableRipple {...props} />);

const linkMap = new Map([
  ['/', 'Home'],
  ['/features', 'Features'],
  ['/team', 'Team'],
  ['/login', 'Login'],
]);
const getTab = (path, current) =>
  path === current ? (
    <StyledActiveTab
      label={
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '0.01em',
            textTransform: 'uppercase',
          }}
        >
          {linkMap.get(path)}
        </Typography>
      }
    />
  ) : (
    <StyledTab
      label={
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '25px',
            letterSpacing: '0.01em',
            textTransform: 'uppercase',
          }}
        >
          {linkMap.get(path)}
        </Typography>
      }
    />
  );

export default function NavMenu({ handleShowLogin, handleFeatures, handleHome, setPurpose }) {
  let location = useLocation();

  return (
    <StyledTabs value={0}>
      <CustomLink label={getTab('/', location.pathname)} to="/" style={{ textDecoration: 'none' }} onClick={handleHome} />
      <CustomLink label={getTab('/features', location.pathname)} style={{ textDecoration: 'none' }} onClick={handleFeatures} />
      {/* <CustomLink label={getTab('/team', location.pathname)} to="/team" style={{ textDecoration: 'none' }} /> */}
      <CustomLink
        label={getTab('/login', location.pathname)}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          handleShowLogin();
          setPurpose('Dropin');
        }}
      />
    </StyledTabs>
  );
}
