import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WhitepaperCard from './WhitePaperCard';
import leftarrow from '../../../../assets/landingpage/ARROW.svg';
import rightarrow from '../../../../assets/landingpage/ARROWRIGHT.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  title: {
    lineHeight: '40px',
    letterSpacing: '1px',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
    paddingBottom: '3%',
  },
  typo3: {
    lineHeight: '30px',
    paddingBottom: '5%',
  },
}));

function Home7(props) {
  const classes = useStyles();
  const handleDownload = () => window.open(pdf1);
  const handleDownload2 = () => window.open(pdf2);
  const handleDownload3 = () => window.open(pdf3);
  const handleDownload4 = () => window.open(pdf4);
  const handleDownload5 = () => window.open(pdf5);
  const handleDownload6 = () => window.open(pdf6);

  const [number, setNumber] = useState(1);
  const handleClick = () => {
    if (number === 1) setNumber(2);
    else if (number === 2) setNumber(1);
  };
  const matches = useMediaQuery('(min-width:890px)');
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(true);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);
  const { Whitepaper1, Whitepaper2, Whitepaper3, Whitepaper4, Whitepaper5, Whitepaper6 } = props;
  const { pdf1, pdf2, pdf3, pdf4, pdf5, pdf6 } = props;
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '2%' }}>
          <div data-aos="fade-right">
            <Typography variant="h3" className={classes.title}>
              White papers
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
          <div data-aos="fade-left">
            <Typography variant="h5" className={classes.typo3}>
              Climate change is a business risk but is also an opportunity for the building sector to innovate and adopt environmentally responsible practices.
              Some of the industry’s pertinent environmental issues, their social and financial consequences and meaningful ways to tackle them optimally are
              examined through a series of white papers.
            </Typography>
          </div>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={1} justifyContent="center" style={{ paddingTop: '8%', paddingLeft: '3%' }}>
            {matches ? <img src={leftarrow} alt="..." style={{ cursor: 'pointer' }} onClick={handleClick} /> : ''}
          </Grid>
          {Whitepaper3 && Whitepaper4 && Whitepaper2 && Whitepaper1 && load ? (
            <Grid container item xs={10} justifyContent="center" style={{ textAlign: 'center', paddingLeft: '2%' }}>
              {number === 1 ? (
                <>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper1 && Whitepaper1} onModalOpen={props.onModalOpen} download={handleDownload} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper2 && Whitepaper2} onModalOpen={props.onModalOpen} download={handleDownload2} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper3 && Whitepaper3} onModalOpen={props.onModalOpen} download={handleDownload3} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper4 && Whitepaper4} onModalOpen={props.onModalOpen} download={handleDownload4} />
                  </Grid>
                  {!matches ? (
                    <>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <WhitepaperCard coverpic={Whitepaper5 && Whitepaper5} onModalOpen={props.onModalOpen} download={handleDownload5} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <WhitepaperCard coverpic={Whitepaper6 && Whitepaper6} onModalOpen={props.onModalOpen} download={handleDownload6} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} />
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper5} onModalOpen={props.onModalOpen} download={handleDownload5} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <WhitepaperCard coverpic={Whitepaper6} onModalOpen={props.onModalOpen} download={handleDownload6} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} />
                </>
              )}
            </Grid>
          ) : (
            <Grid container item xs={10}>
              <Grid item xs={3}>
                <CasaSkeleton variant="rect" height="52vh" width="90%" animation="wave" />
              </Grid>
              <Grid item xs={3}>
                <CasaSkeleton variant="rect" height="52vh" width="90%" animation="wave" />{' '}
              </Grid>
              <Grid item xs={3}>
                <CasaSkeleton variant="rect" height="52vh" width="90%" animation="wave" />{' '}
              </Grid>
              <Grid item xs={3}>
                <CasaSkeleton variant="rect" height="52vh" width="90%" animation="wave" />{' '}
              </Grid>
            </Grid>
          )}
          <Grid item xs={1} justifyContent="center" style={{ textAlign: 'right', paddingTop: '8%', paddingRight: '3%' }}>
            {matches ? <img src={rightarrow} alt="..." style={{ cursor: 'pointer' }} onClick={handleClick} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Home7;
