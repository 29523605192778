import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { FilterSections } from '../FilterSidebar';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  texttitle: MarketplaceTypography.BodySamiBold,
  arrowstyle: {
    color: MarketplaceColors.Secondary,
    fontSize: '180%',
  },
  padding: {
    paddingTop: '5%',
  },
}));

const FilterSidebarEcoLabels = (props) => {
  const classes = useStyles();

  const { URLS } = useContext(ProductsPageContext);
  const { source } = URLS;
  const { section, setSection, CheckboxComponent, ecoLabels, uniqueEcolabels, userSelectedFirstSection } = props;

  const handleSection = (section) => (section === FilterSections.EcoLabels ? setSection(FilterSections.NoSection) : setSection(FilterSections.EcoLabels));

  //implement when a category selected show brands only fo that
  const EcoLabel = (ecolabel) => {
    return CheckboxComponent(section, ecolabel);
  };

  const AllEcoLabels = (ecoLabels) => <>{ecoLabels.map((ecolabel) => EcoLabel(ecolabel?.attributes?.name))}</>;
  const AllEcoLabelsWhenUserSelectedBrands = (ecoLabels) => <>{ecoLabels.map((ecolabel) => EcoLabel(ecolabel))}</>;

  return (
    <Grid container>
      <Typography
        className={`${classes.texttitle} ${classes.padding}`}
        onClick={() => handleSection(section)}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        Eco Labels
        {section === FilterSections.EcoLabels && <ArrowDropUpIcon className={classes.arrowstyle} />}
        {section != FilterSections.EcoLabels && <ArrowDropDownIcon className={classes.arrowstyle} />}
      </Typography>
      {section === FilterSections.EcoLabels && source === NavigatorSource.ecolabels && userSelectedFirstSection !== 'Brands'
        ? AllEcoLabels(ecoLabels)
        : section === FilterSections.EcoLabels && userSelectedFirstSection == 'Brands'
        ? AllEcoLabelsWhenUserSelectedBrands(uniqueEcolabels)
        : section === FilterSections.EcoLabels && source === NavigatorSource.ecolabels && AllEcoLabels(ecoLabels)}
      {section === FilterSections.EcoLabels && source === NavigatorSource.search && AllEcoLabels(ecoLabels)}
    </Grid>
  );
};

export default FilterSidebarEcoLabels;
