import React from 'react';
import useScreenType from '../../../../../../hooks/checkScreenType';
import CertificationsCard from './CertificationsCard';
import CertificationsSpotlightSkeletons from '../../../../common/Skeletons/CertificationsSpotlightSkeletons';
const Certifications = ({ ecoLabels }, props) => {
  const { isMobile } = useScreenType();

  return (
    <>
      {!isMobile && ecoLabels.length === 0 && <CertificationsSpotlightSkeletons />}
      {!isMobile &&
        ecoLabels &&
        ecoLabels.length !== 0 &&
        ecoLabels.slice(0, 5).map((s, index) => index !== 3 && <CertificationsCard index={index} certification={s} />)}
      {isMobile && ecoLabels.length === 0 && <CertificationsSpotlightSkeletons />}
      {isMobile &&
        ecoLabels &&
        ecoLabels.length !== 0 &&
        ecoLabels.slice(0, 5).map((s, index) => index !== 3 && <CertificationsCard index={index} certification={s} />)}
    </>
  );
};

export default Certifications;
