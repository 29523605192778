import React from 'react';

export const AllPaintsIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={81} fill="none" {...props}>
        <g fill={color}>
          <path d="M73.139 10.97h-6.584V5.948A5.955 5.955 0 0 0 60.605 0L8.721 0a5.953 5.953 0 0 0-5.948 5.948v5.02H0v3.12h2.773v5.023a5.954 5.954 0 0 0 5.948 5.947h51.886c3.28 0 5.95-2.667 5.95-5.947v-5.023h5.024v21.616H33.104v14.905h-1.126a3.644 3.644 0 0 0-3.64 3.64v22.878a3.644 3.644 0 0 0 3.64 3.64h5.372a3.645 3.645 0 0 0 3.64-3.64V54.249a3.644 3.644 0 0 0-3.64-3.64h-1.126V38.823h38.475V10.969h-1.56Zm-9.705 8.142a2.832 2.832 0 0 1-2.83 2.827H8.72a2.83 2.83 0 0 1-2.828-2.827V5.948A2.831 2.831 0 0 1 8.72 3.12h51.886c1.56 0 2.83 1.269 2.83 2.828v13.164h-.002ZM37.869 54.248v22.878c0 .286-.233.52-.52.52h-5.373a.521.521 0 0 1-.52-.52V54.248c0-.286.232-.52.52-.52h5.373c.287 0 .52.234.52.52Z" />
          <path d="M10.047 15.602H58.75v3.12H10.047v-3.12Z" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={75} height={81} fill="none" {...props}>
        <g fill={color}>
          <path d="M73.139 10.97h-6.584V5.948A5.955 5.955 0 0 0 60.605 0L8.721 0a5.953 5.953 0 0 0-5.948 5.948v5.02H0v3.12h2.773v5.023a5.954 5.954 0 0 0 5.948 5.947h51.886c3.28 0 5.95-2.667 5.95-5.947v-5.023h5.024v21.616H33.104v14.905h-1.126a3.644 3.644 0 0 0-3.64 3.64v22.878a3.644 3.644 0 0 0 3.64 3.64h5.372a3.645 3.645 0 0 0 3.64-3.64V54.249a3.644 3.644 0 0 0-3.64-3.64h-1.126V38.823h38.475V10.969h-1.56Zm-9.705 8.142a2.832 2.832 0 0 1-2.83 2.827H8.72a2.83 2.83 0 0 1-2.828-2.827V5.948A2.831 2.831 0 0 1 8.72 3.12h51.886c1.56 0 2.83 1.269 2.83 2.828v13.164h-.002ZM37.869 54.248v22.878c0 .286-.233.52-.52.52h-5.373a.521.521 0 0 1-.52-.52V54.248c0-.286.232-.52.52-.52h5.373c.287 0 .52.234.52.52Z" />
          <path d="M10.047 15.602H58.75v3.12H10.047v-3.12Z" />
        </g>
      </svg>
    );
  }
};
