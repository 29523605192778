import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import useApiRequest from '../../hooks/useApiRequest';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SDBasics from '../projects/basics/Basics';
import axios from '../../../config/Axios';
import Header from '../../common/header/Header';
import HeadAndBackIcon from '../../common/header/HeadAndBackIcon';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10%',
  },
  heading: {
    marginTop: '3%',
    width: '100%',
  },
  link: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 850,
    textDecorationLine: 'underline',
    color: '#00C58A',
    paddingTop: '2%',
  },
  subheading: {
    fontWeight: 850,
    color: '#00C58A',
    cursor: 'pointer',
  },
  question: {
    lineHeight: '22px',
    fontWeight: 900,
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingTop: '2%',
  },
  answer: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingLeft: '12px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FAQ = (props) => {
  let history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [section, setSection] = useState('INSIGHTS');
  const [loader, setLoader] = useState(true);

  const [faqs, setFaqs] = useState([]);
  const [assets, setAssets] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest(`/faqs`, {
    verb: 'get',
  });
  if (status === null) makeRequest();

  useEffect(() => {
    if (response && response.data && response.data.data) {
      setFaqs(response.data.data.data);
      setLoader(false);
    }
  }, [response]);

  useEffect(() => {
    axios.get(`/platform-asset`).then((res) => {
      if (res.data && res.data.data) {
        setAssets(res.data.data.data);
      }
    });
  }, []);

  const handleOpen = (section) => {
    if (open === true) setOpen(false);
    setSection(section);
    setOpen(true);
  };

  const handlegoback = () => {
    history && history.goBack();
  };
  let currentprojects = null;

  return (
    <>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      <Header currentprojects={currentprojects && currentprojects} />
      <Grid container xs={12} alignItems="left" className={classes.root}>
        <Grid item xs={2}></Grid>
        <Grid container item xs={8} direction="row">
          <Grid item xs={12}>
            <HeadAndBackIcon heading={`Help`} handleClick={handlegoback} />
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '3%', paddingLeft: 20 }}>
            <SDBasics source="FAQs" />
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '3%', paddingLeft: 20 }}>
            <Typography variant="h4">FAQs</Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: '1%', paddingLeft: 20 }} direction="row">
            {faqs &&
              faqs.map((faq) => {
                return (
                  <>
                    <Grid item xs={12} style={{ marginTop: '25px' }}>
                      <Typography className={classes.subheading} onClick={() => handleOpen(faq.section)}>
                        {faq.section}{' '}
                        <ArrowForwardIosIcon style={faq.section === section && open ? { transform: 'rotateZ(90deg)' } : { transform: 'rotateZ(270deg)' }} />
                      </Typography>
                    </Grid>
                    {faq.section === section && open && <Grid style={{ marginBottom: '-15px' }} />}
                    {faq &&
                      faq.section === section &&
                      faq.data &&
                      open &&
                      faq.data.map((subsec) => {
                        return (
                          <>
                            <Typography className={classes.question}>
                              <FiberManualRecordIcon style={{ fontSize: '10px' }} /> {subsec.question}
                            </Typography>
                            <Typography className={classes.answer}>{subsec.answer}</Typography>
                            {subsec.image ? (
                              <img
                                src={assets.find((asset) => asset._id === subsec.image).assetlocation.URL}
                                alt="..."
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })}
                    {faq.section === section && open && <Grid style={{ height: '30px' }} />}
                  </>
                );
              })}
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
};

export default FAQ;
