import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { MarketplaceColors, MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  stepperstyle: {
    height: '170px',
    width: '170px',
  },
  stepperhead: {
    textAlign: 'center',
    color: MarketplaceColors.Secondary,
    width: '170px',
    paddingTop: '4%',
  },
  steppertext: {
    color: MarketplaceColors.steppertextcolor,
    width: '170px',
    textAlign: 'center',
  },
}));

function Steppercard({ ele, icons }) {
  const classes = useStyles();
  return (
    <Grid container item xs={3} alignItems="flex-start" direction="column" style={{ height: '300px' }}>
      <img src={icons[ele.title]} alt="..." className={classes.stepperstyle} />
      <Typography className={classes.stepperhead} style={MarketplaceTypography.HeaderSmall}>
        {ele.title}
      </Typography>
      <Typography className={classes.steppertext} style={MarketplaceTypography.AllCaps}>
        {ele.text}
      </Typography>
    </Grid>
  );
}

export default Steppercard;
