import React from 'react';

export const AllWallBlocksIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={33} height={46} fill="none" {...props}>
        <g fill="#213162">
          <path d="M1.452 7.156C.422 5.05.01 2.799.289.786a.91.91 0 0 1 .9-.786h29.66a.91.91 0 0 1 .901.786c.28 2.014-.134 4.265-1.162 6.37a84.488 84.488 0 0 1 0 31.19c1.028 2.103 1.441 4.354 1.162 6.369a.91.91 0 0 1-.901.785H1.189a.91.91 0 0 1-.9-.786c-.28-2.013.133-4.264 1.162-6.37a84.498 84.498 0 0 1 0-31.189Zm28.56 36.524c.04-1.376-.304-2.859-.964-4.291h-3.996a.91.91 0 1 1 0-1.82h3.813a82.629 82.629 0 0 0 0-29.637h-3.813a.91.91 0 1 1 0-1.82h3.997c.659-1.433 1.003-2.915.964-4.292H2.025c-.04 1.376.305 2.859.965 4.292h3.997a.91.91 0 1 1 0 1.82H3.174a82.628 82.628 0 0 0 0 29.637h3.813a.91.91 0 1 1 0 1.82H2.99c-.66 1.433-1.005 2.915-.965 4.291h27.988Z" />
          <path d="M4.898 26.301a.91.91 0 0 1 .91-.91h20.425a.91.91 0 1 1 0 1.82H5.808a.91.91 0 0 1-.91-.91ZM26.233 20.11H5.808a.91.91 0 1 1 0-1.82h20.425a.91.91 0 1 1 0 1.82Z" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={93} height={62} fill="none" {...props}>
        <mask id="a" fill="#fff">
          <rect width={44} height={20} x={25} y={21} rx={1.7} />
        </mask>
        <rect width={44} height={20} x={25} y={21} stroke={color} strokeWidth={6} mask="url(#a)" rx={1.7} />
        <mask id="b" fill="#fff">
          <rect width={44} height={20} rx={1.7} />
        </mask>
        <rect width={44} height={20} stroke={color} strokeWidth={6} mask="url(#b)" rx={1.7} />
        <mask id="c" fill="#fff">
          <rect width={44} height={20} y={42} rx={1.7} />
        </mask>
        <rect width={44} height={20} y={42} stroke={color} strokeWidth={6} mask="url(#c)" rx={1.7} />
        <mask id="d" fill="#fff">
          <rect width={44} height={20} x={49} y={42} rx={1.7} />
        </mask>
        <rect width={44} height={20} x={49} y={42} stroke={color} strokeWidth={6} mask="url(#d)" rx={1.7} />
        <mask id="e" fill="#fff">
          <rect width={44} height={20} x={49} rx={1.7} />
        </mask>
        <rect width={44} height={20} x={49} stroke={color} strokeWidth={6} mask="url(#e)" rx={1.7} />
      </svg>
    );
  }
};
