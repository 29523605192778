import React, { useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import closedpolygon from '../../../../../../assets/Marketplace/closedpolygon.svg';
import DrawerLogicalItem from './DrawerLogicalItem';
import CustomCheckBox from '../../../../../common/checkbox/CustomCheckBox';
import { FilterSubSections } from '../../../views/certification-and-super-categories/components/desktopcomponents/sidebar/FilterSidebar';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { Default, NavigatorSource } from '../../../utils/Defaults';
import DrawerFiltersLogicalList from './DrawerFiltersLogicalList';
import { useLocation, useHistory } from 'react-router-dom';
import { useState } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles(() => ({
  checkboxpadding: {
    paddingLeft: '2px',
  },
}));

const DrawerLogicalList = ({ data, handleClickAway, handleRouting, source, filters }) => {
  const { URLS, filtersData, handleFilterData, handleReset, handleAppliedFilters, handleURL, handleCategory, categoryarr } = useContext(ProductsPageContext);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  // const [sourceurl, setSource] = useState(query.get('source'));
  // const [category, setCategory] = useState(query.get('category'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  // const [page, setpage] = useState(query.get('page'));
  // const [pagesize, setpagesize] = useState(query.get('pagesize'));
  const [subcategory, setSubcategory] = useState(query.get('subcategory'));
  const [brands, setBrands] = useState(query.get('brands'));
  const [filtersurl, setFiltersurl] = useState(query.get('filters'));
  const [sort, setSort] = useState(query.get('sort'));
  const [searchquery, setSearchquery] = useState(query.get('searchquery'));

  const { Primary: marketplacePrimary } = MarketplaceColors;
  const [expanded, setExpanded] = React.useState(true);
  const handleChange = () => setExpanded(!expanded);
  let history = useHistory();
  const classes = useStyles();

  const getCheckboxState = (label, name) => {
    let findfromfilterdata = filtersData && filtersData.find((d) => d.value === label) && filtersData.find((d) => d.value === label);
    // if(findfromfilterdata){
    //   setCategorystr(name)
    // }
    return findfromfilterdata?.checked === false ? false : findfromfilterdata?.checked === true ? true : false;
  };
  useEffect(() => {
    // This will run whenever the 'data' prop changes
  }, [data]);
  let iscategory = filtersData.filter((ele) => ele.id === FilterSubSections.SubCategory);

  return (
    <>
      {/* accordion for subcategories */}
      <Accordion square expanded={expanded} onChange={handleChange}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography
                style={{ color: marketplacePrimary, fontSize: '16px' }}
                onClick={() => {
                  handleRouting();
                  handleClickAway();
                  // handleReset();
                }}
              >
                {source && source === 'filter' && iscategory.length !== 0 && (
                  <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary, paddingRight: '10px' }} />
                )}
                <b>{source && source !== 'filter' ? data?.name : `Subcategories`}</b>
              </Typography>
            </Grid>
            <Grid item style={{ color: marketplacePrimary }}>
              <img src={closedpolygon} alt="closedpolygon" />
            </Grid>
          </Grid>
        </AccordionSummary>
        <Grid container direction="row" style={{ marginTop: 0, marginLeft: '5%' }}>
          {source !== 'filter' &&
            data?.subcategories?.map(({ attributes }, index) => {
              return (
                <DrawerLogicalItem
                  index={index}
                  name={attributes?.name}
                  handleRouting={() => {
                    handleReset();
                    handleURL(
                      data?.name,
                      subcategory,
                      brands,
                      supercategory,
                      Default.page,
                      Default.pagesize,
                      NavigatorSource.categories,
                      filtersurl,
                      sort,
                      searchquery
                    );
                    handleFilterData(FilterSubSections.SubCategory, attributes?.name, true);
                    history.push(
                      `/marketplace/categoryDetails?source=${NavigatorSource.categories}&category=${data?.name}&page=${Default.page}&pagesize=${Default.pagesize}&subcategory=${attributes?.name}`
                    );
                    handleClickAway();
                  }}
                />
              );
            })}
          {source === 'filter' &&
            data &&
            data?.subcategories?.map(({ id, attributes }, index) => {
              return (
                <Grid container className={classes.checkboxpadding}>
                  <CustomCheckBox
                    handleChange={(e) => {
                      handleFilterData(FilterSubSections.SubCategory, attributes?.name, e.target.checked);
                      handleCategory(data?.name, attributes?.name, e.target.checked);
                    }}
                    checkState={getCheckboxState(attributes?.name, data.name)}
                    color={MarketplaceColors.Secondary}
                    checkedcolor={MarketplaceColors.Secondary}
                    label={attributes?.name}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Accordion>
      {/* accordion for filters */}
      {filters?.map((d) => (
        <DrawerFiltersLogicalList categoryname={data?.name} data={d && d} handleClickAway={handleClickAway} handleRouting={handleRouting} source={source} />
      ))}
    </>
  );
};

export default DrawerLogicalList;
