import React from 'react';
import { Text, View } from '@react-pdf/renderer';

const Header = (props) => {
  return (
    <View style={{ flexDirection: 'row', display: 'flex' }}>
      <View style={{ direction: 'column', width: '50%', borderTop: '0.5px solid grey' }}></View>
      <View style={{ width: '35%' }}>
        <Text
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            textTransform: 'uppercase',
            color: 'grey',
            fontSize: '9px',
            paddingTop: '-5px',
            textAlign: 'center',
          }}
        >
          {props.companyname}
        </Text>
      </View>
      <View style={{ direction: 'column', width: '12%', borderTop: '0.5px solid grey' }}></View>
      <View>
        <View style={{ backgroundColor: '#E6E6E6', borderRadius: '30px', height: '18px', width: '18px', marginTop: '-10px' }}>
          <Text
            render={({ pageNumber, totalPages }) => `${pageNumber - 1}`}
            style={{ color: 'grey', fontSize: '9px', paddingTop: '5px', paddingLeft: '5px', justifyContent: 'flexEnd' }}
          />
        </View>
      </View>
    </View>
  );
};

export default Header;
