import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, makeStyles, withWidth } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import MenuItems from './MenuItems';
import useScreenType from '../../../../../hooks/checkScreenType';
import MobileProductList from './components/MobileProductList';
import DesktopProductList from './components/DesktopProductList';
import { MarketplaceStyles } from '../../../config/index';
import useGraphqlRequest from '../../../../catalogue/Common/SendRequest';
import { query, supercategoriesquery } from '../Marketplace.query';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
    paddingBottom: MarketplaceStyles.sectionPadding.paddingBottom,
  },
  customContainer: {
    maxWidth: 1440,
    margin: '0 auto',
  },
}));

const MarketplaceSuperCategoriesSpotlight = (props) => {
  const { width, supercategories } = props;
  const { isMobile } = useScreenType(width);
  const classes = useStyles();
  const [selected, setSelected] = useState('Structure');
  const [supercategoryProductList, setSupercategoryProductList] = useState('');

  console.log(supercategoryProductList, 'supercategoryProductListsupercategoryProductList');

  const [state, SendRequest] = useGraphqlRequest(supercategoriesquery(selected));
  if (state && state?.response === null) SendRequest();

  useEffect(() => {
    if (state && state?.response !== null) {
      // console.log(state?.response, 'state?.response');
      setSupercategoryProductList(state?.response?.data?.data?.customSuperCategories?.products);
    }
  }, [state]);
  // console.log(selected, 'state?.response');

  function getRandomElementsFromArray(arr, numElements) {
    const copyArray = [...arr];
    for (let i = copyArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
    }
    return copyArray.slice(0, numElements);
  }

  return (
    <Container className={classes.customContainer}>
      <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          <Typography variant="h5" style={{ color: MarketplaceColors.Primary }}>
            <b>Products by Construction Stage</b>
          </Typography>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          {/* <Typography variant="h6" style={{ color: MarketplaceColors.Secondary }}>
            Choose Financially and Environmentally Impactful Materials
          </Typography> */}
        </Grid>
        <MenuItems
          width={width}
          selected={selected}
          supercategories={supercategories && supercategories}
          setSelected={setSelected}
          // setSupercategoryProductList={setSupercategoryProductList}
          state={state}
        />
        {isMobile ? (
          <MobileProductList
            width={width}
            supercategoryProductList={getRandomElementsFromArray(supercategoryProductList, 16)}
            selected={selected}
            supercategories={supercategories && supercategories}
          />
        ) : (
          <DesktopProductList
            width={width}
            supercategoryProductList={getRandomElementsFromArray(supercategoryProductList, 16)}
            selected={selected}
            supercategories={supercategories && supercategories}
          />
        )}
      </Grid>
    </Container>
  );
};

export default withWidth()(MarketplaceSuperCategoriesSpotlight);
