import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
        width: '166px',
        paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: '166px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '110px',
        },
      },
    },
  },
  select: {
    padding: '1px',
    borderRadius: `15px`,
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      padding: '2px',
      borderRadius: `15px`,
    },
  },
}));

function CommonMapperSelect(props) {
  const classes = useStyles();

  return (
    <>
      {props.fixturesData ? (
        props.fixturesData.map((d) => {
          if (d.name === props.name) {
            return (
              <>
                <Grid alignItems="left" item xs={props.xs} style={props.style}>
                  <Typography variant="h6" color={props.typocolor}>
                    {d.displayName}
                  </Typography>
                  <br />
                  <FormControl variant="outlined" style={{ width: '166px', borderRadius: `50px` }} className={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                    <Select
                      disabled={props.disabled}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label={d.displayName}
                      placeholder={d.displayName}
                      className={classes.select}
                      value={props.value}
                    >
                      {d && d.options.length > 0 && props.stpDisable !== true
                        ? d.options
                            .filter((e) => e !== 'Undecided')
                            .map((value) => {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    props.onChange(value);
                                    props.appendChanged(d._id, value);
                                  }}
                                  value={value}
                                  name={value}
                                  style={{ paddingTop: '2%' }}
                                >
                                  {value}
                                </MenuItem>
                              );
                            })
                        : d &&
                          d.options.length > 0 &&
                          d.options.map((value) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  props.onChange(value);
                                  props.appendChanged(d._id, value);
                                }}
                                value={value}
                                name={value}
                                style={{ paddingTop: '2%' }}
                              >
                                {value}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            );
          }
        })
      ) : (
        <Grid alignItems="left" item xs={props.xs} style={{ marginLeft: '-1%' }}>
          <CasaSkeleton variant="rect" height="2vh" width="20%" animation="wave" style={{ marginTop: '2%' }} />
          <br />
          <CasaSkeleton variant="rect" height="7vh" width="20%" animation="wave" />
        </Grid>
      )}
    </>
  );
}

export default CommonMapperSelect;
