import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorLogo from '../../../assets/anim/error2.jpg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PrimaryButton from '../button/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '10%',
  },
  spacing: {
    paddingTop: '2%',
  },
}));
const Error = () => {
  const classes = useStyles();
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <>
      <Grid container alignItems="center" item xs={12} className={classes.root}>
        <Grid item xs={1} align="right"></Grid>
        <Grid item xs={1} align="left">
          <img src={ErrorLogo} height="100px" alt="..." />
        </Grid>
        <Grid item xs={9} align="left">
          <Typography variant="h3">Server Not Found</Typography>
          <Grid container direction="column" item xs={12}>
            <Typography variant="h5">
              <FiberManualRecordIcon /> Check the address for typing error such as ww.example.com instead of www.example.com
            </Typography>
            <Typography variant="h5">
              <FiberManualRecordIcon /> If you are unable to load any page check your computer's network connection.
            </Typography>
            <Typography variant="h5">
              <FiberManualRecordIcon /> If you computer or network is protected by a firewall or proxy make sure that chrome is permitted to access the web.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1} align="right"></Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} className={classes.spacing}>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <PrimaryButton children="Reload" onClick={handleClick} />
        </Grid>
      </Grid>
    </>
  );
};
export default Error;
