import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const BootstrapInput = withStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      display: 'none', // Hide the menu icon
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontSize: 14,
    width: '70%',
    height: '85px',
    border: 'none',
    outline: 'none',
    padding: '10px 26px 10px 12px',
    fontFamily: 'Lato',
    fontWeight: 500,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    whiteSpace: 'nowrap', // Prevent text from wrapping
    overflow: 'hidden', // Hide overflowed text
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      border: 'none',
      outline: 'none',
    },
    '&:after': {
      border: 'none',
      outline: 'none',
    },
  },
}))(InputBase);

export default function TableDropdown({ arr, tableboxname, menuOpen, setMenuOpen }) {
  const classes = useStyles();
  const obj = {};
  let age = tableboxname;
  const handleChange = () => {};
  const handleClick = (ele, value) => {
    obj[ele] = value;
  };
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);

  return (
    <FormControl className={classes.formControl} variant="standard">
      <Select
        value={age}
        onChange={handleChange}
        displayEmpty
        input={<BootstrapInput name="age" id="age-customized-select" />}
        renderValue={(selected) => selected || 'None'}
        className={classes.selectEmpty}
        open={menuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
        // IconComponent={() => <ArrowDropDownIcon style={{ color: '#66E3BE', fontSize: '20px' }} />}
      >
        <MenuItem value="" style={{ fontSize: 16, fontFamily: 'Lato', fontWeight: 600, fontStyle: 'normal' }}>
          {tableboxname}
        </MenuItem>
        {arr.map((ele) => {
          return (
            <MenuItem value={10}>
              {obj[ele] === true ? (
                <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => handleClick(ele, false)} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => handleClick(ele, true)} />
              )}
              {ele}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 80,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  },
  selectEmpty: {
    backgroundColor: 'transparent',
    color: 'inherit',
    width: '100px', ///change the width
    height: '50px',
    '&:focus': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      border: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
}));
