import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    height: 220,
    borderRadius: '10px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  spacing: {
    marginTop: '3%',
  },
  text: {
    marginBottom: '2%',
  },
});

export default function WaterCard({ cardheading, body, style }) {
  const classes = useStyles();

  return (
    <Grid className={classes.spacing}>
      <Typography className={classes.text} variant="h5">
        {cardheading}
      </Typography>
      <Card className={classes.root} variant="outlined" style={style}>
        <CardContent>{body}</CardContent>
      </Card>
    </Grid>
  );
}
