import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    width: '100%',
    background: 'linear-gradient(90deg, ' + theme.palette.primary.main + ' 0.58%,' + theme.palette.primary.light + ' 100%)',
    borderRadius: '8px',
    '&:focus': {
      outline: 'none',
    },
  },
}))(Button);

export default CustomButton;
