import { useState, useEffect } from 'react';

const useScrollNavigationBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const halfOfNavBarHeight = 64;
      setIsScrolled(scrollTop > halfOfNavBarHeight);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { isScrolled };
};

export default useScrollNavigationBar;
