import React from 'react';
import { Text, View } from '@react-pdf/renderer';

const Footer = (props) => {
  return (
    <View style={{ flexDirection: 'row', display: 'flex', position: 'absolute' }}>
      <View style={{ width: '15%' }}>
        <Text
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            textTransform: 'uppercase',
            color: 'grey',
            fontSize: '9px',
            paddingTop: '-5px',
            textAlign: 'center',
            // position: 'absolute',
            // fontSize: 12,
            // bottom: 30,
            // left: 0,
            // right: 0,
            // textAlign: 'center',
            // color: 'grey',
          }}
        >
          {` SD+ ${props.year}`}
        </Text>
      </View>
      <View style={{ direction: 'column', width: '85%', borderTop: '0.5px solid grey' }}></View>
      <View></View>
    </View>
  );
};

export default Footer;
