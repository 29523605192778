import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import image1 from '../../../../assets/Vector 74.svg';
import numberWithCommas from '../../../../utils/numberToComma';
import appartment1 from '../../../../assets/appartment-1.svg';

export default function AllProjectsCard({ icon, cardaction, rootstyle, style, name, location, space, category, builtup, unit }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: 600,
      maxHeight: 200,
      boxShadow: '2px 8px 16px rgba(24, 50, 119, 0.08)',
      borderRadius: '8px',
      boxSizing: 'border-box',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
    },
    media: {
      width: 60,
      height: 70,
      marginleft: '1rem',
    },
    line: {
      marginLeft: '-3rem',
      height: '105px',
      marginTop: '-1rem',
    },
    linesecond: {
      marginLeft: '-1.3rem',
      height: '105px',
      marginTop: '-1rem',
    },
    // spacing: {
    //   // paddingLeft: theme.spacing(`${space}`),
    // },
    small: {
      paddingLeft: theme.spacing(0),
    },
    allignment: {
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    textcolor: {
      color: 'rgba(0, 0, 0, 0.38)',
      marginTop: '-1rem',
      marginLeft: '-4rem',
    },
    text: {
      marginLeft: '-4rem',
      paddingTop: '10px',
      fontSize: '15px',
      fontWeight: 'bold',
    },
    buttomTextColor: {
      color: 'rgba(0, 0, 0, 0.38)',
      marginTop: '2rem',
      marginLeft: '-4rem',
    },
    buttomText: {
      paddingTop: '10px',
      marginLeft: '-4rem',
      fontSize: '15px',
      fontWeight: 'bold',
    },
    top: {
      marginTop: '8%',
      marginLeft: '-3%',
    },
  }));
  const classes = useStyles();

  return (
    <Card className={classes.root} style={rootstyle}>
      <CardContent style={{ outline: 'none' }}>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            {/* <CardMedia className={classes.media} image={icon} />
             */}
            <img
              src={icon}
              style={{ height: 80, width: 80, padding: icon == appartment1 ? '6%' : '' }}
              alt="website logo"
            />
          </Grid>
          <Grid item xs={7}>
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <img src={image1} alt="..." className={classes.line} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.textcolor}>
                  Project name
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {name ? (
                    name.charAt(0).toUpperCase() + name.slice(1)
                  ) : (
                    <CasaSkeleton variant="rect" height="2vh" width="80%" animation="wave" className={classes.top} />
                  )}
                </Typography>
                <Typography variant="h6" className={classes.buttomTextColor}>
                  Project Category
                </Typography>
                <Typography variant="h6" className={classes.buttomText}>
                  {category ? (
                    category.charAt(0).toUpperCase() + category.slice(1)
                  ) : (
                    <CasaSkeleton variant="rect" height="2vh" width="80%" animation="wave" className={classes.top} />
                  )}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h6" className={classes.textcolor}>
                  Built-Up Area
                </Typography>
                <Typography variant="h6" className={classes.text}>
                  {builtup ? (
                    `${numberWithCommas(builtup)} ${unit}`
                  ) : (
                    <CasaSkeleton variant="rect" height="2vh" width="80%" animation="wave" className={classes.top} />
                  )}
                </Typography>
                <Typography variant="h6" className={classes.buttomTextColor}>
                  Location
                </Typography>
                <Typography variant="h6" className={classes.buttomText}>
                  {location ? (
                    location.split(',')[0].length > 11 ? (
                      `${location.split(',')[0].slice(0, 11)}...`
                    ) : (
                      location.split(',')[0]
                    )
                  ) : (
                    <CasaSkeleton variant="rect" height="2vh" width="80%" animation="wave" className={classes.top} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <img src={image1} alt="..." className={classes.linesecond} />
            <div style={{ marginTop: '-7rem' }}>{cardaction}</div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
