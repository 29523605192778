import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import numberWithCommas from '../../../../../utils/numberToComma';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    boxShadow: '4px 4px 12px rgba(78, 94, 126, 0.2), -3px -3px 6px 0.5px #FFFFFF',
    padding: theme.spacing(0, 1, 2),
  },
  text1: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    paddingTop: '15px',
  },
  text2: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    paddingTop: '15px',
  },
}));

const IGBCCard = (props) => {
  const classes = useStyles();
  const igbc = props.igbc;
  return (
    <>
      <Paper className={classes.paper}>
        <Grid item container xs={12}>
          <Grid item xs={2} style={{ marginLeft: '10px' }}>
            <Grid container direction="column">
              <Typography className={classes.text1}>Project Name</Typography>
              <Typography className={classes.text2}>{igbc && igbc.stationName}</Typography>
              <Typography className={classes.text1}>Possession</Typography>
              <Typography className={classes.text2}>Occupied - {igbc.launchDate.split(' ')[1]}</Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={1}> */}
          <Divider orientation="vertical" flexItem style={{ marginTop: '10px', marginRight: '15px' }} />
          {/* </Grid> */}

          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography className={classes.text1}>IGBC rating</Typography>
              <Typography
                className={classes.text2}
                style={{
                  color: `${
                    igbc && igbc.igbcRating === 'Platinum'
                      ? '#ADB5BD'
                      : igbc.igbcRating === 'Gold'
                      ? '#E4C761'
                      : igbc.igbcRating === 'Silver'
                      ? '#8D949B'
                      : '#42E198'
                  }`,
                }}
              >
                {igbc && igbc.igbcRating}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography className={classes.text1}>Type</Typography>
              <Typography className={classes.text2}>{igbc && igbc.type}</Typography>
              <Typography className={classes.text1}>Avg price per sqft</Typography>
              <Typography className={classes.text2}>
                {igbc && igbc.avgPricePerSqftForProject === null ? 'N/A' : `₹ ${numberWithCommas(igbc.avgPricePerSqftForProject)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Typography className={classes.text1}>Site area</Typography>
              <Typography className={classes.text2}>{igbc && igbc.sitearea}</Typography>
              <Typography className={classes.text1}>No. of apartments</Typography>
              <Typography className={classes.text2}>{igbc && igbc.noOfapts}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography className={classes.text1}>Built-up area</Typography>
              <Typography className={classes.text2}>{igbc && igbc.builtupArea === null ? 'N/A' : numberWithCommas(igbc.builtupArea)}</Typography>
              <Typography className={classes.text1}>Configurations</Typography>
              <Typography className={classes.text2}>{igbc && igbc.configurations}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IGBCCard;
