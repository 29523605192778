import React, { useEffect } from 'react';
import CustomHeader from '../../../common/CustomHeader';
import { useHistory } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import InteriorTable from './components/interiorTable';
let dwellingtype = [];
let windowsdata = [];
let dwellingunitInterior = [];
const DwellingUnitInteriors = ({ residentHealthAndWellBeingData, mount, setMount }) => {
  const history = useHistory();
  const [collapseSection, setCollapseSection] = useState(true);
  const [editTable, setEditTable] = useState(false);
  const [editableData, setEditableData] = useState('');
  const [update, setUpdate] = useState(false);
  // const [showDescription, setsHowDescription] = useState(true);
  const [indexforEdit, setIndexForEditData] = useState('');

  const handleExpandIcon = () => setCollapseSection((prev) => !prev);
  const handleEditableData = (data, type, section) => {
    const body = {};
    body['data'] = data;
    body['type'] = type;
    body['section'] = section;
    setEditableData(body);
  };

  useEffect(() => {
    console.log('called');
  }, [mount]);
  if (
    residentHealthAndWellBeingData &&
    residentHealthAndWellBeingData['customData'] &&
    residentHealthAndWellBeingData['customData'][0] &&
    residentHealthAndWellBeingData['customData'][0]['data']
  ) {
    const customData = residentHealthAndWellBeingData['customData'][0]['data'];
    const dwellingunitdata = customData.find((d) => d.section === 'Dwelling Unit Types');
    const windowdata = customData.find((d) => d.section === 'Window Types');
    const interiordata = customData.find((d) => d.section === 'Dwelling Unit Interiors');
    if (dwellingunitdata) {
      dwellingtype = dwellingunitdata['data'];
      windowsdata = windowdata['data'];
      dwellingunitInterior = interiordata['data'];
      // setsHowDescription(false);
    }
  }

  return (
    <>
      <CustomHeader sectionName="Dwelling Unit Interiors" history={history} expand={true} handleExpandIcon={handleExpandIcon} />

      <Collapse in={collapseSection}>
        <InteriorTable
          // setsHowDescription={setsHowDescription}
          dwellingtype={dwellingtype}
          windowsdata={windowsdata}
          dwellingunitInterior={dwellingunitInterior}
          update={update}
          handleEdit={(data, type, index) => {
            setIndexForEditData(index);
            setEditTable(true);
            handleEditableData(data, type, 'Dwelling Unit Interiors');
          }}
          mount={mount}
          setMount={setMount}
          residentHealthAndWellBeingData={residentHealthAndWellBeingData}
          editableData={editableData}
          handleEditableData={(d) => setEditableData(d)}
          setEditableData={setEditableData}
          editTable={editTable}
          indexforEdit={indexforEdit}
          setEditTable={setEditTable}
          setUpdate={setUpdate}
        />
      </Collapse>
    </>
  );
};

export default DwellingUnitInteriors;
