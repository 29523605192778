import React from 'react';
import logo from '../../../../../assets/new-pdf/SDplus Logo 1.png';
import pageCover from '../../../../../assets/new-pdf/pdf-cover.png';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 20,
    height: window.innerHeight - 20,
  },
  viewPage1: {
    margin: '30px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  image: {
    height: '35px',
    width: '79px',
  },
  orgLogo: {
    height: '120.68px',
    width: '110px',
    marginLeft: '65%',
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
});

const Page1 = (props) => {
  return (
    // <Page size="A4" style={styles.page}>
    <View style={styles.viewPage1}>
      <View>
        <Image style={styles.image} src={logo} />
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View style={{ direction: 'column' }}>
          <View>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', paddingTop: '10px' }}>Sustainability REPORT</Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
                color: '#15B7B9',
                fontSize: '24px',
              }}
            >
              {props.projectname}
            </Text>
          </View>
        </View>
        <View >
          <Image style={styles.orgLogo} src={`${props.asset && props.asset.URL}` + "?not-from-cache"} />
        </View>
      </View>
      <View>
        <Text
          style={{
            fontFamily: 'Open Sans',
            color: '#000000',
            fontSize: '12px',
            paddingTop: '5px',
          }}
        >
          {props.date}
        </Text>
      </View>
      <View>
        <Image style={styles.pageCover} src={pageCover} />
      </View>
    </View>
    // </Page>
  );
};

export default Page1;
