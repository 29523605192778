import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import iconforgotoproject from '../../../assets/databaseImages/iconforgotoproject.svg';

const HeadAndBackIcon = (props) => {
  let history = useHistory();
  let { id } = useParams();
  const handleClick = () => {
    history.push(`/projects/${id}/goals`);
  };
  const handleNavigation = () => {
    if (props.somethingChanged === true) {
      props.toogleShowModal(true);
      props.SETNEXT('insights');
    } else history.push(`/projects/${id}/insights`);
  };
  return (
    <>
      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={0} align="left" style={{ marginTop: '0.5%' }}>
          {props.showArrow === false ? null : (
            <ArrowBackIosIcon
              onClick={props.handleClick}
              style={{ color: props.arrowColor ? props.arrowColor : '#66E3BE', cursor: 'pointer', width: '20.41px', height: '20px' }}
            />
          )}
        </Grid>
        <Grid item xs={props.visitGoals === true ? 9 : 10} align="left">
          <Typography variant={props.headingVariant ? props.headingVariant : 'h4'} style={props.headingStyle}>
            {props.heading}
          </Typography>
        </Grid>
        {props.visitGoals === true ? (
          <Grid item xs={2} align="left" container>
            {props.endIcon === true ? (
              <Grid xs={1}>
                <img src={iconforgotoproject} />
              </Grid>
            ) : (
              ''
            )}
            <Grid xs={10} style={{ paddingLeft: '3%' }}>
              <Typography
                variant="h6"
                onClick={props.handleNavigation ? handleNavigation : handleClick}
                style={{
                  background: 'transparent',
                  color: '#49DDB1',
                  cursor: 'pointer',
                }}
              >
                {props.navigatingText ? props.navigatingText : `VISIT GOALS`}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default HeadAndBackIcon;
