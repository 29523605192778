import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import EnergyFlowDiagram from './EnergyFlowDiagram';
import WaterFlowDiagram from './WaterFlowDiagram';
import WasteFlowDiagram from './WasteFlowDiagram';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    top: 30,
    marginTop: '25%',
  },
});

const Page7 = (props) => {
  const {
    mainGrid,
    residentiallighting,
    residentialhvac,
    residentialfan,
    residentialhotwater,
    homeappliances,
    commonarea,
    dieselgenerator,
    solarweaterheater,
    solarpv,
    biogas,
    //water
    TreatedGreyWater,
    FreshWater,
    CentralizedRo,
    WTP,
    CommonAreaUse,
    DomesticWaterUse,
    HealthFaucet,
    Flushing,
    Drinking,
    GreyWaterSTP,
    BlackWaterSTP,
    //waste
    rejectwaste,
    sanitarywaste,
    medicalwaste,
    ewaste,
    drywaste,
    organicwaste,
  } = props;
  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      <Text
        style={{ fontFamily: 'Open Sans', fontWeight: 'Bold', fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}
      >{`${props.type.toUpperCase()} FLOW DIAGRAM`}</Text>
      <View style={{ marginTop: '5%' }}>
        {props.type === 'energy' && (
          <EnergyFlowDiagram
            mainGrid={mainGrid}
            residentiallighting={residentiallighting}
            residentialhvac={residentialhvac}
            residentialfan={residentialfan}
            residentialhotwater={residentialhotwater}
            homeappliances={homeappliances}
            commonarea={commonarea}
            dieselgenerator={dieselgenerator}
            solarweaterheater={solarweaterheater}
            solarpv={solarpv}
            biogas={biogas}
          />
        )}
        {props.type === 'water' && (
          <WaterFlowDiagram
            TreatedGreyWater={TreatedGreyWater}
            FreshWater={FreshWater}
            CentralizedRo={CentralizedRo}
            WTP={WTP}
            CommonAreaUse={CommonAreaUse}
            DomesticWaterUse={DomesticWaterUse}
            HealthFaucet={HealthFaucet}
            Flushing={Flushing}
            Drinking={Drinking}
            GreyWaterSTP={GreyWaterSTP}
            BlackWaterSTP={BlackWaterSTP}
          />
        )}
        {props.type === 'waste' && (
          <WasteFlowDiagram
            rejectwaste={rejectwaste}
            sanitarywaste={sanitarywaste}
            medicalwaste={medicalwaste}
            ewaste={ewaste}
            drywaste={drywaste}
            organicwaste={organicwaste}
          />
        )}
      </View>
      <View style={styles.footerMargin}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default Page7;
