import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { categoriesQuery, productsQuery } from '../graphql-queries/CategoriesSpotlight.query';

const useCategorieSpotlight = () => {
  let [allCategories, setAllCategories] = useState('');
  let [allProducts, setAllProducts] = useState('');
  let [categoriesWithProductsCount, setCategoriesWithProductsCount] = useState('');

  const [categories, SendCategoriesRequest] = useGraphqlRequest(categoriesQuery);
  const [products, productsRequest] = useGraphqlRequest(productsQuery);

  if (categories && !categories.response) SendCategoriesRequest();
  if (products && !products.response) productsRequest();

  useEffect(() => {
    if (categories.response !== null) setAllCategories(categories.response?.data?.data?.categories?.data);
    if (products.response !== null) setAllProducts(products.response?.data?.data?.products?.data);
  }, [categories, products]);

  useEffect(() => {
    if (allProducts && allCategories) {
      const categoryCounts = {};
      allProducts.forEach((product) => {
        if (categoryCounts[product.attributes.category.data.attributes.name]) categoryCounts[product.attributes.category.data.attributes.name]++;
        else categoryCounts[product.attributes.category.data.attributes.name] = 1;
      });
      const categoriesWithCounts = Object.keys(categoryCounts).map((category) => ({
        name: category,
        count: categoryCounts[category],
      }));
      categoriesWithCounts.sort((a, b) => b.count - a.count);
      setCategoriesWithProductsCount(categoriesWithCounts);
    }
  }, [allCategories, allProducts]);

  return { allCategories, allProducts, categoriesWithProductsCount };
};

export default useCategorieSpotlight;
