import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import UnitAwareTextField from '../../../common/textfield/UnitAwareTextField';
import Skeletons from '../../../common/skeleton/Skeletons';

function UnitAwareTextFieldInputs(props) {
  const { requiredpips, unit, appendChanged, skeleton, area, appendChangedForRequiredPIP, subCategory } = props;
  const [sortedRequiredPIPs, setSortedRequiredPIPs] = useState([]);

  useEffect(() => {
    if (requiredpips) {
      const basicPIPs = ['project__pi_siteArea', 'project__pi_builtUpArea'];
      const sortedData =
        requiredpips?.data?.filter((field) => basicPIPs.includes(field.name))?.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name)) || [];

      setSortedRequiredPIPs(sortedData);
    }
  }, [requiredpips]);

  const villaAdditionalPips = ['project__pi_availableVillaRooftopArea'];
  const apartmentAdditionalPips = ['project__pi_rooftopArea'];
  const villaData = requiredpips?.data?.filter((field) => villaAdditionalPips.includes(field.name)) || [];
  const apartmentData = requiredpips?.data?.filter((field) => apartmentAdditionalPips.includes(field.name)) || [];

  const renderData = subCategory === 'villa' ? [...sortedRequiredPIPs, ...villaData] : [...sortedRequiredPIPs, ...apartmentData];
  return (
    <>
      <Grid container item xs={12}>
        {renderData && requiredpips
          ? renderData?.map((field, index) => {
              return (
                <>
                  <Grid alignItems="left" item xs={12} key={index}>
                    <Typography variant="h5">{field.displayName + '*'}</Typography>
                  </Grid>
                  {field.description && (
                    <Grid alignItems="center" item xs={7} style={{ marginBottom: '1%' }}>
                      <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)', lineHeight: '22px', letterSpacing: '0.571429px' }}>
                        {field.description}
                      </Typography>
                    </Grid>
                  )}
                  <UnitAwareTextField
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    area={area && area}
                    appendChangedForRequiredPIP={(a, b) => appendChangedForRequiredPIP(a, b)}
                  />
                </>
              );
            })
          : skeleton &&
            skeleton.map((d, index) => {
              return (
                <>
                  <Skeletons />
                  <br />
                  <br />
                </>
              );
            })}
      </Grid>
    </>
  );
}

export default UnitAwareTextFieldInputs;
