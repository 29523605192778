import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';

function ShowConstants({ constant, myArray, appendChanged }) {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="flex-start" direction="column" xs={12} className={classes.grid} style={{ marginTop: '4%', marginBottom: '5%' }}>
        <Typography variant="h6">Constants</Typography>
        <Grid item container alignItems="flex-start" direction="row" xs={12} style={{ marginTop: '1%' }}>
          {constant &&
            constant.map((data, index) => {
              return (
                <>
                  <Grid item xs={4} container alignItems="flex-start" direction="column" style={index > 2 ? { marginTop: '3%' } : { marginTop: '1%' }}>
                    <Grid xs>
                      <Typography color="textSecondary" variant="h6">
                        {data.label}
                      </Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" className={classes.grid2}>
                      <Grid xs={7}>
                        <ResetFields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            myArray && !myArray.find((d) => d.id === data.id)
                              ? data.value
                              : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                          }
                          placeholder=""
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged(data.id, e.target.value);
                          }}
                          fullWidth
                          required
                          onClick={() => appendChanged(data.id, data.defaultValue)}
                          disabled={data.editable ? false : true}
                        />
                      </Grid>
                      <Grid xs={4} className={classes.grid} style={{ paddingTop: '3%', marginLeft: '2%' }}>
                        <Typography variant="h6">{data.unit}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
              // }
            })}
        </Grid>
      </Grid>
    </>
  );
}

export default ShowConstants;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '2%',
  },
  grid2: {
    marginTop: '6%',
  },
}));
