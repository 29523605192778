import React from 'react';
import { Grid, GridList, makeStyles } from '@material-ui/core';
import MediaCard from '../../../../common/ProductCard';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  toolbarGrid: {
    textAlign: 'center',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));

const MobileProductList = (props) => {
  const classes = useStyles();
  const { supercategoryProductList, selected, supercategories, width } = props;
  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" style={{ marginTop: '3%' }}>
        <GridList className={classes.gridList}>
          {supercategoryProductList ? (
            supercategoryProductList &&
            supercategoryProductList.map((ele) => {
              return (
                <Grid container direction="row" style={{ marginLeft: '2%', height: 'auto' }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center">
                    <MediaCard
                      ele={ele}
                      width={width}
                      productImage={ele?.image}
                      productName={ele?.name}
                      productBrandName={ele?.brand?.name}
                      productShortDescription={ele?.shortDescription}
                      productCertifications=""
                      productCategoryID={ele?.category?.id}
                      productID={ele?.id}
                    />
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <>
              <Skeleton variant="rect" height="25vh" width="25vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="25vh" width="25vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="25vh" width="25vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="25vh" width="25vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
            </>
          )}
        </GridList>
      </Grid>
    </Grid>
  );
};

export default MobileProductList;
