import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const NaturalStpAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  return (
    <>
      <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'column' , marginBottom: '15%'}}>
        <Grid xs={4}>
          <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Intervention Cost Assumptions</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Capex (per KL)'}
          </Typography>
        </Grid>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(getQuantityValue(schema.nstp__pi_capexMultiplierSource) === 'CUSTOM' || getQuantityValue(schema.nstp__pi_capexMultiplierSource) === '') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nstp__pi_capexMultiplierSource, 'VENDOR');
                  }}
                />
              )}
              {getQuantityValue(schema.nstp__pi_capexMultiplierSource) === 'VENDOR' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nstp__pi_capexMultiplierSource, '');
                  }}
                />
              )}
              <Typography>Selected Vendor</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
              <Typography>{getQuantityValue(schema.nstp_vendorCapex)}</Typography>
              <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
            </Grid>
          </Grid>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' ,marginRight:'25%'}}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(getQuantityValue(schema.nstp__pi_capexMultiplierSource) === 'VENDOR' || getQuantityValue(schema.nstp__pi_capexMultiplierSource) === '') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nstp__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              {getQuantityValue(schema.nstp__pi_capexMultiplierSource) === 'CUSTOM' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nstp__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              <Typography>Custom</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center',marginTop: '2%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nstp__pi_customCapex)}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nstp__pi_customCapex, e.target.value);
                }}
                disabled={getQuantityValue(schema.nstp__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                disableTextField={getQuantityValue(schema.nstp__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
              <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
            </Grid>
          </Grid>
        </div>
        <Grid xs={4}>
          <Grid xs={12} style={{ marginTop: '9%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Project Cost Assumptions</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Water Tanker Price per KL'}
          </Typography>
          <Grid xs={8} style={{ display: 'flex' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '6%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nstp__pi_userTankerPrice)}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nstp__pi_userTankerPrice, +e.target.value);
                }}
                onClick={(e) => {
                  updateQuantityValue(schema.nstp__pi_userTankerPrice, getQuantityDefaultValue(schema.nstp__pi_userTankerPrice));
                }}
              />
              <Typography style={{ marginLeft: '20%' }}>Rs</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4}>
          <Grid xs={12} style={{ marginTop: '15%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Constants</Typography>
          </Grid>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid xs={12}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '6%' }}>
                {'CO2e Emission Factor'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                  <Typography className={classes.textHeader3}>
                  {getQuantityValue(schema.nstp_coTwoEmissionFactor)} {getQuantityUnit(schema.nstp_coTwoEmissionFactor)}
            </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NaturalStpAdvancedParameters;
