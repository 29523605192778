import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Links from '../../../../common/Typography/Links';
import { MarketplaceTypography } from '../../../../config';

const alphabets = ['a', 'b', 'c', 'd', 'e'];
const TextWithPointsandSubpoints = ({ data, image, imageindex, justifyContent, imageStyle }) => {
  return (
    <Grid container>
      <Grid xs={12} item container alignItems="flex-start" justifyContent="flex-start">
        {data.map((ele, index) => {
          return (
            <>
              <Grid container>
                <Typography style={MarketplaceTypography.BodyRegular}>
                  <span style={{ fontWeight: 'bold' }}>{alphabets[index]}.</span> <span style={{ fontWeight: 'bold' }}>{ele.heading.split(':')[0]}:</span>
                  {ele.heading.split(':')[1]}
                </Typography>
              </Grid>
              {image && imageindex === index && (
                <Grid xs={12} item container alignItems="flex-start" justifyContent={justifyContent ? justifyContent : 'center'}>
                  <img src={image} alt="rrr" style={imageStyle} />
                </Grid>
              )}
              {/* <br /> */}
              <Grid container style={{ paddingLeft: '2%' }}>
                {ele.body.split('.').map((e, i) => {
                  return (
                    <Typography style={MarketplaceTypography.BodyRegular}>
                      {i < ele.body.split('.').length - 1 && (
                        <>
                          <span style={{ fontWeight: 'bold', fontSize: '30px', marginTop: '-10px', paddingRight: '10px' }}>.</span>
                          <span style={{ fontWeight: 'bold' }}>{e.split(':')[0]}</span>:{e.split(':')[1]}.
                        </>
                      )}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TextWithPointsandSubpoints;
