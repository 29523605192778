import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import UnitAwareTextField from '../../../../common/textfield/UnitAwareTextField';

function SimplePIPS(props) {
  const { id, index, field, unit, projectPIP, appendChanged, type } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        item
        xs={12}
        key={index}
        style={
          id === 'project__pi_availableClubhouseRooftopArea'
            ? { height: 'Auto', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }
            : type === 'land'
            ? { height: 'Auto', marginLeft: '2%', marginTop: '3%' }
            : index === 0
            ? { height: 'Auto', marginLeft: '2%', marginBottom: '3%' }
            : { height: 'Auto', marginLeft: '2%', marginBottom: '3%' }
        }
      >
        <Grid item alignItems="left" xs={12}>
          <Typography variant="h5">{field?.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
        </Grid>
        {field?.description && field?.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field?.description}
            </Typography>
          </Grid>
        ) : null}

        <UnitAwareTextField index={index} unit={unit && unit} field={field && field} projectPIP={projectPIP && projectPIP} appendChanged={appendChanged} />
      </Grid>
    </>
  );
}

export default SimplePIPS;
