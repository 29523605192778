import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: 0,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&:focus': {
      outline: 0,
    },
    '&$selected': {
      color: theme.palette.common.black,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default AntTabs;
