import React from 'react';
import { Grid, withWidth } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useScreenType from '../../../../hooks/checkScreenType';

export const CertificationsSpotlightSkeletons = (props) => {
  const dummySkeletonCount = [1, 1, 1, 1];
  const { width } = props;
  const { isMobile } = useScreenType();

  return (
    <Grid container alignItems="center" justifyContent="center">
      {dummySkeletonCount.map((ske, index) => (
        <Grid
          key={index}
          container
          direction="row"
          xs={4}
          sm={3}
          md={3}
          lg={3}
          xl={2}
          alignItems="center"
          justifyContent="center"
          style={{ marginLeft: width === 'xl' ? '3%' : '' }}
        >
          <Grid item container xs={12} justifyContent="center">
            <Skeleton variant="circle" animation="wave" height={!isMobile ? '160px' : '80px'} width={!isMobile ? '160px' : '80px'} />
          </Grid>
          <Grid item container xs={12} justifyContent="center" style={{ marginTop: '3%' }}>
            <Skeleton variant="text" animation="wave" height={!isMobile ? '4vh' : '2vh'} width="100px" />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default withWidth()(CertificationsSpotlightSkeletons);
