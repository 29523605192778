import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import EclipseProgress from './EclipseProgress';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import { LegalQuantityTypes } from '../../../../../metafire/utils/types';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1%',
    minHeight: 'auto',
  },
  paper: {
    // minHeight: 290,
    // maxHeight: 500,
    // minWidth: 400,
    // height: 450,
    outline: 'none',
    width: 'auto',
    height: '435px',
    padding: theme.spacing(2, 2, 2, 3),
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    marginLeft: '28px',
  },
  bar1: {
    flexGrow: 1,
    marginLeft: '-55%',
    marginTop: '75%',
    minWidth: '175%',
    transform: 'rotate(-90deg)',
  },
}));

function RightOverview(props) {
  const classes = useStyles();
  const {
    get: { getQuantitiesByTypeDefinition },
    key,
  } = useInterventionDetailsContext();
  const { getValue } = useAppMetafireUtilities();
  const donutvalues = getQuantitiesByTypeDefinition(LegalQuantityTypes?.Donuts);
  const handleDonutValue = (value) => Math.max(0, Math.min(100, value));
  return key !== 'nstp' ? (
    <>
      <Paper className={classes.paper}>
        <Grid container xs={12} item style={{ paddingTop: '2%', marginBottom: '5%' }}>
          <Typography variant="h5" style={{ color: '#9E9E9E', lineHeight: '20px' }}>
            {props.heading}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          // justify="space-evenly"
          // alignItems="flex-start"
        >
          {donutvalues &&
            donutvalues?.map((ele) => {
              return ele?.value?.map((obj, index) => {
                const range = getValue(obj, 0);
                return (
                  <Grid
                    item
                    key={index} // Add a key prop here
                    xs={6}
                    direction="column"
                    style={index > 1 ? { marginTop: '10%' } : null} // Adjust the style condition
                  >
                    <Typography color="textSecondary">{obj?.label}</Typography>
                    <EclipseProgress
                      textcolor={props.textcolor}
                      circlecolor={props.circlecolor}
                      newvalue={handleDonutValue(range)}
                      range={range}
                      staticvalue={handleDonutValue(getValue(obj)) - 100}
                      style={{ marginTop: '10%' }}
                    />
                  </Grid>
                );
              });
            })}
        </Grid>
      </Paper>
    </>
  ) : (
    <></>
  );
}

export default RightOverview;
