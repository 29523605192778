import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import energyIcon from '../../../../../assets/new-pdf/energyIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import WasteIcon from '../../../../../assets/new-pdf/WasteIcon.png';
import MaterialsIcon from '../../../../../assets/new-pdf/MaterialsIcon.png';
import numberWithCommas from '../../../../../utils/numberToComma';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },

  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const Page3 = (props) => {
  const energy = props.energy;
  const water = props.water;
  const waste = props.waste;
  const materials = props.materials;

  let energysaving = energy && energy[0].quantities.resource.category.value;
  let watersaving = water && water[0].quantities.resource.category.value;
  let wastesaving = waste && waste[0].quantities.resource.category.value;
  let materialssaving = materials && materials[0].quantities.resource.category.value;
  let energysavingconverted = energy && energy[0].quantities.resource.categoryConverted.value;
  let watersavingconverted = water && water[0].quantities.resource.categoryConverted.value;
  let wastesavingconverted = waste && waste[0].quantities.resource.categoryConverted.value;
  let materialssavingconverted = materials && materials[0].quantities.resource.categoryConverted.value;
  let energypercentage =
    energy &&
    energy[0].subcategories
      .find((ele) => ele.name === 'Renewable Sources')
      .interventions[0].quantities.details.find((data) => data && data.name === 'energy.totalpercentagesaving');
  let waterpercentage =
    water &&
    water[0].subcategories
      .find((ele) => ele.name === 'Demand Optimization')
      .interventions[0].quantities.details.find((data) => data && data.name === 'water.totalpercentagesaving');
  let wastepercentage =
    waste &&
    waste[0].subcategories
      .find((ele) => ele.name === 'Management')
      .interventions[0].quantities.details.find((data) => data && data.name === 'waste.totalpercentagesaving');

  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>Environmental Impact</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ backgroundColor: '#FFF4D5', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={energyIcon} style={{ height: '20px', width: '25px', top: 14 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              ENERGY
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(energysaving)}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                //   fontWeight: 'SemiBold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              kWh
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px' }}>{`${energypercentage ? energypercentage.value : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>
            {`equivalent to powering ${numberWithCommas(energysavingconverted)} homes`}{' '}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>Energy saved annually</Text>
        </View>
      </View>

      {/* new view */}
      <View style={{ backgroundColor: '#CFF2FA', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={WaterIcon} style={{ height: '20px', width: '30px', top: 14 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Water
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(watersaving)}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                //   fontWeight: 'SemiBold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              KL
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px' }}>{`${waterpercentage ? waterpercentage.value : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>{`saved ${numberWithCommas(
            watersavingconverted
          )} tankers of water a year`}</Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>Water saved annually</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ backgroundColor: 'rgba(188, 138, 95, 0.3)', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={WasteIcon} style={{ height: '20px', width: '25px', top: 14 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Waste
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(wastesaving)}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                //   fontWeight: 'SemiBold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              kg
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px' }}>{`${wastepercentage ? wastepercentage.value : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>
            {`equivalent to ${numberWithCommas(wastesavingconverted)} trucks of waste`}{' '}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>Waste avoided annually</Text>
        </View>
      </View>
      {/* {} */}
      <View style={{ backgroundColor: '#EDCDFF', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={MaterialsIcon} style={{ height: '20px', width: '25px', top: 14 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Materials
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 'SemiBold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(materialssaving)}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Open Sans',
                //   fontWeight: 'SemiBold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              tCO2e
            </Text>
          </View>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '12px', color: 'grey' }}>
            {`equivalent to ${numberWithCommas(materialssavingconverted)} cars off the road for a year`}{' '}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: '5%' }}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default Page3;
