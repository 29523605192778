export const SPV = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  return (
    <svg width={65} height={91} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={color}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.0014 27.9481C34.7052 27.9481 27.9522 34.7011 27.9522 43.0037C27.9522 51.2999 34.7052 58.0529 43.0014 58.0529C51.3008 58.0529 58.0538 51.2999 58.0538 43.0037C58.0538 34.7011 51.3008 27.9481 43.0014 27.9481ZM43.0012 64.4294C31.1882 64.4294 21.5752 54.8196 21.5752 43.0034C21.5752 31.1872 31.1882 21.571 43.0012 21.571C54.8174 21.571 64.4303 31.1872 64.4303 43.0034C64.4303 54.8196 54.8174 64.4294 43.0012 64.4294Z"
        />
        <path
          d="M43.0014 26.4481C33.8764 26.4481 26.4522 33.8731 26.4522 43.0037H29.4522C29.4522 35.5291 35.534 29.4481 43.0014 29.4481V26.4481ZM26.4522 43.0037C26.4522 52.1283 33.8768 59.5529 43.0014 59.5529V56.5529C35.5336 56.5529 29.4522 50.4714 29.4522 43.0037H26.4522ZM43.0014 59.5529C52.129 59.5529 59.5538 52.1285 59.5538 43.0037H56.5538C56.5538 50.4712 50.4726 56.5529 43.0014 56.5529V59.5529ZM59.5538 43.0037C59.5538 33.8729 52.1294 26.4481 43.0014 26.4481V29.4481C50.4721 29.4481 56.5538 35.5293 56.5538 43.0037H59.5538ZM43.0012 62.9294C32.0165 62.9294 23.0752 53.991 23.0752 43.0034H20.0752C20.0752 55.6481 30.3599 65.9294 43.0012 65.9294V62.9294ZM23.0752 43.0034C23.0752 32.0152 32.0171 23.071 43.0012 23.071V20.071C30.3593 20.071 20.0752 30.3592 20.0752 43.0034H23.0752ZM43.0012 23.071C53.9886 23.071 62.9303 32.0154 62.9303 43.0034H65.9303C65.9303 30.3591 55.6461 20.071 43.0012 20.071V23.071ZM62.9303 43.0034C62.9303 53.9909 53.9892 62.9294 43.0012 62.9294V65.9294C55.6455 65.9294 65.9303 55.6483 65.9303 43.0034H62.9303Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.4428 15.4933C41.6796 15.4933 40.2544 14.0681 40.2544 12.3049V5.18839C40.2544 3.42521 41.6796 2 43.4428 2C45.206 2 46.6312 3.42521 46.6312 5.18839V12.3049C46.6312 14.0681 45.206 15.4933 43.4428 15.4933"
        />
        <path
          d="M43.4428 15.4933C41.6796 15.4933 40.2544 14.0681 40.2544 12.3049V5.18839C40.2544 3.42521 41.6796 2 43.4428 2C45.206 2 46.6312 3.42521 46.6312 5.18839V12.3049C46.6312 14.0681 45.206 15.4933 43.4428 15.4933"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.0405 19.3815C26.9374 19.3815 25.8662 18.8108 25.2764 17.7874L21.7185 11.6279C20.8353 10.1072 21.3582 8.15601 22.8853 7.2729C24.4029 6.38978 26.3572 6.91264 27.2403 8.43976L30.7983 14.5993C31.6814 16.1232 31.1586 18.0743 29.6314 18.9543C29.1309 19.2444 28.5825 19.3815 28.0405 19.3815"
        />
        <path
          d="M28.0405 19.3815C26.9374 19.3815 25.8662 18.8108 25.2764 17.7874L21.7185 11.6279C20.8353 10.1072 21.3582 8.15601 22.8853 7.2729C24.4029 6.38978 26.3572 6.91264 27.2403 8.43976L30.7983 14.5993C31.6814 16.1232 31.1586 18.0743 29.6314 18.9543C29.1309 19.2444 28.5825 19.3815 28.0405 19.3815"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6353 30.4574C16.0933 30.4574 15.545 30.3203 15.0444 30.0302L8.88174 26.469C7.3578 25.5859 6.83495 23.6347 7.71488 22.114C8.59799 20.5837 10.5491 20.0608 12.0699 20.9471L18.2326 24.5083C19.7597 25.3914 20.2825 27.3394 19.3994 28.8633C18.8096 29.8867 17.7384 30.4574 16.6353 30.4574"
        />
        <path
          d="M16.6353 30.4574C16.0933 30.4574 15.545 30.3203 15.0444 30.0302L8.88174 26.469C7.3578 25.5859 6.83495 23.6347 7.71488 22.114C8.59799 20.5837 10.5491 20.0608 12.0699 20.9471L18.2326 24.5083C19.7597 25.3914 20.2825 27.3394 19.3994 28.8633C18.8096 29.8867 17.7384 30.4574 16.6353 30.4574"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.3049 45.7456H5.18839C3.42521 45.7456 2 44.3203 2 42.5572C2 40.794 3.42521 39.3688 5.18839 39.3688H12.3049C14.0681 39.3688 15.4933 40.794 15.4933 42.5572C15.4933 44.3203 14.0681 45.7456 12.3049 45.7456"
        />
        <path
          d="M12.3049 45.7456H5.18839C3.42521 45.7456 2 44.3203 2 42.5572C2 40.794 3.42521 39.3688 5.18839 39.3688H12.3049C14.0681 39.3688 15.4933 40.794 15.4933 42.5572C15.4933 44.3203 14.0681 45.7456 12.3049 45.7456"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0387 64.7105C8.93563 64.7105 7.86441 64.1366 7.27461 63.1164C6.39149 61.5925 6.91435 59.6413 8.44147 58.7614L14.6042 55.2066C16.1217 54.3203 18.0729 54.8495 18.9592 56.3735C19.8391 57.8974 19.3162 59.8485 17.7923 60.7285L11.6296 64.2833C11.1291 64.5734 10.5807 64.7105 10.0387 64.7105"
        />
        <path
          d="M10.0387 64.7105C8.93563 64.7105 7.86441 64.1366 7.27461 63.1164C6.39149 61.5925 6.91435 59.6413 8.44147 58.7614L14.6042 55.2066C16.1217 54.3203 18.0729 54.8495 18.9592 56.3735C19.8391 57.8974 19.3162 59.8485 17.7923 60.7285L11.6296 64.2833C11.1291 64.5734 10.5807 64.7105 10.0387 64.7105"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.7135 78.7157C23.1715 78.7157 22.6263 78.5786 22.1226 78.2885C20.5955 77.4085 20.0726 75.4606 20.9558 73.9334L24.5074 67.7739C25.3873 66.25 27.3289 65.7272 28.8624 66.6071C30.3895 67.4902 30.9123 69.4382 30.0292 70.9621L26.4776 77.1216C25.8878 78.1418 24.8166 78.7157 23.7135 78.7157"
        />
        <path
          d="M23.7135 78.7157C23.1715 78.7157 22.6263 78.5786 22.1226 78.2885C20.5955 77.4085 20.0726 75.4606 20.9558 73.9334L24.5074 67.7739C25.3873 66.25 27.3289 65.7272 28.8624 66.6071C30.3895 67.4902 30.9123 69.4382 30.0292 70.9621L26.4776 77.1216C25.8878 78.1418 24.8166 78.7157 23.7135 78.7157"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.5585 84.0063C40.7953 84.0063 39.3701 82.5811 39.3701 80.8179V73.7015C39.3701 71.9383 40.7953 70.5131 42.5585 70.5131C44.3217 70.5131 45.7469 71.9383 45.7469 73.7015V80.8179C45.7469 82.5811 44.3217 84.0063 42.5585 84.0063"
        />
        <path
          d="M42.5585 84.0063C40.7953 84.0063 39.3701 82.5811 39.3701 80.8179V73.7015C39.3701 71.9383 40.7953 70.5131 42.5585 70.5131C44.3217 70.5131 45.7469 71.9383 45.7469 73.7015V80.8179C45.7469 82.5811 44.3217 84.0063 42.5585 84.0063"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M61.5307 79.158C60.4276 79.158 59.3564 78.5873 58.7697 77.5671L55.2086 71.4076C54.3223 69.8837 54.8451 67.9325 56.3691 67.0526C57.8962 66.1599 59.8442 66.6892 60.7241 68.2131L64.2852 74.3726C65.1715 75.8997 64.6487 77.8477 63.1248 78.7276C62.621 79.0209 62.0727 79.158 61.5307 79.158"
        />
        <path
          d="M61.5307 79.158C60.4276 79.158 59.3564 78.5873 58.7697 77.5671L55.2086 71.4076C54.3223 69.8837 54.8451 67.9325 56.3691 67.0526C57.8962 66.1599 59.8442 66.6892 60.7241 68.2131L64.2852 74.3726C65.1715 75.8997 64.6487 77.8477 63.1248 78.7276C62.621 79.0209 62.0727 79.158 61.5307 79.158"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.5264 65.4823C74.9845 65.4823 74.4361 65.3452 73.9356 65.0551L67.7665 61.4972C66.2426 60.6172 65.7197 58.6661 66.5996 57.1422C67.4828 55.615 69.4307 55.0922 70.9546 55.9753L77.1237 59.5333C78.6508 60.4132 79.1737 62.3611 78.2906 63.8883C77.7008 64.9085 76.6295 65.4823 75.5264 65.4823"
        />
        <path
          d="M75.5264 65.4823C74.9845 65.4823 74.4361 65.3452 73.9356 65.0551L67.7665 61.4972C66.2426 60.6172 65.7197 58.6661 66.5996 57.1422C67.4828 55.615 69.4307 55.0922 70.9546 55.9753L77.1237 59.5333C78.6508 60.4132 79.1737 62.3611 78.2906 63.8883C77.7008 64.9085 76.6295 65.4823 75.5264 65.4823"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M80.8115 46.6329H73.6982C71.935 46.6329 70.5098 45.2077 70.5098 43.4445C70.5098 41.6813 71.935 40.2561 73.6982 40.2561H80.8115C82.5746 40.2561 83.9998 41.6813 83.9998 43.4445C83.9998 45.2077 82.5746 46.6329 80.8115 46.6329"
        />
        <path
          d="M80.8115 46.6329H73.6982C71.935 46.6329 70.5098 45.2077 70.5098 43.4445C70.5098 41.6813 71.935 40.2561 73.6982 40.2561H80.8115C82.5746 40.2561 83.9998 41.6813 83.9998 43.4445C83.9998 45.2077 82.5746 46.6329 80.8115 46.6329"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.8124 31.2232C68.7093 31.2232 67.6349 30.6493 67.0483 29.6259C66.1683 28.1052 66.6912 26.1509 68.2183 25.2741L74.381 21.7225C75.9049 20.8394 77.8561 21.3655 78.7328 22.8926C79.6127 24.4133 79.0867 26.3677 77.5628 27.2444L71.4001 30.796C70.8995 31.0861 70.3512 31.2232 69.8124 31.2232"
        />
        <path
          d="M69.8124 31.2232C68.7093 31.2232 67.6349 30.6493 67.0483 29.6259C66.1683 28.1052 66.6912 26.1509 68.2183 25.2741L74.381 21.7225C75.9049 20.8394 77.8561 21.3655 78.7328 22.8926C79.6127 24.4133 79.0867 26.3677 77.5628 27.2444L71.4001 30.796C70.8995 31.0861 70.3512 31.2232 69.8124 31.2232"
          stroke="white"
          stroke-width="3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.7289 19.8298C58.1869 19.8298 57.6386 19.6927 57.138 19.4026C55.6141 18.5226 55.0881 16.5747 55.9712 15.0475L59.5323 8.88167C60.4123 7.35137 62.3634 6.82851 63.8873 7.71163C65.4145 8.59474 65.9341 10.5427 65.0542 12.0698L61.493 18.2357C60.9032 19.2591 59.832 19.8298 58.7289 19.8298"
        />
        <path
          d="M58.7289 19.8298C58.1869 19.8298 57.6386 19.6927 57.138 19.4026C55.6141 18.5226 55.0881 16.5747 55.9712 15.0475L59.5323 8.88167C60.4123 7.35137 62.3634 6.82851 63.8873 7.71163C65.4145 8.59474 65.9341 10.5427 65.0542 12.0698L61.493 18.2357C60.9032 19.2591 59.832 19.8298 58.7289 19.8298"
          stroke="white"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};
