import { Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import MarketplaceNavigatorDesktop from './MarketplaceNavigatorDesktop';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const MarketplaceNavigatorMenu = (props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Grid>
        <MarketplaceNavigatorDesktop open={open} setOpen={setOpen} />
      </Grid>
      <Grid style={{ paddingTop: '2%' }}>
        {open ? <ArrowDropUpIcon onMouseEnter={() => setOpen(false)} /> : <ArrowDropDownIcon onMouseEnter={() => setOpen(true)} />}
      </Grid>
    </>
  );
};

export default MarketplaceNavigatorMenu;
