import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core';
import React from 'react';

const CustomDropDown = ({ selecteddata, setSelectedData, dataArr, disabled }) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        className={classes.select}
        fullWidth
        value={selecteddata}
        onChange={(e) => {
          e.preventDefault();
          setSelectedData(e.target.value);
        }}
        disabled={disabled}
      >
        {dataArr
          ? dataArr.map((value, i) => {
              return (
                <MenuItem value={value} name={value} key={i}>
                  {value}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
};

export default CustomDropDown;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));
