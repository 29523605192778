import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { categoriesQuery } from '../graphql-queries/Categories.query';

const useCategories = () => {
  let [allCategories, setAllCategories] = useState('');
  const [categories, SendCategoriesRequest] = useGraphqlRequest(categoriesQuery);
  if (categories && !categories.response) SendCategoriesRequest();

  useEffect(() => {
    if (categories.response !== null) setAllCategories(categories.response?.data?.data?.categories?.data);
  }, [categories]);

  return { allCategories };
};

export default useCategories;
