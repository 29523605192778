import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ResponsiveHome1 from './responsive/ResponsiveHome1';
import Home1 from './normal/Home1';
import ResponsiveHome2 from './responsive/ResponsiveHome2';
import Home2 from './normal/Home2';
import ResponsiveHome3 from './responsive/ResponsiveHome3';
import Home3 from './normal/Home3';
import ResponsiveHome4 from './responsive/ResponsiveHome4';
import Home4 from './normal/Home4';
import ResponsiveHome5 from './responsive/ResponsiveHome5';
import Home5 from './normal/Home5';
import ResponsiveHome6 from './responsive/ResponsiveHome6';
import Home6 from './normal/Home6';
import Home7 from './normal/Home7';
import SignupAndLogin from './normal/SignupAndLogin';
import worn from '../../../assets/VERSION-TWO/landingPage/worn_dots.png';
import axios from '../../../config/Axios';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ResponsiveHome7 from './responsive/ResponsiveHome7';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    flexGrow: '1',
  },
  modal: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '9%',
  },
}));

function Home() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const [openModal, setOpenModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [purpose, setPurpose] = useState('Dropin');
  const [coverpic1, setCoverpic1] = useState('');
  const [coverpic2, setCoverpic2] = useState('');
  const [coverpic3, setCoverpic3] = useState('');
  const [coverpic4, setCoverpic4] = useState('');
  const [coverpic5, setCoverpic5] = useState('');
  const [coverpic6, setCoverpic6] = useState('');
  const [pdf1, setPdf1] = useState('');
  const [pdf2, setPdf2] = useState('');
  const [pdf3, setPdf3] = useState('');
  const [pdf4, setPdf4] = useState('');
  const [pdf5, setPdf5] = useState('');
  const [pdf6, setPdf6] = useState('');

  const [mobileRequestDemo, setMobileRequestDemo] = useState(false);

  const handleResponsiveRequestDemo = () => {
    setMobileRequestDemo(true);
    handleOpen();
  };

  const toggleSignUpLogin = () => {
    setLogin(!login);
  };
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => {
    setOpenModal(true);
    setLogin(false);
    setPurpose('Dropin');
  };
  const handleOpenforWhitePaper = () => {
    setOpenModal(true);
    setLogin(false);
    setPurpose('White Paper');
  };
  const handleShowLogin = () => {
    setLogin(true);
    setOpenModal(true);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    if (matches) setMobileRequestDemo(false);
  }, [matches]);
  useEffect(() => {
    axios.get(`/platform-asset`).then((res) => {
      if (res.data && res.data.data && res.data.data.data) {
        let all = res.data.data.data;
        let pic1 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-1');
        let pic2 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-2');
        let pic3 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-3');
        let pic4 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-4');
        let pic5 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-5');
        let pic6 = all.find((a) => a.tag === 'sdplus-whitepaper-coverpic-6');

        let pdf1 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-1');
        let pdf2 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-2');
        let pdf3 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-3');
        let pdf4 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-4');
        let pdf5 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-5');
        let pdf6 = all.find((a) => a.tag === 'sdplus-whitepaper-pdf-6');
        setCoverpic1(pic1);
        setCoverpic2(pic2);
        setCoverpic3(pic3);
        setCoverpic4(pic4);
        setCoverpic5(pic5);
        setCoverpic6(pic6);
        setPdf1(pdf1);
        setPdf2(pdf2);
        setPdf3(pdf3);
        setPdf4(pdf4);
        setPdf5(pdf5);
        setPdf6(pdf6);
      }
    });
  }, []);

  return (
    <>
      <div
        className={classes.root}
        style={{
          backgroundImage: `url(${worn})`,
          backgroundRepeat: 'repeat',
        }}
      >
        <Grid container spacing={1}>
          {matches ? (
            <Home1 onModalOpen={handleOpen} handleShowLogin={handleShowLogin} setPurpose={(v) => setPurpose(v)} />
          ) : (
            <ResponsiveHome1 handleRequestDemo={handleResponsiveRequestDemo} />
          )}
          {matches ? <Home2 /> : <ResponsiveHome2 />}
          {matches ? <Home3 /> : <ResponsiveHome3 />}
          {matches ? <Home4 /> : <ResponsiveHome4 handleRequestDemo={handleResponsiveRequestDemo} />}
          {matches ? <Home5 /> : <ResponsiveHome5 />}
          {matches ? (
            <Home7
              onModalOpen={handleOpenforWhitePaper}
              Whitepaper1={coverpic1 && coverpic1.assetlocation.URL}
              Whitepaper2={coverpic2 && coverpic2.assetlocation.URL}
              Whitepaper3={coverpic3 && coverpic3.assetlocation.URL}
              Whitepaper4={coverpic4 && coverpic4.assetlocation.URL}
              Whitepaper5={coverpic5 && coverpic5.assetlocation.URL}
              Whitepaper6={coverpic6 && coverpic6.assetlocation.URL}
              pdf1={pdf1 && pdf1.assetlocation.URL}
              pdf2={pdf2 && pdf2.assetlocation.URL}
              pdf3={pdf3 && pdf3.assetlocation.URL}
              pdf4={pdf4 && pdf4.assetlocation.URL}
              pdf5={pdf5 && pdf5.assetlocation.URL}
              pdf6={pdf6 && pdf6.assetlocation.URL}
            />
          ) : (
            <ResponsiveHome7
              onModalOpen={handleOpenforWhitePaper}
              Whitepaper1={coverpic1 && coverpic1.assetlocation.URL}
              Whitepaper2={coverpic2 && coverpic2.assetlocation.URL}
              Whitepaper3={coverpic3 && coverpic3.assetlocation.URL}
              Whitepaper4={coverpic4 && coverpic4.assetlocation.URL}
              Whitepaper5={coverpic5 && coverpic5.assetlocation.URL}
              Whitepaper6={coverpic6 && coverpic6.assetlocation.URL}
              pdf1={pdf1 && pdf1.assetlocation.URL}
              pdf2={pdf2 && pdf2.assetlocation.URL}
              pdf3={pdf3 && pdf3.assetlocation.URL}
              pdf4={pdf4 && pdf4.assetlocation.URL}
              pdf5={pdf5 && pdf5.assetlocation.URL}
              pdf6={pdf6 && pdf6.assetlocation.URL}
            />
          )}

          {matches ? <Home6 onModalOpen={handleOpen} /> : <ResponsiveHome6 onModalOpen={handleOpen} />}
        </Grid>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <SignupAndLogin login={login} toggleSignUpLogin={toggleSignUpLogin} purpose={purpose} mobileRequestDemo={mobileRequestDemo} />
        </Fade>
      </Modal>
    </>
  );
}
export default Home;
