import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EPSProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_perimeter)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.exteriorPaintsSunny_perimeter)} {getQuantityUnit(schema.exteriorPaintsSunny_perimeter)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_floorToFloorHeight)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.exteriorPaintsSunny_floorToFloorHeight)} {getQuantityUnit(schema.exteriorPaintsSunny_floorToFloorHeight)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_numberOfFloors)}</Typography>
          <Typography className={classes.textHeader3}>{getQuantityValue(schema.exteriorPaintsSunny_numberOfFloors)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_wwr)}</Typography>
          <Typography className={classes.textHeader3}>{getQuantityValue(schema.exteriorPaintsSunny_wwr)}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default EPSProjectInputs;
