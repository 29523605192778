import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';

const CustomOutlinedInput = withStyles({
  root: {
    borderRadius: `8px`,
  },
})(OutlinedInput);

export default CustomOutlinedInput;
