import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { withStyles } from '@material-ui/core/styles';

export const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    border: '1px solid ' + theme.palette.grey[400],
    boxSizing: 'border-box',
    borderRadius: '10px',
    minWidth: '150px',
    maxWidth: '105px',
    '&:focus': {
      outline: 'none',
    },
    minHeight: '60px',
    textTransform: 'capitalize',
  },
  selected: {
    border: '1px solid ' + theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark + '!important',
    color: theme.palette.common.white + '!important',
  },
}))(ToggleButton);
