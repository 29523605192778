import React from 'react';
import { AppBar, Toolbar, makeStyles, Grid } from '@material-ui/core';
import useScrollNavigationBar from '../../../hooks/ScrollNavbar';
import useScreenType from '../../../hooks/checkScreenType';
import MarketplaceNavicon from './common/MarketplaceNavicon';
import MarketplaceNavigatorMenu from './components/desktop/MarketplaceNavigatorMenu';
import MarketplaceSearchBox from './common/MarketplaceSearchBox';
import MarketplaceMenuItems from './components/desktop/MarketplaceMenuItems';
import MarketplaceNavigatorMobile from './components/mobile/MarketplaceNavigatorMobile';
import ProductDetailsTabs from '../views/productDetails/components/ProductDetailsTabs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const SearchboxText = 'Search for product, brand, etc';

const useStyles = makeStyles((theme) => ({
  responsivemenuIcon: {
    fontSize: '230%',
    cursor: 'pointer',
  },
  toolbarGrid: {
    textAlign: 'center',
  },
  menuItemGrid: {
    paddingLeft: '3%',
  },
}));

const MarketplaceNavbar = (props) => {
  const classes = useStyles();
  let history = useHistory();

  const { showInTab, categories } = props;

  const { isMobile } = useScreenType();
  const { isScrolled } = useScrollNavigationBar();
  const [tabvalue, setTabvalue] = useState(0);
  const handleChange = (event, newValue) => setTabvalue(newValue);

  const appbarStyles = {
    transform: isMobile && isScrolled && !showInTab ? 'translateY(-50%)' : showInTab ? 'translateY(-32%)' : 'none',
    boxShadow: !isScrolled ? 'none' : '',
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
  };

  return (
    <AppBar color="transparent" position="fixed" style={appbarStyles}>
      <Toolbar variant="regular">
        <Grid container alignItems="center" justifyContent="center" className={classes.toolbarGrid}>
          <Grid xs={12} sm={12} md={1} lg={1} xl={1} item>
            <MarketplaceNavicon handleClick={() => history.push('/marketplace')} />
          </Grid>
          <Grid xs={1} sm={1} md={2} lg={2} xl={2} item container justifyContent={isMobile ? 'center' : 'flex-end'} alignItems="center">
            {isMobile && <MarketplaceNavigatorMobile categories={categories} />}
            {!isMobile && <MarketplaceNavigatorMenu />}
          </Grid>
          <Grid xs={11} sm={11} md={4} lg={5} xl={5} container justifyContent="center" alignItems="center" className={classes.menuItemGrid}>
            {isMobile && <MarketplaceSearchBox IconButton={true} text={SearchboxText} />}
            {!isMobile && <MarketplaceSearchBox text={SearchboxText} />}
          </Grid>
          <Grid xs={12} sm={12} md={5} lg={4} xl={4} container alignItems="center" justifyContent="center" className={classes.menuItemGrid}>
            {!isMobile && <MarketplaceMenuItems />}
          </Grid>
          {showInTab && (
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container alignItems="center" justifyContent="center" className={classes.menuItemGrid}>
              <ProductDetailsTabs tabvalue={tabvalue} handleChange={handleChange} />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default MarketplaceNavbar;
