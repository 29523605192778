import { Typography } from '@material-ui/core';

export const HoverText = (text, hoverTextColor, mouseOutColor, variantuse, textStyle) => {
  const handleHover = (event) => (event.target.style.color = hoverTextColor);
  const handleMouseOut = (event) => (event.target.style.color = mouseOutColor);
  const variant1 = (
    <Typography
      variant="body2"
      color="textSecondary"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseOut}
      style={{ transition: 'color 0.3s ease', cursor: 'pointer' }}
    >
      {text}
    </Typography>
  );
  const variant2 = (
    <Typography
      color="textSecondary"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseOut}
      style={textStyle ? textStyle : { transition: 'color 0.3s ease', cursor: 'pointer' }}
    >
      {text}
    </Typography>
  );
  if (variantuse) return variant1;
  else return variant2;
};
