import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '0px 100px 100px 0px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '5%',
  },
}));

export default function CustomizedBar({ value, bgcolor, barvalue }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 20,
      background: '#FFFFFF',
      borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: '0px 100px 100px 0px',
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <Grid container className={classes.root}>
      <>
        <Grid container item xs={12} className={classes.spacing}>
          <Grid item xs={8}>
            <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <Typography variant="h6" style={{ color: `${bgcolor}` }}>
              <b>{value}</b>
            </Typography>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}
