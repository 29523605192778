import React from 'react';
import { Grid } from '@material-ui/core';

function LeftArrow({ leftarrow, prev, arrowStyles, imageStyles }, props) {
  return (
      <img src={leftarrow} alt="..." onClick={prev} style={{opacity:1,...imageStyles}} />
  );
}

export default LeftArrow;
