import vendor1 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 12.png';
import vendor2 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 22.png';
import vendor3 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 32.png';
import vendor4 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 42.png';
import vendor5 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList1/image 52.png';
import vendor6 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList2/image 6.png';
import vendor7 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList2/image 7.png';
import vendor8 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList2/image 8.png';
import vendor9 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList2/image 9.png';
import vendor10 from '../../../../assets/VERSION-TWO/landingPage/Vendors Images/dummyVendorsList2/image 10.png';

export const DummyVendorsList2 = [
  {
    image: vendor1,
    name: 'Sewage to Water',
    type: 'ECOSTP',
    desc: 'A biomimmicry innovation to treat sewage naturally without power or chemicals.',
  },
  {
    image: vendor2,
    name: 'Agri Fiber Panel',
    type: 'Strawcture',
    desc: 'Next generation building materials out of waste in form of High Engineered panels',
  },
  {
    image: vendor3,
    name: 'Smart Water Meter',
    type: 'WEGoT',
    desc: 'Real-time water management to help manage consumption & quality with ease.',
  },
  {
    image: vendor4,
    name: 'Air Quality Monitor',
    type: 'Clairco',
    desc: 'Enhanced air purification and real-time insights to improve occupant experience',
  },
  {
    image: vendor5,
    name: 'Radiation Protection',
    type: 'LiveSAFE',
    desc: 'Coatings on ceilings & walls to provide protection from Electromagnetic Radiation.',
  },

  {
    image: vendor6,
    name: 'Terracota',
    type: 'Terracon',
    desc: 'Earth friendly yet stylish wall, roofing & flooring products.',
  },
  {
    image: vendor7,
    name: 'Intelligent Water',
    type: 'SmarterHomes',
    desc: `An intelligent water meter to manage & control water consumption`,
  },
  {
    image: vendor8,
    name: 'Solar Roof Tiles',
    type: 'Anu Solar',
    desc: 'Roof tiles that generate power in additions to providing a beautiful roof.',
  },
  {
    image: vendor9,
    name: 'Clean Water from Air',
    type: 'Elixir',
    desc: 'Sustainable & cost-effective solution to generate pure drinking water from air',
  },
  {
    image: vendor10,
    name: 'Duplex Solar Panels',
    type: 'Birds Eye Energy',
    desc: 'Generate hot water and electricity from a single panel for efficient use of space.',
  },
];
