import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    border: '1px solid ' + theme.palette.grey[400],
    boxSizing: 'border-box',
    borderRadius: '10px',
    minWidth: '150px',
    maxWidth: '105px',
    '&:focus': {
      outline: 'none',
    },
    minHeight: '65px',
    textTransform: 'capitalize',
  },
  selected: {
    border: '1px solid ' + theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark + '!important',
    color: theme.palette.common.white + '!important',
  },
}))(ToggleButton);
function getButtons() {
  return ['Basic Information', 'Land Details', 'Dwelling Unit Details', 'Common Area Details', 'Plumbing Details', 'Material Details', 'File Upload'];
}
function getType() {
  return ['basic', 'land', 'dwelling', 'common', 'fixture', 'materials', 'file'];
}

function TabButtonGrp(props) {
  let history = useHistory();
  const types = getType();

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
        {getButtons().map((btn, index) => (
          <Grid item xs key={index}>
            <StyledButton
              value={btn}
              selected={props.activeStep === types[index]}
              onChange={() => {
                if (props.somethingChanged === true) {
                  props.toogleShowModal(true);
                  props.SETNEXT(types[index]);
                } else history.push(`/projects/${props.projectId}/edit?type=${types[index]}`);
              }}
            >
              <Typography variant="body1">{btn}</Typography>
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default TabButtonGrp;
