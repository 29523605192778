import { useAppApiRequest } from '../../../../hooks/useAppApiRequest';

const useAppPIPScheme = (type) => {
  const { data: re, isError, isFetching } = useAppApiRequest(`/pipschemev2?category=${type}`, { method: 'GET' }, [type], type !== null && type !== undefined);
  const data = re?.data?.data?.data;

  return {
    data,
    isError,
    isFetching,
  };
};
export default useAppPIPScheme;
