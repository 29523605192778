import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

function ShowInterventionCostAssumptionsParameters({ interventionCustomizable, myArray, appendChanged, onClick }) {
  const classes = useStyles();
  return (
    <>
      <Grid container item xs={12}>
        <Typography variant="h6">Intervention Cost Assumptions</Typography>
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
      </Grid>
      <Grid item container alignItems="flex-start" direction="row" xs={12} style={{ marginTop: '1%' }}>
        {interventionCustomizable &&
          interventionCustomizable.map((data, index) => {
            return (
              <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={index > 2 ? { marginTop: '3%' } : { marginTop: '1%' }}>
                  <Grid xs>
                    <Typography color="textSecondary" variant="h6">
                      {data.label}
                    </Typography>
                  </Grid>

                  <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%' }}>
                    {data && !data.hasOwnProperty('valueOptions') ? (
                      <Grid xs={7}>
                        <ResetFields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            myArray && !myArray.find((d) => d.id === data.id)
                              ? data.value
                              : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                          }
                          placeholder=""
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged(data.id, e.target.value);
                          }}
                          fullWidth
                          required
                          onClick={() => appendChanged(data.id, data.defaultValue)}
                          disabled={data.editable ? false : true}
                        />
                      </Grid>
                    ) : (
                      <Grid xs={7}>
                        <Grid container item xs={12}>
                          <Grid item xs={9}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <Select
                                // className={classes.select}
                                value={
                                  myArray && !myArray.find((d) => d.id === data.id)
                                    ? data.value
                                    : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  appendChanged(data.id, e.target.value);
                                }}
                              >
                                {data.valueOptions
                                  ? data.valueOptions.map((value) => (
                                      <MenuItem value={value} name={value}>
                                        {value}
                                      </MenuItem>
                                    ))
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <ReplaySharpIcon style={{ fontSize: '20px', color: '#666666', cursor: 'pointer' }} onClick={onClick} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {data && !data.hasOwnProperty('valueOptions') ? (
                      <Grid xs={3} className={classes.grid} style={{ paddingTop: '7px', paddingLeft: '2%' }}>
                        <Typography variant="h6">{data.unit ? data.unit : ''}</Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
}

export default ShowInterventionCostAssumptionsParameters;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '2%',
  },
  grid2: {
    marginTop: '6%',
  },
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));
