import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import HeaderAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors } from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ResourceCardDesktop from '../../common/ResourceCard/ResourceCardDesktop';
import fallbackimageone from '../../../../../assets/Marketplace/fallbackimageone.svg';
import fallbackimagetwo from '../../../../../assets/Marketplace/fallbackimagetwo.svg';
import BreadCrumbs from '../../common/BreadCrumb';
import cement from '../../../../../assets/Marketplace/material-resources/Cement-07.png';
import steel from '../../../../../assets/Marketplace/material-resources/Steel-feature-06.png';
import paintimage4 from '../../../../../assets/Marketplace/Resources/Paint2.png';
import glazingimage10 from '../../../../../assets/Marketplace/Resources/glazingimage10.png';
import wallblocks from '../../../../../assets/Marketplace/material-resources/wallblocks.png';

const KnowledgeHubData = () => {
  const history = useHistory();
  const cardHeight = '320px';
  const links = [{ href: `/marketplace`, text: 'MarketPlace' }];

  return (
    <Container maxWidth="md">
      <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
        <HeaderAndBackIcon
          heading={<Typography variant="h4">Resources</Typography>}
          arrowColor={MarketplaceColors.Secondary}
          handleClick={() => history.goBack()}
        />
      </Grid>
      <Grid>
        <BreadCrumbs links={links} stage={<b>Resources</b>} paddingLeft="1%" />
      </Grid>
      <Grid style={{ marginTop: '2%', marginLeft: '4%' }} container alignItems="flex-start" justifyContent="flex-start">
        <Typography variant="h6" style={{ color: MarketplaceColors.Secondary }}>
          Empowering Sustainable Choices in Construction
        </Typography>
      </Grid>
      <Grid container justifyContent="center" spacing={2} style={{ marginTop: '2%', marginLeft: '3%' }}>
        <Grid item xs={6} container alignItems="center" justifyContent="flex-start">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardWidth="120%"
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={cement}
            text="Guide"
            longText="Cement For Residential Appartment"
            href={'/marketplace/resources/cement'}
          />
        </Grid>
        <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={steel}
            text="Guide"
            longText="Steel for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/steel'}
          />
        </Grid>
        <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={paintimage4}
            text="Guide"
            longText="Paints for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/paints'}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2} style={{ marginTop: '2%', marginLeft: '3%', marginBottom: '4%' }}>
        <Grid item xs={3} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={glazingimage10}
            text="Guide"
            longText="Glazing for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/glazing'}
          />
        </Grid>
        <Grid item xs={3} container alignItems="center" justifyContent="center">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={fallbackimagetwo}
            text="Guide"
            longText="Interior Flooring for Residential Apartments"
            shortCard={true}
            href={'/marketplace/resources/interiorflooring'}
          />
        </Grid>
        <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
          <ResourceCardDesktop
            cardHeight={cardHeight}
            cardWidth="120%"
            cardPadding="2%"
            imageWidth="100%"
            imageHeight="100%"
            sourceImage={wallblocks}
            text="Guide"
            longText="Wall Blocks for Residential Apartments"
            href={'/marketplace/resources/wallblocks'}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default KnowledgeHubData;
