import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import iconforgotoproject from '../../../../assets/databaseImages/iconforgotoproject.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomHeader = ({ variant, sectionName, i, history, projectId, expand, handleExpandIcon, description }) => {
  return (
    <Grid container xs={12} justifyContent="space-between" alignItems="center">
      <Grid xs={11} container alignItems="center">
        <Grid>
          <Typography variant={variant ? variant : 'h5'}>
            <b>{sectionName}</b>
          </Typography>
        </Grid>
        {expand && (
          <Grid>
            <ExpandMoreIcon style={{ color: '#49DDB1' }} fontSize="large" onClick={handleExpandIcon} />
          </Grid>
        )}
      </Grid>
      {description && (
        <Grid>
          <Typography variant="h6">{description}</Typography>
        </Grid>
      )}
      {i === 0 && (
        <Grid xs={1} container style={{ paddingTop: '2%' }}>
          <Grid xs={3}>
            <img src={iconforgotoproject} />
          </Grid>
          <Grid xs={9}>
            <Typography variant="h6" style={{ color: '#49DDB1', cursor: 'pointer' }} onClick={() => history.push(`/projects/${projectId}/certification`)}>
              Exit
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomHeader;
