import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Grid } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import { DrawerItems } from './MarketplaceNavigatorMobile';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { FilterSections } from '../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  labelfont: {
    textDecoration: 'none !important',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: MarketplaceColors.Secondary,
  },
  selected: {
    backgroundColor: '#FFFFFF',
    color: MarketplaceColors.Secondary,
    '&:focus, &:active, &:hover': {
      textDecoration: 'none',
      color: MarketplaceColors.Secondary,
      backgroundColor: '#FFFFFF',
    },
  },
}));

const MobileFilterNavigationList = (props) => {
  const classes = useStyles();
  const { URLS, filtersData, categoryarr } = useContext(ProductsPageContext);

  const { handleSelected, selected, categories, selectedCategory, setSelectedCategory } = props;
  const { category } = URLS;
  let getcatgories = category !== null ? category.split(',') : [];
  let isBrand = filtersData.filter((ele) => ele.id === FilterSections.Brands);
  const isCategory = (name) => categoryarr && categoryarr.filter((ele) => ele.name === name && ele.checked === true);
  // console.log(categoryarr, 'categoryarr');
  // console.log(name, 'categoryarr')
  return (
    <Grid item xs={12}>
      <List>
        {categories &&
          categories.map(({ attributes }, index) =>
            getcatgories.map((ele) => {
              if (ele === attributes?.name) {
                return (
                  <ListItem button key={index} className={selectedCategory === attributes?.name ? classes.selected : classes.labelfont}>
                    {isCategory(attributes?.name).length !== 0 && (
                      <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary, paddingRight: '5px' }} />
                    )}
                    <ListItemText
                      primary={attributes?.name}
                      onClick={() => {
                        handleSelected(attributes?.name);
                        setSelectedCategory(attributes?.name);
                      }}
                    />
                  </ListItem>
                );
              }
            })
          )}
        <ListItem button className={selected === DrawerItems.brands ? classes.selected : classes.labelfont}>
          {isBrand.length !== 0 && <FiberManualRecordIcon style={{ fontSize: '10px', color: MarketplaceColors.Secondary, paddingRight: '10px' }} />}
          <ListItemText
            primary="Brands"
            onClick={() => {
              handleSelected(DrawerItems.brands);
              setSelectedCategory('');
            }}
          />
        </ListItem>
        {/* eco labels to be implemented */}
        {/* <ListItem button className={selected === DrawerItems.ecolabel ? classes.selected : classes.labelfont}>
          <ListItemText
            primary="Eco label"
            onClick={() => {
              handleSelected(DrawerItems.ecolabel);
              setSelectedCategory('');
            }}
          />
        </ListItem> */}
        {/* filters to be implemented */}
        {/* <ListItem button className={selected === DrawerItems.filters ? classes.selected : classes.labelfont}>
          <ListItemText
            primary="Filters"
            onClick={() => {
              handleSelected(DrawerItems.filters);
              setSelectedCategory('');
            }}
          />
        </ListItem> */}
      </List>
    </Grid>
  );
};

export default MobileFilterNavigationList;
