import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import AntTabs, { AntTab } from '../../../common/Tab/AntTabs';

const useStyles = makeStyles((theme) => ({
  base: {
    overflow: 'hidden',
  },
  root: {
    marginTop: '10%',
  },
  block: {
    height: '300px',
  },
  paper2: {
    background: '#FAFAFA',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    paddingTop: '4%',
  },
  design: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
  },
  design2: {
    borderRight: '1px solid rgba(0, 0, 0, 0.14)',
    borderSpacing: '10px',
    borderCollapse: 'separate',
  },
  text: {
    color: '##000000',
  },
  color: {
    color: '#00C58A',
    paddingRight: '20px',
  },
  text2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.83)',
    paddingTop: '3%',
  },
}));

function VendorsTab(props) {
  const classes = useStyles();
  const vendor = props.vendor;
  const [value, setValue] = useState(0);
  const [data, setData] = useState(vendor && vendor.features);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (value === 0) setData(vendor.features);
    if (value === 1) setData(vendor.properties);
    if (value === 2) setData(vendor.maintainance);
    if (value === 3) setData(vendor.aboutUs);
  }, [value, vendor]);

  return (
    <React.Fragment>
      <Grid className={classes.base}>
        <Grid item xs={12} align="center">
          <AntTabs value={value} onChange={handleChange} aria-label="ant example" className={classes.design}>
            <AntTab disableRipple label={<Typography variant="h5">Features</Typography>} className={classes.design2} />
            <AntTab disableRipple label={<Typography variant="h5">Properties</Typography>} className={classes.design2} />
            <AntTab disableRipple label={<Typography variant="h5">Maintenance</Typography>} className={classes.design2} />
            <AntTab disableRipple label={<Typography variant="h5">About us</Typography>} className={classes.design2} />
          </AntTabs>
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={12}>
        {data
          ? data.map((newdata) => {
              return (
                <Grid container direction="row" item xs={12}>
                  <Typography variant="h4" style={{ color: '#00C58A', paddingRight: '12px', paddingTop: '2%' }}>
                    .
                  </Typography>
                  <Grid item xs={10}>
                    <Typography className={classes.text2}>{newdata}</Typography>
                  </Grid>
                </Grid>
              );
            })
          : vendor &&
            vendor.features.map((newdata) => {
              return (
                <Grid container direction="row" item xs={12}>
                  <Typography variant="h4" style={{ color: '#00C58A', paddingRight: '20px' }}>
                    .
                  </Typography>
                  <Grid item xs={10}>
                    <Typography variant="h5">{newdata}</Typography>
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
    </React.Fragment>
  );
}

export default VendorsTab;
