import React from 'react';
import { ResourcesData, types } from '../MaterialSelectionGuideData';
import MarketplaceNavbar from '../../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import HeaderAndBackIcon from '../../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Seek from '../../../../../../assets/Marketplace/Resources/Group 1.svg';
import Check from '../../../../../../assets/Marketplace/Resources/Group 1 (1).svg';
import Inquire from '../../../../../../assets/Marketplace/Resources/Group 1 (2).svg';
import Choose from '../../../../../../assets/Marketplace/Resources/Group 1 (3).svg';
import Steppercard from './components/StepperCard';
import ImageWithSource from './components/ImageWithSource';
import ScrollableTable from './components/ScrollableTable';
import ImageAndSource from './components/ImageandSource';
import TextWithPoints from './components/TextWithPoints';
import TextWithHeading from './components/TextWithHeading';
import TextWithPointsandSubpoints from './components/TextwithPointAndSubpoint';

const useStyles = makeStyles((theme) => ({
  stepperstyle: {
    height: '170px',
    width: '170px',
  },
  padding: {
    paddingLeft: '20px',
    paddingTop: '4vh',
    paddingBottom: '6vh',
  },
  stepperhead: {
    textAlign: 'center',
    color: MarketplaceColors.Secondary,
    width: '170px',
    paddingTop: '4%',
  },
  steppertext: {
    color: MarketplaceColors.steppertextcolor,
    width: '170px',
    textAlign: 'center',
  },
  textstyle: MarketplaceTypography.BodyRegular,
}));

const icons = { Seek: Seek, Check: Check, Inquire: Inquire, Choose: Choose };

const Headers = ['', 'Typically used in Residential Buildings'];
const secondHeader = ['Environmental Impacts', 'Steel Rebar', 'BOF Steel', 'DRI Steel', 'EAF Steel'];
function createData(name, a) {
  return { name, a };
}
function createDataforsecondTable(name, a, b, c, d) {
  return { name, a, b, c, d };
}
const Steel = (props) => {
  const history = useHistory();
  const steelData = ResourcesData.filter((d) => d.iid === 'Steel');
  const steppers = steelData[0]?.data.find((d) => d.type === types.steppers);
  console.log(steppers, 'steelData');
  const classes = useStyles();
  function TableHeaders() {
    let tableHeaders = [];
    steelData[0]?.data.find((a) => a.id === 5)?.data.map((e, index) => (index === 1 ? tableHeaders.push(e.value1, e.value2) : ''));
    return tableHeaders;
  }
  function Tablerows() {
    let tablerows = [];
    steelData[0]?.data.find((a) => a.id === 5)?.data.map((e, index) => (index !== 0 ? tablerows.push(createData(e.value1, e.value2)) : ''));
    return tablerows;
  }
  function SecondTableHeaders() {
    let tableHeaders = [];
    steelData[0]?.data
      .find((a) => a.id === 27)
      ?.data.map((e, index) => (index === 1 ? tableHeaders.push(e.value1, e.value2, e.value3, e.value4, e.value5) : ''));
    return tableHeaders;
  }
  function SecondTablerows() {
    let tablerows = [];
    steelData[0]?.data
      .find((a) => a.id === 27)
      ?.data.map((e, index) => (index !== 0 ? tablerows.push(createDataforsecondTable(e.value1, e.value2, e.value3, e.value4, e.value5)) : ''));
    return tablerows;
  }
  return (
    <div>
      <MarketplaceNavbar />
      <Container maxWidth="md">
        <Grid style={{ marginTop: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeaderAndBackIcon
            heading={<Typography variant="h4">{steelData[0].title}</Typography>}
            arrowColor={MarketplaceColors.Secondary}
            handleClick={() => history.goBack()}
          />
        </Grid>
        {steelData.map(({ data }) => (
          <Grid container alignItems="flex-start" justifyContent="flex-start">
            {/* steppers */}
            <Grid style={{ marginTop: '2%', marginLeft: '4%' }} container alignItems="flex-start" justifyContent="flex-start">
              {steppers.data.map((ele) => (
                <Steppercard ele={ele} icons={icons} />
              ))}
            </Grid>
            {data.map((d) => {
              return (
                <Grid style={{ marginLeft: '4%' }} container>
                  {/* text */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.text && (d.id === 2 || d.id === 21 || d.id === 24 || d.id === 26) && (
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        {d.text}
                      </Typography>
                    )}
                  </Grid>
                  {/* image */}
                  <Grid container alignItems="flex-start" justifyContent="center" style={{ marginTop: '2%' }}>
                    {d.type === types.image && d.id === 3 && <ImageWithSource image={d.image} source={d.source} />}
                  </Grid>
                  {/* Heading */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.heading && (d.id === 4 || d.id === 19 || d.id === 22) && (
                      <Typography style={MarketplaceTypography.Heading3}>{d.title}</Typography>
                    )}
                  </Grid>
                  {/* table */}
                  <Grid style={{ marginTop: '2%', justifyContent: 'center', alignItems: 'center', width: '80%', marginLeft: '10%' }} container>
                    {d.type === types.table && d.id === 5 && <ScrollableTable rows={Tablerows()} tableHeaders={TableHeaders()} Headers={Headers} />}
                  </Grid>
                  {/* Heading */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.heading && d.id === 6 && <Typography style={MarketplaceTypography.Heading3}>{d.title}</Typography>}
                  </Grid>
                  {/* image with source*/}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.image && (d.id === 7 || d.id === 8 || d.id === 13 || d.id === 14 || d.id === 17) && (
                      <ImageAndSource
                        classname={classes.textstyle}
                        image={d.image}
                        source={d.source}
                        imageStyle={{ width: 'auto' }}
                        justifyContent="flex-start"
                      />
                    )}
                  </Grid>
                  {/* Text with headig */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.text && (d.id === 9 || d.id === 12) && <TextWithHeading classname={classes.textstyle} text={d.text} />}
                  </Grid>
                  {/* subhead */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.subhead && d.id === 10 && (
                      <Typography className={classes.textstyle} style={{ textAlign: 'justify' }}>
                        <span style={{ fontWeight: 'bold' }}>{d.title}</span>
                      </Typography>
                    )}
                  </Grid>
                  {/* Text with points */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.textwithpoints && (d.id === 11 || d.id === 20) && <TextWithPoints classname={classes.textstyle} data={d.data} />}
                  </Grid>
                  {/* Text with headig */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.text && (d.id === 15 || d.id === 16) && (
                      <Typography style={MarketplaceTypography.CaptionLarge}>
                        <span style={{ fontWeight: 'bold' }}>{d.text.split(':')[0]}</span>
                        {d.text.split(':')[1]}
                      </Typography>
                    )}
                  </Grid>
                  {/* Image full width */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.imagefulllength && (d.id === 18 || d.id === 23) && <img src={d.image} alt="..." width="100%" />}
                  </Grid>
                  {/* Text with point and sub point */}
                  <Grid container alignItems="flex-start" justifyContent="flex-start">
                    {d.type === types.textwithpointsandsubpoints && d.id === 25 && <TextWithPointsandSubpoints data={d.data} />}
                  </Grid>
                  {/* table */}
                  <Grid style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }} container>
                    {d.type === types.table && d.id === 27 && (
                      <ScrollableTable rows={SecondTablerows()} tableHeaders={SecondTableHeaders()} Headers={secondHeader} />
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Container>
      <Grid style={{ marginTop: '100px' }}></Grid>
      <MarketplaceFooter />
    </div>
  );
};

export default Steel;
