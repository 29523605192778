import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import Fields from '../../../../common/textfield/Fields';

function LocationBox(props) {
  const {
    field,
    type,
    index,
    options,
    loading,
    open,
    onOpen,
    onClose,
    onChange,
    handleSearch,
    onInputChange,
    getOptionLabel,
    isOptionEqualToValue,
    defaultValue,
  } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        item
        xs={12}
        key={index}
        style={
          index === 0 ? { height: 'Auto', marginTop: '1%', marginBottom: '1%', marginLeft: '2%' } : { height: 'Auto', marginLeft: '2%', marginBottom: '1%' }
        }
      >
        <Grid alignItems="left" item xs={12}>
          <Typography variant="h5">{field.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field.description}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={2}>
          <Autocomplete
            id="combo-box-demo"
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            loading={loading}
            defaultValue={defaultValue}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => (
              <Fields
                placeholder="Location"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {<SearchIcon onClick={handleSearch} style={{ color: '#66E3BE', marginRight: '-65%', fontSize: '40px', cursor: 'pointer' }} />}
                    </React.Fragment>
                  ),
                }}
                fullWidth
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LocationBox;
