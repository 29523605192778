import React from 'react';
import { Typography, Grid, withWidth, Paper } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import CustomDropdown from '../common/CustomDropdown';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import mandateImg from '../../../../../assets/interventions/mandate.svg';

const WWMCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    state,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const endUseValueOptions = [
    { option: 'Biogas (1,000+ kgs)', label: 'Biogas (1,000+ kgs)', enabled: getQuantityValue(schema.wwm_disableBiogas) },
    { option: 'Biogas to Electricity (1,000+ kgs)', label: 'Biogas to Electricity (1,000+ kgs)', enabled: getQuantityValue(schema.wwm_disableBiogas) },
    { option: 'Natural Composting (upto 1,000 kgs)', label: 'Natural Composting (upto 1,000 kgs)', enabled: true },
  ];
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm__pi_capacity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wwm__pi_capacity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_capacity, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_capacity, getQuantityDefaultValue(schema.wwm__pi_capacity));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wwm__pi_capacity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm__pi_endUse)}</Typography>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={11} style={{ marginRight: '30px' }}>
              <CustomDropdown
                value={getQuantityValue(schema.wwm__pi_endUse)}
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_endUse, e.target.value);
                }}
                options={endUseValueOptions}
                disabled={false}
              />
            </Grid>
            <Grid item xs={2}>
              <ReplaySharpIcon
                style={{ fontSize: '20px', color: '#666666', cursor: 'pointer' }}
                onClick={() => updateQuantityValue(schema.wwm__pi_endUse, getQuantityDefaultValue(schema.wwm__pi_endUse))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_areaRequired)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {getQuantityValue(schema.wwm_areaRequired)} {getQuantityUnit(schema.wwm_areaRequired)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '20px' }}>
        <Paper className={classes.mandatePaperStyle}>
          <Grid container xs={12} direction="row">
            <Grid item xs={1} style={{ paddingLeft: '10px', paddingTop: '20px' }}>
              <img src={mandateImg} alt="..." />
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.mandateText}>{getQuantityValue(schema.wwm_mandateMsg)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default WWMCustomBasicParameters;
