export const Water = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  return (
    <svg width={65} height={91} viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={color}>
        <path d="M56.7501 54V56.25H51.1251V61.875H48.8751V56.25H43.2501V54H48.8751V48.375H51.1251V54H56.7501ZM61.2501 55.125C61.2501 61.3266 56.2017 66.375 50.0001 66.375C48.6108 66.375 47.2833 66.1106 46.0514 65.6465C42.2545 68.3241 37.814 69.75 33.125 69.75C20.7194 69.75 10.625 59.6556 10.625 47.25C10.625 35.2404 31.3082 4.07221 32.1884 2.75041C32.6047 2.12323 33.6424 2.12323 34.0615 2.75041C34.8828 3.98227 53.6227 32.223 55.4755 45.306C58.9152 47.2325 61.2496 50.9085 61.2496 55.1244L61.2501 55.125ZM43.7565 64.4766C40.7415 62.4544 38.7502 59.0176 38.7502 55.125C38.7502 48.9234 43.7987 43.875 50.0002 43.875C51.0437 43.875 52.0506 44.0297 53.0124 44.2969C50.6134 32.9425 36.8628 11.1961 33.1254 5.43129C29.0248 11.7565 12.8754 37.3057 12.8754 47.2501C12.8754 58.4161 21.9594 67.5001 33.1254 67.5001C36.9364 67.5001 40.5672 66.451 43.7568 64.4766L43.7565 64.4766ZM59.0001 55.125C59.0001 50.1637 54.9614 46.125 50.0001 46.125C45.0388 46.125 41.0001 50.1637 41.0001 55.125C41.0001 60.0863 45.0388 64.125 50.0001 64.125C54.9614 64.125 59.0001 60.0863 59.0001 55.125ZM18.5001 47.2494H16.2501C16.2501 53.7432 20.0554 59.7372 25.9419 62.5182L26.9038 60.4847C21.7991 58.0716 18.5002 52.8773 18.5002 47.2488L18.5001 47.2494Z" />
      </g>
    </svg>
  );
};
