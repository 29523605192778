import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { extractData } from '../../../../utils/dataConditionsCheck';

export default function EclipseProgress({ newvalue, range, staticvalue, style, textcolor, circlecolor, creditDetailsData }) {
  const useStylesFacebook = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: '#F28D14',
    },
    top: {
      color: '#E5E5E5',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    down: {
      color: '#26E0A3',
      position: 'absolute',
      left: 0,
      borderRadius: '10px',
    },
  }));
  const classes = useStylesFacebook();

  return (
    <Box position="relative" display="inline-flex" style={style}>
      <CircularProgress
        variant="static"
        style={{
          color: circlecolor,
          borderRadius: '10px',
        }}
        value={newvalue}
        thickness={5}
        size={80}
      />
      <CircularProgress variant="static" value={staticvalue} className={classes.top} classes={{ circle: classes.circle }} thickness={5} size={80} />
      <CircularProgress
        variant="static"
        // value={100}
        className={classes.down}
        value={newvalue}
        thickness={5}
        size={80}
      />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6" style={{ color: textcolor }}>
          <b>
            {creditDetailsData.overall
              ? `${extractData(creditDetailsData.overall.totalpointsachieved)}/${extractData(creditDetailsData.overall.totalpoints)}`
              : '-'}
          </b>
        </Typography>
      </Box>
    </Box>
  );
}
