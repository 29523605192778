import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Buffer } from 'buffer';
window.Buffer = Buffer;
const ele = (
  <>
    <App />
  </>
);

ReactDOM.render(ele, document.getElementById('root'));
