import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CustomizedBar from '../common/CustomisedBar';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  fffff: {
    minHeight: 150,
    padding: 15,
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    marginBottom: '5%',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
    fontSize: '13px',
  },
  custom: {
    fontSize: '14px',
    fontWeight: 850,
    letterSpacing: '0.71px',
    color: '#00C58A',
    cursor: 'pointer',
    textAlign: 'right',
  },
}));

export default function IGBCDocumentation({ documentUploadPercentage }) {
  const classes = useStyles();
  const matchRoute = useRouteMatch();
  let history = useHistory();
  const matches = useMediaQuery('(min-width:1280px)');

  return (
    <>
      <Grid xs={!matches ? 11 : 12} container style={{ paddingBottom: '3%' }}>
        <Grid xs>
          <Typography variant="h6">Documentation</Typography>
        </Grid>
        <Grid xs>
          <Typography variant="h6" className={classes.custom} onClick={() => history.push(`${matchRoute.url}/upload`)}>
            <AddBoxRoundedIcon style={{ marginBottom: '-5px', fontSize: '20px' }} />
            Upload Documents
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={!matches ? 11 : 12} className={classes.fffff} container direction="column">
        <Grid xs>
          <Typography className={classes.secondaryTextcolor}>Documentation Progress</Typography>
        </Grid>
        <Grid xs style={{ marginTop: '2%' }}>
          <CustomizedBar percentValue={true} insideColor="#E5E5E5" bgcolor="#FBA9AA" value={documentUploadPercentage} barvalue={documentUploadPercentage} />
        </Grid>
        <Grid xs>
          <Typography style={{ lineHeight: '22px', color: '#666666', fontSize: '13px' }}>Upload all the required documents to get IGBC certified</Typography>
        </Grid>
      </Grid>
    </>
  );
}
