import { AllCementIcon } from './icons/Cements';
import { AllFlooringIcon } from './icons/Floorings';
import { AllSteelIcon } from './icons/Steel';
import { AllWallBlocksIcon } from './icons/WallBlocks';
import { AllGlazingIcon } from './icons/Glazing';
import { AllPlumbingFixturesIcon } from './icons/PlumbingFixtures';
import { AllPaintsIcon } from './icons/Paints';
import { WasteWater } from './icons/WasteWater';
import { HotWater } from './icons/HotWater';
import { Water } from './icons/Water';
import { Waste } from './icons/Waste';
import { SPV } from './icons/SPV';
import { RainWaterHarvesting } from './icons/RainWaterHarvesting';

export const getIcons = (isMobile, isselected, name) => {
  const categoriesWithIcons = [
    { name: 'Cement', icons: <AllCementIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Flooring', icons: <AllFlooringIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Glazing', icons: <AllGlazingIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Paints', icons: <AllPaintsIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Plumbing Fixtures', icons: <AllPlumbingFixturesIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Steel', icons: <AllSteelIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Wall Blocks', icons: <AllWallBlocksIcon isMobile={isMobile} isselected={isselected} /> },
    { name: 'Waste Water Treatment', icons: <WasteWater isMobile={isMobile} isselected={isselected} /> },
    { name: 'Hot Water Management', icons: <HotWater isMobile={isMobile} isselected={isselected} /> },
    { name: 'Water Management', icons: <Water isMobile={isMobile} isselected={isselected} /> },
    { name: 'Waste Management Companies', icons: <Waste isMobile={isMobile} isselected={isselected} /> },
    { name: 'Wet Waste Management', icons: <Waste isMobile={isMobile} isselected={isselected} /> },
    { name: 'SPV', icons: <SPV isMobile={isMobile} isselected={isselected} /> },
    { name: 'Rain Water Harvesting', icons: <RainWaterHarvesting isMobile={isMobile} isselected={isselected} /> },
  ];
  const category = categoriesWithIcons?.find((d) => d?.name === name);
  if (category) {
    const IconComponent = category.icons;
    return IconComponent;
  }
  return <AllCementIcon isMobile={isMobile} isselected={isselected} />;
};
