import React from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormHelperText } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Loader from '../../../../config/Loader';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
}));

function OrganisationInput(props) {
  const { organisationv2, handleOrganisation, orgv2, clickedContinue, userRoles } = props;
  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 15,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: clickedContinue === true && !organisationv2 ? '1px solid red' : '1px solid #ced4da',

      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),

      '&:focus': {
        borderRadius: 15,
        borderColor: 'rgba(0, 0, 0, 0.38);',
        boxShadow: `${fade('rgba(0, 0, 0, 0.38', 0.25)} 0 0 0 0.1rem`,
      },
    },
  }))(InputBase);
  const classes = useStyles();

  return (
    <>
      <Grid container justify="left" direction="row" item xs={12}>
        {Loader(() => (
          <>
            <Grid container item xs={12} className={classes.spacing}>
              <Typography variant="h5"> Enter Organisation Name*</Typography>
            </Grid>
            <Grid container item xs={2}>
              <NativeSelect id="demo-customized-select-native" value={organisationv2} onChange={handleOrganisation} input={<BootstrapInput />}>
                <option aria-label="None" value="none" />
                {orgv2 &&
                  orgv2.data.map((option) => {
                    return <option value={option._id}>{option.name}</option>;
                  })}
              </NativeSelect>
              {clickedContinue === true && !organisationv2 ? <FormHelperText style={{ color: 'red' }}>organisation missing.</FormHelperText> : ''}
            </Grid>
          </>
        ))(userRoles.admins)}
      </Grid>
    </>
  );
}

export default OrganisationInput;
