import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import ApiError from '../../../common/Error/ApiError';

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

export const EditProjectLandDetailsPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const {
    get: { form, projectPIP, isError, isFetching },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const basicPIPs = ['project__pi_landscapeArea', 'project__pi_softsurfaceArea'];

  const additionalPIPs = [
    'project__pi_concretePavement',
    'project__pi_gravelPavement',
    'project__pi_openGridConcretePavement',
    'project__pi_flatSurface',
    'project__pi_vegetationFlatSurface',
    'project__pi_vegetationOpenGridGrassPavement',
    'project__pi_vegetationSlightlySlopedSurface',
    'project__pi_vegetationHillySurface',
    'project__pi_vegetationSteepSurface',
    'project__pi_grassSlightlySlopedSurface',
    'project__pi_grassHillySurface',
    'project__pi_grassSteepSurface',
  ];
  const simplePIPs = data?.filter((field) => basicPIPs.includes(field.name))?.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name));
  const additionalLandscapePIPs = data?.filter((field) => additionalPIPs.includes(field.name));

  const { data: projectData } = useAppProjectDetails(id);

  // useEffect(() => {
  //   if (projectPIP.length !== 0 && myArray.length !== 0) {
  //     myArray.forEach((ele) => {
  //       let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
  //       if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
  //     });
  //   }
  // }, [projectPIP, myArray]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSave = async (next) => {
    await axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: Object.entries(form?.pipvalues).map(([key, value]) => ({
          PIPScheme: key,
          value,
        })),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };

  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/* pips */}
      {type && type === 'land' && simplePIPs && projectPIP?.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      {/* Aditional lasnscape area */}
      {type && type === 'land' && additionalLandscapePIPs && projectPIP?.length !== 0
        ? additionalLandscapePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
