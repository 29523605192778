import React, { useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AQI from './AQI';
import IGBC from './IGBC';
import Weather from './Weather';
import ComponentTitle from '../components/ComponentTitle';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(1),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
}));

const LocationInsightsCarousal = (props) => {
  const classes = useStyles();
  const insights = props.insights;
  let carousals = [
    { id: 1, title: 'Location Based Insights - AQI', data: 'component1' },
    { id: 2, title: 'Location Based Insights - IGBC ', data: 'component2' },
    { id: 3, title: 'Location Based Insights - Annual Weather', data: 'component3' },
  ];
  let str = carousals && carousals.length - 1;

  const [number, setNumber] = useState(0);

  const handleBefore = (e) => {
    e.preventDefault();
    if (number === 0 && str) setNumber(str);
    else if (number > 0) setNumber(number - 1);
  };
  const handleNext = () => {
    if (number >= 0 && number < str) setNumber(number + 1);
    else if (number === str) setNumber(0);
  };

  return (
    <>
      {insights ? (
        <>
          <ComponentTitle title={carousals && carousals[number].title} marginbottom={0} />
          <Container className={classes.gridContainer} disableGutters>
            <Grid container alignItems="center" item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
              <Grid item xs={1} align="left" alignItems="flex-start">
                <NavigateBeforeIcon style={{ color: '#2E2E2E', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleBefore} />
              </Grid>
              <Grid container item xs={10} align="center">
                {number === 0 ? <AQI aqi={insights && insights.aqi} /> : ''}
                {number === 1 ? <IGBC igbc={insights && insights.igbc} /> : ''}
                {number === 2 ? <Weather weather={insights && insights.temperature} /> : ''}
              </Grid>
              <Grid item xs={1} align="right" alignItems="flex-end">
                <NavigateNextIcon style={{ color: '#2E2E2E', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleNext} />
              </Grid>
            </Grid>
          </Container>
          <Grid container item xs={12} alignItems="center" alignContent="center">
            <Grid item xs={12} align="center">
              {carousals &&
                carousals.map((c, index) => {
                  return <FiberManualRecordIcon style={index === number ? { color: '#42E198' } : { color: 'rgba(196, 196, 196, 0.5)' }} />;
                })}
            </Grid>
          </Grid>
        </>
      ) : (
        <Skeleton variant="rect" width="100%" height={130} animation="wave" />
      )}
    </>
  );
};
export default LocationInsightsCarousal;
