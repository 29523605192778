import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';

export const checkScreenWidth = (start, end, width) => {
  if (width > start && width <= end) return true;
  else return false;
};

const useScreenType = () => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isdesktop, setIsDesktop] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateScreenWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  useEffect(() => {
    if (screenWidth) {
      const checked = checkScreenWidth(0, theme.breakpoints.mobile, screenWidth);
      const tabChecked = checkScreenWidth(theme.breakpoints.mobile, theme.breakpoints.tablet, screenWidth);
      const laptopchecked = checkScreenWidth(theme.breakpoints.tablet, theme.breakpoints.laptop, screenWidth);
      const desktopchecked = checkScreenWidth(theme.breakpoints.laptop, theme.breakpoints.desktop, screenWidth);

      setIsMobile(checked);
      setIsTablet(tabChecked);
      setIsLaptop(laptopchecked);
      setIsDesktop(desktopchecked);
    }
  }, [screenWidth]);

  return { isMobile, isTablet, isLaptop, isdesktop };
};

export default useScreenType;
