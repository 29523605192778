import React, { useEffect, useState } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CustomInput from '../../../common/textfield/CustomInput';
import CustomLink from '../../../common/link/CustomLink';
import PrimaryButton from '../../../common/button/Button';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    paddingTop: '0%',
    paddingLeft: '3%',
    paddingBottom: '3%',
  },
  rootModal: {
    '& > *': {
      margin: theme.spacing(1),
      paddingRight: '20%',
      width: '25ch',
    },
    maxWidth: 205,
    marginRight: '2%',
    marginBottom: '2%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formModal: {
    width: '100%',
    marginTop: '-1%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 800,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SubCategory = () => {
  const classes = useStyles();
  const data = [1, 1, 1, 1, 11, 1, 1, 1, 1];
  const [searchedCategoryScheme, setSearchedCategoryScheme] = useState('');

  const [value, setValue] = React.useState('');
  const [categoryname, setCategoryname] = useState('');
  const [name, setName] = React.useState('');
  const [category, setCategory] = useState('');
  const [categoryScheme, setCategoryScheme] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [{ status, response }, makeRequest] = useApiRequest('/subcategoryschemev2', {
    verb: 'get',
  });

  if (status === null) makeRequest();

  useEffect(() => {
    if (response) {
      setCategoryScheme(response.data.data);
    }
    axios.get('/categorySchemeV2').then((response) => {
      setCategory(response.data.data);
    });
  }, [response]);

  const handleMultiline = (event) => setValue(event.target.value);
  const handleName = (event) => setName(event.target.value);

  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = categoryScheme && categoryScheme.data.filter((category) => category.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchedCategoryScheme(newData);
  };

  const handleCreateCategoryScheme = () => {
    let body = { name: name, category: categoryname, description: value };
    axios
      .post(`/subcategoryschemev2`, body)
      .then(() => {
        makeRequest();
        setValue('');
        setName('');
      })
      .catch((err) => console.log(err, 'err'));
  };

  const handleDropdownChange = (event) => {
    setCategoryname(event.target.value);
  };

  // Update a subcategory

  const [on, setOn] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');

  const [updatedName, setUpdatedName] = useState(null);
  const [updatedCategory, setUpdatedCategory] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState(null);

  const handleClose = () => setOn(false);
  const handleOpen = (data) => {
    setSelectedID(data._id);
    setSelectedName(data.name);
    setSelectedCategory(data.category);
    setSelectedDescription(data.description);
    setOn(true);
  };

  const handleUpdateSubCategory = () => {
    let body = {
      name: updatedName && updatedName !== null ? updatedName : selectedName,
      category: updatedCategory && updatedCategory !== null ? updatedCategory : selectedCategory,
      description: updatedDescription && updatedDescription !== null ? updatedDescription : selectedDescription,
    };
    axios
      .put(`/subcategoryschemev2/${selectedID}`, body)
      .then(() => {
        makeRequest();
        setOn(false);
      })
      .catch((err) => console.log(err, 'err'));
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Subcategories Management</Typography>
        </Grid>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Subcategory name" />
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
        <Grid item xs={6} align="left">
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="standard-basic" label="Name" onChange={handleName} value={name} />
            <TextField id="standard-multiline-flexible" label="Description" multiline rowsMax={4} value={value} onChange={handleMultiline} />
          </form>
        </Grid>
        <Grid item xs={2} align="left">
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>

            <Select
              placeholder="select Category"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categoryname}
              onChange={handleDropdownChange}
            >
              {category &&
                category.data.map((data) => {
                  return <MenuItem value={data.name}>{data.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3} align="left">
          <Button variant="contained" color="primary" onClick={handleCreateCategoryScheme}>
            Add
          </Button>
        </Grid>
        <Grid item xs />
      </Grid>
      <div className={classes.root}>
        {searchValue && searchedCategoryScheme ? (
          searchedCategoryScheme.map((data, index) => {
            return (
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>Subcategory</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>Description</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.description}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>category</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.category.toUpperCase()}</Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <UpdateIcon onClick={() => handleOpen(data)} />
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
            );
          })
        ) : categoryScheme ? (
          categoryScheme.data.map((data, index) => {
            return (
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>Subcategory</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>Description</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.description}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.heading}>
                        <b>category</b>
                      </Typography>
                      <br />
                      <Typography className={classes.secondaryHeading}>{data.category.toUpperCase()}</Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <UpdateIcon onClick={() => handleOpen(data)} />
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
            );
          })
        ) : (
          <>
            {data &&
              data.map(() => {
                return <Skeleton animation="wave" height={50} />;
              })}
          </>
        )}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={on}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={on}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Update : {selectedName && selectedName}
            </Typography>
            <br />
            <form className={classes.formModal} noValidate>
              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid></Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={updatedName === null ? selectedName : updatedName}
                      onChange={(e) => setUpdatedName(e.target.value)}
                      placeholder="Name"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update name"
                      name="name"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '10%' }}>
                  <Grid></Grid>
                  <Grid direction="column">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                      <Select
                        placeholder="select Category"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedCategory === null ? selectedCategory : updatedCategory}
                        onChange={(e) => setUpdatedCategory(e.target.value)}
                      >
                        {category &&
                          category.data.map((data) => {
                            return <MenuItem value={data.name}>{data.name}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '10%' }}>
                  <Grid></Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={updatedDescription === null ? selectedDescription : updatedDescription}
                      onChange={(e) => setUpdatedDescription(e.target.value)}
                      placeholder="description"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Description"
                      name="description"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid container item xs={12} style={{ marginTop: '10%' }}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <CustomLink activeOnlyWhenExact={true} onClick={handleClose} label={<Typography variant="h5">CANCEL </Typography>} />
                  </Grid>
                  <Grid item xs={6}>
                    <PrimaryButton children="UPDATE" onClick={handleUpdateSubCategory} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default SubCategory;
