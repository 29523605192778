import { useAppApiRequest } from '../../../../hooks/useAppApiRequest';

const useAppProjectDetails = (projectId) => {
  const {
    data: mo,
    isError,
    isFetching,
  } = useAppApiRequest(`/projectv2/${projectId}/basics`, { method: 'GET' }, [projectId], projectId !== null && projectId !== undefined);
  const projectData = mo?.data?.data?.data;

  return {
    data: projectData,
    isError,
    isFetching,
  };
};
export default useAppProjectDetails;
