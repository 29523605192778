import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VendorsTab from './Tabs';
import axios from '../../../../config/Axios';
import Skeleton from '@material-ui/lab/Skeleton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '6%',
  },
  paper: {
    background: 'rgba(0, 0, 0, 0.05)',
    boxShadow: 'none',
    height: '35vh',
  },
  paper3: {
    background: '#FAFAFA',
    boxShadow: 'none',
    height: '35vh',
  },
  paper2: {
    background: '#FAFAFA',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '4%',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.83)',
    paddingBottom: '5%',
    paddingTop: '3%',
  },
}));
const WorkDetails = (props) => {
  const classes = useStyles();
  const vendor = props.vendor;
  let interventionv2 = vendor && vendor.interventionScheme.metadata;

  const [number, setNumber] = useState(0);
  const [infographics, setInfographics] = useState('');
  const [show, setShow] = useState(false);

  let str = vendor && interventionv2.infographics.staticassetids.length - 1;

  useEffect(() => {
    setShow(true);
    let id = interventionv2 && interventionv2.infographics.staticassetids[number];
    axios.get(`/sign-s3/${id}`).then((response) => {
      if (response && response.data.data) {
        setInfographics(response.data.data.data);
        setShow(false);
      }
    });
  }, [number, interventionv2]);
  const handleBefore = (e) => {
    e.preventDefault();
    if (number === 0 && str) {
      setNumber(str);
    } else if (number > 0) {
      setNumber(number - 1);
    }
  };
  const handleNext = () => {
    if (number >= 0 && number < str) {
      setNumber(number + 1);
    } else if (number === str) {
      setNumber(0);
    }
  };
  return (
    <>
      <Grid container direction="column" item xs={9} className={classes.spacing}>
        <Typography variant="body2">How does it work</Typography>
        <Typography className={classes.text}>{interventionv2.howitworks}</Typography>
        {infographics ? (
          <>
            <Grid container alignItems="center" item xs={12}>
              <Grid item xs={2} align="left">
                <NavigateBeforeIcon style={{ color: '#26E0A3', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleBefore} />
              </Grid>
              <Grid item xs={8} align="center">
                {show ? <Skeleton variant="rect" width="auto" height={300} /> : <img src={infographics.URL} alt="..." width="200" height="300" />}
              </Grid>
              <Grid item xs={2} align="right">
                <NavigateNextIcon style={{ color: '#26E0A3', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleNext} />
              </Grid>
            </Grid>
          </>
        ) : (
          <Paper className={classes.paper}></Paper>
        )}
      </Grid>
      <Grid container direction="column" item xs={9} className={classes.spacing}>
        <VendorsTab vendor={vendor} />
      </Grid>
    </>
  );
};
export default WorkDetails;
