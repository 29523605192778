import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useApiRequest from '../../../hooks/useApiRequest';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomLink from '../../../common/link/CustomLink';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import UsersTable from './components/Table';
import axios from '../../../../config/Axios';
import swal from 'sweetalert';
import SearchBar from '../../../common/Searchbar/Search';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    paddingBottom: '5%',
  },
}));

const Users = () => {
  const skele = [1, 2, 3];
  const classes = useStyles();
  const [users, setUsers] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState('');
  const [userModal, setUserModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest('/users', {
    verb: 'get',
  });
  const handleClose = () => setUserModal(false);
  const addUserModal = () => setUserModal(true);
  const handleCancelUpdate = () => setUserModal(false);
  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleRole = (e) => setRole(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setUsers(response.data.data);
    }
  }, [response]);
  const handleDelete = (id) => {
    axios
      .delete(`/users/${id}`)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully deleted User' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = users && users.data.filter((user) => user.role !== 'admin' && user.email.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchedData(newData);
  };

  const handleAddUser = () => {
    const body = {
      email: email,
      role: role,
      name: name,
      password: password,
      // organisation: '6013737c1b16190023b8ea7b',
    };
    axios
      .post(`/users`, body)
      .then(() => {
        setUserModal(false);
        swal({ icon: 'success', title: 'Successfully added User' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={9} align="left">
          <Typography variant="h3">Users Management</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <CustomLink
            onClick={addUserModal}
            label={
              <Typography variant="h5">
                New User
                <AddCircleRoundedIcon style={{ fontSize: '32px' }} />
              </Typography>
            }
          />
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="search by Email" style={{ width: '80%' }} />
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        {searchedData && searchedData ? (
          searchedData.map((user) => {
            return (
              <UsersTable
                name={user.name}
                email={user.email}
                role={user.role}
                organisation={user.organisation ? user.organisation : null}
                delete={() => handleDelete(user._id)}
                id={user._id}
              />
            );
          })
        ) : users && users.data ? (
          users.data.map((user) => {
            return user.role !== 'admin' ? (
              <UsersTable
                name={user.name}
                email={user.email}
                role={user.role}
                delete={() => handleDelete(user._id)}
                organisation={user.organisation ? user.organisation : null}
                id={user._id}
                handleUpdate={() => makeRequest()}
              />
            ) : null;
          })
        ) : (
          <>
            {skele &&
              skele.map((data) => {
                return <Skeleton animation="wave" height={50} width="100%" />;
              })}
          </>
        )}
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={userModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={userModal}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Add New User
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField id="standard-basic" label="name" value={name} onChange={handleName} className={classes.inputField} /> <br />
              <TextField id="standard-basic" label="email" value={email} onChange={handleEmail} className={classes.inputField} /> <br />
              <br />
              <Typography>Role</Typography>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleRole} className={classes.inputField} style={{ width: '100%' }}>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="super">Super</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>{' '}
              <br />
              <TextField id="standard-basic" label="password" type="password" value={password} onChange={handlePassword} className={classes.inputField} />
              <Grid item xs />
              <br />
            </form>
            <Link size="small" color="primary" onClick={handleAddUser}>
              Add
            </Link>
            <Link size="small" color="primary" onClick={handleCancelUpdate} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default Users;
