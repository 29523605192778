import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, Card, CardActionArea } from '@material-ui/core';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import useScreenType from '../../../../hooks/checkScreenType';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { getIcons } from '../../assets/CategoryIcons';
import { Default, NavigatorSource } from '../../utils/Defaults';
import { ProductsPageContext } from '../../../../../routing/IndexRouting';
import { usePrevious } from '../../hooks/useCategoryDetailsData';
import Links from '../Typography/Links';

export const cardDesktopStyle = {
  textAlign: 'center',
  boxSizing: 'border-box',
  borderRadius: '50%',
  width: '160px',
  height: '160px',
};

export const cardnonSelected = {
  background: MarketplaceColors.CommonWhite,
  border: `3px solid ${MarketplaceColors.Primary}`,
};
export const cardSelected = {
  background: `linear-gradient(0deg, ${MarketplaceColors.Secondary}, ${MarketplaceColors.Secondary})`,
  border: `3px solid ${MarketplaceColors.Secondary}`,
  color: MarketplaceColors.CommonWhite,
};
export const cardMobileStyle = {
  textAlign: 'center',
  boxSizing: 'border-box',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
};

const useStyles = makeStyles((theme) => ({
  card: cardDesktopStyle,
  cardMobile: cardMobileStyle,
  cardnonSelected: cardnonSelected,
  cardSelected: cardSelected,
  categorynametext: {
    color: MarketplaceColors.Primary,
    paddingTop: '15px',
  },
  cardActionArea: {
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    // pointerEvents: 'none',
  },
}));

const CategoryCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { appliedfilters, handleFilterData, handleAppliedFilters } = useContext(ProductsPageContext);
  const { category, name, selected, cardsource } = props;
  const { isMobile } = useScreenType();
  // const { desktopIcon, mobileIcon, selecteddesktop } = getIcons(name ? name : category?.name ? category?.name : 'Cement');

  const { search } = useLocation();
  const location = useLocation();
  const query = new URLSearchParams(search);
  const previousLocation = usePrevious(location);
  const [sourceurl, setSource] = useState(query.get('source'));
  const [categoryurl, setCategoryurl] = useState(query.get('category'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  const [page, setpage] = useState(query.get('page'));
  const [pagesize, setpagesize] = useState(query.get('pagesize'));
  const [isselected, setSelected] = useState(selected);
  const [sort, setSort] = useState(query.get('sort'));

  useEffect(() => {
    if (previousLocation !== location) {
      const source = query.get('source');
      setSource(source);
      setCategoryurl(query.get('category'));
      setSuperCategory(query.get('supercategory'));
      setpage(query.get('page'));
      setpagesize(query.get('pagesize'));
    }
  }, [previousLocation, location]);

  // console.log(name, category?.name, 'name');
  useEffect(() => {
    if (appliedfilters.length !== 0) {
      let get = appliedfilters.find((ele) => ele?.value === name)?.checked ? true : false;
      setSelected(get);
    }
  }, [appliedfilters, name]);

  const truncateString = (value) => {
    return value.split(' ').join('\n');
  };

  const handleClickforCategories = () => {
    if (sort === null) {
      history.push(
        `/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
          Default.pagesize
        }`
      );
    } else {
      history.push(
        `/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
          Default.pagesize
        }&sort=${sort}`
      );
    }
  };

  const handleClickforSuperCategories = (isselected, name, appliedfilters) => {
    setSelected(!isselected);
    handleAppliedFilters(NavigatorSource.categories, name, !isselected);
    handleFilterData(NavigatorSource.categories, name, !isselected);
    if (appliedfilters.length === 1) {
      if (sourceurl === NavigatorSource.categories)
        history.push(`/marketplace/categoryDetails?source=${sourceurl}&category=${categoryurl}&page=${page}&pagesize=${pagesize}`);
      else if (sourceurl === NavigatorSource.supercategories)
        history.push(`/marketplace/categoryDetails?source=${sourceurl}&supercategory=${supercategory}&page=${page}&pagesize=${pagesize}`);
    }
  };
  return (
    <>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center">
        <Card
          disableRipple
          className={
            !isMobile
              ? `${classes.card} ${!isselected ? classes.cardnonSelected : classes.cardSelected}`
              : `${classes.cardMobile} ${!isselected ? classes.cardnonSelected : classes.cardSelected}`
          }
        >
          <Links
            variant="string"
            href={
              cardsource !== 'categorydetails' &&
              `/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
                Default.pagesize
              }`
            }
            tag={
              <CardActionArea
                disableRipple
                className={classes.cardActionArea}
                onClick={() => cardsource === 'categorydetails' && handleClickforSuperCategories(isselected, name, appliedfilters)}
              >
                {getIcons(isMobile, isselected, name ? name : category?.name ? category?.name : 'Cement')}
              </CardActionArea>
            }
            isBold={false}
            className={classes.cardActionArea}
            style={{
              textAlign: 'center',
              maxWidth: 'auto',
              // overflowWrap: 'break-word',
              display: '-webkit-box',
              '-webkit-line-clamp': '3',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'center',
              alignItems: 'center',
              ...MarketplaceTypography.BodySamiBold,
            }}
          />
        </Card>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center">
          <Links
            variant="string"
            href={`/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
              Default.pagesize
            }`}
            tag={truncateString(name ? name : category?.name ? category?.name : 'Cement')}
            isBold={false}
            className={classes.categorynametext}
            style={{
              textAlign: 'center',
              maxWidth: '88px',
              overflowWrap: 'break-word',
              display: '-webkit-box',
              '-webkit-line-clamp': '3',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...MarketplaceTypography.BodySamiBold,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryCard;

// <a
//             href={`/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
//               Default.pagesize
//             }`}
//           >
//             <CardActionArea className={classes.cardActionArea}>
//               {!isMobile ? <img src={desktopIcon ? desktopIcon : cement} alt="imm" /> : <img src={mobileIcon ? mobileIcon : cementMobile} alt="imm" />}
//             </CardActionArea>
//           </a>
{
  /* <Links
variant="string"
href={`/marketplace/categorydetails?source=${NavigatorSource.categories}&category=${name ? name : category?.name}&page=${Default.page}&pagesize=${
  Default.pagesize
}`}
tag={name ? name : category?.name ? category?.name : 'Cement'}
isBold={false}
className={classes.categorynametext}
style={MarketplaceTypography.BodySamiBold}
/> */
}
