import axios from 'axios';
import cookie from 'react-cookies';

const useGraphqlRequest = async (endpoint) => {
    const token = cookie.load('SDPLUS_TOKEN');
    const data = await 
        axios
          .post(
            '/graphql',
            {
              query: endpoint,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    return data?.data;
};

export default useGraphqlRequest;
