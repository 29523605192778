import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VerticalLinearStepper from '../commmon/VerticalLinearStepper';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  textSD: {
    lineHeight: '40px',
    letterSpacing: '1px',
    paddingLeft: '12%',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  button: {
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '70%',
    height: '6vh',
    marginTop: '8%',
  },
  buttonText: {
    color: '#FFFFFF',
    WebkitTextFillColor: '#FFFFFF',
  },
  typo: {
    lineHeight: '20px',
    paddingLeft: '12%',
  },
  typo2: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
}));

function Home2(props) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <div data-aos="fade-right">
            <Typography variant="h3" className={classes.textSD}>
              SD+
            </Typography>
          </div>
          <div data-aos="fade-left">
            <Typography className={classes.typo} variant="body2">
              Sustainable Resource Planning Platform
              <br />
              <br />
              <br />
            </Typography>
          </div>
          <div data-aos="fade-right">
            <Typography className={classes.typo2} variant="h5">
              SD+ makes designing a sustainable <br />
              building a smooth experience by <br />
              presenting you with all the financial & <br />
              environmental data required to make an
              <br />
              informed decision.
              <br />
              <br /> The platform auto generates sustainable <br />
              solutions tailored to your project’s needs <br /> & seamlessly integrates them into the
              <br />
              building design.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <div data-aos="fade-left">
            <VerticalLinearStepper />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default Home2;
