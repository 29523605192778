import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const CustomInput = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderRadius: `20px`,
            },
        },
    },
})(TextField);

export default CustomInput



// <TextField
// id="standard-number"
// label="Number"
// type="number"
// InputLabelProps={{
//   shrink: true,
// }}
// />