import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { categoryDetailsQuery, productDetailsQuery } from '../graphql-queries/ProductDetails.query';

const useProductDetails = (productID, categoryID) => {
  const [categoryDetailsData, setCategoryDetailsData] = useState('');
  const [productDetailsData, setProductDetailsData] = useState('');

  const [categoryDetails, SendCategoryDetailsRequest] = useGraphqlRequest(categoryDetailsQuery(categoryID));
  const [productDetails, SendRroductDetailsRequest] = useGraphqlRequest(productDetailsQuery(productID));

  if (categoryDetails && categoryDetails?.response === null) SendCategoryDetailsRequest();
  if (productDetails && productDetails?.response === null) SendRroductDetailsRequest();

  // Function to fetch data with new parameters
  const fetchData = (newProductID, newCategoryID) => {
    SendCategoryDetailsRequest(newCategoryID);
    SendRroductDetailsRequest(newProductID);
  };

  useEffect(() => {
    if (categoryDetails && categoryDetails?.response !== null) setCategoryDetailsData(categoryDetails.response?.data?.data?.category?.data);
    if (productDetails && productDetails?.response !== null) setProductDetailsData(productDetails.response?.data?.data?.product?.data);
  }, [categoryDetails, productDetails]);

  return { categoryDetailsData, productDetailsData, fetchData };
};

export default useProductDetails;
