import React, { useEffect, useState } from 'react';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const VendorRequestView = () => {
  const data = [1, 2, 3, 4, 5, 6];
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [dropins, setDropins] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest('/joinus', {
    verb: 'get',
  });
  const [searchValue, setSearchValue] = useState('');
  const [searchedDropin, setSearchedDropin] = useState('');
  if (status === null) makeRequest();

  useEffect(() => {
    if (response) {
      setDropins(response.data.data.data);
    }
  }, [response]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = dropins && dropins.filter((project) => project.organisationname.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchedDropin(newData);
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Vendor Requests</Typography>
        </Grid>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by organisation name" />
        </Grid>
        <Grid item xs />
      </Grid>
      <div className={classes.root}>
        {/* {searchValue && searchedDropin ? (
          searchedDropin.map((data, index) => {
            return (
              <>
                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography className={classes.heading} variant="h6">
                      <b>{data.organisationname.toUpperCase()}</b>
                    </Typography>
                    <Typography className={classes.secondaryHeading}>{data.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Email</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.email}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Brochurelink</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.brochurelink ? data.brochurelink : 'none'}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Websitelink</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.websitelink ? data.websitelink : 'None'}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Location</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.location ? data.location : 'None'}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <br />
              </>
            );
          })
        ) : dropins ? (
          dropins.map((data, index) => {
            return (
              <>
                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography className={classes.heading} variant="h6">
                      <b>{data.organisationname.toUpperCase()}</b>
                    </Typography>
                    <Typography className={classes.secondaryHeading}>{data.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Email</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.email}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Brochurelink</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.brochurelink ? data.brochurelink : 'none'}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Websitelink</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.websitelink ? data.websitelink : 'None'}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>Location</Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>{data.location ? data.location : 'None'}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <br />
              </>
            );
          })
        ) : (
          <>
            {data &&
              data.map((data) => {
                return <Skeleton animation="wave" height={50} />;
              })}
          </>
        )} */}
      </div>
    </>
  );
};
export default VendorRequestView;
