import React from 'react';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import FaqData from './FaqData';
import MarketplaceFooter from '../../footer/MarketplaceFooter';

const Faq = () => {
  return (
    <>
      <MarketplaceNavbar />
      <FaqData />
      <MarketplaceFooter />
    </>
  );
};

export default Faq;
