import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MarketplaceColors } from '../../../config';
import { HoverText } from '../../../utils/HoverText';
import SuperCategoriesLogicalGroup from './SuperCategoriesLogicalGroup';
import Links from '../../../common/Typography/Links';
import { Default } from '../../../utils/Defaults';

const { Primary, TextColorTertiary } = MarketplaceColors;

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: '2%',
  },
  heading: {
    color: Primary,
    cursor: 'pointer',
  },
  subHeading: {
    marginTop: '2%',
    marginBottom: '3%',
  },
}));

const EcolabelsLogicalGroup = ({ data, source, superCategoriesData, supercategoriesSource }) => {
  const classes = useStyles();

  return (
    <Grid xs={3} item className={classes.main}>
      <Links
        variant="string"
        href={`/marketplace/categoryDetails?source=${source}&page=${Default.page}&pagesize=${Default.pagesize}`}
        tag={`Certifications`}
        isBold={true}
        className={classes.heading}
      />

      {data?.slice(0, 5)?.map(({ attributes }) => (
        <Grid>
          <Grid xs={12} style={{ marginBottom: '3%' }}>
            <Links
              variant="string"
              /* currently its disabled as certifiations all functions are not integrated in the backend*/
              href={`/marketplace/categoryDetails?source=${source}&filters=${attributes?.name}&page=${Default.page}&pagesize=${Default.pagesize}`}
              tag={HoverText(attributes?.name?.includes(':') ? attributes?.name?.split(':')[0] : attributes?.name, Primary, TextColorTertiary, false)}
              isBold={true}
              className={classes.subHeading}
            />
          </Grid>
        </Grid>
      ))}
      <SuperCategoriesLogicalGroup data={superCategoriesData && superCategoriesData} source={supercategoriesSource && supercategoriesSource} />
    </Grid>
  );
};

export default EcolabelsLogicalGroup;
