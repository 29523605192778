import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import EnergyRounded from '../../../../../assets/new-pdf/EnergyRounded.png';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const Page4 = (props) => {
  const data = props.data;
  let capex = data && data[0].quantities.finance.capex.value;
  let opexsaving = data && data[0].quantities.finance.opexSavings.value;

  let barvalue1 = capex;
  let barvalue2 = opexsaving;

  let calculatedBarvalue1 = (barvalue2 / barvalue1) * 90;

  let totalsaving = data && data[0].quantities.finance.opexSavings.value;
  let totalressaving = data && data[0].quantities.resource.category.value;
  let unit = data && data[0].quantities.resource.category.unit;
  let barvalue3 =
    props.category === 'Energy'
      ? data &&
        data[0].subcategories
          .find((ele) => ele.name === 'Renewable Sources')
          .interventions[0].quantities.details.find((para) => para && para.name === 'energy.annualbaselineenergyconsumption').value
      : props.category === 'Water'
      ? data &&
        data[0].subcategories
          .find((ele) => ele.name === 'Demand Optimization')
          .interventions[0].quantities.details.find((para) => para && para.name === 'water.annualbaselinewaterconsumption').value
      : props.category === 'Waste'
      ? data &&
        data[0].subcategories
          .find((ele) => ele.name === 'Management')
          .interventions[0].quantities.details.find((para) => para && para.name === 'waste.annualbaselinewastegenration').value
      : 0;
  let barvalue4 = props.category !== 'Materials' ? barvalue3 - totalressaving : 0;
  let calculatedBarvalue2 = props.category !== 'Materials' ? (barvalue4 / barvalue3) * 90 : 0;
  let percentage = props.percentage;

  return (
    <View>
      <View style={{ backgroundColor: '#15B7B9', height: '201px' }}>
        <View style={{ padding: '30px 20px 10px 20px' }}>
          <Header companyname={props.companyname} />
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', width: '50%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>{props.heading}</Text>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '30px', textTransform: 'uppercase' }}>Sustainability</Text>
          </View>
          <View style={{ width: '20%' }} />
          <View style={{ width: '20%' }}>
            <Image style={{ height: '100px', width: '100px' }} src={props.icon} />
          </View>
        </View>
      </View>
      <View style={styles.viewPage2}>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', textTransform: 'uppercase' }}>financial impact</Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '11px', textTransform: 'uppercase', color: '#666666', paddingTop: '15px' }}>Capex</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: '240px',
                backgroundColor: '#F78D8D',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', color: '#F78D8D' }}>{rsToCr('Rs', barvalue1)} Cr</Text>
          </View>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '11px', textTransform: 'uppercase', color: '#666666', paddingTop: '15px' }}>
            Annual OPEX Savings
          </Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: `${2.4 * (isNaN(calculatedBarvalue1) ? 0 : calculatedBarvalue1)}px`,
                backgroundColor: '#40E4CE',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', color: '#40E4CE' }}>{rsToCr('Rs', barvalue2)} Cr</Text>
          </View>
        </View>
        {/* new set */}
        <View style={{ paddingTop: '40px' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', textTransform: 'uppercase' }}>Resource Impact</Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '11px', textTransform: 'uppercase', color: '#666666', paddingTop: '15px' }}>{props.title1}</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View style={{ height: '24px', width: '240px', backgroundColor: '#F78D8D', borderTopRightRadius: '25px', borderBottomRightRadius: '25px' }}></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', color: '#F78D8D' }}>{`${numberWithCommas(
              barvalue3
            )} ${unit}`}</Text>
          </View>
        </View>
        <View>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '11px', textTransform: 'uppercase', color: '#666666', paddingTop: '15px' }}>{props.title2}</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: `${2.4 * (isNaN(calculatedBarvalue2) ? 0 : calculatedBarvalue2)}px`,
                backgroundColor: '#40E4CE',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '14px', color: '#40E4CE' }}>{`${numberWithCommas(
              barvalue4
            )} ${unit}`}</Text>
          </View>
        </View>
        {/* new set */}
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '45px' }}>
          <View style={{ width: '60%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '24px' }}>{`${rsToCr('Rs', totalsaving)} Cr`}</Text>
          </View>
          <View>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '24px' }}>{`${numberWithCommas(totalressaving)} ${unit}`}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '35px' }}>
          <View style={{ width: '50%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '11px', color: 'grey' }}>
              {props.category === 'Energy'
                ? 'is the annual savings on electricity bills for the project'
                : props.category === 'Water'
                ? `Is the annual savings that would otherwise be spent to procure water for the project from external sources like water tankers`
                : props.category === 'Waste'
                ? 'Is the annual savings for the project, with reduced waste sent to landfills'
                : props.category === 'Materials' 
                ? 'Is the annual savings for the project, with reduced carbon dioxide emissions' 
                : '' }
            </Text>
          </View>
          <View style={{ width: '10%' }} />
          <View style={{ width: '40%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '11px', color: 'grey' }}>
              {props.category === 'Energy'
                ? `with ${numberWithCommas(totalressaving)} ${unit} of energy saved by deploying Renewable sources of energy, the project saves ${
                    percentage && percentage.value
                  }% energy`
                : props.category === 'Water'
                ? `with ${numberWithCommas(totalressaving)} ${unit} of water being saved, the project has ${
                    percentage && percentage.value
                  }% less water needed from external sources`
                : props.category === 'Waste'
                ? `with ${numberWithCommas(totalressaving)} ${unit} of waste avoided from being sent to the landfill, the project has ${
                    percentage && percentage.value
                  }% less waste polluting the environment`
                : props.category === 'Materials' ? `with ${numberWithCommas(totalressaving)} ${unit} of carbon dioxide avoided, the project has ${
                  percentage && percentage.value
                }% less air polluting the environment` : ''}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footerMargin}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default Page4;
