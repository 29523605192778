export const interventionDetailsQuery = (identifiers) =>
  `{
  getProducts(identifiers: "${identifiers}") {
    totalproducts
    products {
      id
      sku_name
      short_description
      catalog_sku_id
      image
      brand {
        id
        name
      }
      category{
        id
        name
      }
      subcategory{
        id
        name
      }
      ecolabels
      quality_assurance_and_reliability
      environmental_impact_ecosystem_health
      environmental_impact_human_health
      environmental_impact_circularity
      sdg
      resource_savings
      environmental_impact_climate_health
      warranty_and_after_sales
      references
      default_product
      sdplus_interventions
      price
      properties
      filters
    }
  }
}
`;
