import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const CustomIconButton = withStyles({
    root: {
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

export default CustomIconButton
