import { Grid, makeStyles, Typography, withWidth } from '@material-ui/core';
import React, { useContext } from 'react';
import useScreenType from '../../../hooks/checkScreenType';
// import logo from '../../../../assets/Marketplace/logo.svg';
import logo from '../../../../assets/Marketplace/sdplusmarketplacelogo.svg';

import Links from '../common/Typography/Links';
import { HoverText } from '../utils/HoverText';
import { MarketplaceColors } from '../config';
import { ProductsPageContext } from '../../../../routing/IndexRouting';
import { useLocation, useHistory } from 'react-router-dom';

const TextStyle = (showCursor) => {
  return {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.71px',
    padding: '10px',
    cursor: showCursor && 'pointer',
    transition: 'color 0.3s ease',
  };
};

const Footer = (props) => {
  const { width } = props;
  const history = useHistory();
  const { isMobile } = useScreenType(width);
  const { handleShowContactUs } = useContext(ProductsPageContext);
  const { Primary, TextColorTertiary } = MarketplaceColors;

  const useStyles = makeStyles((theme) => ({
    main: {
      backgroundColor: '#EBF7FF',
      height: width === 'xs' || width === 'sm' ? '320px' : '218px',
    },
    text: TextStyle(false),
    padding: {
      paddingLeft: '5%',
    },
  }));
  const classes = useStyles();

  const FooterData = [
    {
      id: 1,
      data: [
        { name: 'Support', link: null },
        { name: 'FAQ', link: `/marketplace/faq` },
        { name: 'Contact Us', link: null },
      ],
    },
    {
      id: 2,
      data: [
        { name: 'Policy', link: null },
        { name: 'Terms Of Use', link: '/marketplace/terms-and-conditions' },
        { name: 'Privacy Policy', link: `/marketplace/privacy-policy` },
      ],
    },
    {
      id: 3,
      data: [
        { name: 'Socials', link: null },
        { name: 'Twitter', link: 'https://twitter.com/smarterdharma' },
        { name: 'Linkedin', link: 'https://in.linkedin.com/company/smarter-dharma' },
      ],
    },
  ];

  const nameBasedRouting = (event, name, link) => {
    event.preventDefault();
    if (name === 'Contact Us') {
      handleShowContactUs(true);
    } else if (name === 'Twitter' || name === 'Linkedin') {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  };

  const FooterLinks = (data) => {
    return data.map((ele, index) => {
      return index === 0 ? (
        <Typography style={TextStyle(false)}>{ele?.name.toUpperCase()}</Typography>
      ) : (
        <Links
          variant="string"
          href={ele?.link}
          tag={HoverText(ele?.name.toUpperCase(), Primary, TextColorTertiary, false, TextStyle(true))}
          isBold={false}
          onClick={(event) => nameBasedRouting(event, ele.name, ele?.link)}
          target="_blank"
        />
      );
    });
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" className={classes.main}>
        {FooterData.map(({ data }) => (
          <Grid item xs={width === 'xs' || width === 'sm' ? 4 : 2} className={classes.padding}>
            {FooterLinks(data)}
          </Grid>
        ))}
        <Grid
          xs={width === 'xs' || width === 'sm' ? 12 : 6}
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ borderLeft: !isMobile && 'solid 1px #808080', height: '130px', paddingLeft: isMobile ? '5%' : '3%', paddingRight: '3%' }}
        >
          <a href="/marketplace" onclick="return false;">
            <img src={logo} alt="sdpluslogo" />
          </a>

          <Typography className={classes.text} style={{ color: '#666666' }}>
            INDIA'S 1ST SUSTAINABLE MATERIAL AND SOLUTIONS MARKETPLACE, WHERE YOU CAN DISCOVER, RESEARCH AND CHOOSE THE BEST SUSTAINABLE PRODUCTS THAT MEET YOUR
            PROJECT GOALS.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(Footer);
