import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import earth from '../../../../assets/VERSION-TWO/landingPage/new earth small 1.png';

const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '10%',
  },
  typo: {
    lineHeight: '20px',
  },
  typo3: {
    lineHeight: '30px',
    paddingLeft: '12%',
  },
  card: {
    maxWidth: 650,
  },
  media: {
    height: 240,
  },
  newRoot: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  cardwrapper: { marginTop: '30px', marginLeft: '5%' },
  root: {
    '& > *': {
      margin: theme.spacing(3, 0, 0, 0),
      width: '25ch',
    },
  },
  text1: {
    marginLeft: '12%',
  },
  button: {
    color: 'white',
    background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
    width: '50%',
    height: '6vh',
    marginTop: '3%',
    [theme.breakpoints.down('md')]: {
      width: '70%',
      height: '6vh',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: '6vh',
    },
  },
}));

function ResponsiveHome6({ onModalOpen }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        alignContent="center"
        style={{ background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)' }}
      >
        <div data-aos="fade-left" style={{ textAlign: 'center' }}>
          <Typography
            style={{
              lineHeight: '40px',
              letterSpacing: '1px',
              color: 'rgba(0, 0, 0, 0.82)',
            }}
            variant="h4"
          >
            There is always more you can do for our planet
            <br />
          </Typography>
        </div>
        <div data-aos="fade-right" style={{ textAlign: 'center' }}>
          <Button variant="contained" className={classes.button} onClick={onModalOpen}>
            <Typography className={classes.buttonText}>request demo</Typography>
          </Button>
        </div>
        <div data-aos="fade-left" style={{ textAlign: 'center' }}>
          <img src={earth} style={{ width: '95%', paddingTop: '20px', paddingBottom: '20px' }} alt="Earth" />
        </div>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" style={{ background: 'white' }}>
        <Typography
          variant="h6"
          style={{
            paddingLeft: '6%',
            paddingTop: '3%',
            background: 'black',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          A platform for a sustainable future.
        </Typography>
        <Grid item xs={12} style={{ marginBottom: '2%' }}></Grid>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" style={{ background: 'white' }}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography className={classes.text1} variant="h6">
            get in touch
            <br />
            <br />
          </Typography>

          <Typography className={classes.text1} variant="h6" color="textSecondary">
            connect@sdplus.io
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} direction="column">
          <Grid item style={{ marginTop: '13%' }}></Grid>
          <Grid item>
            <Typography style={{ fontSize: '8px' }} variant="h6" color="textSecondary">
              Copyright © 2020 SD+. All Rights Reserved
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '2%' }}></Grid>
      </Grid>
    </>
  );
}
export default ResponsiveHome6;
