import { Grid, Modal, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { MarketplaceTypography } from '../../../config';
import useScreenType from '../../../../../hooks/checkScreenType';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import ContactUs from '../contact-us/ContactUs';
import BecomeVendor from '../become-vendor/BecomeVendor';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuItems: {
    cursor: 'pointer',
    fontWeight: 'Semi Bold',
  },
  gridPadding: {
    paddingLeft: '5%',
  },
}));

const MarketplaceMenuItems = (props) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [joinus, setJoinus] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const { isMobile } = useScreenType(props.width);
  const { contactUs, handleShowContactUs } = useContext(ProductsPageContext);
  const { AllCapsSmall } = MarketplaceTypography;
  const classes = useStyles();

  return (
    <>
      <Grid xs={4}>
        <Typography onClick={handleOpen} style={AllCapsSmall} className={classes.menuItems}>
          BECOME A VENDOR
        </Typography>
      </Grid>
      <Grid xs={3} className={classes.gridPadding} onClick={() => history.push('/marketplace/resources')}>
        <Typography className={classes.menuItems} style={AllCapsSmall}>
          RESOURCES
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Typography className={classes.menuItems} style={AllCapsSmall} onClick={() => handleShowContactUs(true)}>
          CONTACT US
        </Typography>
      </Grid>
      {/* become a vendor */}
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={openModal} onClose={handleClose}>
        <BecomeVendor handleClose={handleClose} joinus={joinus} setJoinus={setJoinus} isMobile={isMobile} />
      </Modal>
      {/* contact us popup */}
      <Modal
        open={contactUs}
        onClose={() => handleShowContactUs(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <ContactUs closeModal={() => handleShowContactUs(false)} />
      </Modal>
    </>
  );
};

export default MarketplaceMenuItems;
