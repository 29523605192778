import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../../config/Axios';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '3%',
  },
  paper: {
    background: '#FAFAFA',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '4%',
    paddingBottom: '3%',
    height: 'auto',
    width: '100%',
  },
  paper2: {
    background: '#C4C4C4',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '4%',
    paddingBottom: '3%',
    height: '45vh',
  },
  image: {
    height: '40vh',
    paddingLeft: '30%',
  },
  indicator: {
    border: '3px solid rgba(0, 0, 0, 0.83)',
  },
}));

const Carousel = (props) => {
  const classes = useStyles();
  const vendor = props.vendor;
  console.log(vendor, 'vendor');

  let str = vendor && vendor.productImages.length - 1;
  const [product, setProduct] = useState('');
  const [number, setNumber] = useState(0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
    let id = vendor && vendor.productImages[number];
    axios.get(`/sign-s3/${id}`).then((response) => {
      if (response && response.data.data) {
        setProduct(response.data.data.data);
        setShow(false);
      }
    });
  }, [number, vendor]);
  const handleBefore = (e) => {
    e.preventDefault();
    if (number === 0 && str) {
      setNumber(str);
    } else if (number > 0) {
      setNumber(number - 1);
    }
  };
  const handleNext = () => {
    if (number >= 0 && number < str) {
      setNumber(number + 1);
    } else if (number === str) {
      setNumber(0);
    }
  };
  return (
    <>
      {product ? (
        <Paper className={classes.paper}>
          <>
            <Grid container alignItems="center" item xs={12}>
              <Grid item xs={2} align="left">
                <NavigateBeforeIcon style={{ color: '#26E0A3', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleBefore} />
              </Grid>
              <Grid item xs={8} align="center">
                {show ? <Skeleton variant="rect" width="auto" height={300} /> : <img src={product.URL} alt="..." width="auto" height="300" />}
              </Grid>
              <Grid item xs={2} align="right">
                <NavigateNextIcon style={{ color: '#26E0A3', cursor: 'pointer', height: '40px', width: '40px' }} onClick={handleNext} />
              </Grid>
            </Grid>
          </>
        </Paper>
      ) : (
        <Paper className={classes.paper2}></Paper>
      )}
    </>
  );
};
export default Carousel;
