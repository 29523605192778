import React, { useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import ProductDetailsTabs from './ProductDetailsTabs';
import { useState } from 'react';
import ProductDetailsTableData from './ProductDetailsTableData';

// icon to be changed for all currently its common
import icon1 from '../../../../../../assets/Marketplace/icon1.svg';
import { formatString } from '../../../../interventions/intervention/utils';

export const Tabs = [
  { id: 0, name: 'Environmental Impact', icon: icon1 },
  { id: 1, name: 'Certifications and Standards', icon: icon1 },
  { id: 2, name: 'Properties', icon: icon1 },
  { id: 3, name: 'General', icon: icon1 },
];

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: '4%',
    marginBottom: '5%',
  },
  tableTitle: {
    marginTop: '1%',
  },
  tableGrid: {
    marginTop: '1%',
    marginBottom: '5%',
  },
}));

const ProductDetailsTable = (props) => {
  const classes = useStyles();
  const { productAttributes } = props;
  const [tabvalue, setTabvalue] = useState(0);
  // const [environempact, setEnvironmentalImpact] = useState('');
  const [properties, setProperties] = useState('');
  // const [general, setGeneral] = useState('');
  const [certifications_and_standards, setCertification_and_standards] = useState('');
  const [circularity, setCircularity] = useState('');
  const [climateHealth, setClimateHealth] = useState('');
  const [ecoSystemHealth, setEcoSystemHealth] = useState('');
  const [humanHealth, setHumanHealth] = useState('');
  const [referance, setReferance] = useState('');
  const [sdgs, setSDGS] = useState('');
  const handleChange = (event, newValue) => setTabvalue(newValue);

  useEffect(() => {
    if (productAttributes) {
      setCircularity(productAttributes?.environmental_impact_circularity);
      setClimateHealth(productAttributes?.environmental_impact_climate_health);
      setEcoSystemHealth(productAttributes?.environmental_impact_ecosystem_health);
      setHumanHealth(productAttributes?.environmental_impact_human_health);
      setReferance(productAttributes?.references);
      setSDGS(productAttributes?.sdg);
      // setEnvironmentalImpact(productAttributes?.environmental_impacts);
      setCertification_and_standards(productAttributes?.certifications_and_standards || '');
      setProperties(productAttributes?.properties);
      // setGeneral(productAttributes?.general);
    }
  }, [productAttributes]);

  const sourcingRegex = /Sourcing,(.*?),End-of-Life/;
  const sourcingMatch = '';
  const sourcing = '';
  const endoflifeRegex = /End-of-Life,(.*?),Waste/;
  const endoflifeMatch = '';
  const endoflife = '';
  const waste = '';
  const staticData = [
    {
      name: 'Environmental Impcat',
      data: [
        {
          header: 'Circularity',
          table: [
            {
              title: '',
              type: productAttributes?.metadata?.environmental_impact_circularity_is_nested ? 'object' : 'string',
              data: productAttributes?.metadata?.environmental_impact_circularity_is_nested ? circularity : formatString(circularity),
            },
          ],
        },
        {
          header: 'Climate Health',
          table: [
            {
              title: '',
              type: 'string',
              data: formatString(climateHealth),
            },
          ],
        },
        {
          header: 'Ecosystem Health',
          table: [
            {
              title: '',
              type: 'string',
              data: formatString(ecoSystemHealth),
            },
          ],
        },
        {
          header: 'Human Health',
          table: [
            {
              title: '',
              type: 'string',
              data: formatString(humanHealth),
            },
          ],
        },
      ],
    },
    {
      name: 'General',
      data: [
        {
          header: '',
          table: [
            {
              title: 'Procurement Information',
              type: 'table',
              data: [
                { name: 'Product Code', value: productAttributes?.catalog_sku_id },
                { name: 'Manufacturing Locations', value: productAttributes?.brand?.data?.attributes?.manufacturing_locations },
                { name: 'Region of Operation', value: productAttributes?.brand?.data?.attributes?.region_of_operation },
                { name: 'Quality Assurance and Reliability (QAR)', value: productAttributes?.quality_assurance_and_reliability },
                { name: 'Warranty and After Sales', value: productAttributes?.warranty_and_after_sales },
              ],
            },
          ],
        },
        {
          header: '',
          table: [
            {
              title: 'References',
              type: 'string',
              subtype: 'url',
              data: referance,
            },
          ],
        },
        {
          header: '',
          table: [
            {
              title: 'Sustainable Development Goals',
              type: 'string',
              subtype: 'nodash',
              data: sdgs,
            },
          ],
        },
        {
          header: 'About Us',
          data: productAttributes?.description,
        },
      ],
    },
    {
      name: 'Properties',
      data: [
        {
          header: '',
          table: [
            {
              title: '',
              type: 'string',
              data: formatString(properties),
            },
          ],
        },
      ],
    },
  ];
  return (
    <>
      <Grid container xs={12} alignItems="center" justifyContent="center" className={classes.tabs}>
        <ProductDetailsTabs tabvalue={tabvalue} handleChange={handleChange} />
      </Grid>
      {/* <Grid container alignItems="center" justifyContent="flex-start" className={classes.tableTitle}>
        <Grid xs={0}>
          <img src={Tabs[tabvalue]?.icon} alt="ggg" />
        </Grid>
        <Grid xs={10} style={{ paddingLeft: '2%' }}>
          <Typography variant="h5">{Tabs[tabvalue]?.name}</Typography>
        </Grid>
      </Grid> */}

      <Grid container xs={12} alignItems="center" justifyContent="center" className={classes.tableGrid}>
        {tabvalue === 0 && (
          <ProductDetailsTableData
            newValue={staticData[0]}
            implement="new"
            // tableData={environempact && environempact}
            // tab={Tabs[tabvalue]?.name}
          />
        )}
        {tabvalue === 1 && <ProductDetailsTableData tableData={certifications_and_standards && certifications_and_standards} tab={Tabs[tabvalue]?.name} />}
        {tabvalue === 2 && <ProductDetailsTableData implement="new" newValue={staticData[2]} tab={Tabs[tabvalue]?.name} />}
        {tabvalue === 3 && (
          <ProductDetailsTableData
            newValue={staticData[1]}
            implement="new"
            // tableData={general && general}
            // tab={Tabs[tabvalue]?.name}
          />
        )}
      </Grid>
    </>
  );
};

export default ProductDetailsTable;
