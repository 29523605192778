export const extractData = (data) => {
  if (data === '-') {
    return '-';
  } else if (data === 'NA') {
    return '';
  } else if (data === '') {
    return '-';
  } else if (data === undefined) {
    return '-';
  } else if (data === 0) {
    return 0;
  } else if (!data) {
    return '-';
  } else {
    return data;
  }
};
