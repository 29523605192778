import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WhitepaperCard from '../normal/WhitePaperCard';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
const useStyles = makeStyles((theme) => ({
  navbar2: {
    marginBottom: '6%',
  },
  title: {
    lineHeight: '40px',
    letterSpacing: '1px',
    background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
    paddingBottom: '3%',
  },
  typo3: {
    lineHeight: '30px',
    paddingBottom: '5%',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));

function ResponsiveHome7(props) {
  const classes = useStyles();

  const { Whitepaper1, Whitepaper2, Whitepaper3, Whitepaper4, Whitepaper5, Whitepaper6 } = props;
  const { pdf1, pdf2, pdf3, pdf4, pdf5, pdf6 } = props;
  let arr = [
    { cover: Whitepaper1, pdf: pdf1 },
    { cover: Whitepaper2, pdf: pdf2 },
    { cover: Whitepaper3, pdf: pdf3 },
    { cover: Whitepaper4, pdf: pdf4 },
    { cover: Whitepaper5, pdf: pdf5 },
    { cover: Whitepaper6, pdf: pdf6 },
  ];
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '2%' }}>
          <div data-aos="fade-right">
            <Typography variant="h3" className={classes.title}>
              White papers
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
          <div data-aos="fade-left">
            <Typography variant="h5" className={classes.typo3}>
              Climate change is a business risk but is also an opportunity for the building sector to innovate and adopt environmentally responsible practices.
              Some of the industry’s pertinent environmental issues, their social and financial consequences and meaningful ways to tackle them optimally are
              examined through a series of white papers.
            </Typography>
          </div>
        </Grid>
        <Grid item container xs={12}>
          <GridList className={classes.gridList} cols={2.0}>
            {arr &&
              arr.map((tile, index) => (
                <GridListTile key={index} cols={1.3} rows={2.2}>
                  <React.Fragment>
                    <WhitepaperCard coverpic={tile && tile.cover} onModalOpen={props.onModalOpen} download={() => window.open(tile.pdf)} />
                  </React.Fragment>
                </GridListTile>
              ))}
          </GridList>
        </Grid>
      </Grid>
    </>
  );
}
export default ResponsiveHome7;
