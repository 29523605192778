import { TextField, Typography, withStyles } from '@material-ui/core';
import { MarketplaceColors } from '../config';

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '18px 30px',
      background: '#ffff',
      borderRadius: '5px',
      fontWeight: '500',
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #79747E',
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        borderColor: '#00000061',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #6750A4',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ED5E68',
      },
    },
  },
})(TextField);

export const MarketPlaceJoinUsInput = ({ label, currentvalue, formValidation, stylename, ...props }) => {
  return (
    <StyledTextField
      label={label}
      {...props}
      error={currentvalue === '' && formValidation}
      helperText={
        currentvalue === '' && formValidation ? (
          <Typography className={stylename} style={{ color: MarketplaceColors.error, paddingTop: '5px' }}>
            Oops! It seems like you forgot to fill in the field.
          </Typography>
        ) : (
          ''
        )
      }
    />
  );
};
