import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesDualFlushProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesDualFlush_oneBHK,
    efficientFixturesDualFlush_twoBHK,
    efficientFixturesDualFlush_threeBHK,
    efficientFixturesDualFlush_fourBHK,
    efficientFixturesDualFlush_studio,
    efficientFixturesDualFlush_pentHouseOneBhk,
    efficientFixturesDualFlush_pentHouseTwoBhk,
    efficientFixturesDualFlush_pentHouseThreeBhk,
    efficientFixturesDualFlush_pentHouseFourBhk,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesDualFlush_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesDualFlush_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesDualFlush_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesDualFlush_fourBHK);
  const studio = getQuantityValue(efficientFixturesDualFlush_studio);

  const penthouse_onebhk = getQuantityValue(efficientFixturesDualFlush_pentHouseOneBhk);
  const penthouse_twobhk = getQuantityValue(efficientFixturesDualFlush_pentHouseTwoBhk);
  const penthouse_threebhk = getQuantityValue(efficientFixturesDualFlush_pentHouseThreeBhk);
  const penthouse_fourbhk = getQuantityValue(efficientFixturesDualFlush_pentHouseFourBhk);

  const onebhk_label = getQuantityLabel(efficientFixturesDualFlush_oneBHK);
  const twobhk_label = getQuantityLabel(efficientFixturesDualFlush_twoBHK);
  const threebhk_label = getQuantityLabel(efficientFixturesDualFlush_threeBHK);
  const fourbhk_label = getQuantityLabel(efficientFixturesDualFlush_fourBHK);
  const studio_label = getQuantityLabel(efficientFixturesDualFlush_studio);

  const onebhk_unit = getQuantityUnit(efficientFixturesDualFlush_oneBHK);
  const twobhk_unit = getQuantityUnit(efficientFixturesDualFlush_twoBHK);
  const threebhk_unit = getQuantityUnit(efficientFixturesDualFlush_threeBHK);
  const fourbhk_unit = getQuantityUnit(efficientFixturesDualFlush_fourBHK);
  const studio_unit = getQuantityUnit(efficientFixturesDualFlush_studio);

  const penthouse_onebhk_label = getQuantityLabel(efficientFixturesDualFlush_pentHouseOneBhk);
  const penthouse_twobhk_label = getQuantityLabel(efficientFixturesDualFlush_pentHouseTwoBhk);
  const penthouse_threebhk_label = getQuantityLabel(efficientFixturesDualFlush_pentHouseThreeBhk);
  const penthouse_fourbhk_label = getQuantityLabel(efficientFixturesDualFlush_pentHouseFourBhk);

  const penthouse_onebhk_unit = getQuantityUnit(efficientFixturesDualFlush_pentHouseOneBhk);
  const penthouse_twobhk_unit = getQuantityUnit(efficientFixturesDualFlush_pentHouseTwoBhk);
  const penthouse_threebhk_unit = getQuantityUnit(efficientFixturesDualFlush_pentHouseThreeBhk);
  const penthouse_fourbhk_unit = getQuantityUnit(efficientFixturesDualFlush_pentHouseFourBhk);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{studio_label && studio_label}</Typography>
          <Typography className={classes.textHeader3}>
            {studio} {studio_unit && studio_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{onebhk_label && onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {onebhk} {onebhk_unit && onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{twobhk_label && twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {twobhk} {twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {threebhk} {threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {fourbhk} {fourbhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_onebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_onebhk} {penthouse_onebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_twobhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_twobhk} {penthouse_twobhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_threebhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_threebhk} {penthouse_threebhk_unit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{penthouse_fourbhk_label}</Typography>
          <Typography className={classes.textHeader3}>
            {penthouse_fourbhk} {penthouse_fourbhk_unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesDualFlushProjectInputs;
