import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Heading from '../common/Heading';
import CustomizedBar from '../common/ProgressBarWithTitle';
import DocumentTable from './DocumentTable';
import TabButtonGrp from '../common/ButtonGroup';
import CustomBreadCrumbs from '../../../common/breadcrumbs/CustomBreadCrumbs';
import axios from 'axios';
import UploadIGBCDocument from './common/AddFile';
import { useHistory, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { PreviousPageContext } from '../../../../routing/IndexRouting';
import useApiRequest from '../../../hooks/useApiRequest';

function DocumentDashboard({ creditDetailsForaSingleCredit, igbcCategoryContext, documentUploadPercentage, creditname, source }) {
  const history = useHistory();
  console.log(igbcCategoryContext && igbcCategoryContext, 'igbcCategoryContext');

  const useStyles = makeStyles((theme) => ({
    gridWithPadding: {
      padding: theme.spacing(3),
    },
    main: {
      marginTop: history && history.location.pathname.includes('/certification/upload') ? '3%' : '',
    },
    root: {
      paddingTop: history && history.location.pathname.includes('/certification/upload') ? '3%' : '',
    },
    heading: {
      marginTop: '2%',
      width: '100%',
    },
    spacing: {
      padding: theme.spacing(0, 0, 0, 3),
    },
  }));

  const classes = useStyles();
  const { setPreviousPage } = useContext(PreviousPageContext);
  const [activeStep, setActiveStep] = useState('Sustainable Design');
  const [activeCategoryid, setActiveCategoryid] = useState('');
  const [creditdata, setCreditdata] = useState('');
  const [section, setSection] = useState('');
  const [phase, setPhase] = useState('');
  const [creditassets, setCreditAssets] = useState([]);
  const [open, setOpen] = useState(false);

  const params = useParams();
  const projectId = params && params.id;

  const [showTabs, setShowTabs] = useState(false);
  const [igbcCertificationData, setigbcCertificationData] = useState('');
  const [activecategorydata, setActiveCategorydata] = useState('');
  const [CreditId, setCreditId] = useState('');
  const [re, sendRequest] = useApiRequest(`/projectv2/${projectId}/igbc-data`, {
    verb: 'get',
  });

  if (re.status === null && projectId) {
    sendRequest();
  }

  useEffect(() => {
    if (re.response && re.response.data && re.response.data.data && re.response.data.data.data) {
      const IGBCdata = re.response.data.data.data.certifications.find((certification) => certification.name === 'IGBC');
      const activecategorydata = IGBCdata.categories.find((category) => category.name === activeStep);
      setigbcCertificationData(IGBCdata && IGBCdata);
      setActiveCategorydata(activecategorydata);
    }
  }, [activeStep, re.response]);

  //for percentage
  const [categoryPercentage, setCategoryPercentage] = useState(0);
  const [creditPercentage, setCreditPercentage] = useState(0);
  useEffect(() => {
    if (creditDetailsForaSingleCredit && creditDetailsForaSingleCredit._id) {
      axios.get(`/igbc-credit-asset/${projectId}/percentage?credit=${creditDetailsForaSingleCredit._id}`).then((response) => {
        if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.percentage) {
          setCreditPercentage(response.data.data.data.percentage);
        } else {
          setCreditPercentage(0);
        }
      });
    }
  }, [creditDetailsForaSingleCredit, projectId]);

  useEffect(() => {
    if (activeCategoryid) {
      axios.get(`/igbc-credit?id=${activeCategoryid}`).then((resp) => {
        if (resp.data && resp.data.data && resp.data.data.data) {
          setCreditdata(resp.data.data.data);
        }
        axios.get(`/igbc-credit-asset/${projectId}?category=${activeCategoryid}`).then((response) => {
          if (response && response.data && response.data.data && response.data.data.data) {
            setCreditAssets(response.data.data.data);
          }
        });
        axios.get(`/igbc-credit-asset/${projectId}/percentage?category=${activeCategoryid}`).then((response) => {
          if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.percentage) {
            setCategoryPercentage(response.data.data.data.percentage);
          } else {
            setCategoryPercentage(0);
          }
        });
      });
    } else {
      axios.get(`/igbc-category?name=${igbcCategoryContext}`).then((response) => {
        if (response && response.data && response.data.data && response.data.data.data) {
          console.log(response.data.data.data, 'igbcCategoryContext');
          setActiveCategoryid(response.data.data._id);
          axios.get(`/igbc-credit?id=${response.data.data.data._id}`).then((resp) => {
            if (resp.data && resp.data.data && resp.data.data.data) setCreditdata(resp.data.data.data);
            axios.get(`/igbc-credit-asset/${projectId}?category=${response.data.data.data._id}`).then((response) => {
              if (response && response.data && response.data.data && response.data.data.data) setCreditAssets(response.data.data.data);
            });
            axios.get(`/igbc-credit-asset/${projectId}/percentage?category=${response.data.data.data._id}`).then((response) => {
              if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.percentage) {
                setCategoryPercentage(response.data.data.data.percentage);
              } else {
                setCategoryPercentage(0);
              }
            });
          });
        }
      });
    }
  }, [activeCategoryid, igbcCategoryContext, projectId]);

  useEffect(() => {
    if (history && history.location && history.location.pathname) {
      if (history.location.pathname.includes('/certification/upload')) setShowTabs(true);
      else setShowTabs(false);
    }
  }, [history]);

  function GetNumber(creditassets, creditdata, creditname) {
    if (creditassets && creditdata && creditname) {
      const getCredit = creditdata.find((credit) => credit.creditName === creditname);
      let totalSections = 0;
      let totalAssetsforCredit = 0;
      getCredit?.CreditMedadata?.documentationPhases?.map((section) => {
        totalSections = totalSections + section.sections.length;
        section.sections.map((creditsection) => {
          creditassets.map((asset) => {
            if (asset.section === creditsection.name) {
              totalAssetsforCredit = totalAssetsforCredit + 1;
            }
          });
        });
      });
      return totalAssetsforCredit;
    }
  }

  useEffect(() => {
    setPreviousPage('certificationDocumentUpload');
  }, []);
  return (
    <>
      <Grid className={classes.main}>
        <Grid className={classes.root}>
          {showTabs ? (
            <Grid className={classes.gridWithPadding}>
              <Heading heading="IGBC Document Database" className={classes.heading} />
              <CustomBreadCrumbs links={[{ href: `/projects/${projectId}/certification`, text: 'Building certification' }]} stage="IGBC document database" />
            </Grid>
          ) : (
            ''
          )}
          {showTabs ? (
            <Grid className={classes.spacing}>
              <TabButtonGrp activeStep={activeStep} setActiveStep={setActiveStep} setActiveCategoryid={(id) => setActiveCategoryid(id)} />
            </Grid>
          ) : (
            ''
          )}
          <Grid className={classes.gridWithPadding} item xs={6}>
            <CustomizedBar
              bgcolor="#26E0A3"
              documentslength={creditassets && creditassets.length}
              activeStep={igbcCategoryContext ? igbcCategoryContext : activeStep}
              totalAssetsforCredit={GetNumber(creditassets, creditdata, creditname)}
              documentUploadPercentage={source === 'credit details' ? creditPercentage && creditPercentage : categoryPercentage && categoryPercentage}
              source={source}
            />
          </Grid>
          <Grid className={classes.gridWithPadding} item xs={10}>
            <DocumentTable
              creditdata={creditdata && creditdata}
              open={open}
              setOpen={setOpen}
              setSection={setSection}
              setPhase={setPhase}
              creditassets={creditassets}
              activeCategoryid={activeCategoryid && activeCategoryid}
              setCreditAssets={setCreditAssets}
              creditDetailsForaSingleCredit={creditDetailsForaSingleCredit && creditDetailsForaSingleCredit}
              projectID={projectId && projectId}
              setCreditId={setCreditId}
              source={source === 'credit details' ? 'credit' : 'category'}
              requestid={source === 'credit details' ? CreditId : activeCategoryid}
              setPercentage={source === 'credit details' ? setCreditPercentage : setCategoryPercentage}
            />
          </Grid>
        </Grid>
      </Grid>
      <UploadIGBCDocument
        open={open}
        setOpen={setOpen}
        activeCategoryid={activeCategoryid ? activeCategoryid : creditDetailsForaSingleCredit && creditDetailsForaSingleCredit.category}
        section={section}
        phase={phase}
        projectid={projectId && projectId}
        setCreditAssets={setCreditAssets}
        CreditId={CreditId}
        source={source === 'credit details' ? 'credit' : 'category'}
        requestid={source === 'credit details' ? CreditId : activeCategoryid}
        setPercentage={source === 'credit details' ? setCreditPercentage : setCategoryPercentage}
      />
    </>
  );
}

export default DocumentDashboard;
