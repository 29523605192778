import React from 'react';

export const AllPlumbingFixturesIcon = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#213162';
  if (isMobile) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={81} fill="none" {...props}>
        <path
          fill={color}
          d="M42.5 27.733V19.55c0-4.263-3.472-7.735-7.735-7.735h-6.9a2.191 2.191 0 0 0-2.18-1.951h-2.76V5.546h3.199A2.782 2.782 0 0 0 28.9 2.778 2.781 2.781 0 0 0 26.133 0h-12.21a2.784 2.784 0 0 0-2.777 2.778 2.776 2.776 0 0 0 2.777 2.768h3.2v4.317h-2.76a2.199 2.199 0 0 0-2.18 1.951H7.663V5.116a.881.881 0 0 0-.879-.88H1.38a.881.881 0 0 0-.879.88v25.21c0 .484.396.88.88.88h5.414a.881.881 0 0 0 .879-.88v-6.69h4.518a2.204 2.204 0 0 0 2.18 1.943h11.322a2.204 2.204 0 0 0 2.18-1.942h2.813v4.096c0 .483.396.879.879.879H41.63c.484 0 .87-.395.87-.879ZM12.913 2.778c0-.563.457-1.02 1.02-1.02h12.21c.562 0 1.02.457 1.02 1.02 0 .562-.457 1.01-1.02 1.01H13.924c-.563 0-1.011-.456-1.011-1.01Zm5.97 2.769h2.293v4.316h-2.294V5.547Zm-12.967 23.9H2.258V6.003h3.657v23.444Zm1.758-15.866h4.492v8.307H7.673v-8.307Zm18.46 9.802a.45.45 0 0 1-.448.448H14.37a.45.45 0 0 1-.448-.448V12.079a.45.45 0 0 1 .448-.449h11.313a.45.45 0 0 1 .449.449v11.304Zm14.61 3.472h-8.307V22.76a.881.881 0 0 0-.88-.879h-3.665v-8.307h6.874a5.983 5.983 0 0 1 5.977 5.978v7.304Z"
        />
      </svg>
    );
  } else {
    return (
      <svg width={75} height={81} viewBox="0 0 84 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <path
            id="Vector"
            d="M84 55.4666V39.0987C84 30.5722 77.0556 23.6278 68.5291 23.6278H54.7284C54.4819 21.43 52.6366 19.7251 50.3682 19.7251H44.8474V11.0928H51.2467C54.3054 11.0928 56.8019 8.59618 56.8019 5.55523C56.8027 2.51353 54.3238 0 51.265 0H26.8455C23.7868 0 21.2902 2.49658 21.2902 5.55523C21.2902 8.61389 23.7868 11.0928 26.8455 11.0928H33.2447V19.7251H27.724C25.4563 19.7251 23.6101 21.4483 23.3637 23.6278H14.3273V10.2312C14.3273 9.26435 13.5364 8.47337 12.5695 8.47337H1.75781C0.790977 8.47337 0 9.26435 0 10.2312V60.6525C0 61.6193 0.790977 62.4103 1.75781 62.4103H12.5872C13.5541 62.4103 14.3451 61.6193 14.3451 60.6525V47.2735H23.3814C23.6448 49.4537 25.4909 51.1585 27.7417 51.1585H50.3849C52.6349 51.1585 54.4811 49.453 54.7452 47.2735H60.3711V55.4659C60.3711 56.4327 61.162 57.2237 62.1289 57.2237H82.2593C83.2262 57.2237 83.9995 56.4327 83.9995 55.4659L84 55.4666ZM24.8236 5.5558C24.8236 4.43076 25.7374 3.51619 26.8632 3.51619H51.2827C52.4077 3.51619 53.3223 4.43006 53.3223 5.5558C53.3223 6.68084 52.4084 7.5777 51.2827 7.5777H26.8462C25.7212 7.5777 24.8243 6.6631 24.8243 5.5558H24.8236ZM36.7611 11.0933H41.3495V19.7257H36.7611V11.0933ZM10.829 58.8945H3.51525V12.0069H10.829V58.8945ZM14.3454 27.1627H23.3287V43.7762H14.3454V27.1627ZM51.2648 46.7657C51.2648 47.258 50.8601 47.6626 50.3678 47.6626L27.7416 47.6619C27.2493 47.6619 26.8446 47.2572 26.8446 46.765V24.1575C26.8446 23.6653 27.2493 23.2606 27.7416 23.2606H50.3678C50.8601 23.2606 51.2648 23.6653 51.2648 24.1575V45.5538V46.7657ZM80.4838 53.7101H63.8703V45.5178C63.8703 44.551 63.0793 43.76 62.1125 43.76H54.781V27.1465H68.5293C75.122 27.1465 80.4838 32.5082 80.4838 39.101V53.7101Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M72.2082 60.2009C71.1008 62.4509 65.5625 73.8963 65.5625 77.676C65.5625 82.2114 69.2548 85.886 73.7725 85.886C78.308 85.886 81.9826 82.1937 81.9826 77.676C81.9826 73.8963 76.445 62.4509 75.3369 60.2009C74.7571 59.006 72.7881 59.006 72.2084 60.2009H72.2082ZM78.4845 77.6937C78.4845 80.2779 76.375 82.3881 73.7901 82.3881C71.2059 82.3881 69.0956 80.2786 69.0956 77.6937C69.0956 75.9359 71.5039 70.0634 73.7901 65.088C76.0755 70.0634 78.4845 75.9182 78.4845 77.6937Z"
            fill={color}
          />
        </g>
      </svg>
    );
  }
};
