import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginBottom: '10%',
  },
});

export default function BasicTable({ rows, tableHeaders }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell
                align="left"
                style={
                  index === 0
                    ? MarketplaceTypography.PageHeading
                    : index === 1
                    ? { background: 'var(--UI-Table-Intervention-Category, #F7F7F7)' }
                    : { background: '#F1FFF3' }
                }
              >
                {index > 0 ? (
                  <Typography style={MarketplaceTypography.CaptionLarge}>{header}</Typography>
                ) : (
                  <Typography style={MarketplaceTypography.PageHeading}>
                    <b>{header}</b>
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell scope="row">
                <Typography style={MarketplaceTypography.PageHeading}>
                  <b> {row.name}</b>
                </Typography>
              </TableCell>

              <TableCell align="left" style={{ background: 'var(--UI-Table-Intervention-Category, #F7F7F7)' }}>
                <Typography style={MarketplaceTypography.CaptionLarge}>{row.a}</Typography>
              </TableCell>
              <TableCell align="left" style={{ background: '#F1FFF3' }}>
                <Typography style={MarketplaceTypography.CaptionLarge}>{row.b}</Typography>
              </TableCell>
              <TableCell align="left" style={{ background: '#F1FFF3' }}>
                <Typography style={MarketplaceTypography.CaptionLarge}>{row.c}</Typography>
              </TableCell>
              <TableCell align="left" style={{ background: '#F1FFF3' }}>
                <Typography style={MarketplaceTypography.CaptionLarge}>{row.d}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
