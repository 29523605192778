import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { ProjectContext } from '../../../../routing/IndexRouting';
import PrimaryButton from '../../../common/button/Button';
// import CloseIcon from '@material-ui/icons/Close';
// import { Link } from 'react-router-dom';
// import sidebaricon2 from '../../../assets/sidebaricon2.svg';
// import { useHistory } from 'react-router-dom';
// import cookie from 'react-cookies';
import axios from '../../../../config/Axios';
// import CommonAllProjectsCard from './myprojects/CommonAllProjectsCard';
// import appartment from '../../../assets/appartment.svg';
// import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import { UserContext } from '../../../../routing/IndexRouting';
import swal from 'sweetalert';
// import { TextField } from '@material-ui/core';
// import { contactUsContext } from '../DropDown';
import TextField from '@material-ui/core/TextField';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '700px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  modalhead: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'left',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  text1: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.38)',
    paddingTop: '2%',
    paddingBottom: '6%',
  },
  text2: {
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    fontWeight: '500',
  },
  textArea: {
    resize: 'none',
    // overflow: 'hidden',
    borderRadius: '10px',
    background: '#FFFFFF',
    border: '1.125px solid #9E9E9E',
    boxSizing: 'border-box',
  },
  text3: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '1px',
    fontWeight: '900',
    color: '#1FDEDB',
    paddingTop: '4%',
    cursor: 'pointer',
  },
  // container: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  // },
}));

const ContactUs = (props) => {
  const { projectData } = useContext(ProjectContext);
  //   const { contactus, handleContactus } = useContext(contactUsContext);
  const [message, setMessage] = React.useState('');
  let currentLocation = window.location.href.split('/');

  //   let history = useHistory();
  const closeModal = props.handleClose;
  const modal = props.modal;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  //   const [open, setOpen] = React.useState(modal);
  const { userDetails } = useContext(UserContext);
  const [page, setpage] = useState('Intervention');
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const handleMessage = () => {
    const body = {
      email: userDetails.email,
      name: userDetails.name,
      organisation: userDetails.organisation.name,
      project: projectData.name,
      page: page,
      message: message,
    };
    axios.post('/feedbacks/contact-us', body).then((res) => {
      if (res && res.data && res.data.data && res.data.data.body.data.feedbackAcknowledgement === true) {
        closeModal();
        swal('Thank you!', 'Our team will get in touch with you shortly.', 'success');
      } else {
        closeModal();
        swal('Oops! An error occured while sending your message.', 'Please email connect@sdplus.io directly.', 'error');
      }
    });
  };

  useEffect(() => {
    if (currentLocation[currentLocation.length - 2] === 'vendors') {
      setpage('Vendor details');
    } else if (currentLocation[currentLocation.length - 2] === 'interventions') {
      setpage('Intervention Details');
    } else {
      setpage(currentLocation[currentLocation.length - 1][0].toUpperCase() + currentLocation[currentLocation.length - 1].slice(1));
    }
  }, [currentLocation]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <Typography className={classes.modalhead}>Contact Us</Typography>
      <Typography className={classes.text1}>
        This message will be sent to<span style={{ color: 'rgba(0, 219, 153, 0.82)' }}> connect@sdplus.io</span>{' '}
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Typography className={classes.text2} style={{ color: '#9E9E9E' }}>
            Username{' '}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.text2}>{userDetails && userDetails.name}</Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ height: '20px' }} />
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Typography className={classes.text2} style={{ color: '#9E9E9E' }}>
            Project Name
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.text2}>
            {page === 'Projects' ? props.currentprojects && props.currentprojects.name : projectData && projectData.name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.text2} style={{ color: '#9E9E9E' }}>
            Page
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.text2}>{page}</Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ height: '20px' }} />
      <Grid container item xs={12}>
        {/* <form className={classes.container}> */}
        <TextField
          id="standard-multiline-flexible"
          rows="7"
          cols="115"
          autoFocus
          fullWidth
          multiline
          // maxRows={10}
          // className={classes.textField}
          margin="normal"
          variant="outlined"
          value={message}
          onChange={handleChange}
        />
        {/* </form> */}
      </Grid>
      <Grid container xs={12} style={{ height: '20px' }} />
      <Grid container item xs={12}>
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Typography className={classes.text3} onClick={() => closeModal()}>
            Cancel
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <PrimaryButton children="Send message" onClick={handleMessage} />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Typography>Contact Us</Typography>
      <Modal open={modal} onClose={closeModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {body1}
      </Modal>
    </div>
  );
};
export default ContactUs;
