import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../../config/Axios';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '3%',
    paddingLeft: '2%',
  },
  spacing2: {
    paddingLeft: '1%',
  },
  spacing3: {
    marginTop: '5%',
  },
  spacing4: {
    paddingTop: '2%',
  },
  paper3: {
    background: '#FAFAFA',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '4%',
    paddingBottom: '3%',
  },
  paper2: {
    background: '#FAFAFA',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: '1%',
    boxSizing: 'borderbox',
    boxShadow: 'none',
    borderRadius: '20px',
    height: '25vh',
  },
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    boxShadow: 'none',
    height: '40px',
    color: theme.palette.text.secondary,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '3%',
    marginBottom: '3%',
  },
  bartext: {
    textAlign: 'left',
    color: '#000000',
  },
  line: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3),
    height: '70%',
  },
}));

const Timeline = (props) => {
  const classes = useStyles();
  const vendor = props.vendor;
  const [clientRecommendations, setClientRecomendations] = useState('');
  const [organisations, setOrganisations] = useState('');

  useEffect(() => {
    axios
      .get('/organisationsschemev2')
      .then((response) => {
        if (response && response.data) setOrganisations(response.data.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  const findOrganisationImage = (v) => {
    const data = organisations && organisations.find((d) => d._id === v);
    if (data.payloadId) {
      return data.payloadId.URL;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (vendor) {
      setClientRecomendations(vendor.clientRecommendations);
    }
  }, [vendor]);

  return (
    <Grid container direction="column" item xs={12} className={classes.spacing3}>
      <Grid container direction="column" item xs={12} className={classes.spacing3}>
        <Typography variant="body2">Recommended by</Typography>
        <Grid container item xs={12} className={classes.spacing4}>
          {clientRecommendations &&
            clientRecommendations.map((v) => {
              return <img src={findOrganisationImage(v)} alt="..." width="10%" height="10%" style={{ marginLeft: '2%' }} />;
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Timeline;
