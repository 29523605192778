import { NavigatorSource } from '../utils/Defaults';
import {
  buildCustomProductsQuery,
  buildCustomSupercategoryQuery,
  buildEcolabelQuery,
  buildSearchQuery,
  buildgetProductsQuery,
} from '../views/certification-and-super-categories/utils/QueryParser';

export const categoryDetailsQuery = (
  source,
  category,
  subcategory,
  superCategory,
  certifications,
  brands,
  page,
  pagesize,
  filters,
  sort = 'default',
  searchquery,
  identifiers,
  productstatus
) => {
  if (source === NavigatorSource.categories) {
    return categorySourceFilterQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.supercategories) {
    return superCategoriesSourceFilterQuery(category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.ecolabels) {
    return ecolabelsFilterQuery(brands, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.search) {
    return searchFilterQuery(brands, page, pagesize, filters, sort, searchquery);
  } else if (source === NavigatorSource.intervention) {
    return interventionSourceFilterQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus);
  }
};

export const categorySourceFilterQuery = (category, subcategory, brands, certifications, page, pagesize, filters, sort) => `query Q {
  categories(filters:{name:{ in:["${category}"] }}){
    data {
     id 
     attributes {
       name 
       type 
       subcategories { 
        data { 
          id 
          attributes 
          { name 
          }
        }} 
       filters { 
        data { 
         id 
         attributes { 
          name 
          options { 
            id 
            option 
          }
        }}}  
       brands { 
        data { 
          id 
          attributes {
             name 
            }
          }} 
        }}
 }
 customProducts(${buildCustomProductsQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort)}) {
  totalproducts
  products{
    id  
    catalog_sku_id
    sku_name
    short_description
    image
    brand{
      name
    }
    category{
      id
      name
    }
    subcategory{
      name
    }
    ecolabels
  }
  }   
}`;

export const superCategoriesSourceFilterQuery = (category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort) => `query Q {
  customSuperCategories(
    ${buildCustomSupercategoryQuery(category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort)}      
  ) {
    totalproducts
    id
    Name
    products{
      id
      sku_name
      short_description
      image
      brand{
        name
      }
      category{
        id
        name
      }
      subcategory{
        name
      }
      ecolabels
    }
  }
  categories(filters: { super_categories: { Name: { in: "${superCategory}" } } }) {
      data {
        id
        attributes {
          name
          type
          brands {
            data {
              attributes {
                name
              }
            }
          }
          filters {
            data {
              attributes {
                name
                options {
                  option
                }
              }
            }
          }
          subcategories {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
}  
`;

export const ecolabelsFilterQuery = (brands, page, pagesize, filters, sort) => `query Q {
  ecoLabels {
    data {
      id
      attributes {
        name
      }
    }
  }
  brands(pagination:{page:1, pageSize:200}) {
    data {
      id
      attributes {
        name
      }
    }
  }
  customProducts(${buildEcolabelQuery(brands, page, pagesize, filters, sort)}) {
    totalproducts
    products {
      id
      sku_name
      short_description
      image
      brand {
        name
      }
      category {
        id
        name
      }
      subcategory {
        name
      }
      ecolabels
    }
  }
}
`;
export const searchFilterQuery = (brands, page, pagesize, filters, sort, searchquery) => `query Q {
  ecoLabels {
    data {
      id
      attributes {
        name
      }
    }
  }
  brands(pagination:{page:1, pageSize:200}) {
    data {
      id
      attributes {
        name
      }
    }
  }
  searchProducts(${buildSearchQuery(brands, page, pagesize, filters, sort, searchquery)}) {
    totalproducts
    products {
      id
      sku_name
      short_description
      image
      brand {
        name
      }
      category {
        id
        name
      }
      subcategory {
        id
        name
      }
      ecolabels
    }
  }
}
`;

export const interventionSourceFilterQuery = (
  category,
  subcategory,
  brands,
  certifications,
  page,
  pagesize,
  filters,
  sort,
  identifiers,
  productstatus
) => `query Q {
  categories(filters:{name:{ in:["${category}"] }}){
    data {
     id 
     attributes {
       name 
       type 
       subcategories { 
        data { 
          id 
          attributes 
          { name 
          }
        }} 
       filters { 
        data { 
         id 
         attributes { 
          name 
          options { 
            id 
            option 
          }
        }}}  
       brands { 
        data { 
          id 
          attributes {
             name 
            }
          }} 
        }}
 }
 getProducts(${buildgetProductsQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus)}){
  totalproducts
  products {
      id
      catalog_sku_id
      sku_name
      short_description
      image
      brand {
        id
        name
      }
      category{
        id
        name
      }
      subcategory{
        id
        name
      }
      ecolabels
      quality_assurance_and_reliability
      environmental_impact_ecosystem_health
      environmental_impact_human_health
      environmental_impact_circularity
      sdg
      resource_savings
      environmental_impact_climate_health
      warranty_and_after_sales
      references
      default_product
      sdplus_interventions
      price
      properties
      filters
    }
}
}`;
