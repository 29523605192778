import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Fields from '../../../../common/textfield/Fields';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import ResetFields from '../common/textFiledwithReset';

function ShowCapexAndDetails({ source, setSource, capexMultiplier, VendorcapexMultiplier, myArray, appendChanged, multiplierSource }) {
  return (
    <>
      <Grid container item xs={12} style={{ marginTop: '2%' }}>
        <Typography variant="h6" color="textSecondary">
          {capexMultiplier && capexMultiplier.label}
        </Typography>
        <CustomizedTooltips
          icon="info"
          title={
            <Typography>
              Capex is calculated by using the Capex multiplier values. The Capex multiplier value can be either the system default value or user entered custom
              value or the default value for the selected vendor
            </Typography>
          }
          placement="right"
        />
        <Grid item container alignItems="flex-start" direction="row" xs={12}>
          <Grid item xs={4} container alignItems="flex-start" style={{ marginTop: '1%' }}>
            <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
              {source === 'DEFAULT' ? (
                <CheckCircleRoundedIcon style={{ color: '#49DDB1' }} />
              ) : (
                <RadioButtonUncheckedOutlinedIcon
                  style={{ color: '#49DDB1', cursor: 'pointer' }}
                  onClick={() => {
                    setSource('DEFAULT');
                    appendChanged(multiplierSource.id, 'DEFAULT');
                  }}
                />
              )}
            </Grid>
            <Grid item xs={10} container direction="column" style={{ paddingLeft: '1%' }}>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="h6">
                  Default
                </Typography>
              </Grid>
              <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                <Grid xs={9} item>
                  <ResetFields id="outlined-basic" variant="outlined" value={capexMultiplier.defaultValue} placeholder="" fullWidth required disabled={true} />
                </Grid>
                <Grid xs={2} container justifyContent="center" alignItems="center">
                  <Typography variant="h6">{capexMultiplier.unit}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {VendorcapexMultiplier && (
            <Grid item xs={4} container alignItems="flex-start" style={{ marginTop: '1%' }}>
              <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
                {source === 'VENDOR' ? (
                  <CheckCircleRoundedIcon style={{ color: '#49DDB1' }} />
                ) : (
                  <RadioButtonUncheckedOutlinedIcon
                    style={{ color: '#49DDB1', cursor: 'pointer' }}
                    onClick={() => {
                      setSource('VENDOR');
                      appendChanged(multiplierSource.id, 'VENDOR');
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={10} container direction="column" style={{ paddingLeft: '1%' }}>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="h6">
                    Selected Vendor
                  </Typography>
                </Grid>
                <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                  <Grid xs={9} item>
                    <ResetFields
                      id="outlined-basic"
                      variant="outlined"
                      value={VendorcapexMultiplier?.value}
                      placeholder=""
                      fullWidth
                      required
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={2} container justifyContent="center" alignItems="center">
                    <Typography variant="h6">{VendorcapexMultiplier?.unit}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={4} container alignItems="flex-start" style={{ marginTop: '1%' }}>
            <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
              {source === 'CUSTOM' ? (
                <CheckCircleRoundedIcon style={{ color: '#49DDB1' }} />
              ) : (
                <RadioButtonUncheckedOutlinedIcon
                  style={{ color: '#49DDB1', cursor: 'pointer' }}
                  onClick={() => {
                    setSource('CUSTOM');
                    appendChanged(multiplierSource.id, 'CUSTOM');
                  }}
                />
              )}
            </Grid>
            <Grid xs={10} container direction="column" style={{ paddingLeft: '1%' }}>
              <Grid xs={12} item>
                <Typography color="textSecondary" variant="h6">
                  Custom
                </Typography>
              </Grid>
              <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                <Grid xs={9} item>
                  <Fields
                    id="outlined-basic"
                    variant="outlined"
                    value={
                      myArray && !myArray.find((d) => d.id === capexMultiplier.id)
                        ? capexMultiplier.value
                        : myArray.find((d) => d.id === capexMultiplier.id) && myArray.find((d) => d.id === capexMultiplier.id).value
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      appendChanged(capexMultiplier.id, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={source && source === 'CUSTOM' ? false : true}
                  />
                </Grid>
                <Grid xs={2} container justifyContent="center" alignItems="center">
                  <Typography variant="h6">{capexMultiplier.unit}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ShowCapexAndDetails;
