import { Default } from '../../utils/Defaults';

export const query = `query Q{
    superCategories{
      data{
        id
        attributes{
          Name
          products{
            data{
              id
              attributes{
                name
                shortDescription
                brand{
                  data{
                    attributes{
                      name
                    }
                  }
                }
                category{
                  data{
                    id
                    attributes{
                      name
                    }
                  }
                }
                subcategory{
                  data{
                    attributes{
                      name
                    }
                  }
                }
               image
              }
            }
          }
        }
      }
    }  
    }`;

export const supercategoriesquery = (superCategory) => `query Q {
  customSuperCategories(
    page:${Default.page}, pagesize:${100}, supercategoryQuery:"${superCategory}"  
  ) {
    totalproducts
    id
    Name
    products{
      id
      catalog_sku_id
      sku_name
      short_description
      image
      brand{
        name
      }
      category{
        id
        name
      }
      subcategory{
        name
      }
      ecolabels
    }
  }
}  
`;
