import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import UnitAwareTextField from '../../../../common/textfield/UnitAwareTextField';

function ComplexPIPchildren(props) {
  const { index, field, unit, projectPIP, appendChanged } = props;
  return (
    <>
      <Grid container direction="row" item xs={12} key={index} style={{ height: 'Auto', marginTop: '3%', marginBottom: '5%', marginLeft: '10%' }}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary" style={{ marginBottom: '4%' }}>
            {field.displayName}
          </Typography>
        </Grid>
        <UnitAwareTextField
          index={index}
          unit={unit && unit}
          field={field && field}
          projectPIP={projectPIP && projectPIP}
          appendChanged={appendChanged}
          xs={7}
        />
      </Grid>
    </>
  );
}

export default ComplexPIPchildren;
