import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

function ShowProjectParameters({ projectinputs }) {
  var regExp = /[a-zA-Z]/g;
  const classes = useStyles();
  return (
    <>
      <Grid container item xs={12} style={{ marginTop: '15px' }}>
        <Typography variant="h6">Project Inputs</Typography>
        <CustomizedTooltips icon="info" title={<Typography>Relevant project inputs used for calculating intervention values</Typography>} placement="right" />
      </Grid>
      <Grid item container alignItems="flex-start" direction="row" xs={12}>
        {projectinputs &&
          projectinputs.map((data, index) => {
            return (
              <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={index > 2 ? { marginTop: '3%' } : { marginTop: '1%' }}>
                  <Grid xs>
                    <Typography color="textSecondary" variant="h6">
                      {data.label}
                    </Typography>
                  </Grid>
                  <Grid xs={12} container alignItems="flex-start" direction="row" className={classes.grid}>
                    <Grid xs={4}>
                      <Typography variant="h6" style={{ marginTop: '10px' }}>
                        <b>
                          {regExp.test(data.value && data.value) ? data.value : numberWithCommas(data.value && data.value)}
                          <span>{data?.unit === 'NA' ? '' : data?.unit} </span>
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
}

export default ShowProjectParameters;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '2%',
  },
}));
