import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem, Backdrop, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import PreferenceUnits from '../common/components/PreferenceUnits';
import LocationBox from '../common/components/Location';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import Fields from '../../../common/textfield/Fields';
import OrganisationSelect from './Components/OrganisationSelect';
import TooglebuttonGroupForCreateProject from '../../createproject/components/TooglebuttonGroupForCreateProject';
import { makeStyles } from '@material-ui/core/styles';
import { useAppApiRequest } from '../../../../hooks/useAppApiRequest';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import ApiError from '../../../common/Error/ApiError';
import { Cities } from '../../../../utils/cities';

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));

export const EditProjectBasicPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const classes = useStyles();

  //Location Service
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [value, setValue] = React.useState(options[0]);
  const [inputchange, setInputChange] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const { data: pips } = useAppApiRequest(`/pipschemev2?required=true`, { method: 'GET' });
  const requiredpips = pips?.data?.data;

  const { data: projectData } = useAppProjectDetails(id);

  const {
    get: { form, city, projectPIP, isFetching, isError },
    set: { handlePIPValueUpdate, handleUpdateForm },
  } = useAppProjectPIPMapping(id, {
    name: null,
    organisation: null,
    category: null,
    subcategory: null,
    location: {},
    city: null,
  });

  const { data } = useAppPIPScheme(type);
  const unitPreferencesPIP = data?.filter((field) => field.name === 'project__pi_unitPreferenceForArea');
  const locationBox = data?.filter((field) => field.name === 'location');

  const basicPIPs = ['project__pi_city', 'project__pi_siteArea', 'project__pi_builtUpArea'];
  const simplePIPs = data?.filter((field) => basicPIPs.includes(field.name))?.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name)) || [];

  const villaAdditionalPips = ['project__pi_availableVillaRooftopArea'];
  const apartmentAdditionalPips = ['project__pi_rooftopArea'];
  const villaData = data?.filter((field) => villaAdditionalPips.includes(field.name)) || [];
  const apartmentData = data?.filter((field) => apartmentAdditionalPips.includes(field.name)) || [];

  const simplePIPsBySelection = form?.subcategory === 'villa' ? [...simplePIPs, ...villaData] : [...simplePIPs, ...apartmentData];

  // useEffect(() => {
  //   if (projectPIP && myArray.length !== 0) {
  //     myArray.forEach((ele) => {
  //       let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
  //       if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
  //     });
  //   }
  // }, [projectPIP, myArray]);

  //useEffects
  useEffect(() => {
    if (projectData) {
      handleUpdateForm('name', projectData?.name);
      handleUpdateForm('category', projectData?.category);
      handleUpdateForm('subcategory', projectData?.subcategory);
      handleUpdateForm('organisation', projectData?.organisation);
    }
  }, [projectData]);

  useEffect(() => {
    if (city) {
      handleUpdateForm('city', city?.value);
    }
  }, [city]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.SETNEXT('');
        props.setFalseAfterSomethingChanged();
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSearch = (e) => {
    setSearch(true);
    setLoading(true);
    axios.get(`/location/?location=${inputchange}`).then((resp) => {
      if (resp && resp.data && resp.data.data) setOptions([...resp.data.data.data]);
    });
    setOpen(true);
    setLoading(false);
    return () => setSearch(false);
  };

  const updateProjectMapping = async () => {
    return axios.put(`/ProjectPIPMapping`, {
      projectId: id,
      values: Object.entries(form?.pipvalues).map(([key, value]) => ({
        PIPScheme: key,
        value,
      })),
    });
  };

  const updateProjectV2 = async (loc) => {
    return axios.put(`/projectv2/${id}`, {
      newLocation: {
        name: loc.name,
        type: 'Point',
        coordinates: [loc.coordinates.lat, loc.coordinates.long],
      },
    });
  };

  const updateProjectV2Basics = async () => {
    return axios.put(`/projectv2/${id}/basics`, {
      name: form?.name,
      category: form?.category,
      subcategory: form?.subcategory,
      organisation: form?.organisation,
    });
  };

  const getLocation = async () => {
    return axios.get(`/location/place?id=${newloc.placeId}`);
  };

  const handleSave = async (next) => {
    await updateProjectMapping();

    if (type === 'basic' && newloc && newloc.placeId) {
      const loc = await getLocation();
      if (loc) {
        await updateProjectV2(loc.data.data.data);
      }
    }
    await updateProjectV2Basics();

    props.toogleShowModal(false);
    handlSetSomethingChanged(false);
    props.setFalseAfterSomethingChanged();
    props.SETNEXT('');
    swal({ icon: 'success', title: 'Data Saved' });
    if (next && next === 'insights') history.push(`/projects/${id}/insights`);
    if (next && next === 'projects') history.push(`/projects`);
    else history.push(`/projects/${id}/edit?type=${next}`);
  };

  const handleCityChange = (value) => {
    const cityschema = requiredpips?.data?.find((ele) => ele?.name === 'project__pi_city');
    handleUpdateForm('city', value);
    handlePIPValueUpdate(cityschema?._id, value);
  };

  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>
      {/* Project Creation pips are now added here */}
      {/* Name */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '3%' }}>
        <Grid item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5"> Enter Project Name*</Typography>
        </Grid>
        <Grid item xs={2}>
          <Fields
            // error={!projectName ? true : false}
            // helperText={!projectName ? 'project name missing.' : ''}
            id="standard-error-helper-text"
            placeholder="Project Name*"
            value={form?.name}
            onChange={(event) => handleUpdateForm('name', event.target.value)}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      {/* Organisation */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '3%' }}>
        <Grid container item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5"> Enter Organisation Name*</Typography>
        </Grid>
        <Grid item xs={2}>
          <OrganisationSelect selectedOrganisationID={form?.organisation || ''} handleChange={(value) => handleUpdateForm('organisation', value)} />
        </Grid>
      </Grid>
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%' }}>
        <TooglebuttonGroupForCreateProject
          category={form?.category}
          handleChange={(event, value) => value && handleUpdateForm('category', value)}
          subCategory={form?.subcategory}
          setSubCategory={(value) => handleUpdateForm('subcategory', value)}
          requiredpips={requiredpips}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          pipValues={form?.pipvalues}
          disableSubcategorySelection={true}
          // clickedContinue={clickedContinue && clickedContinue}
          // setChange={(v) => setChange(v)}
          // change={change && change}
        />
      </Grid>
      {/* units */}
      {type && type === 'basic' && unitPreferencesPIP && projectPIP?.length !== 0
        ? unitPreferencesPIP.map((field, index) => {
            return (
              <PreferenceUnits
                field={field}
                type={type}
                index={index}
                radioButtonValue={form?.unitPIPValue}
                onChange={(e) => {
                  handleUpdateForm('unitPIPValue', e.target.value);
                  handlePIPValueUpdate(field._id, e.target.value);
                }}
              />
            );
          })
        : null}
      {/* location box */}
      {type && type === 'basic' && locationBox && projectData
        ? locationBox.map((field, index) => {
            return (
              <LocationBox
                field={field}
                type={type}
                index={index}
                options={options}
                loading={loading}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setNewloc(newValue);
                  if (newValue != null) {
                    handlePIPValueUpdate(field._id, newValue.description);
                  }
                }}
                onInputChange={(e, newInputValue) => {
                  setInputChange(newInputValue);
                }}
                handleSearch={handleSearch}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) => option.description === value.description}
                defaultValue={{
                  description: projectData?.location?.name,
                  co_ordinates: projectData?.location?.coordinates,
                }}
              />
            );
          })
        : null}
      {/* pips */}
      {type && type === 'basic' && simplePIPsBySelection && projectPIP?.length !== 0 && projectData && city
        ? simplePIPsBySelection?.map((field, index) => {
            if (field.name !== 'project__pi_city') {
              return (
                <SimplePIPS
                  key={index}
                  index={index}
                  unit={form?.unitPIPValue}
                  field={field}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                />
              );
            } else {
              return (
                <Grid style={{ paddingLeft: '2%', paddingBottom: '2%' }}>
                  <Grid item xs={12} className={classes.spacing}>
                    <Typography variant="h5"> Enter Project City*</Typography>
                  </Grid>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select value={form?.city} onChange={(e) => handleCityChange(e.target.value)}>
                      {Cities.map((city) => (
                        <MenuItem key={city} value={city} name={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              );
            }
          })
        : null}
      {type === 'basic' && (
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '2%' }}>
          *All fields are mandatory
        </Typography>
      )}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
