import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import mandateImg from '../../../../../assets/interventions/mandate.svg';

const CSPCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_dailyDryWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_dailyDryWaste)} {getQuantityUnit(schema.csp_dailyDryWaste)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_dailyMedicalWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_dailyMedicalWaste)} {getQuantityUnit(schema.csp_dailyMedicalWaste)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_dailyEWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_dailyEWaste)} {getQuantityUnit(schema.csp_dailyEWaste)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_areaRequired)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_areaRequired)} {getQuantityUnit(schema.csp_areaRequired)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: '3%' }}>
        <Paper className={classes.mandatePaperStyle}>
          <Grid container xs={12} direction="row">
            <Grid item xs={1} style={{ paddingLeft: '3%', paddingTop: '1%' }}>
              <img src={mandateImg} alt="..." />
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.mandateText}>{getQuantityValue(schema.csp_mandateMsg)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CSPCustomBasicParameters;
