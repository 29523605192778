import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, withWidth } from '@material-ui/core';
import icon1 from '../../../../../../assets/Marketplace/icon1.svg';
import ProductDetailsTableData from './ProductDetailsTableData';
import useScreenType from '../../../../../hooks/checkScreenType';
import { MarketplaceColors } from '../../../config';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

export const useStyles = makeStyles((theme) => ({
  tableGrid: {
    marginTop: '1%',
  },
}));

const DefaultTabs = [
  { id: 0, name: 'Environmental Impact', icon: icon1, collapsible: true, table: true, tableData: [] },
  { id: 1, name: 'Certifications and Standards', icon: icon1, collapsible: true, table: true, tableData: [] },
  { id: 2, name: 'Properties', icon: icon1, collapsible: true, table: true, tableData: [] },
  { id: 3, name: 'General', icon: icon1, collapsible: true, table: true, tableData: [] },
];

const ProductDetailsTableMobile = (props) => {
  const { width, productAttributes } = props;
  const { isMobile } = useScreenType(width);
  const classes = useStyles();

  const [tabData, setTabData] = useState(DefaultTabs);

  useEffect(() => {
    if (productAttributes) {
      const Tabs = [
        { id: 0, name: 'Environmental Impact', icon: icon1, collapsible: true, table: true, tableData: productAttributes?.environmental_impacts },
        {
          id: 1,
          name: 'Certifications and Standards',
          icon: icon1,
          collapsible: true,
          table: true,
          tableData: productAttributes?.certifications_and_standards,
        },
        { id: 2, name: 'Properties', icon: icon1, collapsible: true, table: true, tableData: productAttributes?.properties },
        { id: 3, name: 'General', icon: icon1, collapsible: true, table: true, tableData: productAttributes?.general },
      ];
      setTabData(Tabs);
    }
  }, [productAttributes]);

  const handleChange = (index, data) => {
    let arr = [];
    const { id, name, icon, collapsible, table, tableData } = data;
    const newObj = { id: id, name: name, icon: icon, collapsible: !collapsible, table: table, tableData: tableData };
    let copiedShopCart = { ...tabData };
    delete copiedShopCart[index];
    copiedShopCart[index] = newObj;
    for (const data in copiedShopCart) arr.push(copiedShopCart[data]);
    setTabData(arr);
  };

  return (
    <>
      {tabData.length !== 0 &&
        tabData?.map((data, index) => {
          return (
            <>
              {data.table && (
                <>
                  <Grid container xs={12} alignItems="center" justifyContent="flex-start" style={{ marginTop: '4%', marginBottom: '6%' }}>
                    <Grid xs={10} container alignItems="center" justifyContent="flex-start" style={{ paddingLeft: '3%' }}>
                      <Typography
                        variant={!isMobile ? 'h5' : 'h6'}
                        onClick={(e) => handleChange(index, data)}
                        style={!isMobile ? null : { color: MarketplaceColors.Secondary }}
                      >
                        {!isMobile ? data.name : <b>{data.name}</b>}
                      </Typography>
                    </Grid>
                    <Grid xs={2} container alignItems="center" justifyContent="center">
                      {data.collapsible ? <RemoveIcon onClick={(e) => handleChange(index, data)} /> : <AddIcon onClick={(e) => handleChange(index, data)} />}
                    </Grid>
                  </Grid>
                  {data.collapsible && (
                    <Grid container xs={12} alignItems="center" justifyContent="center" className={classes.tableGrid}>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ marginTop: '1%', marginBottom: '2%', paddingLeft: '2%', paddingRight: '2%' }}
                      ></Grid>
                      <Grid xs={12} container alignItems="center" justifyContent="center" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                        <ProductDetailsTableData tableData={data && data.tableData} />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          );
        })}
    </>
  );
};

export default withWidth()(ProductDetailsTableMobile);

{
  /* <Typography
                          variant={!isMobile ? 'h5' : 'h6'}
                          onClick={(e) => handleChange(index, data)}
                          style={!isMobile ? null : { color: MarketplaceColors.CommonBlack }}
                        >
                          {!isMobile ? null : <b>Section 1</b>}
                        </Typography> */
}
