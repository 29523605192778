import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { DummyVendorsList2 } from './DummyVendorsList2';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function VendorsList3(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      maxHeight: 'auto',
      marginLeft: '11%',
      marginRight: '10%',
      paddingTop: '5%',
      paddingBottom: '2%',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      width: '170px',
      height: '250px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        width: '133px',
        height: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '103px',
        height: '250px',
      },
    },
    media: {
      width: '173px',
      height: '130px',
      [theme.breakpoints.down('md')]: {
        width: '133px',
        height: '100px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '103px',
        height: '100px',
      },
    },
    icon: {
      marginTop: 200,
      textAlign: 'center',
      background: 'rgba(0, 0, 0, 0.05)',
      width: '41px',
      height: '92px',
    },
  }));
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);
  return (
    <>
      <Grid container xs={11} sm={11} md={11} lg={11} xl={11} alignItems="center" style={{ marginLeft: '6%' }}>
        <Paper elevation={3} style={{ width: '95%', height: 'auto', padding: '10px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div data-aos="fade-right" style={{ textAlign: 'right' }}>
              <Button onClick={props.handleClose}>
                <CloseIcon />
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div data-aos="fade-right" style={{ textAlign: 'center' }}>
              <Typography variant="body2">Vendor Catalogue</Typography>
            </div>
          </Grid>
          <br />
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction="row" style={{ marginLeft: '3%' }}>
            <Grow in={checked} style={{ transformOrigin: '0 10 0' }} {...(checked ? { timeout: 1000 } : {})}>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11} direction="row" container>
                {DummyVendorsList2 &&
                  DummyVendorsList2.map((tile, index) => {
                    return (
                      <>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} key={index} style={{ paddingBottom: '10px', paddingRight: '20%' }}>
                          <Card className={classes.card}>
                            <CardActionArea>
                              <CardMedia className={classes.media} image={tile.image} />
                              <CardContent>
                                <Typography style={{ color: '#224B8B', fontSize: '12.1636px' }}>{tile.name}</Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  <b>{tile.type}</b>
                                </Typography>

                                <Typography style={{ fontSize: ' 9.58846px', marginTop: '4%' }}>{tile.desc}</Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grow>

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} alignContent="flex-start">
              <div data-aos="fade-right" className={classes.icon} onClick={props.handleScroll}>
                <ArrowForwardIosIcon style={{ marginTop: 38 }} />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
export default VendorsList3;
