import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '6%',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.38)',
    paddingLeft: '6%',
  },
}));
const Contact = (props) => {
  const classes = useStyles();
  const vendor = props.vendor;
  return (
    <Grid container direction="column" item xs={12} className={classes.spacing}>
      <Typography variant="body2">Contact Us</Typography>
      <Grid container item xs={12} className={classes.spacing}>
        {props.contactPhoto && props.contactPhoto.URL ? (
          <Grid item xs={3} style={{ height: '50px', width: '50px' }}>
            <img src={props.contactPhoto.URL} alt="..." width="200" height="200" />
          </Grid>
        ) : (
          <Grid item xs={3} style={{ backgroundColor: '#C4C4C4' }}></Grid>
        )}

        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography variant="h6">{`${vendor ? vendor.contact.manager : ''} will be happy to help you with any queries you have!`} </Typography>
          <br />
          <Typography variant="h6">You can reach out using:</Typography>
          <br />
          <Typography variant="h5">{vendor ? vendor.contact.phone : ''}</Typography>
          <br />
          <Typography variant="h6" className={classes.text}>
            OR
          </Typography>
          <br />
          <Typography variant="h5">{vendor ? vendor.contact.email : ''}</Typography>
        </Grid>
      </Grid>

      <br />
      <br />
    </Grid>
  );
};
export default Contact;
