import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { DummyVendors } from './DummyVendors';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
// modal implementaion
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VendorsList2 from './VendorsList2';
import VendorsList3 from './VendorsList3';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

function Home4(props) {
  const useStyles = makeStyles((theme) => ({
    navbar2: {
      marginBottom: '6%',
    },
    title: {
      lineHeight: '40px',
      letterSpacing: '1px',
      background: 'linear-gradient(152.49deg, #03CBFF -0.03%, #3681F0 100.09%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    button: {
      background: 'linear-gradient(136.5deg, #00CEFF 0%, #3B78EE 136.3%)',
      width: '70%',
      height: '6vh',
      marginTop: '8%',
    },
    buttonView: {
      background: 'linear-gradient(136.5deg, rgba(0, 206, 255, 0.75) 0%, rgba(59, 120, 238, 0.75) 136.3%)',
      borderRadius: '200px',
      width: '10px',
      height: '60px',
      padding: '10px',
    },
    buttonText: {
      color: '#FFFFFF',
      WebkitTextFillColor: '#FFFFFF',
    },
    typo: {
      lineHeight: '20px',
      paddingLeft: '12%',
    },
    typo2: {
      lineHeight: '30px',
    },
    typo3: {
      lineHeight: '30px',
    },
    paper: {
      width: '95%',
      height: 'auto',
      padding: '10px',
      borderRadius: ' 11.4171px',
    },
    zoomIcon: {
      height: '30px',
      width: '30px',
      color: 'white',
    },
    card: {
      width: '170px',
      height: '250px',
      borderRadius: ' 11.4171px',
      [theme.breakpoints.down('md')]: {
        width: '133px',
        height: '290px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '103px',
        height: '330px',
      },
    },
    media: {
      width: '173px',
      height: '130px',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    newRoot: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      textAlign: 'center',
      overflow: 'hidden',
    },
    gridList: {
      flexWrap: 'nowrap',
      width: '100%',
      transform: 'translateZ(0)',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  }));
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [vendorScroll, setVendorScroll] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleVendorScroll = (prevState) => {
    setVendorScroll(!prevState);
  };
  return (
    <>
      <Grid item xs={12} className={classes.navbar2}></Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <div data-aos="fade-right" style={{ textAlign: 'left' }}>
            <Typography className={classes.title} variant="h3">
              Reliable Vendors
              <br />
              <br />
            </Typography>
          </div>

          <div data-aos="fade-left">
            <Typography className={classes.typo2} variant="h5">
              Discover & select the best vendors for <br /> all innovative sustainable solutions.
              <br />
              <br />
              Vendors are rated on 8 parameters <br /> including projects implemented, client <br /> references, pricing & technology <br />
              maturity.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div data-aos="fade-right">
            <Paper elevation={3} className={classes.paper}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div data-aos="fade-right" style={{ textAlign: 'center' }}>
                  <Typography variant="body2">Vendor Catalogue</Typography>
                </div>
              </Grid>
              <br />
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction="row">
                {DummyVendors &&
                  DummyVendors.map((tile, index) => {
                    return (
                      <>
                        {index < 5 ? (
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index} style={{ paddingBottom: '10px' }}>
                            <Card className={classes.card}>
                              <CardActionArea>
                                <CardMedia className={classes.media} image={tile.image} />
                                <CardContent>
                                  <Typography style={{ color: '#224B8B', fontSize: '12.1636px' }}>{tile.name}</Typography>
                                  <Typography style={{ fontSize: '10px' }}>
                                    <b>{tile.type}</b>
                                  </Typography>

                                  <Typography style={{ fontSize: ' 9.58846px', marginTop: '4%' }}>{tile.desc}</Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            key={index}
                            style={{ paddingBottom: '10px' }}
                            container
                            alignItems="center"
                            alignContent="center"
                          >
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} alignItems="center" alignContent="center" style={{ textAlign: 'center' }}>
                              <Button className={classes.buttonView} onClick={handleOpen}>
                                <ZoomOutMapIcon className={classes.zoomIcon} />
                              </Button>

                              <Typography style={{ color: '#3BC7EE' }}>Expand Vendor Catalogue</Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.newRoot}>
            <GridList className={classes.gridList} cols={1}>
              <GridListTile cols={1} rows={3.5}>
                {vendorScroll && vendorScroll === true ? (
                  <VendorsList3 handleClose={handleClose} handleScroll={() => handleVendorScroll(vendorScroll)} />
                ) : (
                  <VendorsList2 handleClose={handleClose} handleScroll={() => handleVendorScroll(vendorScroll)} />
                )}
              </GridListTile>
            </GridList>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
export default Home4;
