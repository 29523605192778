import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
// import dummyVendor from '../../../../../assets/new-pdf/dummyVendor.png';
import vendorcivil from '../../../../../assets/new-pdf/vendorcivil.png';

const ActiveIntervCard = (props) => {
  return (
    <View style={{ border: props.border, height: '162px', marginTop: '16px' }}>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View style={{ paddingTop: '10px', width: '45%', paddingLeft: '10px', paddingRight: '10px' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '12px', color: props.color }}>{props.name && props.name}</Text>
          <Text style={{ fontFamily: 'Open Sans', fontSize: '10px', color: '#666666', paddingTop: '10px' }}>{props.content}</Text>
          <Text
            style={{ fontFamily: 'Open Sans', fontSize: '10px', color: '#666666', paddingTop: '5px' }}
          >{`Capacity: ${props.capacity} ${props.cpacityunit}`}</Text>
          <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}>
            <View>
              <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', paddingTop: '10px' }}>Selected Vendor</Text>
            </View>
            <View style={{ width: '15px' }} />
            <View style={{ backgroundColor: '#FFFFFF', border: '0.811881px solid #666666', borderRadius: '8px', height: '40px', width: '80px' }}>
              {props.name === 'Rooftop RWH' || props.name === 'Surface RWH' ? (
                <Image style={{ height: '23px', width: '73px', bottom: 10, left: 5, position: 'absolute' }} src={vendorcivil} />
              ) : props.selectedVendor && props.selectedVendor.logo !== undefined ? (
                <Image style={{ height: '13px', width: '69px', bottom: 10, left: 5, position: 'absolute' }} src={props.selectedVendor.logo} />
              ) : (
                ''
              )}
            </View>
          </View>
        </View>
        <View style={{ width: '20%', paddingTop: '10px' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}>CAPEX</Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}>(Cr)</Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.capex}
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center', paddingTop: '15px' }}>
            Space Required
          </Text>
          <Text
            style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}
          >{`(${props.areaunit})`}</Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.area}
          </Text>
        </View>
        <View style={{ width: '30%', paddingTop: '10px' }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}>
            Annual Energy Savings
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}> {`(${props.unit})`}</Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.resource}
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center', paddingTop: '15px' }}>
            Annual OPEX Savings
          </Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '10px', color: '#666666', textAlign: 'center' }}>(Cr)</Text>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'SemiBold', fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.opex}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ActiveIntervCard;
