import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Container } from '@material-ui/core';
import PageTitle from '../insightsPage/insights/components/PageTitle';
import ComponentTitle from '../insightsPage/insights/components/ComponentTitle';
import IGBCStatus from './components/IGBCStatus';
import IGBCSummary from './components/IGBCSummary';
import IGBCCategoriesTogglers from './components/IGBCCategoriesTogglers';
import IGBCCategoriesTable from './components/IGBCCategoriesTable';
import { IGBCCategoryContext } from '../../../projectRouting/Index';
import useApiRequest from '../../hooks/useApiRequest';
import { PreviousPageContext } from '../../../routing/IndexRouting';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    backgroundColor: 'rgba(24, 50, 119, 0.07)',
  },
}));

export default function Certification(props) {
  const classes = useStyles();
  const params = useParams();
  const projectId = params && params.id;
  const [categories, setCategories] = useState([]);
  const [igbcCertificationData, setigbcCertificationData] = useState('');
  const [categoriesData, setCategoriesData] = useState('');
  const [selectedCategoryData, setSelectedCategoryData] = useState('');
  const [dataUploadprogress, setDataUploadprogress] = useState('');
  const [documentUploadPercentage, setDocumentUploadPercentage] = useState('');
  const [bargraphData, setBargraphdata] = useState([]);

  const { igbcCategoryContext, handleIGBCCategory } = useContext(IGBCCategoryContext);
  const { setPreviousPage } = useContext(PreviousPageContext);

  const [{ status, response }, makeRequest] = useApiRequest(`/igbc-category`, {
    verb: 'get',
  });
  const [re, sendRequest] = useApiRequest(`/projectv2/${projectId}/igbc-data`, {
    verb: 'get',
  });

  if (status === null) makeRequest();

  useEffect(() => {
    setPreviousPage('certification');
  }, []);

  useEffect(() => {
    if (response && response.data && response.data && response.data.data.data) {
      let a = response.data.data.data.find((d) => d.categoryName === 'Sustainable Design')?.categoryName;
      let b = response.data.data.data.find((d) => d.categoryName === 'Water Conservation')?.categoryName;
      let c = response.data.data.data.find((d) => d.categoryName === 'Energy Efficiency')?.categoryName;
      let d = response.data.data.data.find((d) => d.categoryName === 'Material and Resources')?.categoryName;
      let e = response.data.data.data.find((d) => d.categoryName === 'Resident Health and Wellbeing')?.categoryName;
      let f = response.data.data.data.find((d) => d.categoryName === 'Innovation and Design')?.categoryName;
      setCategories([a, b, c, d, e, f]);
    }
  }, [response]);

  useEffect(() => {
    if (re.status === null && projectId) sendRequest();
  }, [re, projectId]);

  useEffect(() => {
    if (re.response && re.response.data && re.response.data.data && re.response.data.data.data) {
      const IGBCdata = re.response.data.data.data.certifications.find((certification) => certification.name === 'IGBC');
      const categories = IGBCdata && IGBCdata.categories;
      let a = IGBCdata.categories.find((d) => d.name === 'Sustainable Design');
      let b = IGBCdata.categories.find((d) => d.name === 'Water Conservation');
      let c = IGBCdata.categories.find((d) => d.name === 'Energy Efficiency');
      let d = IGBCdata.categories.find((d) => d.name === 'Material and Resources');
      let e = IGBCdata.categories.find((d) => d.name === 'Resident Health and Wellbeing');
      let f = IGBCdata.categories.find((d) => d.name === 'Innovation and Design');
      setBargraphdata([a, b, c, d, e, f]);
      setigbcCertificationData(IGBCdata && IGBCdata);
      setDataUploadprogress(IGBCdata && IGBCdata.dataUploadprogress ? IGBCdata.dataUploadprogress : 0);
      setDocumentUploadPercentage(IGBCdata && IGBCdata.overall && IGBCdata.overall.documentUploadPercentage ? IGBCdata.overall.documentUploadPercentage : 0);
      setCategoriesData(categories && categories);
    }
  }, [re]);

  useEffect(() => {
    if (igbcCategoryContext && categoriesData) {
      const data = categoriesData.find((data) => data.name === igbcCategoryContext);
      if (data) setSelectedCategoryData(data && data);
      else setSelectedCategoryData('');
    }
  }, [igbcCategoryContext, categoriesData]);

  return (
    <>
      <Container className={classes.container}>
        <PageTitle title="Building Certification" />
        <IGBCStatus />
        <ComponentTitle title="IGBC Summary" marginbottom={0} />
        <IGBCSummary
          igbcCertificationData={igbcCertificationData && igbcCertificationData}
          dataUploadprogress={dataUploadprogress && dataUploadprogress}
          projectId={projectId}
          bargraphData={bargraphData && bargraphData}
        />
      </Container>
      <Container disableGutters={true}>
        <IGBCCategoriesTogglers
          categories={categories && categories}
          igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
          handleIGBCCategory={(data) => handleIGBCCategory(data)}
        />
      </Container>
      <Container disableGutters={true} style={{ backgroundColor: 'rgba(24, 50, 119, 0.07)', paddingLeft: '2%', paddingRight: '2%' }}>
        <IGBCCategoriesTable
          igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
          selectedCategoryData={selectedCategoryData && selectedCategoryData}
        />
      </Container>
    </>
  );
}
