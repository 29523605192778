import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Grid, Typography } from '@material-ui/core';

const ComponentNavigator = ({ onClick, color, text }) => {
  return (
    <Grid container xs={12} item justifyContent="flex-start" alignItems="center" onClick={onClick}>
      <Grid xs={2} container justifyContent="center" alignItems="center">
        <ChevronLeftIcon style={{ fontSize: '180%', color: color }} />
      </Grid>
      <Grid xs={7}>
        <Typography variant="h5" style={{ color: color }}>
          <b>{text}</b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ComponentNavigator;
