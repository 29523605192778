import React from 'react';
import { Container, Grid, makeStyles, withWidth } from '@material-ui/core';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import HeadAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import CategoriesGrid from './components/CategoriesGrid';
import useScreenType from '../../../../hooks/checkScreenType';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MarketplaceFooter from '../../footer/MarketplaceFooter';

const Categories = (props) => {
  const { width } = props;
  const { isMobile } = useScreenType(width);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(15),
      paddingRight: theme.spacing(!isMobile ? 22 : 2),
      paddingLeft: theme.spacing(!isMobile ? 22 : 2),
      paddingBottom: theme.spacing(!isMobile ? 5 : 3),
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <MarketplaceNavbar />
      <Container maxWidth="lg">
        <Grid style={{ marginTop: '8%', paddingRight: '10%', paddingLeft: '10%' }} container alignItems="flex-start" justifyContent="flex-start">
          <HeadAndBackIcon
            heading="Categories"
            arrowColor={MarketplaceColors.Secondary}
            variant={!isMobile ? 'h4' : null}
            style={MarketplaceTypography.PageHeading}
            iconHeight={isMobile ? '18px' : null}
            iconWidth={isMobile ? '20px' : null}
            bold={true}
            handleClick={() => history.push('/marketplace')}
          />
        </Grid>
        <CategoriesGrid />
      </Container>
      <MarketplaceFooter />
    </React.Fragment>
  );
};

export default withWidth()(Categories);
