import React, { useState } from 'react';
import InquiryNavBar from '../../common/header/InquiryNavBar';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../common/button/CustomButton';
import Fields from '../../common/textfield/Fields';
import { Link } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 826,
    margin: `10% auto`,
    padding: theme.spacing(2),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: '#000000',
  },
  desc: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
    paddingBottom: '3%',
  },
}));

function ForgotPassword() {
  let history = useHistory();
  const classes = useStyles();
  const handleClick = () => history.push('/');
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleEmail = (e) => {
    setError(false);
    setSuccess(false);
    setResponse(false);
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    let body = {
      email: email,
    };
    axios.post(`/users/resetpassword`, body).then((res) => {
      if (res.data && res.data.data && res.data.data.data) {
        if (res.data.data.data === `Invalid Email Address`) {
          setError(true);
        } else if (res.data.data.data === `Please Check Your Inbox`) {
          setSuccess(true);
        }
        setResponse(res.data.data.data);
      }
    });
  };
  return (
    <>
      <InquiryNavBar />
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column" wrap="nowrap" spacing={3}>
          <Grid item xs>
            <Typography className={classes.title}>Can’t access your account?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.desc}>Please enter your registered email address below to recieve password reset link.</Typography>
            <Typography className={classes.desc}>
              You will not be able to reset your password if the provided email addesss does not exist in our database.
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.text}>Registered email address</Typography>
            {error ? (
              <Fields error placeholder="Type Here" value={email} onChange={handleEmail} fullWidth />
            ) : (
              <Fields placeholder="Type Here" value={email} onChange={handleEmail} fullWidth />
            )}

            {response ? (
              <Typography className={classes.text} style={{ color: error ? 'red' : success ? '#26E0A3' : '' }}>
                {response}
              </Typography>
            ) : (
              ''
            )}
            {success && (
              <Typography className={classes.text} style={{ color: '#26E0A3' }}>
                The link will be active for 15 minutes.
              </Typography>
            )}
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid container direction="row" alignItems="center" item xs={12}>
            <Grid item xs={3}>
              {success ? (
                <CustomButton disabled type="submit" variant="contained" onClick={handleSubmit} style={{ borderRadius: '40px' }}>
                  Submit
                </CustomButton>
              ) : (
                <CustomButton type="submit" variant="contained" onClick={handleSubmit} style={{ borderRadius: '40px' }}>
                  Submit
                </CustomButton>
              )}
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={3}>
              <CustomButton type="submit" variant="contained" onClick={handleClick} style={{ borderRadius: '40px' }}>
                Back
              </CustomButton>
            </Grid>
          </Grid>
          <Typography className={classes.text} style={{ paddingTop: '10%' }}>
            Contact
            <span>
              <Link style={{ color: 'blue' }}> connect@sdplus.io</Link>
            </span>{' '}
            if your issue is not resolved
          </Typography>
        </Grid>
      </Paper>
    </>
  );
}

export default ForgotPassword;
