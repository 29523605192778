import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#F28D14',
  },
  top: {
    color: '#E5E5E5',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  down: {
    color: '#ffb958',
    position: 'absolute',
    left: 0,
    marginTop: '-5%',
    marginLeft: '-5%',
    borderRadius: '10px',
  },
}));

export default function EclipseProgress({ newvalue, range, staticvalue }) {
  const classes = useStylesFacebook();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" style={{ color: '#ffb958', borderRadius: '10px' }} value={newvalue} thickness={3} size={70} />
      <CircularProgress variant="static" value={staticvalue} className={classes.top} classes={{ circle: classes.circle }} thickness={1} size={70} />
      <CircularProgress
        variant="static"
        // value={100}
        className={classes.down}
        value={newvalue}
        thickness={3}
        size={78}
      />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h5" component="div" style={{ color: '#F28D14' }}>{`${Math.round(range)}%`}</Typography>
      </Box>
    </Box>
  );
}
