import React from 'react';
import { Grid } from '@material-ui/core';
import CasaSkeleton from './CasaSkeleton';

function Skeletons(props) {
  const { index } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        item
        xs={12}
        key={index}
        style={
          index === 0 ? { height: 'Auto', marginTop: '5%', marginBottom: '4%', marginLeft: '2%' } : { height: 'Auto', marginLeft: '2%', marginBottom: '4%' }
        }
      >
        <Grid alignItems="left" justifyContent="flex-start" item xs={12}>
          <CasaSkeleton variant="rect" height="2vh" width="30%" animation="wave" />
        </Grid>
        <Grid alignItems="left" justifyContent="flex-start" item xs={12} style={{ marginTop: '2%' }}>
          <CasaSkeleton variant="rect" height="2vh" width="40%" animation="wave" />
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" item xs={12} style={{ marginTop: '2%' }}>
          <Grid item xs={2}>
            <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" />
          </Grid>
          <Grid item xs={1}>
            <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Skeletons;
