import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useScreenType from '../../../../hooks/checkScreenType';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { MarketplaceColors, MarketplaceTypography } from '../../config';

const { TagPrimary, TagSecondary } = MarketplaceColors;
const { HeaderSmall } = MarketplaceTypography;

const useStyles = makeStyles((theme) => ({
  cardDesktop: {
    width: '240px',
    height: '320px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
    paddingBottom: '1%',
  },
  cardMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  media: {
    height: 140,
  },
  customText: {
    color: TagPrimary,
    backgroundColor: TagSecondary,
    fontSize: '8.57px',
    padding: '1%',
    cursor: 'pointer',
    width: '25px',
    height: '13px',
  },
  customLongText: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    fontSize: HeaderSmall.fontSize,
    letterSpacing: HeaderSmall.letterSpacing,
    color: MarketplaceColors.TextColorPrimary,
  },
  cardIcon: {
    color: MarketplaceColors.Secondary,
    fontSize: '120%',
    cursor: 'pointer',
  },
}));

function ResourceCardMobile(props) {
  const classes = useStyles();
  const { width, productimage, text, longText } = props;
  const { isMobile } = useScreenType(width);
  return (
    <Card className={isMobile ? classes.cardMobile : classes.cardDesktop}>
      <CardActionArea style={{ padding: '3%' }}>
        <CardMedia className={classes.media} image={productimage} title="Guide" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: '3%' }}>
              <Typography className={classes.customText}>{text}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.customLongText}>
                <b>{longText.slice(0, 55)}...</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid item xs={12} container justifyContent="flex-end" alignItems="flex-end">
          <ArrowRightAltIcon className={classes.cardIcon} />
        </Grid>
      </CardActions>
    </Card>
  );
}

export default withWidth()(ResourceCardMobile);
