import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import surfaceRunoff from '../../../../../assets/new-pdf/dummy.png';

const IntervDataCatd = (props) => {
  return (
    <View>
      {/* common card */}
      <View
        style={{
          border: props.border,
          // height: `${props.waterdata && props.waterdata.data.length * 41.2}px`,
          marginTop: '10px',
          borderRadius: '10px',
        }}
      >
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '15px' }} />
          <View style={{ paddingTop: '13px', width: '8%' }}>
            <Image style={{ height: '25px', width: '25px' }} src={props.icon} />
          </View>
          <View style={{ paddingTop: '16px', width: '38%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>{props.waterdata.intervention}</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>Before</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>After</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Open Sans', fontSize: '9px' }}>Improvement</Text>
          </View>
        </View>
        {props.waterdata.data.map((insideData) => {
          return (
            <View style={{ flexDirection: 'row', display: 'flex' }}>
              <View style={{ width: '15px' }} />
              <View style={{ paddingBottom: '15px', width: '8%' }}></View>
              <View style={{ paddingBottom: '13px', width: '38%' }}>
                <Text style={{ fontFamily: 'Open Sans', fontSize: '9px', color: '#666666' }}>{insideData.name}</Text>
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                <Text style={{ fontFamily: 'Open Sans', fontSize: '9px', color: '#666666' }}>{`${insideData.before} ${
                  insideData.unit !== '-' && insideData.before !== '-' ? insideData.unit : ''
                }`}</Text>
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                <Text style={{ fontFamily: 'Open Sans', fontSize: '9px', color: props.color }}>{`${insideData.after} ${
                  insideData.unit !== '-' && insideData.after !== '-' ? insideData.unit : ''
                }`}</Text>
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                <Text style={{ fontFamily: 'Open Sans', fontSize: '9px', color: props.color }}>{insideData.Improvement}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default IntervDataCatd;
