import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Backdrop, CircularProgress, FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import swal from 'sweetalert';
import useApiRequest from '../../../hooks/useApiRequest';
import axios from '../../../../config/Axios';
import Fields from '../../../common/textfield/Fields';
import PrimaryButton from '../../../common/button/Button';
import { GreenCheckbox } from '../common/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';
import { ExtractStructuredCategoriesData } from './extractStructuredCategoriesData';
import CustomHeader from '../common/CustomHeader';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default function CommonEditCertification(props) {
  const classes = useStyles();
  let history = useHistory();

  const { activeStep, projectId, handleNext, loader, setLoader } = props;

  const prevActiveStep = usePrevious(activeStep);
  const [categoryDetails, setCategoryDetails] = useState('');
  const [categoryData, setCategoryData] = useState('');
  const [myArray, setMyArray] = useState([]);

  const [{ status, response }, makeRequest] = useApiRequest(`/igbc-category?name=${activeStep && activeStep}`, {
    verb: 'get',
  });

  if (status === null && activeStep) makeRequest();

  const appendChanged = (id, value) => {
    const body = { id: id, value: value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  const checkBeforeSaving = (data) => {
    const a =
      data &&
      data.length !== 0 &&
      data.find((d) => d.id === 'area-details-efficient-lifts-and-escelators') &&
      data.find((d) => d.id === 'area-details-efficient-lifts-and-escelators').value;
    const b =
      data &&
      data.length !== 0 &&
      data.find((d) => d.id === 'Efficient-Lifts-and-Escalators') &&
      data.find((d) => d.id === 'Efficient-Lifts-and-Escalators').value;
    if (a === true && !b) {
      return { value: false, error: 'You have not selected any one of the efficient vertical systems from Efficient Lifts & Escalators' };
    } else return { value: true, error: '' };
  };

  const handleSave = (data, projectID) => {
    const { value, error } = checkBeforeSaving(data);
    if (value) {
      const body = { projectID, data };
      axios.get(`iip-category-mapping?projectID=${projectID}`).then((res) => {
        if (res && res.data && res.data.data && res.data.data.data.length === 0) {
          axios.post(`iip-category-mapping`, body).then((rrr) => {
            if (rrr && rrr.data && rrr.data.data) {
              swal({ icon: 'success', title: 'Data Saved' });
              handleNext();
            }
          });
        } else {
          axios.put(`iip-category-mapping`, body).then((fff) => {
            if (fff && fff.data && fff.data.data) {
              swal({ icon: 'success', title: 'Data Updated' });
              handleNext();
            }
          });
        }
      });
    } else swal({ icon: 'error', title: error && error });
  };

  useEffect(() => {
    if (prevActiveStep !== activeStep) makeRequest();
  }, [activeStep, prevActiveStep]);

  useEffect(() => {
    if (projectId && categoryDetails && categoryData) {
      axios.get(`iip-category-mapping?projectID=${projectId}`).then((res) => {
        if (res && res.data && res.data.data && res.data.data.data) setMyArray(res.data.data.data[0].data);
      });
    }
  }, [projectId, categoryDetails, categoryData]);

  useEffect(() => {
    if (response && response.data && response.data.data && response.data.data.data) {
      const cd = response.data.data.data;
      if (cd) {
        axios.get(`igbc-input-parameter?category=${cd && cd._id}`).then((res) => {
          if (res && res.data && res.data.data && res.data.data.data) {
            setCategoryData(res.data.data.data);
            setCategoryDetails(response.data.data.data);
            setLoader(false);
          }
        });
      }
    }
  }, [response]);

  const { save, structuredSectionData } = ExtractStructuredCategoriesData(categoryDetails, categoryData);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      <Grid container justifyContent="flex-start" direction="row" style={{ marginBottom: '3%' }}>
        {structuredSectionData &&
          structuredSectionData.length !== 0 &&
          structuredSectionData.map((section, i) => {
            return (
              <>
                <Grid xs={12} style={{ marginTop: '2%' }} container direction="row" alignItems="center">
                  <CustomHeader sectionName={section && section.sectionName} i={i} history={history} projectId={projectId} />
                  {section.iips &&
                    section.iips.hasOwnProperty('others') &&
                    section.iips.others.map((f) => {
                      if (f.type === 'boolean') {
                        return (
                          <>
                            <Grid xs={12} container style={{ marginTop: '1%' }} key={f.name}>
                              <Grid item xs={0}>
                                <GreenCheckbox
                                  checked={
                                    myArray &&
                                    myArray.length !== 0 &&
                                    myArray.find((d) => d.id === f.name) &&
                                    myArray.find((d) => d.id === f.name).value === true
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => appendChanged(f.name, e.target.checked)}
                                />
                              </Grid>
                              <Grid item xs={11}>
                                <Typography variant="h6" style={{ marginTop: '0.5%', lineHeight: 2 }}>
                                  {f.label}
                                </Typography>
                              </Grid>
                              {f.hasOwnProperty('valueOptions') &&
                                f.valueOptions.length !== 0 &&
                                myArray &&
                                myArray.length !== 0 &&
                                myArray.find((d) => d.id === f.name) &&
                                myArray.find((d) => d.id === f.name).value === true && (
                                  <Grid item xs={12} container style={{ marginTop: '1%', marginLeft: '3%' }}>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={
                                          myArray &&
                                          myArray.length !== 0 &&
                                          myArray.find((d) => d.id === 'Efficient-Lifts-and-Escalators') &&
                                          myArray.find((d) => d.id === 'Efficient-Lifts-and-Escalators').value
                                        }
                                        onChange={(e) => appendChanged('Efficient-Lifts-and-Escalators', e.target.value)}
                                      >
                                        {f.valueOptions.map((d) => {
                                          return (
                                            <>
                                              <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
                                                <FormControlLabel value={d} control={<GreenRadio />} label={d} />
                                              </Grid>
                                            </>
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                )}
                            </Grid>
                          </>
                        );
                      }
                    })}
                  {section.iips &&
                    section.iips.number.map((f) => {
                      return (
                        <>
                          <Grid xs={4} container direction="row" alignItems="center" style={{ marginTop: '1%', marginBottom: '1%' }} key={f.name}>
                            <Grid alignItems="left" item xs={12} style={{ marginBottom: '4%' }}>
                              <Typography variant="h6">{f.label}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Fields
                                key={f.name}
                                placeholder="Enter Value"
                                value={
                                  myArray && myArray.length !== 0 ? myArray.find((t) => t.id === f.name) && myArray.find((t) => t.id === f.name).value : ''
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  appendChanged(f.name, e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1} style={{ paddingLeft: '2%' }}>
                              <Typography variant="h6">{f.unit}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  {section.iips &&
                    section.iips.boolean &&
                    section.iips.boolean.data.map((f, indec) => {
                      return (
                        <>
                          <Grid xs={12} style={indec && indec > 0 ? { marginTop: '1%' } : { marginTop: '1%' }} key={f.name}>
                            <Typography variant="h6">
                              <b>{f.label}</b>
                            </Typography>
                            <br />
                            <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                              {f.description}
                            </Typography>
                          </Grid>
                          {f.data.map((f) => {
                            return (
                              <>
                                <Grid xs={12} container style={{ marginTop: '1%' }} key={f.name}>
                                  <Grid item xs={0}>
                                    <GreenCheckbox
                                      checked={
                                        myArray &&
                                        myArray.length !== 0 &&
                                        myArray.find((d) => d.id === f.name) &&
                                        myArray.find((d) => d.id === f.name).value === true
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => appendChanged(f.name, e.target.checked)}
                                    />
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Typography variant="h6" style={{ marginTop: '0.5%', lineHeight: 2 }}>
                                      {f.label}
                                    </Typography>
                                  </Grid>
                                  {f.hasOwnProperty('valueOptions') &&
                                    f.valueOptions.length !== 0 &&
                                    myArray &&
                                    myArray.length !== 0 &&
                                    myArray.find((d) => d.id === f.name) &&
                                    myArray.find((d) => d.id === f.name).value === true && (
                                      <Grid item xs={12} container style={{ marginTop: '1%', marginLeft: '3%' }}>
                                        <FormControl component="fieldset">
                                          <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={
                                              myArray &&
                                              myArray.length !== 0 &&
                                              myArray.find((d) => d.id === 'Efficient-Lifts-and-Escalators') &&
                                              myArray.find((d) => d.id === 'Efficient-Lifts-and-Escalators').value
                                            }
                                            onChange={(e) => appendChanged('Efficient-Lifts-and-Escalators', e.target.value)}
                                          >
                                            {f.valueOptions.map((d) => {
                                              return (
                                                <>
                                                  <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
                                                    <FormControlLabel value={d} control={<GreenRadio />} label={d} />
                                                  </Grid>
                                                </>
                                              );
                                            })}
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    )}
                                </Grid>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </Grid>
              </>
            );
          })}
      </Grid>
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={2}>
          <PrimaryButton
            onClick={() =>
              save && save !== 'Exit' ? handleSave(myArray && myArray, projectId && projectId) : history.push(`/projects/${projectId}/certification`)
            }
            children={save && save}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton children="Next" onClick={() => handleNext()} />
        </Grid>
      </Grid>
    </>
  );
}
