import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CustomDrawer from '../common/CustomDrawer';
import CustomTable from './components/CustomTable';
import CustomHeader from '../../../common/CustomHeader';
import { Sections } from '../DataTypes';

const DwellingUnitTypes = ({ mount, setMount }) => {
  const history = useHistory();
  const [collapseSection, setCollapseSection] = useState(true);
  const [editTable, setEditTable] = useState(false);
  const [editableData, setEditableData] = useState('');
  const [update, setUpdate] = useState(false);
  const [indexforEdit, setIndexForEditData] = useState('');

  const handleExpandIcon = () => setCollapseSection((prev) => !prev);
  const handleEditableData = (data, type, section) => {
    const body = {};
    body['data'] = data;
    body['type'] = type;
    body['section'] = section;
    setEditableData(body);
  };

  return (
    <>
      {/* Header */}
      <CustomHeader sectionName={Sections.Dwelling_Unit_Types} i={1} history={history} expand={true} handleExpandIcon={handleExpandIcon} />

      {/* Drawer */}
      <CustomDrawer
        customAddButton={true}
        title="ADD DWELLING UNIT TYPE"
        section={Sections.Dwelling_Unit_Types}
        editTable={editTable}
        handleEditTable={(val) => setEditTable(val)}
        editableData={editableData}
        handleEditableData={(d) => setEditableData(d)}
        setUpdate={setUpdate}
        update={update}
        indexforEdit={indexforEdit}
        mount={mount}
        setMount={setMount}
      />
      {/* Table */}
      <Collapse in={collapseSection}>
        <CustomTable
          update={update}
          setUpdate={setUpdate}
          handleEdit={(data, type, index) => {
            setIndexForEditData(index);
            setEditTable(true);
            handleEditableData(data, type, Sections.Dwelling_Unit_Types);
          }}
          mount={mount}
          setMount={setMount}
        />
      </Collapse>
    </>
  );
};

export default DwellingUnitTypes;
