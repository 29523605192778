import React from 'react';
import { withStyles, Checkbox } from '@material-ui/core';

export const GreenCheckbox = withStyles({
  root: {
    marginLeft: -10,
    color: '#2EE1AC',
    '&$checked': {
      color: '#2EE1AC',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
