import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
const TwrCustomAdvanceParameters = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const customPrice = getQuantityValue(schema.twr__pi_customCapex);
  const capexMultiplierSource = getQuantityValue(schema.twr__pi_capexMultiplierSource);
  const userTankerPrice = getQuantityValue(schema.twr__pi_userTankerPrice);
  const stpEfficiency = getQuantityValue(schema.twr__pi_userStpEfficiency);
  const treatedWaterPrice = getQuantityValue(schema.twr__pi_userOffsiteReusePrice);
  return (
    <>
      <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid xs={4}>
          <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">
              <b>Intervention Cost Assumptions</b>
            </Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Tertiary Treatment Capex (per KL)'}
          </Typography>
        </Grid>
        <Grid xs={8} style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(capexMultiplierSource === 'CUSTOM' || capexMultiplierSource === '') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_capexMultiplierSource, 'VENDOR');
                  }}
                />
              )}
              {capexMultiplierSource === 'VENDOR' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_capexMultiplierSource, '');
                  }}
                />
              )}
              <Typography>{getQuantityLabel(schema.twr__pi_capexMultiplierSource)}</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
              <Typography>
                {getQuantityValue(schema.twr_vendorCapex)} {getQuantityUnit(schema.twr_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(capexMultiplierSource === 'VENDOR' || capexMultiplierSource === '') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              {capexMultiplierSource === 'CUSTOM' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_capexMultiplierSource, '');
                  }}
                />
              )}
              <Typography>{getQuantityLabel(schema.twr__pi_customCapex)}</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={customPrice}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.twr__pi_customCapex, +e.target.value);
                }}
                disabled={getQuantityValue(schema.twr__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                disableTextField={getQuantityValue(schema.twr__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
              <Typography style={{ marginLeft: '2%' }}>{getQuantityUnit(schema.twr__pi_customCapex)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4}>
          <Grid xs={12} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">
              <b>Project Cost Assumptions</b>
            </Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {getQuantityLabel(schema.twr__pi_userTankerPrice)}
          </Typography>
          <Grid xs={8} style={{ display: 'flex' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={userTankerPrice}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.twr__pi_userTankerPrice, +e.target.value);
                }}
                onClick={(e) => {
                  updateQuantityValue(schema.twr__pi_userTankerPrice, getQuantityDefaultValue(schema.twr__pi_userTankerPrice));
                }}
              />
              <Typography style={{ marginLeft: '20%' }}>{getQuantityUnit(schema.twr__pi_userTankerPrice)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid xs={4} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">
              <b>Constants</b>
            </Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid xs={8}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {getQuantityLabel(schema.twr__pi_userStpEfficiency)}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '150px' }}>
                <Grid style={{ marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={stpEfficiency}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.twr__pi_userStpEfficiency, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.twr__pi_userStpEfficiency, getQuantityDefaultValue(schema.twr__pi_userStpEfficiency));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {getQuantityLabel(schema.twr__pi_userOffsiteReusePrice)}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '200px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={treatedWaterPrice}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.twr__pi_userOffsiteReusePrice, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.twr__pi_userOffsiteReusePrice, getQuantityDefaultValue(schema.twr__pi_userOffsiteReusePrice));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>{getQuantityUnit(schema.twr__pi_userOffsiteReusePrice)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '7%' }}>
                {getQuantityLabel(schema.twr_coTwoEmissionFactor)}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '3%' }}>
                <Typography>{getQuantityValue(schema.twr_coTwoEmissionFactor) + '          ' + getQuantityUnit(schema.twr_coTwoEmissionFactor)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default TwrCustomAdvanceParameters;
