import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios from '../../../../../../config/Axios';
import swal from 'sweetalert';
// import useApiRequest from '../../../../../hooks/useApiRequest';
// import { useParams } from 'react-router';

const options = ['Edit Note', 'Delete'];

const editNoteStyle = {
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.5',
  color: 'rgba(102, 102, 102, 1)',
};
const deleteNoteStyle = {
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.5',
  color: 'rgba(237, 94, 104, 1)',
};

const ITEM_HEIGHT = 48;

export default function MenuItems({
  noteid,
  fetchAllNotes,
  setHideAddnotes,
  setNote,
  data,
  Uploadedimagesurls,
  setUploadedimageURLS,
  setNoteid,
  statcAssetids,
  drawerContentRef,
  makeapirequest,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option) => {
    if (option === 'Delete') {
      swal({
        title: `Delete this note permanently?`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios.delete(`/note-taking/${noteid}`).then((re) => {
            if (re.data && re.data.data) {
              // makeRequest();
              fetchAllNotes();
              handleClose();
            }
          });
        }
      });
    }
    if (option === 'Edit Note') {
      handleClose();
      setHideAddnotes(false);
      setNote(data?.note);
      data?.staticassetids.map((assets) => statcAssetids.push(assets?._id));
      setUploadedimageURLS(data?.staticassetids);
      setNoteid(data?._id);
      if (drawerContentRef.current) {
        drawerContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
            borderRadius: '4px',
            // boxShadow: "none"
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} style={index === 0 ? editNoteStyle : deleteNoteStyle} selected={option === 'Pyxis'} onClick={(e) => handleMenuClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
