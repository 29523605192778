import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const OutlinedTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `8px`,
        width: 'auto',
        // paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
        },
      },
    },
  },
}))(TextField);

export default OutlinedTextField;
