import React from 'react';
import Carousal from '../../../../common/Carousal/Carousal';
import { Grid, withWidth } from '@material-ui/core';

function CarousalMobile({ rightarrowmobile, leftarrowmobile, arrowStyles, updateCurrentSlide, next, prev, currentSlide }, props) {
  return (
    <Grid
      container
      direction="column"
      item
      xs={12}
      style={{
        paddingBottom: '3%',
        paddingTop: '20%',
      }}
    >
      <Carousal
        next={next && next}
        prev={prev && prev}
        updateCurrentSlide={updateCurrentSlide}
        currentSlide={currentSlide}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <img src={rightarrowmobile} alt="..." onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 1 }} />
        }
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <img src={leftarrowmobile} alt="..." onClick={onClickHandler} title={label} style={{ ...arrowStyles }} />
        }
      />
    </Grid>
  );
}

export default withWidth()(CarousalMobile);
