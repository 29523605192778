import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Links from '../../../../common/Typography/Links';
import { MarketplaceTypography } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  textstyle: MarketplaceTypography.BodyRegular,
}));

const ImageWithSource = ({ source, image, imageStyle, justifyContent }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid xs={12} item container alignItems="flex-start" justifyContent={justifyContent ? justifyContent : 'center'}>
        <img src={image} alt="rrr" style={imageStyle} />
      </Grid>
      <Grid xs={12} item container alignItems="flex-start" justifyContent={justifyContent ? justifyContent : 'flex-start'} style={{ marginTop: '2%' }}>
        <Links
          variant="string"
          href={source}
          tag={source}
          isBold={false}
          style={{ fontStyle: 'italic', textDecoration: 'underline' }}
          className={classes.textstyle}
        />
      </Grid>
    </Grid>
  );
};

export default ImageWithSource;
