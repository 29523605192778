/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Links({ href, tag, onClick, variant, color, style, className, isBold }) {
  return (
    <Typography color={color} style={style} className={className}>
      <Link href={href} onClick={onClick} variant={variant} color="inherit" underline="none">
        {isBold ? <b>{tag}</b> : tag}
      </Link>
    </Typography>
  );
}
