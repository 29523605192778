import { useInterventionDetailsContext } from '../context/InterventionDetails';
import InterventionParametersOverview from '../components/InterventionParametersOverview';
import InterventionResultsOverview from '../components/InterventionResultsOverview';
import InterventionOverview from '../components/InterventionOverview';
import InterventionImpactAndGoals from '../components/InterventionImpactAndGoals';
import { sortResponse } from '../../intervention/utils';

let material = ['#6300A5', '#BF55FF', '#DA9AFF', '#A744E4', '#8F34CC', '#7724B5', '#60189E', '#4B0E89'];
let waste = ['#F3DDC8', '#E29D69', '#BC8A5F', '#DF530D', '#C24100', '#9E3600', '#7B2B00', '#592200', '#3D1600', '#231000'];
let water = ['#CAF0F8', '#90E0EF', '#00B4D8', '#0077B6', '#03045E', '#005a9e', '#004280', '#002d63', '#001847', '#000d2b'];
let energy = ['#FFC971CC', '#F9C74F', '#F9844A', '#F94144', '#C32F27', '#9F2622', '#7C1E1C', '#591614', '#3A0E0E', '#200808'];

// TODO: Rename all releavant components and Simplify props
const InterventionDetailsSummary = () => {
  const { intervention: interventiondetails, meta, key } = useInterventionDetailsContext();
  const { quantities, capexTotalValue, capexTotalConvertedValue, customizable, mandateSummary, result1, result2, sdgNumbers, sdgText, capexConverted } = meta;
  const reorderedInput = sortResponse(customizable, key);
  return (
    <div>
      <InterventionParametersOverview
        title={'Intervention Parameters'}
        inputs={reorderedInput}
        mandateSummary={mandateSummary}
        selectedInterventionDetails={quantities}
      />
      <InterventionResultsOverview
        capexTotalValue={capexTotalValue}
        capexTotalConvertedValue={capexTotalConvertedValue}
        title1={'Intervention Results'}
        title2={'Show CAPEX(total)'}
        capexConverted={capexConverted}
        result1={result1}
        result2={result2}
        selectedInterventionDetails={quantities}
        sdgNumbers={sdgNumbers && sdgNumbers}
        sdgText={sdgText && sdgText}
      />

      {/* TODO: colors should not directly map with intervention name, need to fix it */}
      <InterventionOverview
        title={'Overview'}
        inputs={quantities}
        name={interventiondetails?.name}
        circlecolor={
          interventiondetails &&
          (interventiondetails?.name === 'Solar PV' ||
            interventiondetails?.name === 'Solar Water Heater' ||
            // interventiondetails.name === 'Hot Water Management Apartment' ||
            interventiondetails.name === 'Hot Water Management Villa',
          interventiondetails.name === 'Treated Water Reuse')
            ? '#F28D14'
            : interventiondetails?.name === 'Wet Waste Management' ||
              interventiondetails?.name === 'Sanitary Napkin Incinerator' ||
              interventiondetails?.name === 'Collection & Segregation Point'
            ? '#BC8A5F'
            : interventiondetails?.name === 'Exterior Paints'
            ? '#BF55FF'
            : '#91E6FA'
        }
        textcolor={
          interventiondetails &&
          (interventiondetails?.name === 'Solar PV' ||
            interventiondetails?.name === 'Solar Water Heater' ||
            interventiondetails.name === 'Hot Water Management Villa')
            ? '#F28D14'
            : interventiondetails?.name === 'Wet Waste Management' ||
              interventiondetails?.name === 'Sanitary Napkin Incinerator' ||
              interventiondetails?.name === 'Collection & Segregation Point'
            ? '#BC8A5F'
            : interventiondetails?.name === 'Exterior Paints'
            ? '#BF55FF'
            : '#91E6FA'
        }
        color={
          interventiondetails &&
          (interventiondetails?.name === 'Solar PV' ||
            interventiondetails?.name === 'Solar Water Heater' ||
            interventiondetails?.name === 'Hot Water Management Villa')
            ? // || interventiondetails.name === 'Hot Water Management Apartment'
              energy
            : interventiondetails?.name === 'Wet Waste Management' ||
              interventiondetails?.name === 'Sanitary Napkin Incinerator' ||
              interventiondetails?.name === 'Collection & Segregation Point'
            ? waste
            : interventiondetails?.name === 'Exterior Paints'
            ? material
            : water
        }
      />

      {/* TODO: colors should not directly map with intervention name, need to fix it */}
      <InterventionImpactAndGoals
        title1={'Secondary Impact'}
        title2={'Contribution to Goals'}
        inputs={quantities}
        goalcontributioncolor={
          interventiondetails &&
          (interventiondetails?.name === 'Solar PV' ||
            interventiondetails?.name === 'Solar Water Heater' ||
            interventiondetails?.name === 'Hot Water Management Villa')
            ? // || interventiondetails.name === 'Hot Water Management Apartment'
              '#F28D14'
            : interventiondetails?.name === 'Wet Waste Management' ||
              interventiondetails?.name === 'Sanitary Napkin Incinerator' ||
              interventiondetails?.name === 'Collection & Segregation Point '
            ? '#BC8A5F'
            : '#91E6FA'
        }
      />
    </div>
  );
};

export default InterventionDetailsSummary;
